import { useContext } from 'react';

import { InvestigationContext } from './InvestigationContext';
import InvestigationProvider from './InvestigationProvider';

const useInvestigationContext = () => {
  const context = useContext(InvestigationContext);

  if (typeof context === 'undefined' || context === null) {
    throw new Error(
      'useInvestigationContext must be used within a InvestigationProvider',
    );
  }

  return context;
};

export default useInvestigationContext;
export { InvestigationProvider };
