import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { NotificationPopupVariants } from 'components/common/NotificationPopup/types';
import routePaths from 'constants/routePaths';
import useApi from 'contexts/api';
import useAuth from 'contexts/auth';
import useGlobalLoader from 'contexts/globalLoader';
import useResponsePopup from 'contexts/responsePopup';
import useUser from 'contexts/user';
import { useResendEmailVerification } from 'utils/hooks/useResendEmailVerification';

export const EmailVerification = () => {
  const { t } = useTranslation();
  const { dataExists } = useAuth();
  const { emailVerificationControllerApi } = useApi();
  const { refresh } = useUser();
  const { openPopup, closePopup } = useResponsePopup();
  const { showLoader, hideLoader } = useGlobalLoader();
  const navigate = useNavigate();
  const { verificationToken } = useParams();
  const [linkSent, setLinkSent] = useState(false);

  const handleCloseVerificationNavigateToUpdateProfile = useCallback(() => {
    closePopup();
    navigate(routePaths.updateProfile);
  }, [closePopup, navigate]);

  const [isResendVerificationLinkAvailable, handleResendVerificationLink] =
    useResendEmailVerification(
      handleCloseVerificationNavigateToUpdateProfile,
      false,
    );

  const verifyEmail = useCallback(async () => {
    if (!verificationToken || linkSent) return;

    try {
      showLoader();
      await emailVerificationControllerApi.verifyEmail({
        token: verificationToken,
      });

      await refresh();
      openPopup({
        variant: NotificationPopupVariants.Success,
        title: t('pages.emailVerification.verificationSuccess'),
        primaryButton: {
          text: t('buttons.continueSession'),
          onClick: handleCloseVerificationNavigateToUpdateProfile,
        },
        allowPopupClose: false,
      });
    } catch (e) {
      if (dataExists) {
        openPopup({
          variant: NotificationPopupVariants.Warning,
          title: t`pages.emailVerification.verificationLinkExpired`,
          subtitle: t`pages.emailVerification.verificationLinkExpiredSubtitle`,
          ...(isResendVerificationLinkAvailable && {
            primaryButton: {
              text: t`buttons.resendVerificationLink`,
              onClick: handleResendVerificationLink,
            },
          }),
          allowPopupClose: false,
        });
      } else {
        openPopup({
          variant: NotificationPopupVariants.Warning,
          title: t`pages.emailVerification.verificationLinkExpired`,
          subtitle: t`pages.emailVerification.verificationLinkExpiredSubtitleUnauthorized`,
          primaryButton: {
            text: t`buttons.signIn`,
            onClick: handleCloseVerificationNavigateToUpdateProfile,
          },
          allowPopupClose: false,
        });
      }
    } finally {
      setLinkSent(true);
      hideLoader();
    }
  }, [
    dataExists,
    emailVerificationControllerApi,
    handleCloseVerificationNavigateToUpdateProfile,
    handleResendVerificationLink,
    hideLoader,
    isResendVerificationLinkAvailable,
    linkSent,
    openPopup,
    showLoader,
    t,
    verificationToken,
    refresh,
  ]);

  useEffect(() => {
    verifyEmail();
  }, [verifyEmail]);

  return <></>;
};
