import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

export const BottomPartContainer = styled(Grid)(({ theme }) => ({
  flexGrow: 1,

  backgroundColor: theme.palette.white.main,
  borderRadius: '25px 25px 0 0',
  padding: theme.spacing(7.5, 7.5, 10, 7.5),
  [theme.breakpoints.down('desktop')]: {
    padding: theme.spacing(5, 3, 10, 3),
  },
}));
