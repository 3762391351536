import { useCallback, useEffect, useRef, useState } from 'react';

interface UseAsyncResourceParams<T, D extends T | undefined> {
  fetchResource: () => Promise<T> | void;
  defaultValue?: D;
  pullingInterval: number;
}

export function useAsyncResourceWithPulling<T, D extends T | undefined>({
  defaultValue,
  fetchResource,
  pullingInterval,
}: UseAsyncResourceParams<T, D>) {
  type Resource = D extends T ? T : T | undefined;
  const firstTime = useRef(true);

  const [resource, setResource] = useState<Resource>(defaultValue as Resource);
  const [isLoading, setIsLoading] = useState(true);

  const fetch = useCallback(
    async (silent = false) => {
      if (!silent) {
        setIsLoading(true);
      }
      firstTime.current = false;
      try {
        const resp = await fetchResource();

        setResource(resp as Resource);
      } finally {
        setIsLoading(false);
      }
    },
    [fetchResource],
  );

  useEffect(() => {
    fetch();
  }, [fetch]);

  useEffect(() => {
    const id = setInterval(() => {
      fetch(true);
    }, pullingInterval * 1000);

    return () => clearInterval(id);
  }, [fetch, pullingInterval]);

  return { resource, isLoading, setResource, fetch };
}
