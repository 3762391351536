import { Box, Grid, Typography } from '@mui/material';
import axios from 'axios';
import { useCallback, useMemo, useState } from 'react';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import CreatedUserWrapper from './styles/CreatedUserWrapper';
import useApi from '../../../contexts/api';
import { SelectItem } from '../../../types/general';
import { useAsyncResource } from '../../../utils/hooks/useAsyncResource';
import CommonButton from '../CommonButton';
import CopyButton from '../CopyButton';
import CopyIcon from '../CopyIcon';
import { FormSelect } from '../form/Select';
import TextField from '../form/TextField';
import OrHorizontal from '../OrHorizontal';
import {
  CustomerCreateResponseDto,
  CustomerDto,
} from 'openapi-api/admin-service';
import { getUserCredentialsCopyText } from 'utils/functions/getUserCredentialsCopyText';

type Props = {
  setUserIsCreated: (status: boolean) => void;
};

const SelectOrCreateCustomer = ({ setUserIsCreated }: Props) => {
  const { customerControllerApi } = useApi();
  const { t } = useTranslation();
  const { setValue, trigger, watch, clearErrors, getValues } = useFormContext();
  const getCustomers = useCallback(async () => {
    try {
      const response = await customerControllerApi.allCustomers();

      return response.data as CustomerDto[];
    } catch {}
  }, [customerControllerApi]);

  const [createdUser, setCreatedUser] =
    useState<CustomerCreateResponseDto | null>(null);

  const clearCreatedUser = useCallback(() => {
    setUserIsCreated(false);
    setCreatedUser(null);
  }, [setUserIsCreated]);

  const newCustomer = watch('newCustomer');

  const { resource: customers } = useAsyncResource({
    fetchResource: getCustomers,
  });

  const selectItems: SelectItem[] = useMemo(
    () =>
      customers?.map(({ name }) => ({
        label: name as string,
        value: name as string,
      })) || [],
    [customers],
  );

  const createUser = useCallback(async () => {
    try {
      const isFieldValid = await trigger('newCustomer');
      if (!isFieldValid) return;
      const name = getValues('newCustomer');
      const { data } = await customerControllerApi.createCustomer({
        customerCreateRequestDto: { name },
      });
      setCreatedUser(data);
      setUserIsCreated(true);
    } catch (e) {
      if (axios.isAxiosError(e) && e.response?.data.message) {
        toast.error(e.response.data.message);
      }
    }
  }, [trigger, getValues, customerControllerApi, setUserIsCreated]);

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <FormSelect
        name="existingCustomer"
        label={t`username`}
        items={selectItems}
        fullWidth
        dataTestId="selectCustomer"
        onItemClick={() => {
          setValue('newCustomer', '');
          clearErrors('newCustomer');
          clearCreatedUser();
        }}
      />
      <OrHorizontal />
      <Typography variant="h4">{t`components.assetOnboarding.createUser`}</Typography>
      <Box display="flex" flexDirection="column" gap={1.5}>
        <TextField
          label={t`username`}
          fullWidth
          onInput={() => {
            setValue('existingCustomer', '');
            clearErrors('existingCustomer');
            clearCreatedUser();
          }}
          testId="newCustomer"
          name="newCustomer"
        />

        {createdUser && (
          <CreatedUserWrapper>
            <Grid
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid display="flex" flexDirection="column" gap={1}>
                <Typography
                  variant="bodyS"
                  color="grey.800"
                >{t`username`}</Typography>
                <Typography variant="bodyMStrong">
                  {createdUser.name}
                </Typography>
              </Grid>
              <CopyIcon text={createdUser.name} />
            </Grid>
            <Grid
              display="flex"
              justifyContent="space-between"
              gap={3}
              alignItems="center"
            >
              <Grid display="flex" flexDirection="column" gap={1}>
                <Typography
                  variant="bodyS"
                  color="grey.800"
                >{t`password`}</Typography>
                <Typography variant="bodyMStrong">
                  {createdUser.password}
                </Typography>
              </Grid>
              <CopyIcon text={createdUser.password} />
            </Grid>
            <CopyButton text={getUserCredentialsCopyText(createdUser)} />
          </CreatedUserWrapper>
        )}
      </Box>

      {!!newCustomer && !createdUser && (
        <CommonButton
          variant="contained"
          onClick={createUser}
          sx={{ width: '100%' }}
        >
          {t`components.assetOnboarding.generateCreds`}
        </CommonButton>
      )}
    </Box>
  );
};
export default SelectOrCreateCustomer;
