import { useContext } from 'react';

import GlobalLoaderContext from './Context';
import GlobalLoaderProvider from './Provider';

const useGlobalLoader = () => {
  const context = useContext(GlobalLoaderContext);

  if (typeof context === 'undefined' || context === null) {
    throw new Error(
      'useGlobalLoader must be used within an GlobalLoaderProvider',
    );
  }

  return context;
};

export default useGlobalLoader;
export { GlobalLoaderProvider };
