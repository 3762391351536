import { Tooltip } from '@mui/material';
import Typography from '@mui/material/Typography';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { OperationalStatusWrapper } from './styles';
import { WindTurbineDtoOperationalStatusEnum } from 'openapi-api/admin-service';

export const OperationalStatusChip: FC<{
  operationalStatus: WindTurbineDtoOperationalStatusEnum;
}> = ({ operationalStatus }) => {
  const { t } = useTranslation();
  const text =
    operationalStatus === WindTurbineDtoOperationalStatusEnum.ACTIVATED
      ? t('active')
      : t('inactive');

  return (
    <OperationalStatusWrapper operationalStatus={operationalStatus}>
      <Tooltip title={text}>
        <Typography
          variant="bodyS"
          sx={{
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
        >
          {text}
        </Typography>
      </Tooltip>
    </OperationalStatusWrapper>
  );
};
