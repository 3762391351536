import { TablePagination as MUITablePagination, SxProps } from '@mui/material';
import { GridPaginationModel } from '@mui/x-data-grid';
import { FC, useCallback } from 'react';

import { Wrapper } from './styles';
import { TablePaginationVariants } from './types';
import { ChevronDownL } from 'components/icons';

interface Props {
  count?: number;
  page?: number;
  rowsPerPage?: number;
  rowsPerPageOptions: {
    label: string;
    value: number;
  }[];
  onPaginationModelChange?: (paginationModel: GridPaginationModel) => void;
  variant?: TablePaginationVariants;
  sx?: SxProps;
}

export const TablePagination: FC<Props> = ({
  count = 0,
  page = 0,
  rowsPerPage = 20,
  rowsPerPageOptions,
  onPaginationModelChange,
  variant = TablePaginationVariants.TABLE,
  sx,
}) => {
  const onPageChange = useCallback(
    (_: any, pageNumber: number) => {
      window.scrollTo({ top: 0 });
      onPaginationModelChange?.({ page: pageNumber, pageSize: rowsPerPage });
    },
    [onPaginationModelChange, rowsPerPage],
  );

  const onRowsPerPageChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      onPaginationModelChange?.({
        page: 0,
        pageSize: +e.target.value,
      });
    },
    [onPaginationModelChange],
  );

  return (
    <Wrapper variant={variant} sx={sx}>
      <MUITablePagination
        component="div"
        count={count}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={rowsPerPageOptions}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        slotProps={{ select: { IconComponent: ChevronDownL } }}
      />
    </Wrapper>
  );
};
