import * as yup from 'yup';

import { TranslationFunction } from 'types/general';

const validationSchema = (t: TranslationFunction) =>
  yup.object({
    username: yup.string().required(t('form.errors.fieldRequired')),
    password: yup.string().required(t('form.errors.fieldRequired')),
  });

export default validationSchema;
