import { useContext } from 'react';

import { NotificationsContext } from './NotificationsContext';
import NotificationsProvider from './NotificationsProvider';

const useNotificationsContext = () => {
  const context = useContext(NotificationsContext);

  if (typeof context === 'undefined' || context === null) {
    throw new Error(
      'useNotificationsContext must be used within an NotificationsProvider',
    );
  }

  return context;
};

export default useNotificationsContext;
export { NotificationsProvider };
