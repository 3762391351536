import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { FC, useMemo } from 'react';

import { CheckMarkIcon, RedCrossIcon } from './styles';

interface Props {
  value: string;
  regExp: RegExp;
  title: string | null;
}

export const ValidationLine: FC<Props> = ({ title, regExp, value }) => {
  const icon = useMemo(() => {
    if (!value) return <CheckMarkIcon />;
    return regExp.test(value) ? (
      <CheckMarkIcon isActive />
    ) : (
      <RedCrossIcon data-testid="red-cross-icon" />
    );
  }, [value, regExp]);

  return (
    <Grid
      gap={1.5}
      marginBottom={1}
      display="grid"
      gridTemplateColumns="24px 1fr"
      alignItems="center"
    >
      {icon}
      <Typography variant="bodyXS">{title}</Typography>
    </Grid>
  );
};
