import { Divider, Grid, Typography } from '@mui/material';
import { ChangeEvent, FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { WindTurbineControlWrapper } from './styles';
import Switch from 'components/common/form/Switch';

interface WindTurbineControlProps {
  name: string;
  value?: boolean;
  onClick: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  dataTestId?: string;
  children?: ReactElement;
}

export const WindTurbineControl: FC<WindTurbineControlProps> = ({
  name,
  value,
  onClick,
  dataTestId,
  children,
}) => {
  const { t } = useTranslation();

  return (
    <WindTurbineControlWrapper container direction="column">
      <Grid
        container
        item
        justifyContent="space-between"
        alignItems="center"
        flexGrow={1}
        wrap="nowrap"
      >
        <Typography variant="h4" color="black.600">
          {name}
        </Typography>
        <Switch checked={value} onChange={onClick} data-testid={dataTestId} />
      </Grid>
      <Divider sx={{ my: 2 }} />
      <Grid
        container
        item
        alignItems="center"
        maxWidth="100%"
        flexWrap="nowrap"
      >
        <Typography variant="subheading" color="black.600" mr={1.5}>
          {t('pages.turbine.status')}
        </Typography>
        {children}
      </Grid>
    </WindTurbineControlWrapper>
  );
};
