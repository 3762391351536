import { Typography } from '@mui/material';
import { FC } from 'react';
import { Trans } from 'react-i18next';

import { WTLink } from './WTLink';
import { AssetConnectionErrorDto } from 'openapi-api/admin-service';
import { getStringFromISODate } from 'utils/functions/getStringFromISODate';
import { getWindTurbinePageLink } from 'utils/functions/getWindTurbinePageLink';

interface Props {
  details: AssetConnectionErrorDto;
  extended: boolean;
  locationId?: number | string;
}

const TemplateAssetConnectionError: FC<Props> = ({
  details,
  extended,
  locationId,
}) => {
  const { publicId, eventDateTime, assetNameForAdmin } = details;

  if (!publicId) {
    return null;
  }

  return (
    <>
      <Typography variant={extended ? 'bodyM' : 'bodyS'}>
        <Trans
          i18nKey="notifications.assetConnectionError"
          values={{ eventDateTime: getStringFromISODate(eventDateTime) }}
          components={[
            <WTLink
              key={`assetConnectionError-link-${publicId}`}
              link={
                getWindTurbinePageLink({
                  assetLocationId: locationId,
                  assetPublicId: publicId,
                }).statuses
              }
              publicId={publicId}
              linkText={assetNameForAdmin}
              newTab={extended}
            />,
          ]}
        />
      </Typography>
    </>
  );
};

export default TemplateAssetConnectionError;
