/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { PageRestorationRequestDto } from '../models';
// @ts-ignore
import { RestorationRequestCreateDto } from '../models';
// @ts-ignore
import { RestorationRequestDto } from '../models';
/**
 * RestorationRequestControllerApi - axios parameter creator
 * @export
 */
export const RestorationRequestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {RestorationRequestCreateDto} restorationRequestCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRestorationRequest: async (restorationRequestCreateDto: RestorationRequestCreateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'restorationRequestCreateDto' is not null or undefined
            assertParamExists('createRestorationRequest', 'restorationRequestCreateDto', restorationRequestCreateDto)
            const localVarPath = `/restoration/request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(restorationRequestCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRestorationRequestById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getRestorationRequestById', 'id', id)
            const localVarPath = `/restoration/request/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRestorationRequests: async (page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/restoration/request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} restorationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetRestorationRequest: async (restorationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'restorationId' is not null or undefined
            assertParamExists('resetRestorationRequest', 'restorationId', restorationId)
            const localVarPath = `/restoration/request/{restorationId}/reset`
                .replace(`{${"restorationId"}}`, encodeURIComponent(String(restorationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RestorationRequestControllerApi - functional programming interface
 * @export
 */
export const RestorationRequestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RestorationRequestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {RestorationRequestCreateDto} restorationRequestCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRestorationRequest(restorationRequestCreateDto: RestorationRequestCreateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRestorationRequest(restorationRequestCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRestorationRequestById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestorationRequestDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRestorationRequestById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRestorationRequests(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageRestorationRequestDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRestorationRequests(page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} restorationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetRestorationRequest(restorationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetRestorationRequest(restorationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RestorationRequestControllerApi - factory interface
 * @export
 */
export const RestorationRequestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RestorationRequestControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {RestorationRequestControllerApiCreateRestorationRequestRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRestorationRequest(requestParameters: RestorationRequestControllerApiCreateRestorationRequestRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.createRestorationRequest(requestParameters.restorationRequestCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RestorationRequestControllerApiGetRestorationRequestByIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRestorationRequestById(requestParameters: RestorationRequestControllerApiGetRestorationRequestByIdRequest, options?: AxiosRequestConfig): AxiosPromise<RestorationRequestDto> {
            return localVarFp.getRestorationRequestById(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RestorationRequestControllerApiGetRestorationRequestsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRestorationRequests(requestParameters: RestorationRequestControllerApiGetRestorationRequestsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<PageRestorationRequestDto> {
            return localVarFp.getRestorationRequests(requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RestorationRequestControllerApiResetRestorationRequestRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetRestorationRequest(requestParameters: RestorationRequestControllerApiResetRestorationRequestRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.resetRestorationRequest(requestParameters.restorationId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createRestorationRequest operation in RestorationRequestControllerApi.
 * @export
 * @interface RestorationRequestControllerApiCreateRestorationRequestRequest
 */
export interface RestorationRequestControllerApiCreateRestorationRequestRequest {
    /**
     * 
     * @type {RestorationRequestCreateDto}
     * @memberof RestorationRequestControllerApiCreateRestorationRequest
     */
    readonly restorationRequestCreateDto: RestorationRequestCreateDto
}

/**
 * Request parameters for getRestorationRequestById operation in RestorationRequestControllerApi.
 * @export
 * @interface RestorationRequestControllerApiGetRestorationRequestByIdRequest
 */
export interface RestorationRequestControllerApiGetRestorationRequestByIdRequest {
    /**
     * 
     * @type {number}
     * @memberof RestorationRequestControllerApiGetRestorationRequestById
     */
    readonly id: number
}

/**
 * Request parameters for getRestorationRequests operation in RestorationRequestControllerApi.
 * @export
 * @interface RestorationRequestControllerApiGetRestorationRequestsRequest
 */
export interface RestorationRequestControllerApiGetRestorationRequestsRequest {
    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof RestorationRequestControllerApiGetRestorationRequests
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof RestorationRequestControllerApiGetRestorationRequests
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof RestorationRequestControllerApiGetRestorationRequests
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for resetRestorationRequest operation in RestorationRequestControllerApi.
 * @export
 * @interface RestorationRequestControllerApiResetRestorationRequestRequest
 */
export interface RestorationRequestControllerApiResetRestorationRequestRequest {
    /**
     * 
     * @type {string}
     * @memberof RestorationRequestControllerApiResetRestorationRequest
     */
    readonly restorationId: string
}

/**
 * RestorationRequestControllerApi - object-oriented interface
 * @export
 * @class RestorationRequestControllerApi
 * @extends {BaseAPI}
 */
export class RestorationRequestControllerApi extends BaseAPI {
    /**
     * 
     * @param {RestorationRequestControllerApiCreateRestorationRequestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RestorationRequestControllerApi
     */
    public createRestorationRequest(requestParameters: RestorationRequestControllerApiCreateRestorationRequestRequest, options?: AxiosRequestConfig) {
        return RestorationRequestControllerApiFp(this.configuration).createRestorationRequest(requestParameters.restorationRequestCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RestorationRequestControllerApiGetRestorationRequestByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RestorationRequestControllerApi
     */
    public getRestorationRequestById(requestParameters: RestorationRequestControllerApiGetRestorationRequestByIdRequest, options?: AxiosRequestConfig) {
        return RestorationRequestControllerApiFp(this.configuration).getRestorationRequestById(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RestorationRequestControllerApiGetRestorationRequestsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RestorationRequestControllerApi
     */
    public getRestorationRequests(requestParameters: RestorationRequestControllerApiGetRestorationRequestsRequest = {}, options?: AxiosRequestConfig) {
        return RestorationRequestControllerApiFp(this.configuration).getRestorationRequests(requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RestorationRequestControllerApiResetRestorationRequestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RestorationRequestControllerApi
     */
    public resetRestorationRequest(requestParameters: RestorationRequestControllerApiResetRestorationRequestRequest, options?: AxiosRequestConfig) {
        return RestorationRequestControllerApiFp(this.configuration).resetRestorationRequest(requestParameters.restorationId, options).then((request) => request(this.axios, this.basePath));
    }
}
