import { alpha, styled } from '@mui/material';

export const AppliedFilterItem = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  padding: theme.spacing(1, 2.5),
  border: `1px solid ${theme.palette.grey[300]}`,
  borderRadius: '20px',
  backgroundColor: alpha(theme.palette.grey[900], 0.05),
}));
