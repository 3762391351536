import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import {
  StatusFilterItem,
  StatusFilterItemCircle,
  StatusFilterWrapper,
} from './styles';
import { IStatusFilterProps, StatusFilterValues } from './types';
import { parksFilterByStatusName } from '../../constants';

export const StatusFilter: FC<IStatusFilterProps> = ({
  counters,
  onFilterModelChange,
}) => {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.only('desktop'));

  const onItemClick = useCallback(
    (value: StatusFilterValues) => {
      if (value === StatusFilterValues.TOTAL) {
        onFilterModelChange({
          field: parksFilterByStatusName,
          value: undefined,
        });
        return;
      }

      onFilterModelChange({ field: parksFilterByStatusName, value });
    },
    [onFilterModelChange],
  );

  const filterByStatusValue = useMemo(
    () => searchParams.get(parksFilterByStatusName) || StatusFilterValues.TOTAL,
    [searchParams],
  );

  const itemsToRender = useMemo(
    () =>
      [
        {
          label: isDesktop
            ? t('pages.parks.counters.total')
            : t('pages.parks.counters.totalShort'),
          value: StatusFilterValues.TOTAL,
          count: counters?.locations,
        },
        {
          label: 'active',
          value: StatusFilterValues.ACTIVE,
          count: counters?.activeStatus,
        },
        {
          label: 'inactive',
          value: StatusFilterValues.INACTIVE,
          count: counters?.inactiveStatus,
        },
        {
          label: 'error',
          value: StatusFilterValues.ERROR,
          count: counters?.errorStatus,
        },
      ].map(({ label, value: itemValue, count }) => (
        <StatusFilterItem
          key={itemValue}
          isActive={filterByStatusValue === itemValue}
          onClick={() => onItemClick(itemValue)}
        >
          <Grid gap={1} container>
            <StatusFilterItemCircle filterValue={itemValue} />
            <Typography variant="subheading">{label}</Typography>
          </Grid>
          <Typography variant="h3">{count}</Typography>
        </StatusFilterItem>
      )),
    [t, isDesktop, counters, filterByStatusValue, onItemClick],
  );

  return <StatusFilterWrapper>{itemsToRender}</StatusFilterWrapper>;
};
