import { styled } from '@mui/material/styles';

export const UserDataWrapper = styled('div')(({ theme }) => ({
  borderRadius: theme.spacing(1.5),
  padding: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  backgroundColor: theme.palette.grey[900],
  width: '100%',
  textAlign: 'left',
}));
