import { styled } from '@mui/material';

export const WeatherHourlyWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3.5),
  padding: theme.spacing(3, 4),
  borderRadius: '20px',
  backgroundColor: theme.palette.grey[50],

  [theme.breakpoints.down('desktop')]: {
    gap: theme.spacing(2.5),
    padding: theme.spacing(3),
  },

  [theme.breakpoints.down('tablet')]: {
    width: '100%',
  },
}));
