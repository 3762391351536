import { FC, useCallback } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { CustomSelectProps } from './types';
import { Select } from 'components/common/Select';

export const FormSelect: FC<CustomSelectProps> = ({
  name,
  label,
  items,
  helperText,
  onItemClick,
  fullWidth,
  multiple,
  ...otherProps
}) => {
  const { control, setValue } = useFormContext();
  const {
    field,
    fieldState: { error },
  } = useController({ control, name });
  const helperTextValue = error?.message || helperText;

  const handleItemClick = useCallback(
    (value: string) => {
      if (!multiple) {
        setValue(name, value, { shouldDirty: true, shouldValidate: true });
        onItemClick?.(value);
        return;
      }

      const isValueExist = field.value.includes(value);
      const newValue = isValueExist
        ? field.value.filter((item: string) => item !== value)
        : [...field.value, value];
      setValue(name, newValue, { shouldDirty: true, shouldValidate: true });
      onItemClick?.(value);
    },
    [name, multiple, field.value, setValue, onItemClick],
  );

  return (
    <Select
      {...field}
      name={name}
      label={label}
      items={items}
      onItemClick={handleItemClick}
      multiple={multiple}
      fullWidth={fullWidth}
      helperText={helperTextValue}
      error={!!error}
      {...otherProps}
    />
  );
};
