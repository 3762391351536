import { styled } from '@mui/material';

export const WindTurbineChartWrapper = styled('div')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  gap: theme.spacing(3),
  padding: theme.spacing(3),
  borderRadius: '25px',
  boxShadow:
    '0px 5px 10px rgba(30, 34, 40, 0.10), 0px 0px 1px rgba(30, 34, 40, 0.08)',
  backgroundColor: theme.palette.white.main,
  overflow: 'hidden',

  [theme.breakpoints.down('tablet')]: {
    margin: theme.spacing(0, -2.5),
  },
}));
