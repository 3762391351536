import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  notificationsPopupTabs,
  getTabIndicatorColorMap,
  tabTitleTranslationMap,
} from './constants';
import {
  CloseButton,
  NotificationsBlockHeaderWrapper,
  NotificationsPopover,
  NotificationsWrapper,
  TabsSectionStyled,
} from './styles';
import { NotificationsList } from '../NotificationsList';
import { ITab } from 'components/common/TabsSection/TabsSection';
import { Close } from 'components/icons';
import useNotificationsContext from 'contexts/notifications';
import { NotificationDtoTypeEnum } from 'openapi-api/admin-service';

export const NotificationsPopup: FC<{ anchorEl: HTMLElement | null }> = ({
  anchorEl,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    isNotificationsPopupOpened,
    initialTab,
    closeNotificationsPopup,
    notifications,
    loadNotifications,
    readNotification,
  } = useNotificationsContext();

  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down('desktop'));

  const indicatorColorMap = useMemo(
    () => getTabIndicatorColorMap(theme),
    [theme],
  );

  const tabs: ITab[] = useMemo(
    () =>
      notificationsPopupTabs.map((key) => ({
        label: (
          <Typography variant="bodyMStrong">
            {t(tabTitleTranslationMap[key])}
          </Typography>
        ),
        value: (
          <NotificationsList
            notifications={notifications}
            handleNotificationClick={readNotification}
          />
        ),
        indicatorColor: indicatorColorMap[key],
        key: key,
      })),
    [indicatorColorMap, notifications, readNotification, t],
  );

  const handleChangeTab = useCallback(
    (tab: ITab) => {
      loadNotifications(tab.key as NotificationDtoTypeEnum);
    },
    [loadNotifications],
  );

  return (
    <NotificationsPopover
      anchorEl={anchorEl}
      open={!!isNotificationsPopupOpened}
      onClose={closeNotificationsPopup}
      anchorReference={isTabletOrMobile ? 'anchorPosition' : 'anchorEl'}
      anchorPosition={{ left: 0, top: 0 }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: isTabletOrMobile ? 0 : -8,
        horizontal: 'right',
      }}
      transitionDuration={isTabletOrMobile ? 0 : 'auto'}
      marginThreshold={isTabletOrMobile ? 0 : 16}
    >
      <NotificationsWrapper>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            maxHeight: { desktop: '510px' },
          }}
        >
          <NotificationsBlockHeaderWrapper>
            <Typography variant="h4" color="black.900">
              {t('notifications.title')}
            </Typography>
            <CloseButton
              onClick={closeNotificationsPopup}
              sx={{ display: { mobile: 'block', desktop: 'none' } }}
            >
              <Close />
            </CloseButton>
          </NotificationsBlockHeaderWrapper>
          <TabsSectionStyled
            tabs={tabs}
            initialTab={initialTab}
            onChange={handleChangeTab}
          />
        </Box>
      </NotificationsWrapper>
    </NotificationsPopover>
  );
};
