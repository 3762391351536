import { TranslationFunction } from 'types/general';

export const getRowsPerPage = (
  t: TranslationFunction,
  showOnlyValues?: boolean,
) => [
  { label: showOnlyValues ? '10' : t('rowsPerPage10'), value: 10 },
  { label: showOnlyValues ? '20' : t('rowsPerPage20'), value: 20 },
  { label: showOnlyValues ? '50' : t('rowsPerPage50'), value: 50 },
];
