import {
  AssetDetailsDto,
  GrafanaPanelParametersDto,
} from 'openapi-api/admin-service';

export type PanelAssetItem = {
  asset: AssetDetailsDto;
  panel: GrafanaPanelParametersDto;
};

export const getPanelAssetItems = (
  assets: AssetDetailsDto[],
  panels: GrafanaPanelParametersDto[],
): PanelAssetItem[] =>
  assets.reduce((assetPanelMapping: PanelAssetItem[], asset) => {
    const assetPanel = panels.find((panel) => {
      const plcAddress = panel?.panelVariables?.plc_address.toString();
      return asset.publicId && plcAddress === asset.publicId;
    });

    return assetPanel
      ? [...assetPanelMapping, { asset, panel: assetPanel }]
      : assetPanelMapping;
  }, []);
