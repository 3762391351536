import { styled } from '@mui/material';

import CommonButton from 'components/common/CommonButton';

export const AddLinkButton = styled(CommonButton)(({ theme }) => ({
  width: '320px',

  [theme.breakpoints.down('desktop')]: {
    width: '162px',
    padding: theme.spacing(1.875, 2.5),
  },
}));
