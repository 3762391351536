import { styled } from '@mui/material';

import { RestorationRequestDtoStatusEnum } from 'openapi-api/admin-service';

export const InvestigationRequestStatusWrapper = styled('div')<{
  status: RestorationRequestDtoStatusEnum;
}>(({ theme, status }) => {
  let bgColor: string | undefined = theme.palette.grey[50];
  switch (status) {
    case RestorationRequestDtoStatusEnum.PENDING:
      bgColor = theme.palette.yellow[200];
      break;
    case RestorationRequestDtoStatusEnum.COMPLETED:
      bgColor = theme.palette.green[200];
      break;
    case RestorationRequestDtoStatusEnum.FAILED:
      bgColor = theme.palette.warning[200];
      break;
    case RestorationRequestDtoStatusEnum.RESET:
      bgColor = theme.palette.grey[50];
      break;
  }

  return {
    padding: theme.spacing(0.75, 2),
    height: '25px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: bgColor,
    borderRadius: '15px',
  };
});
