import { styled } from '@mui/material';

export const WeatherDailyWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  border: `1px solid ${theme.palette.grey[100]}`,
  borderRadius: '12px',

  [theme.breakpoints.down('tablet')]: {
    width: '100%',
  },
}));
