import {
  Tooltip,
  TooltipProps,
  Typography,
  TypographyProps,
} from '@mui/material';
import { debounce } from 'lodash';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';

export interface TextWithTooltipProps {
  text: string;
  linesVisible?: number;
  textProps?: TypographyProps;
  tooltipProps?: Omit<TooltipProps, 'children' | 'title'>;
}

export const TextWithTooltip: FC<TextWithTooltipProps> = ({
  text,
  linesVisible = 1,
  textProps,
  tooltipProps,
}) => {
  const [showTooltip, setShowTooltip] = useState(true);
  const containerRef = useRef<HTMLDivElement>(null);

  const checkClampedState = useCallback(() => {
    if (containerRef.current) {
      const { clientHeight, scrollHeight } = containerRef.current;
      setShowTooltip(clientHeight !== scrollHeight);
    }
  }, []);

  useEffect(() => {
    setTimeout(() => checkClampedState(), 100);
  }, [checkClampedState, text]);

  useEffect(() => {
    const debouncedCheck = debounce(checkClampedState, 100);

    window.addEventListener('resize', debouncedCheck);

    return () => {
      window.removeEventListener('resize', debouncedCheck);
    };
  }, [checkClampedState]);

  return (
    <Tooltip title={showTooltip ? text : ''} {...tooltipProps}>
      <Typography
        ref={containerRef}
        sx={{
          WebkitLineClamp: linesVisible,
          '-webkit-box-orient': 'vertical',
          display: '-webkit-box',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          overflowWrap: 'break-word',
        }}
        {...textProps}
      >
        {text}
      </Typography>
    </Tooltip>
  );
};
