import { styled } from '@mui/material/styles';

const ButtonsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  alignItems: 'center',
  [theme.breakpoints.down('desktop')]: {
    gap: theme.spacing(3),
    flexDirection: 'column',
  },
}));

export default ButtonsWrapper;
