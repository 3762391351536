import { styled } from '@mui/material';

const ActionsList = styled('ul')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.5),
  margin: theme.spacing(1.5, 0, 2.5),
  padding: 0,
  listStyleType: 'none',
}));

export default ActionsList;
