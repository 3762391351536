import { Typography, TypographyOwnProps } from '@mui/material';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TextWithTooltip } from 'components/common/TextWithTooltip';

interface Props extends TypographyOwnProps {
  conditionCode?: number;
  isDay?: boolean;
  showTooltip?: boolean;
}

export const ConditionTitle: FC<Props> = ({
  conditionCode,
  isDay,
  showTooltip,
  ...typographyProps
}) => {
  const { t } = useTranslation();

  const title = useMemo(
    () =>
      t([
        `weather.conditions.${conditionCode}.${isDay ? 'day' : 'night'}`,
        `weather.conditions.${conditionCode}`,
      ]),
    [t, conditionCode, isDay],
  );

  if (showTooltip) {
    return (
      <TextWithTooltip
        textProps={typographyProps}
        tooltipProps={{ placement: 'top' }}
        text={title}
      />
    );
  }

  return <Typography {...typographyProps}>{title}</Typography>;
};
