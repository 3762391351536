import { Grid, Typography } from '@mui/material';
import { FC } from 'react';

import {
  GaugeChartWrapper,
  MechanicalParameterNameWrapper,
  MechanicalParameterWrapper,
} from './styles';
import { WIDGETS } from '../../constants';
import { GrafanaIframe } from '../GrafanaIframe';
import { GrafanaIframeTheme } from 'types/general';

interface MechanicalParameterProps {
  title: string;
  name: string;
  widget: WIDGETS;
}

export const MechanicalParameter: FC<MechanicalParameterProps> = ({
  title,
  name,
  widget,
}) => {
  return (
    <MechanicalParameterWrapper container>
      <MechanicalParameterNameWrapper container item>
        <Typography variant="subheading" color="grey.600">
          {title}
        </Typography>
        <Typography variant="h4" color="white.main">
          {name}
        </Typography>
      </MechanicalParameterNameWrapper>
      <Grid item>
        <GaugeChartWrapper>
          <GrafanaIframe panelId={widget} theme={GrafanaIframeTheme.dark} />
        </GaugeChartWrapper>
      </Grid>
    </MechanicalParameterWrapper>
  );
};
