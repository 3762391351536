import { CircularProgress } from '@mui/material';

import LoaderWrapper from './styles/LoaderWrapper';

const FullSizeLoader = () => (
  <LoaderWrapper data-testid="fullsize-loader">
    <CircularProgress />
  </LoaderWrapper>
);
export default FullSizeLoader;
