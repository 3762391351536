const toHMS = (seconds: number) => {
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = (seconds % 3600) % 60;

  return {
    minutes: minutes >= 10 ? minutes : '0' + minutes,
    seconds: secs >= 10 ? secs : '0' + secs,
  };
};

export default toHMS;
