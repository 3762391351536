import { useMediaQuery, useTheme } from '@mui/material';
import { EmblaCarouselType, EmblaOptionsType } from 'embla-carousel';
import useEmblaCarousel from 'embla-carousel-react';
import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react';

import {
  CarouselContentWrapper,
  CarouselWrapper,
  NextPrevButton,
  SlideWrapper,
  SlidesContainer,
} from './styles';
import { ArrowLeft } from 'components/icons';

interface IWeatherSliderProps {
  slides: {
    key?: string;
    component: ReactNode;
  }[];
  options?: EmblaOptionsType;
}

export const WeatherSlider: FC<IWeatherSliderProps> = ({ slides, options }) => {
  const [canScrollPrev, setCanScrollPrev] = useState<boolean>(false);
  const [canScrollNext, setCanScrollNext] = useState<boolean>(true);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.only('desktop'));
  const [emblaRef, emblaApi] = useEmblaCarousel(options);

  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev();
  }, [emblaApi]);

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext();
  }, [emblaApi]);

  const onSelect = useCallback((carouselApi: EmblaCarouselType) => {
    setCanScrollPrev(carouselApi.canScrollPrev());
    setCanScrollNext(carouselApi.canScrollNext());
  }, []);

  useEffect(() => {
    if (emblaApi) {
      emblaApi.on('select', onSelect);
      setCanScrollPrev(emblaApi.canScrollPrev());
      setCanScrollNext(emblaApi.canScrollNext());
    }
  }, [emblaApi, onSelect]);

  return (
    <CarouselWrapper data-testid="weather-slider">
      {isDesktop && (
        <NextPrevButton
          onClick={scrollPrev}
          disabled={!canScrollPrev}
          data-testid="weather-slider-prev"
        >
          <ArrowLeft />
        </NextPrevButton>
      )}
      <CarouselContentWrapper ref={emblaRef}>
        <SlidesContainer>
          {slides.map(({ key, component }) => (
            <SlideWrapper key={`embla-carousel-slide-${key}`}>
              {component}
            </SlideWrapper>
          ))}
        </SlidesContainer>
      </CarouselContentWrapper>
      {isDesktop && (
        <NextPrevButton
          next
          onClick={scrollNext}
          disabled={!canScrollNext}
          data-testid="weather-slider-next"
        >
          <ArrowLeft />
        </NextPrevButton>
      )}
    </CarouselWrapper>
  );
};
