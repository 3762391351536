import { alpha, styled } from '@mui/material';

export const ChipFilterButton = styled('div')<{
  active?: boolean;
  size: 'small' | 'medium';
  variant: 'outlined' | 'contained';
}>(({ theme, active, size, variant }) => ({
  ...theme.typography.bodyS,
  lineHeight: '140%',
  padding: size === 'small' ? theme.spacing(0.5, 1.25) : theme.spacing(1, 2.5),
  borderRadius: '18px',
  cursor: 'pointer',
  ...(variant === 'outlined' && {
    outlineWidth: '1px',
    outlineStyle: 'solid',
    outlineOffset: '-1px',
  }),
  ...(active
    ? {
        outlineColor: theme.palette.green[500],
        backgroundColor: theme.palette.green[500],
        color: theme.palette.black[500],
        '&:hover': {
          backgroundColor: theme.palette.green[500],
        },
      }
    : {
        outlineColor: alpha(theme.palette.grey[900], 0.05),
        backgroundColor: theme.palette.white.main,
        color: theme.palette.grey[600],
        ...(variant === 'contained' && {
          backgroundColor: alpha(theme.palette.grey[900], 0.05),
          color: theme.palette.black[500],
        }),
      }),
}));
