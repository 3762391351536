import { Box, Theme, Typography, useMediaQuery } from '@mui/material';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { TextEllipsis } from './components';
import { SeparatorLine } from './styles';
import routePaths from 'constants/routePaths';
import useNotificationsContext from 'contexts/notifications';
import { WeatherWarningDto } from 'openapi-api/admin-service';

interface Props {
  details: WeatherWarningDto;
  extended: boolean;
}

dayjs.extend(utc);

export const TemplateWeatherWarning: FC<Props> = ({ details, extended }) => {
  const { t } = useTranslation();
  const {
    headline,
    locationId,
    category,
    effectiveDatetime,
    expiresDatetime,
    description,
  } = details;

  const isMobileOrTablet = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('desktop'),
  );
  const { closeNotificationsPopup } = useNotificationsContext();

  const target = useMemo(() => {
    if (extended) return '_blank';
  }, [extended]);

  const onLinkClick = useMemo(() => {
    if (!extended && isMobileOrTablet) return closeNotificationsPopup;
  }, [extended, isMobileOrTablet, closeNotificationsPopup]);

  const formatDateTime = (dateTimeStr: string | undefined) =>
    dayjs(dateTimeStr).utc().format('HH:mm UTC DD.MM.YYYY');

  return (
    <Box display="flex" flexDirection="column" gap={2} width="100%">
      <Link
        to={routePaths.parks.specific(locationId as number).root}
        key={locationId}
        target={target}
        onClick={onLinkClick}
      >
        <Typography
          variant="bodySStrong"
          color="grey.900"
          sx={(theme) => ({ '&:hover': { color: theme.palette.green[600] } })}
        >
          {headline}
        </Typography>
      </Link>
      <SeparatorLine />
      <Box display="grid" gridTemplateColumns="104px auto" gap={1}>
        <Typography variant="bodyS" color="grey.900">
          {t('notifications.weatherWarning.category')}
        </Typography>
        <Typography variant="bodySStrong" color="grey.900">
          {category}
        </Typography>
        <Typography variant="bodyS" color="grey.900">
          {t('notifications.weatherWarning.effectiveTime')}
        </Typography>
        <Typography variant="bodySStrong" color="grey.900">
          {formatDateTime(effectiveDatetime)}
        </Typography>
        <Typography variant="bodyS" color="grey.900">
          {t('notifications.weatherWarning.expirationTime')}
        </Typography>
        <Typography variant="bodySStrong" color="grey.900">
          {formatDateTime(expiresDatetime)}
        </Typography>
      </Box>
      {!!description && (
        <>
          <SeparatorLine />
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            gap={1}
          >
            <TextEllipsis text={description} linesVisible={1} />
          </Box>
        </>
      )}
    </Box>
  );
};
