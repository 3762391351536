import { FC } from 'react';

import { CommonSvgProps } from 'types/general';

export const ArrowRight: FC<CommonSvgProps> = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.5858 16L13 17.4142L17.7071 12.7071C17.7093 12.705 17.7114 12.7028 17.7135 12.7006C17.8907 12.5202 18 12.2729 18 12C18 11.7234 17.8877 11.4731 17.7063 11.292L13 6.58578L11.5858 8L14.5858 11L7 11V13L14.5858 13L11.5858 16Z"
      fill="#21252C"
    />
  </svg>
);
