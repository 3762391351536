import { styled } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';

const TableWrapper = styled(DataGrid, {
  shouldForwardProp: (prop) => prop !== 'colCount',
})<{ colCount?: number }>(({ theme, colCount }) => {
  const borderColor = theme.palette.grey['100'];

  return {
    border: 'none',
    backgroundColor: theme.palette.white.main,
    fontFamily: 'DM Sans',
    fontWeight: 400,
    borderRadius: 0,
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 400,
    },
    '& .MuiDataGrid-iconButtonContainer': {
      marginLeft: '2px',
      visibility: 'visible !important',
      width: 'auto !important',
    },
    '& .MuiDataGrid-columnHeaders': {
      border: 'none',
      backgroundColor: theme.palette.white.main,
      top: 0,
      left: 0,
      zIndex: 1,
    },
    '& .MuiDataGrid-columnSeparator--sideRight': {
      display: 'none',
    },
    '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus, & .MuiDataGrid-columnHeader:focus':
      {
        outline: 'none',
      },
    '& .MuiDataGrid-cell': {
      borderBottom: `1px solid ${borderColor}`,
      padding: theme.spacing(3.5, 2, 3.5, 2),
      position: 'relative',
      [theme.breakpoints.down('tablet')]: {
        padding: theme.spacing(2.5, 2, 2.5, 2),
      },
      '&:first-of-type': {
        borderLeft: `1px solid ${borderColor}`,
        paddingLeft: theme.spacing(4),
        [theme.breakpoints.down('tablet')]: {
          paddingLeft: theme.spacing(2),
        },
      },
      [colCount ? `&:nth-of-type(${colCount})` : '&:last-of-type']: {
        borderRight: `1px solid ${borderColor}`,
        paddingRight: theme.spacing(4),
        [theme.breakpoints.down('tablet')]: {
          paddingRight: theme.spacing(2),
        },
      },
    },
    '& .MuiDataGrid-row:first-of-type': {
      borderRadius: theme.spacing(1.5, 1.5, 0, 0),
      overflow: 'hidden',
    },
    '& .MuiDataGrid-row:first-of-type .MuiDataGrid-cell': {
      borderTop: `1px solid ${borderColor}`,
    },
    '& .MuiDataGrid-row:first-of-type .MuiDataGrid-cell:first-of-type': {
      borderTopLeftRadius: theme.spacing(1.5),
    },
    [`& .MuiDataGrid-row:first-of-type .MuiDataGrid-cell${
      colCount ? `:nth-of-type(${colCount})` : ':last-of-type'
    }`]: {
      borderTopRightRadius: theme.spacing(1.5),
    },
    '& .MuiDataGrid-row:last-of-type .MuiDataGrid-cell': {
      borderBottom: 'none',
    },
    '& .MuiGrid-root': {
      maxWidth: '100%',
    },
  };
});

export default TableWrapper;
