import { WindTurbineDtoRatedPowerEnum } from 'openapi-api/admin-service';

export const mapRatedPower = (power: WindTurbineDtoRatedPowerEnum) => {
  switch (power) {
    case WindTurbineDtoRatedPowerEnum._5:
      return 5;
    case WindTurbineDtoRatedPowerEnum._15:
      return 15;
    case WindTurbineDtoRatedPowerEnum._55:
      return 55;
    default:
      return power;
  }
};
