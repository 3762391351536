import { styled } from '@mui/material/styles';

const TimeWrapper = styled('span')(({ theme }) => ({
  color: theme.palette.green[600],
  backgroundColor: '#FAFAFE',
  padding: theme.spacing(2.5),
  borderRadius: theme.spacing(1.5),
}));

export default TimeWrapper;
