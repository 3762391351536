import { alpha, styled } from '@mui/material';

export const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '12px',
  outline: `1px solid ${theme.palette.grey[100]}`,
  outlineOffset: '-1px',
  backgroundColor: theme.palette.white.main,
  cursor: 'pointer',
  overflow: 'hidden',

  '&:hover': {
    // TODO: move all shadows to src/styles/shadows.ts
    boxShadow: `0 0 1px 0 ${alpha(
      theme.palette.black[600] as string,
      0.08,
    )}, 0 5px 10px 0 ${alpha(theme.palette.black[600] as string, 0.1)}`,
  },
}));
