import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { UserDataWrapper } from './styles';
import CopyButton from 'components/common/CopyButton';
import CopyIcon from 'components/common/CopyIcon';
import { NotificationPopup } from 'components/common/NotificationPopup';
import SuccessDialog from 'components/common/SuccessDialog';
import useApi from 'contexts/api';
import { UserUpdatePasswordResponseDto } from 'openapi-api/admin-service';
import { getUserCredentialsCopyText } from 'utils/functions/getUserCredentialsCopyText';
import { usePopup } from 'utils/hooks/usePopup';

type ReturnType = [JSX.Element, () => void];

export const useResetPassword = (
  customerId?: number,
  customerName?: string,
): ReturnType => {
  const { customerControllerApi } = useApi();
  const [isResetWarningOpen, openResetWarning, closeResetWarning] = usePopup();
  const [isResetSuccessOpen, openResetSuccess, closeResetSuccess] = usePopup();
  const [resetData, setResetData] =
    useState<UserUpdatePasswordResponseDto | null>();
  const { t } = useTranslation();

  const resetPassword = useCallback(async () => {
    if (!customerId) return;

    try {
      closeResetWarning();
      const { data } = await customerControllerApi.updatePassword({
        customerId,
      });
      setResetData(data);
      openResetSuccess();
    } catch (e) {
      if (axios.isAxiosError(e) && e.response?.data.message) {
        toast.error(e.response.data.message);
      }
    }
  }, [openResetSuccess, customerId, closeResetWarning, customerControllerApi]);

  const resetComponents = useMemo(
    () => (
      <>
        <NotificationPopup
          title={t`popup.resetPassword.title`}
          isOpen={isResetWarningOpen}
          primaryButton={{
            text: t`popup.resetPassword.yesReset`,
            onClick: resetPassword,
          }}
          secondaryButton={{
            text: t`cancel`,
            onClick: closeResetWarning,
          }}
          onClose={closeResetWarning}
        />
        <SuccessDialog
          title={t`popup.resetPassword.successTitle`}
          buttonText={t`continueSession`}
          onClose={closeResetSuccess}
          open={isResetSuccessOpen}
        >
          <UserDataWrapper>
            <Grid
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid display="flex" flexDirection="column" gap={1}>
                <Typography
                  variant="bodyS"
                  color="grey.600"
                >{t`username`}</Typography>
                <Typography variant="bodyMStrong" color="white.main">
                  {customerName}
                </Typography>
              </Grid>
              <CopyIcon text={customerName} />
            </Grid>
            <Grid
              display="flex"
              justifyContent="space-between"
              gap={3}
              alignItems="center"
            >
              <Grid display="flex" flexDirection="column" gap={1}>
                <Typography
                  variant="bodyS"
                  color="grey.600"
                >{t`password`}</Typography>
                <Typography variant="bodyMStrong" color="white.main">
                  {resetData?.password}
                </Typography>
              </Grid>
              <CopyIcon text={resetData?.password} />
            </Grid>
            <CopyButton
              light
              text={getUserCredentialsCopyText({
                ...resetData,
                name: customerName,
              })}
            />
          </UserDataWrapper>
        </SuccessDialog>
      </>
    ),
    [
      isResetWarningOpen,
      resetPassword,
      closeResetWarning,
      t,
      closeResetSuccess,
      isResetSuccessOpen,
      resetData,
      customerName,
    ],
  );

  return [resetComponents, openResetWarning];
};
