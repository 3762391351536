import { styled } from '@mui/material/styles';

const OrWrapper = styled('div')(({ theme }) => ({
  height: 'auto',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  '&:before': {
    content: "''",
    width: '100%',
    height: '1px',
    backgroundColor: theme.palette.grey[100],
    position: 'absolute',
    left: 0,
  },
}));

export default OrWrapper;
