import { Link as MUILink, LinkProps as LinkPropsMUI } from '@mui/material';
import { PropsWithChildren, MouseEvent, useCallback, forwardRef } from 'react';
import { useNavigate, LinkProps } from 'react-router-dom';

import publicPath from 'utils/functions/publicPath';

export const Link = forwardRef<
  HTMLAnchorElement,
  PropsWithChildren<LinkProps & LinkPropsMUI>
>(({ to, children, state, className, ...other }, ref) => {
  const navigate = useNavigate();

  const handleClick = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      event.preventDefault();
      navigate(to, { state });
    },
    [navigate, state, to],
  );

  return (
    <MUILink
      {...other}
      href={publicPath(to as string)}
      onClick={handleClick}
      className={className}
      ref={ref}
    >
      {children}
    </MUILink>
  );
});
