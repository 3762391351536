import { Accordion } from '@mui/material';
import { styled } from '@mui/material/styles';

const MenuAccordion = styled(Accordion)(({ theme }) => ({
  boxShadow: 'none',
  margin: '0 !important',
  '&:before': {
    content: 'none',
  },
  '& .MuiAccordionSummary-root': {
    minHeight: 'auto !important',
    height: '44px',
    padding: theme.spacing(0, 2.5),
    '& .MuiAccordionSummary-content': {
      margin: 0,
    },
  },
  '& .MuiAccordionDetails-root': {
    '& .MuiCheckbox-root': {
      paddingTop: 0,
      paddingBottom: 0,
    },
    '& .MuiListItemButton-root': {
      padding: theme.spacing(1.5, 2.5),
    },
  },
}));

export default MenuAccordion;
