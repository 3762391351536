import { FC } from 'react';

import { History } from '../History';
import { ReportContentContainer } from '../ReportContent';
import useReport from 'contexts/report';

export const BottomPartContent: FC = () => {
  const { reportValues } = useReport();

  if (reportValues) return <ReportContentContainer />;

  return <History />;
};
