import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

const TabletTopLine = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: `1px solid ${theme.palette.grey[100]}`,
  '& svg': {
    color: theme.palette.grey[500],
  },
}));

export default TabletTopLine;
