import { useEffect, useState } from 'react';

const useLocalStorage = <T>(
  key: string,
): [T | null, (value: T | null) => void] => {
  const [value, setValue] = useState<T | null>(() => {
    const val = localStorage.getItem(key);
    return val ? JSON.parse(val) : null;
  });

  useEffect(() => {
    if (!value) {
      localStorage.removeItem(key);
      return;
    }
    localStorage.setItem(key, JSON.stringify(value));
  }, [value, key]);

  return [value, setValue];
};

export default useLocalStorage;
