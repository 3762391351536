import { useContext } from 'react';

import GlobalDataContext from './Context';
import GlobalDataProvider from './Provider';

const useGlobalData = () => {
  const context = useContext(GlobalDataContext);

  if (typeof context === 'undefined' || context === null) {
    throw new Error('useGlobalData must be used within an GlobalDataProvider');
  }

  return context;
};

export default useGlobalData;
export { GlobalDataProvider };
