import { FC } from 'react';

import { CommonButtonStyled } from './styles';
import { ICommonButtonProps } from '../CommonButton/CommonButton';
import { ThreeDots } from 'components/icons';

const MoreActionsButton: FC<ICommonButtonProps> = (props) => {
  return (
    <CommonButtonStyled {...props} variant="outlined">
      <ThreeDots />
    </CommonButtonStyled>
  );
};

export default MoreActionsButton;
