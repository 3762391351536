import { styled } from '@mui/material';

import CommonButton from 'components/common/CommonButton';

export const ShowHideButton = styled(CommonButton, {
  shouldForwardProp: (prop) => prop !== 'clamped',
})<{ clamped: boolean }>(({ theme, clamped }) => ({
  minWidth: 'auto',
  textTransform: 'uppercase',
  color: clamped ? theme.palette.green[600] : theme.palette.grey[500],
  ...theme.typography.buttonS,

  '&:hover': {
    color: clamped ? theme.palette.green[600] : theme.palette.grey[500],
  },

  [theme.breakpoints.down('tablet')]: {
    ...(clamped && { color: theme.palette.black[600] }),
    '&:hover': {
      color: clamped ? theme.palette.black[600] : theme.palette.grey[500],
    },
  },
}));
