import { styled } from '@mui/material';

export const MobileBottomPanel = styled('div')(({ theme }) => ({
  position: 'fixed',
  left: 0,
  bottom: 0,
  display: 'flex',
  justifyContent: 'center',
  gap: theme.spacing(2),
  width: '100%',
  padding: theme.spacing(2, 0, 2.75),
  backgroundColor: theme.palette.white.main,
  boxShadow: `0px -5px 10px 0px rgba(30, 34, 40, 0.1), 0px 0px 1px 0px rgba(30, 34, 40, 0.08)`,

  [theme.breakpoints.down('tablet')]: {
    gap: theme.spacing(1.5),

    '& .MuiButton-root': {
      padding: theme.spacing(1.75, 2.5),
    },
  },
}));
