import { SwipeableDrawer } from '@mui/material';
import { styled } from '@mui/material/styles';

const SwipeDrawerWrapper = styled(SwipeableDrawer)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: theme.spacing(2, 2, 0, 0),
  },
}));

export default SwipeDrawerWrapper;
