import { Palette, PaletteColor, styled } from '@mui/material';

type ColorType = keyof {
  [Key in keyof Palette as Palette[Key] extends PaletteColor
    ? Key
    : never]: true;
};

export const RoundChip = styled('div')<{
  color: string;
  colorKey: string;
  size: number;
}>(({ theme, color, colorKey, size }) => ({
  display: 'inline-block',
  background: theme.palette[color as ColorType][colorKey as keyof PaletteColor],
  borderRadius: '50%',
  width: `${size}px`,
  height: `${size}px`,
}));
