import dayjs from 'dayjs';

import { SelectTimePeriods, dayjsManipulateTypeMapping } from './constants';

export const getFromDateInUnixMs = (
  toDate: number,
  timePeriod: SelectTimePeriods,
) => {
  return (
    dayjs(toDate).subtract(1, dayjsManipulateTypeMapping[timePeriod]).unix() *
    1000
  );
};
