import * as yup from 'yup';

import { TranslationFunction } from '../../../../types/general';

export const validationSchemaStep1 = (t: TranslationFunction) =>
  yup.object().shape(
    {
      existingCustomer: yup
        .string()
        .when('newCustomer', (newCustomer, schema) => {
          if (!newCustomer?.[0])
            return schema.required(t('form.errors.fieldRequired'));
          return schema;
        }),
      newCustomer: yup
        .string()
        .when('existingCustomer', (existingCustomer, schema) => {
          if (!existingCustomer?.[0])
            return schema
              .required(t('form.errors.fieldRequired'))
              .matches(
                /^[a-zA-Z0-9!@#$%^&*]{10,32}$/,
                t('form.errors.incorrectUsername'),
              );
          return schema;
        }),
      existingParks: yup.array().optional(),
      newPark: yup.object().optional(),
      macAddresses: yup.array().optional(),
    },
    [['newCustomer', 'existingCustomer']],
  );

export const validationSchemaStep2 = (
  t: TranslationFunction,
  isParkNameExist: (parkName: string) => Promise<boolean>,
) =>
  yup.object().shape(
    {
      existingCustomer: yup.string(),
      newCustomer: yup.string(),
      existingParks: yup.array().when('newPark', (newPark, schema) => {
        if (!newPark?.[0])
          return schema
            .of(
              yup.object({
                value: yup.string(),
              }),
            )
            .required(t('form.errors.fieldRequired'));
        return schema;
      }),
      newPark: yup.object().when('existingParks', (existingParks, schema) => {
        if (!existingParks?.[0]?.length)
          return schema
            .shape({
              name: yup
                .string()
                .required(t('form.errors.fieldRequired'))
                .matches(
                  /^[a-zA-Z0-9 ]{1,48}$/,
                  t('form.errors.incorrectParkName'),
                )
                .strict()
                .trim(t('form.errors.spacesAreNotAllowed'))
                .test({
                  message: t('form.errors.uniqueParkName'),
                  test: async (parkName = '') => {
                    try {
                      const parkNameExists = await isParkNameExist(parkName);
                      return !parkNameExists;
                    } catch (e) {
                      return false;
                    }
                  },
                }),
              coordinates: yup
                .string()
                .required(t('form.errors.fieldRequired'))
                .matches(
                  /^(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)$/,
                  t('form.errors.incorrectFormat'),
                ),
            })
            .required(t('form.errors.fieldRequired'));
        return schema;
      }),
      macAddresses: yup.array().optional(),
    },
    [['existingParks', 'newPark']],
  );

export const validationSchemaStep3 = (t: TranslationFunction) =>
  yup.object().shape({
    existingCustomer: yup.string(),
    newCustomer: yup.string(),
    existingParks: yup.array().optional(),
    newPark: yup.object().optional(),
    macAddresses: yup
      .array()
      .of(
        yup
          .array()
          .min(1)
          .of(
            yup.object({
              value: yup
                .string()
                .required(t('form.errors.fieldRequired'))
                .matches(
                  /^[0-9a-f]{2}([\\.:-])[0-9a-f]{2}([\\.:-])[0-9a-f]{2}([\\.:-])[0-9a-f]{2}([\\.:-])[0-9a-f]{2}([\\.:-])[0-9a-f]{2}$/,
                  t('form.errors.incorrectMac'),
                ),
            }),
          ),
      )
      .test({
        message: t('form.errors.uniqueMac'),
        test: (parksMacs, context) => {
          if (
            !parksMacs?.length ||
            !parksMacs?.every(
              (macsArr) =>
                macsArr?.length && macsArr.every(({ value }) => !!value),
            )
          ) {
            return true;
          }

          const allMacs = parksMacs.map((macsArr) =>
            (macsArr as { value: string }[]).map(({ value }) => value),
          );

          const valuesToIndexesMapping: Record<string, [number, number][]> = {};

          for (let parkIndex = 0; parkIndex < allMacs.length; parkIndex++) {
            for (let macInd = 0; macInd < allMacs[parkIndex].length; macInd++) {
              const currentMac = allMacs[parkIndex][macInd];
              if (valuesToIndexesMapping[currentMac]?.length) {
                valuesToIndexesMapping[currentMac].push([parkIndex, macInd]);
              } else {
                valuesToIndexesMapping[currentMac] = [[parkIndex, macInd]];
              }
            }
          }

          const indexesOfDuplicatedMacs = Object.values(
            valuesToIndexesMapping,
          ).flatMap((arrOfIndexesOfOccurrences) =>
            arrOfIndexesOfOccurrences.length > 1
              ? arrOfIndexesOfOccurrences
              : [],
          );

          if (!indexesOfDuplicatedMacs.length) return true;

          return new yup.ValidationError(
            indexesOfDuplicatedMacs.map(
              ([parkIndex, macIndex]) =>
                new yup.ValidationError([
                  context.createError({
                    path: `${context.path}.${parkIndex}.${macIndex}.value`,
                  }),
                ]),
            ),
          );
        },
      }),
  });

export const validationSchemas = [
  validationSchemaStep1,
  validationSchemaStep2,
  validationSchemaStep3,
];
