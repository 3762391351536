import { Paper, styled } from '@mui/material';

const DatePickerPaper = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(1.5),
  marginBottom: theme.spacing(1.5),
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',

  '& .MuiPickersCalendarHeader-label': {
    ...theme.typography.bodySStrong,
    color: theme.palette.black[900],
  },

  '& .MuiDayCalendar-weekDayLabel': {
    ...theme.typography.bodyXS,
    color: theme.palette.grey[600],
  },

  '& .MuiPickersDay-root': {
    ...theme.typography.bodyXS,
    color: theme.palette.black[500],
    '&.Mui-selected, &.Mui-selected:hover': {
      color: theme.palette.white.main,
      backgroundColor: theme.palette.green[600],
      '&:hover, &:focus': {
        backgroundColor: theme.palette.green[600],
      },
    },
    '&.MuiPickersDay-today:not(.Mui-selected)': {
      borderColor: theme.palette.grey[200],
    },
  },

  '& .MuiPickersYear-yearButton': {
    ...theme.typography.bodyXS,
    color: theme.palette.black[500],
    '&.Mui-selected': {
      color: theme.palette.white.main,
      backgroundColor: theme.palette.green[600],
      '&:hover, &:focus': {
        backgroundColor: theme.palette.green[600],
      },
    },
  },
}));

export default DatePickerPaper;
