import { styled } from '@mui/material';

export const UserProfileBottomButtons = styled('div')(({ theme }) => ({
  display: 'none',

  [theme.breakpoints.down('desktop')]: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
}));
