import { createContext } from 'react';

import { ResponsePopupState } from './Provider';

export interface IResponsePopupContext {
  openPopup: (state: ResponsePopupState) => void;
  closePopup: () => void;
}

const ResponsePopupContext = createContext<IResponsePopupContext>(null!);

export default ResponsePopupContext;
