import { styled } from '@mui/material';

export const IframeAggregatedTotal = styled('iframe')(({ theme }) => ({
  height: '120px',

  [theme.breakpoints.down('desktop')]: {
    height: '232px',
  },

  [theme.breakpoints.down('tablet')]: {
    height: '432px',
  },
}));

export const IframePerfomanceDiagram = styled('iframe')(({ theme }) => ({
  height: '156px',

  [theme.breakpoints.down('desktop')]: {
    height: '172px',
  },
}));

export const IframeTotalActiveEnergyWrapper = styled('div')(() => ({
  position: 'relative',
  height: '24px',
  width: '200px',
  overflow: 'hidden',
}));

export const IframeTotalActiveEnergy = styled('iframe')(() => ({
  position: 'absolute',
  top: '-16px',
  bottom: '-16px',
  right: '-15px',
  width: 'auto',
}));
