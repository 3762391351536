import {
  Checkbox,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { FC, useCallback } from 'react';

import { FilterProps } from '../types';
import { Checked } from 'components/icons';

export const CheckboxFilter: FC<FilterProps> = ({
  values,
  field,
  localValues,
  setLocalValues,
}) => {
  const handleToggle = useCallback(
    (fieldName: string, value: string) => {
      const newValues = localValues[field]?.value
        ? localValues[fieldName].value?.split(',')
        : [];
      let localValue = value;
      if (newValues?.length) {
        if (newValues.includes(value)) {
          localValue = [...newValues.filter((v) => v !== value)].join(',');
        } else {
          localValue = [...newValues, value].join(',');
        }
      }

      setLocalValues((old) => ({ ...old, [fieldName]: { value: localValue } }));
    },
    [localValues, setLocalValues, field],
  );

  return (
    <>
      {values.map(({ label, value }) => (
        <ListItem disablePadding key={value}>
          <ListItemButton
            onClick={() => handleToggle(field, value as string)}
            dense
          >
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={
                  !!localValues[field]?.value
                    ?.split(',')
                    .includes(value as string)
                }
                tabIndex={-1}
                disableRipple
                checkedIcon={<Checked />}
                inputProps={{ 'aria-labelledby': label }}
                data-testid={`${label}=${value}`}
              />
            </ListItemIcon>
            <ListItemText
              id={label}
              sx={{ my: 0 }}
              primary={
                <Typography variant="bodyM" color="black.900">
                  {label}
                </Typography>
              }
            />
          </ListItemButton>
        </ListItem>
      ))}
    </>
  );
};
