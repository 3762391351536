import { Box, styled } from '@mui/material';

export const ErrorsListWrapper = styled(Box)(({ theme }) => ({
  '& > *': {
    border: `1px solid ${theme.palette.grey[100]}`,
  },
  '& > *:not(:last-of-type)': {
    borderBottom: 'none',
  },
}));
