import { useCallback, useEffect, useState } from 'react';

import useGlobalLoader from 'contexts/globalLoader';

interface UseAsyncResourceParams<T, D extends T | undefined> {
  fetchResource: () => Promise<T> | void;
  defaultValue?: D;
  defaultIsLoading?: boolean;
  autoFetching?: boolean;
  disableGlobalLoader?: boolean;
}

export function useAsyncResource<T, D extends T | undefined>({
  defaultValue,
  fetchResource,
  defaultIsLoading = false,
  autoFetching = true,
  disableGlobalLoader = false,
}: UseAsyncResourceParams<T, D>) {
  type Resource = D extends T ? T : T | undefined;

  const [resource, setResource] = useState<Resource>(defaultValue as Resource);
  const [isLoading, setIsLoading] = useState(defaultIsLoading);

  const { showLoader, hideLoader } = useGlobalLoader();

  useEffect(() => {
    if (defaultIsLoading && !disableGlobalLoader) {
      showLoader();
    }
  }, [defaultIsLoading, disableGlobalLoader, showLoader]);

  const fetch = useCallback(async () => {
    try {
      if (!disableGlobalLoader) {
        showLoader();
      }
      setIsLoading(true);

      const resp = await fetchResource();

      setResource(resp as Resource);
    } finally {
      if (!disableGlobalLoader) {
        hideLoader();
      }
      setIsLoading(false);
    }
  }, [disableGlobalLoader, fetchResource, hideLoader, showLoader]);

  useEffect(() => {
    if (autoFetching) {
      fetch();
    }
  }, [autoFetching, fetch]);

  return { resource, isLoading, setResource, fetch };
}
