import { Box, SxProps, Theme } from '@mui/material';
import { FC, useState, ChangeEvent, useCallback, useEffect } from 'react';

import SearchFieldClearIcon from './styles/SearchFieldClearIcon';
import SearchFieldIcon from './styles/SearchFieldIcon';
import SearchTextField from './styles/SearchTextField';

interface SearchProps {
  onSearch: (value: string) => void;
  defaultValue?: string | null;
  value?: string;
  placeholder?: string;
  searchOnTyping?: boolean;
  fullwidth?: boolean;
  disabled?: boolean;
  light?: boolean;
  sx?: SxProps<Theme>;
}

export const Search: FC<SearchProps> = ({
  onSearch,
  defaultValue,
  value: propValue,
  placeholder = 'Search',
  searchOnTyping,
  disabled,
  sx,
  light,
}: SearchProps) => {
  const [value, setValue] = useState<string>(defaultValue || '');

  useEffect(() => {
    if (propValue) {
      setValue(propValue);
    }
  }, [propValue]);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setValue(e.target.value);
      if (!e.target.value) {
        onSearch('');
      }
      if (searchOnTyping) {
        onSearch(e.target.value);
      }
    },
    [searchOnTyping, onSearch],
  );

  return (
    <SearchTextField
      autoComplete="off"
      light={light}
      disabled={disabled}
      InputProps={{
        disabled: disabled,
        startAdornment: value ? (
          <>
            <Box
              onClick={() => {
                setValue('');
                onSearch('');
              }}
              role="presentation"
              data-testid="clear"
            >
              <SearchFieldClearIcon />
            </Box>
            <SearchFieldIcon
              onClick={() => onSearch(value)}
              className="search"
              fontSize="small"
              data-testid="search"
            />
          </>
        ) : (
          <SearchFieldIcon
            onClick={() => onSearch(value)}
            className="search"
            fontSize="small"
            data-testid="search"
            light={light}
          />
        ),
      }}
      variant={'outlined'}
      type="text"
      value={value}
      placeholder={placeholder}
      fullWidth
      onChange={handleChange}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          onSearch(value);
        }
      }}
      sx={sx}
    />
  );
};
