import { yupResolver } from '@hookform/resolvers/yup';
import { Typography } from '@mui/material';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-router-dom';

import { FormValues } from './types';
import { validationSchema } from './validationSchema';
import CommonButton from 'components/common/CommonButton';
import TextField from 'components/common/form/TextField';
import { NotificationPopupVariants } from 'components/common/NotificationPopup/types';
import { Popup } from 'components/common/Popup';
import { IPopupProps } from 'components/common/Popup/types';
import useApi from 'contexts/api';
import useResponsePopup from 'contexts/responsePopup';
import useWindTurbine from 'contexts/WindTurbine';

interface Props extends IPopupProps {}

export const EditAssetDetailsPopup: FC<Props> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const { windTurbine, refetch } = useWindTurbine();
  const { assetControllerApi } = useApi();
  const { openPopup, closePopup } = useResponsePopup();

  const defaultValues = useMemo(
    () => ({ assetName: windTurbine?.name }),
    [windTurbine?.name],
  );

  const form = useForm<FormValues>({
    resolver: yupResolver(validationSchema(t)),
    defaultValues,
  });
  const {
    handleSubmit,
    reset,
    register,
    formState: { isDirty, isSubmitSuccessful },
  } = form;

  useEffect(() => {
    if (defaultValues.assetName) {
      reset(defaultValues);
    }
  }, [defaultValues, reset]);

  const handlePopupClose = useCallback(() => {
    reset(defaultValues);
    onClose();
  }, [reset, defaultValues, onClose]);

  const onSubmit = handleSubmit(async ({ assetName }: FormValues) => {
    if (!windTurbine?.publicId) return;

    try {
      await assetControllerApi.updateAsset({
        assetPublicId: windTurbine.publicId,
        assetUpdateDto: { name: assetName },
      });
      await refetch();
      openPopup({
        variant: NotificationPopupVariants.Success,
        title: t`components.editAssetDetailsForm.success.title`,
        primaryButton: {
          text: t`buttons.continueSession`,
          onClick: closePopup,
        },
      });
    } catch (e) {
      openPopup({
        variant: NotificationPopupVariants.Error,
        title: t`components.errorPopup.title`,
        subtitle: t`components.errorPopup.subtitle`,
        secondaryButton: {
          text: t`buttons.cancel`,
          onClick: closePopup,
        },
      });
    }
  });

  useEffect(() => {
    if (!isSubmitSuccessful) return;

    handlePopupClose();
  }, [isSubmitSuccessful, handlePopupClose]);

  return (
    <Popup isOpen={isOpen} onClose={handlePopupClose}>
      <FormProvider {...form}>
        <Form style={{ height: '100%' }} onSubmit={onSubmit}>
          <Popup.InnerWrapper>
            <Popup.Content>
              <Typography variant="h2" textAlign="left" sx={{ mb: 5 }}>
                {t`pages.turbine.editAssetDetails`}
              </Typography>
              <TextField
                label={t`components.editAssetDetailsForm.assetNameInputLabel`}
                fullWidth
                testId="assetName"
                {...register('assetName')}
              />
            </Popup.Content>
            <Popup.Buttons>
              <CommonButton
                disabled={!isDirty}
                type="submit"
                sx={{ width: { mobile: '226px', tablet: 'auto' } }}
              >{t`buttons.save`}</CommonButton>
              <CommonButton variant="outlined" onClick={handlePopupClose}>
                {t`buttons.cancel`}
              </CommonButton>
            </Popup.Buttons>
          </Popup.InnerWrapper>
        </Form>
      </FormProvider>
    </Popup>
  );
};
