import { Box, Typography } from '@mui/material';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  InfoBlock,
  InfoBlockGrid,
  ThreeDotsIcon,
  UserProfileBottomButtons,
  UserProfileHead,
  UserProfileHeadButtons,
  UserProfileWrapper,
} from './styles';
import { Breadcrumbs } from 'components/common/Breadcrumbs';
import { BreadcrumbItem } from 'components/common/Breadcrumbs/types';
import CommonButton from 'components/common/CommonButton';
import routePaths from 'constants/routePaths';
import useApi from 'contexts/api';
import { useAsyncResource } from 'utils/hooks/useAsyncResource';

export const UserProfile: FC = () => {
  const { t } = useTranslation();
  const { userId } = useParams();
  const { adminUserControllerApi } = useApi();

  const getUserDetails = useCallback(async () => {
    if (typeof userId === 'undefined') return;

    try {
      return (
        await adminUserControllerApi.getAdminUserDetailsById({
          userId: Number(userId),
        })
      ).data;
    } catch {}
  }, [userId, adminUserControllerApi]);

  const { resource: user, isLoading } = useAsyncResource({
    fetchResource: getUserDetails,
  });

  const breadcrumbItems = useMemo(
    (): BreadcrumbItem[] => [
      { label: t('pages.users.title'), path: routePaths.users.root },
      ...(!isLoading && !!user?.userId
        ? [
            {
              label: user.username as string,
              path: routePaths.user.profile(user?.userId),
            },
          ]
        : []),
    ],
    [t, isLoading, user],
  );

  const buttons = useMemo(
    () => (
      <>
        <CommonButton variant="outlined">
          {t('pages.userProfile.historyLogButtonText')}
        </CommonButton>
        <CommonButton
          variant="outlined"
          startIcon={<ThreeDotsIcon />}
          sx={{ width: '50px', minWidth: 'auto', padding: 0 }}
        ></CommonButton>
      </>
    ),
    [t],
  );

  return (
    <UserProfileWrapper>
      <Breadcrumbs items={breadcrumbItems} />
      <UserProfileHead>
        <Typography variant="h2">{user?.username}</Typography>
        <UserProfileHeadButtons>{buttons}</UserProfileHeadButtons>
      </UserProfileHead>
      <Box sx={{ mt: { mobile: 2, desktop: 0 } }}>
        <Typography variant="h4">
          {t('pages.userProfile.generalInfoTitle')}
        </Typography>
        <InfoBlock>
          <InfoBlockGrid>
            <Typography variant="bodyMStrong">{t('username')}</Typography>
            <Typography variant="bodyM">{user?.username}</Typography>
            <Typography
              variant="bodyMStrong"
              sx={{ mt: { mobile: 2, desktop: 0 } }}
            >
              {t('role')}
            </Typography>
            <Typography variant="bodyM">
              {t(`userRoles.${user?.rolePermission}`)}
            </Typography>
            <Typography
              variant="bodyMStrong"
              sx={{ mt: { mobile: 2, desktop: 0 } }}
            >
              {t('emailAddress')}
            </Typography>
            <Typography variant="bodyM">{user?.email}</Typography>
          </InfoBlockGrid>
          <CommonButton
            variant="outlined"
            size="small"
            sx={{ width: 'fit-content', backgroundColor: 'transparent' }}
          >
            {t('pages.userProfile.editInfoButtonText')}
          </CommonButton>
        </InfoBlock>
      </Box>
      <UserProfileBottomButtons>{buttons}</UserProfileBottomButtons>
    </UserProfileWrapper>
  );
};
