import { styled } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const DatePickerStyled = styled(DatePicker)(() => ({
  width: '100%',
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
}));

export default DatePickerStyled;
