import { styled } from '@mui/material';

export const ReportContentHead = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  gap: theme.spacing(3),

  [theme.breakpoints.down('desktop')]: {
    flexWrap: 'wrap',
    gap: theme.spacing(2.5),
  },

  [theme.breakpoints.down('tablet')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}));
