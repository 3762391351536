import { styled } from '@mui/material';

export const MissingDataNoticeItem = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  gap: theme.spacing(0.5),

  [theme.breakpoints.down('desktop')]: {
    flexDirection: 'column',
  },
}));
