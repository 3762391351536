import { Box as MuiBox, styled } from '@mui/material';

export const NotificationsWrapper = styled(MuiBox)(({ theme }) => ({
  width: '363px',
  padding: 0,

  [theme.breakpoints.down('desktop')]: {
    width: '100%',
    height: '100%',
    borderRadius: 0,
  },
}));
