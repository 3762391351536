import { styled } from '@mui/material/styles';

import CommonButton from 'components/common/CommonButton';

export const MarkAllAsReadButton = styled(CommonButton)(({ theme }) => ({
  margin: theme.spacing(3, 0, 3, 0),
  textDecoration: 'none',

  '& svg path': {
    stroke: theme.palette.grey[800],
    transition: 'stroke 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  '&:hover': {
    '& svg path': {
      stroke: theme.palette.green[600],
    },
  },
}));
