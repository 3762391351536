import { Box, Typography } from '@mui/material';
import { FC } from 'react';

import {
  ButtonsWrapper,
  CloseIcon,
  ContentWrapper,
  DialogStyled,
  Header,
} from './styles';
import { MobileBottomMenuProps, ParentComposition } from './types';

const MobileBottomMenu: FC<MobileBottomMenuProps> & ParentComposition = ({
  title,
  isOpen = false,
  onClose,
  children,
}) => {
  return (
    <DialogStyled open={isOpen} onClose={onClose}>
      <Header>
        <Typography variant="h5" color="black.900">
          {title}
        </Typography>
        <Box onClick={onClose}>
          <CloseIcon />
        </Box>
      </Header>
      {children}
    </DialogStyled>
  );
};

MobileBottomMenu.Content = ContentWrapper;
MobileBottomMenu.Buttons = ButtonsWrapper;

export default MobileBottomMenu;
