import { useMediaQuery, useTheme } from '@mui/material';
import { PickersActionBarProps } from '@mui/x-date-pickers';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ActionButton, TimePickerActionBar } from './styles';

export const CustomActionBar: FC<PickersActionBarProps> = (props) => {
  const breakpoints = useTheme().breakpoints;
  const isMobile = useMediaQuery(breakpoints.down('desktop'));
  const { t } = useTranslation();

  return (
    <TimePickerActionBar>
      {isMobile && (
        <ActionButton variant="text" color="secondary" onClick={props.onClear}>
          {t('buttons.cancel')}
        </ActionButton>
      )}
      <ActionButton variant="text" onClick={props.onAccept}>
        {t('buttons.ok')}
      </ActionButton>
    </TimePickerActionBar>
  );
};
