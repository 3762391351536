import * as yup from 'yup';

import { TranslationFunction } from 'types/general';

export const validationSchema = (t: TranslationFunction) =>
  yup.object({
    assetName: yup
      .string()
      .required(t`form.errors.fieldRequired`)
      .matches(
        /^$|^[a-zA-Z0-9 \-:]+$/,
        t`form.errors.incorrectAssetName.format`,
      )
      .matches(/^[^\s]+(\s+[^\s]+)*$/, t`form.errors.spacesAreNotAllowed`)
      .max(48, t`form.errors.incorrectAssetName.length`),
  });
