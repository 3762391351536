import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

export const TopLineWrapper = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  [theme.breakpoints.down('desktop')]: {
    flexDirection: 'column',
    gap: theme.spacing(5),
  },
}));
