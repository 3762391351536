import { styled } from '@mui/material';

const WrapperOuter = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('tablet')]: {
    display: 'flex',
    flexGrow: 1,
  },
}));

export default WrapperOuter;
