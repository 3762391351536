import { Box, Theme, useMediaQuery } from '@mui/material';
import {
  FC,
  ReactNode,
  SyntheticEvent,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { StyledTab, StyledTabList, StyledTabPanel } from './styles';

export interface ITab {
  label: ReactNode;
  value: ReactNode;
  key: string;
  indicatorColor?: string;
}

interface ITabsSectionProps {
  tabs: ITab[];
  onChange?: (tab: ITab) => void;
  className?: string;
  initialTab?: number;
  standardWidthFrom?: 'desktop' | 'tablet';
}

export const TabsSection: FC<ITabsSectionProps> = ({
  tabs,
  onChange,
  className,
  standardWidthFrom = 'desktop',
  initialTab = 0,
}) => {
  const [value, setValue] = useState(initialTab);

  useEffect(() => {
    if (typeof initialTab !== 'undefined') {
      setValue(initialTab);
    }
  }, [initialTab]);

  const handleChange = (_: SyntheticEvent, newValue: number) => {
    setValue(newValue);
    onChange?.(tabs[newValue]);
  };

  const isDesktop = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.up('desktop'),
  );

  const isTablet = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.up('tablet'),
  );

  const indicatorColors = tabs.map(({ indicatorColor }) => indicatorColor);

  const variant = useMemo(() => {
    if (standardWidthFrom === 'desktop') {
      return isDesktop ? 'standard' : 'fullWidth';
    }

    return isTablet ? 'standard' : 'scrollable';
  }, [isTablet, isDesktop, standardWidthFrom]);

  return (
    <Box
      sx={{
        flexGrow: 1,
        minHeight: 0,
        display: 'flex',
        flexDirection: 'column',
      }}
      className={className}
    >
      <StyledTabList
        value={value}
        onChange={handleChange}
        variant={variant}
        scrollButtons={false}
        TabIndicatorProps={{
          style: { backgroundColor: indicatorColors[value] },
        }}
      >
        {tabs.map(({ label, key }, index) => (
          <StyledTab key={`tab-${key}`} label={label} value={index} />
        ))}
      </StyledTabList>
      {tabs.map(({ value: tabContent, key }, index) => (
        <StyledTabPanel
          role="tabpanel"
          key={`tab-panel-${key}`}
          hidden={value !== index}
        >
          {tabContent}
        </StyledTabPanel>
      ))}
    </Box>
  );
};
