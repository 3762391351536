import { styled } from '@mui/material';

export const ChartCardWrapper = styled('div')(({ theme }) => ({
  height: '228px',
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1.5),
  borderRadius: '12px',
  backgroundColor: theme.palette.white.main,
}));
