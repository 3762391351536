import { FC } from 'react';

import { CommonSvgProps } from 'types/general';

export const RadioChecked: FC<CommonSvgProps> = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 10C0 4.48 4.48 0 10 0C15.52 0 20 4.48 20 10C20 15.52 15.52 20 10 20C4.48 20 0 15.52 0 10ZM7 10C7 11.6575 8.3425 13 10 13C11.6575 13 13 11.6575 13 10C13 8.3425 11.6575 7 10 7C8.3425 7 7 8.3425 7 10Z"
        fill="#B5F268"
      />
    </svg>
  );
};
