import { styled } from '@mui/material';

export const TopBlock = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(5),
  padding: theme.spacing(5, 0),
  borderRadius: '20px',
  backgroundColor: theme.palette.grey[50],
  textAlign: 'center',

  [theme.breakpoints.down('tablet')]: {
    padding: theme.spacing(5, 6.5),
  },
}));
