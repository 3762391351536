import { Box, Typography } from '@mui/material';
import { FC } from 'react';

import {
  ActionsItem,
  ActionsList,
  CloseButton,
  DialogStyled,
  Head,
} from './styles';
import { IMoreActionsMenuProps } from './types';
import { Close } from 'components/icons';

const MoreActionsMenu: FC<IMoreActionsMenuProps> = ({
  title,
  items,
  onClose,
  dataTestId,
}) => {
  return (
    <DialogStyled open onClose={onClose}>
      <CloseButton onClick={onClose} data-testid={`${dataTestId}-close-button`}>
        <Close />
      </CloseButton>
      <Box>
        <Head>
          <Typography variant="h5">{title}</Typography>
        </Head>
        <ActionsList>
          {items.map((item) => (
            <ActionsItem
              key={`mobile-menu-action-${item.label}`}
              onClick={item.onClick}
              data-testid={item.dataTestId}
            >
              <Typography variant="bodyL">{item.label}</Typography>
            </ActionsItem>
          ))}
        </ActionsList>
      </Box>
    </DialogStyled>
  );
};

export default MoreActionsMenu;
