import { styled } from '@mui/material';

export const WindTurbineChartSubheader = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(1.5, 2.5),
  borderRadius: '2px',
  backgroundColor: theme.palette.grey[50],
}));
