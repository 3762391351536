import { TranslationFunction } from 'types/general';

export function convertWh(
  w: number,
  t: TranslationFunction,
  precision: number = 3,
) {
  if (Math.abs(w) <= 1000)
    return `${w.toFixed(precision)} ${t('converters.Wh')}`;
  if (Math.abs(w) < 1_000_000)
    return `${(w / 1_000).toFixed(precision)} ${t('converters.kWh')}`;
  if (Math.abs(w) < 1_000_000_000_000)
    return `${(w / 1_000_000).toFixed(precision)} ${t('converters.MWh')}`;
  else
    return `${(w / 1_000_000_000_000).toFixed(precision)} ${t(
      'converters.TWh',
    )}`;
}
