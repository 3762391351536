/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface ReportReadyDtoAllOf
 */
export interface ReportReadyDtoAllOf {
    /**
     * 
     * @type {string}
     * @memberof ReportReadyDtoAllOf
     */
    'event'?: ReportReadyDtoAllOfEventEnum;
    /**
     * 
     * @type {string}
     * @memberof ReportReadyDtoAllOf
     */
    'link'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportReadyDtoAllOf
     */
    'reportFormat'?: ReportReadyDtoAllOfReportFormatEnum;
    /**
     * 
     * @type {string}
     * @memberof ReportReadyDtoAllOf
     */
    'customerName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportReadyDtoAllOf
     */
    'eventDateTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportReadyDtoAllOf
     */
    'generationDatetime'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportReadyDtoAllOf
     */
    'startDatetime'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportReadyDtoAllOf
     */
    'endDatetime'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ReportReadyDtoAllOfEventEnum {
    ASSET_ERROR_FIRED = 'ASSET_ERROR_FIRED',
    ASSET_ERROR_RESOLVED = 'ASSET_ERROR_RESOLVED',
    ASSET_CONNECTION_ERROR = 'ASSET_CONNECTION_ERROR',
    ASSET_CONNECTION_ERROR_RESOLVED = 'ASSET_CONNECTION_ERROR_RESOLVED',
    REPORT_READY = 'REPORT_READY',
    ASSET_ADDED = 'ASSET_ADDED',
    ASSET_TAGS_COLLECTION_ERROR = 'ASSET_TAGS_COLLECTION_ERROR',
    ASSET_TAGS_COLLECTION_ERROR_RESOLVED = 'ASSET_TAGS_COLLECTION_ERROR_RESOLVED',
    ASSET_OPERATIONAL_STATUS_CHANGED = 'ASSET_OPERATIONAL_STATUS_CHANGED',
    ASSET_ACTIVE_POWER_DEFLECTION_ERROR = 'ASSET_ACTIVE_POWER_DEFLECTION_ERROR',
    ASSET_ACTIVE_POWER_DEFLECTION_ERROR_RESOLVED = 'ASSET_ACTIVE_POWER_DEFLECTION_ERROR_RESOLVED',
    CONTROLLER_DISCONNECTED = 'CONTROLLER_DISCONNECTED',
    CONTROLLER_CONNECTED = 'CONTROLLER_CONNECTED',
    WEATHER_WARNING = 'WEATHER_WARNING',
    INVESTIGATION_DASHBOARD_READY = 'INVESTIGATION_DASHBOARD_READY'
}
/**
    * @export
    * @enum {string}
    */
export enum ReportReadyDtoAllOfReportFormatEnum {
    CSV = 'CSV',
    PDF = 'PDF'
}


