import { Grid } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ChipFilterButton } from './styles';

export type ChipFilterValue = { tKey: string; value: string };

interface Props {
  value: ChipFilterValue;
  options: ChipFilterValue[];
  optionsSize?: 'small' | 'medium';
  variant?: 'outlined' | 'contained';
  onChange: (value: ChipFilterValue) => void;
  className?: string;
}

export const ChipFilter: FC<Props> = ({
  value: currentValue,
  options,
  optionsSize = 'small',
  variant = 'outlined',
  onChange,
  className,
}) => {
  const { t } = useTranslation();

  return (
    <Grid container gap={0.5} width="auto" className={className}>
      {options.map((option) => (
        <ChipFilterButton
          key={option.tKey}
          active={currentValue.value === option.value}
          size={optionsSize}
          variant={variant}
          onClick={() => onChange(option)}
        >
          {t(option.tKey)}
        </ChipFilterButton>
      ))}
    </Grid>
  );
};
