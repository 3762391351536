import { Box, Theme, useMediaQuery } from '@mui/material';
import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ViewAllContainer } from './styles';
import NotificationMessage from '../NotificationMessage';
import CommonButton from 'components/common/CommonButton';
import ConnectUser from 'components/common/ConnectUser';
import { NoData } from 'components/common/NoData';
import routePaths from 'constants/routePaths';
import useNotificationsContext from 'contexts/notifications';
import { NotificationDto } from 'openapi-api/admin-service';

interface INotificationsListProps {
  notifications: NotificationDto[];
  handleNotificationClick?: (notification: NotificationDto) => void;
}

export const NotificationsList: FC<INotificationsListProps> = ({
  notifications,
  handleNotificationClick,
}) => {
  const { t } = useTranslation();
  const [addUserId, setAddUserId] = useState<string>();
  const isDesktop = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.up('desktop'),
  );
  const { isLoading, setIgnoreClickOutside, closeNotificationsPopup } =
    useNotificationsContext();

  const handleCloseConnectUser = useCallback(
    (e?: React.MouseEvent<Element, MouseEvent>) => {
      e?.stopPropagation();
      setAddUserId(undefined);
      setIgnoreClickOutside(false);
    },
    [setIgnoreClickOutside],
  );

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          minHeight: 0,
          borderRadius: '0 0 12px 12px',
          overflowY: 'scroll',
        }}
      >
        {!isLoading && !notifications.length ? (
          <NoData
            text={t('pages.notifications.noUnreadNotifications')}
            buttonText={t('pages.notifications.checkAllNotifications')}
            buttonLinkTo={routePaths.notifications.specific('all')}
            onButtonClick={closeNotificationsPopup}
            sx={{ button: { backgroundColor: 'transparent' } }}
          />
        ) : (
          <>
            {notifications.map((notification) => (
              <NotificationMessage
                key={notification?.id}
                notification={notification}
                onClick={handleNotificationClick}
                setAddUserId={setAddUserId}
              />
            ))}
            <ViewAllContainer>
              <Link
                to={routePaths.notifications.specific('all')}
                onClick={closeNotificationsPopup}
              >
                <CommonButton
                  variant="outlined"
                  size={isDesktop ? 'small' : 'large'}
                >{t`viewAll`}</CommonButton>
              </Link>
            </ViewAllContainer>
          </>
        )}
      </Box>
      {addUserId && (
        <ConnectUser
          onClose={handleCloseConnectUser}
          onSuccess={handleCloseConnectUser}
          publicId={addUserId}
        />
      )}
    </>
  );
};
