import { Box, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { MobileSubMenu } from './MobileSubMenu';
import {
  LogoLink,
  MobileMenuBody,
  MobileMenuHeader,
  MobileMenuUser,
  MobileMenuUserArrow,
  MobileMenuUserList,
  MobileMenuUserListItem,
  MobileMenuUserListItemWrapper,
  MobileMenuWrapper,
  TabWrapper,
} from './styles';
import useAuth from '../../../contexts/auth';
import useGlobalData from '../../../contexts/globalData';
import CommonButton from '../CommonButton';
import { Link } from '../Link';
import { ChevronDownL, Close, Logo, Logout, Settings } from 'components/icons';
import routePaths from 'constants/routePaths';
import { generateTabs } from 'constants/tabs';
import useUser from 'contexts/user';

export const MobileMenu = () => {
  const { onLogout } = useAuth();
  const { user, translatedRole } = useUser();
  const { setIsMobileMenuOpen, setIsAssetOnboardingFormOpened } =
    useGlobalData();
  const { t } = useTranslation();

  const [isMobileUserMenuOpen, setMobileUserMenuOpen] =
    useState<boolean>(false);

  const tabs = useMemo(() => generateTabs(t), [t]);

  const onLogoutClick = useCallback(() => {
    setIsMobileMenuOpen(false);
    onLogout();
  }, [setIsMobileMenuOpen, onLogout]);

  const handleAssetOnboardingClick = useCallback(() => {
    setIsMobileMenuOpen(false);
    setIsAssetOnboardingFormOpened(true);
  }, [setIsMobileMenuOpen, setIsAssetOnboardingFormOpened]);

  useEffect(() => {
    document.body.style.overflowY = 'hidden';

    return () => {
      document.body.style.overflowY = '';
    };
  }, []);

  return (
    <MobileMenuWrapper>
      <MobileMenuHeader>
        <LogoLink
          to={routePaths.parks.root}
          onClick={() => setIsMobileMenuOpen(false)}
        >
          <Logo />
        </LogoLink>
        <Box
          data-testid="close-mobile-menu"
          onClick={() => setIsMobileMenuOpen(false)}
        >
          <Close />
        </Box>
      </MobileMenuHeader>
      <MobileMenuBody>
        <Grid>
          {tabs.map((tab) =>
            !!tab.submenu ? (
              <MobileSubMenu
                key={tab.value}
                tab={tab}
                itemClick={() => !tab.disabled && setIsMobileMenuOpen(false)}
              />
            ) : (
              <Link
                key={tab.value}
                to={tab.value}
                component="a"
                sx={{ textDecoration: 'none' }}
              >
                <TabWrapper
                  onClick={() => !tab.disabled && setIsMobileMenuOpen(false)}
                  disabled={tab.disabled}
                >
                  <Typography variant="h4">{tab.label}</Typography>
                </TabWrapper>
              </Link>
            ),
          )}
          <CommonButton
            variant="contained"
            data-testid="assetOnboarding"
            sx={{
              width: '100%',
              maxWidth: '335px',
              my: 4,
            }}
            onClick={handleAssetOnboardingClick}
          >
            {t`assetOnboarding`}
          </CommonButton>
        </Grid>

        <Grid
          gap={2.5}
          container
          flexDirection="column"
          alignItems="center"
          maxWidth="335px"
        >
          <MobileMenuUser
            open={isMobileUserMenuOpen}
            onClick={() => setMobileUserMenuOpen((prev) => !prev)}
            data-testid="mobile-menu-user"
          >
            <Box display="flex" flexDirection="column" gap={0.5}>
              <Typography variant="h5" color="white.main">
                {user.username}
              </Typography>
              <Typography
                variant="bodyM"
                color="grey.400"
                textTransform="capitalize"
              >
                {translatedRole}
              </Typography>
            </Box>
            <MobileMenuUserArrow>
              <ChevronDownL />
            </MobileMenuUserArrow>
          </MobileMenuUser>
          {isMobileUserMenuOpen && (
            <MobileMenuUserList>
              <MobileMenuUserListItem>
                <Link to={routePaths.updateProfile} component="a">
                  <MobileMenuUserListItemWrapper
                    onClick={() => setIsMobileMenuOpen(false)}
                  >
                    <Settings />
                    <Typography variant="bodyMStrong">
                      {t('components.header.updateProfile')}
                    </Typography>
                  </MobileMenuUserListItemWrapper>
                </Link>
              </MobileMenuUserListItem>
              <MobileMenuUserListItem>
                <MobileMenuUserListItemWrapper onClick={onLogoutClick}>
                  <Logout />
                  <Typography variant="bodyMStrong">
                    {t('components.header.logOut')}
                  </Typography>
                </MobileMenuUserListItemWrapper>
              </MobileMenuUserListItem>
            </MobileMenuUserList>
          )}
        </Grid>
      </MobileMenuBody>
    </MobileMenuWrapper>
  );
};
