export const fieldsToSend = ['page', 'size', 'sort'];

export const sorting = {
  parkName: 'turbineName',
  customerName: 'customerName',
  status: 'status',
  createDatetime: 'createDatetime',
};

export const investigationDateTimeFormat = 'DD/MM/YYYY HH:mm:ss';
