import { useMediaQuery, useTheme } from '@mui/material';
import { FC } from 'react';

import { ReportContent } from './ReportContent';
import { PopupStyled } from './styles';
import { BottomPartContainer } from '../../styles';
import useReport from 'contexts/report';

export const ReportContentContainer: FC = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.only('desktop'));
  const { setReportValues } = useReport();

  return !isDesktop ? (
    <PopupStyled isOpen onClose={() => setReportValues(undefined)}>
      <ReportContent />
    </PopupStyled>
  ) : (
    <BottomPartContainer>
      <ReportContent />
    </BottomPartContainer>
  );
};
