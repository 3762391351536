import { GridSortModel } from '@mui/x-data-grid';
import { GridPaginationModel } from '@mui/x-data-grid/models/gridPaginationProps';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { FilterModel } from 'types/general';
import {
  formatQueryToSort,
  formatSortToQuery,
} from 'utils/functions/formatQuery';
import useUpdateSearchParams from 'utils/hooks/useUpdateSearchParams';

const ignoreKeys = ['sort', 'sortBy', 'page'];

const useDataGrid = (defaultSize = 10) => {
  const [showSearchOverlay, setShowSearchOverlay] = useState(false);
  const { searchParams, updateSearchParams, setSearchParams } =
    useUpdateSearchParams();

  const paginationModel: GridPaginationModel = {
    page: +(searchParams.get('page') ?? 0),
    pageSize: +(searchParams.get('size') ?? defaultSize),
  };

  const search = searchParams.get('search') ?? '';

  const onPaginationModelChange = useCallback(
    ({ page, pageSize }: GridPaginationModel) => {
      updateSearchParams('page', page);
      updateSearchParams('size', pageSize, 'size');
    },
    [updateSearchParams],
  );

  const onSearch = useCallback(
    (value: string) => {
      updateSearchParams('search', value);
    },
    [updateSearchParams],
  );

  const sortModel = useMemo(() => {
    return searchParams.has('sort')
      ? formatQueryToSort(searchParams.get('sort')!)
      : [];
  }, [searchParams]);

  const onSortModelChange = useCallback(
    (value: GridSortModel) => {
      const sorting = formatSortToQuery(value);
      updateSearchParams('sort', sorting);
    },
    [updateSearchParams],
  );

  const onFilterModelChange = useCallback(
    ({ field, value }: FilterModel) => {
      updateSearchParams(field, value);
    },
    [updateSearchParams],
  );

  useEffect(() => {
    const params = [];

    searchParams.forEach((value, key) => {
      if (!ignoreKeys.includes(key) && value !== 'All' && value)
        params.push([key, value]);
    });

    setShowSearchOverlay(!!params.length);
  }, [searchParams]);

  return {
    paginationModel,
    onPaginationModelChange,
    search,
    onSearch,
    sortModel,
    onSortModelChange,
    onFilterModelChange,
    showSearchOverlay,
    setShowSearchOverlay,
    setSearchParams,
    searchParams,
    updateSearchParams,
  };
};

export default useDataGrid;
