import { Typography } from '@mui/material';
import { FC } from 'react';

import {
  ChartCardWrapper,
  InvestigationDataChartIframe,
  InvestigationDataChartIframeWrapper,
} from '../styles';
import useAuth from 'contexts/auth';
import useWindTurbinePanels from 'contexts/WindTurbinePanels';
import getGrafanaWidgetLink from 'utils/functions/getGrafanaWidgetLink';

interface Props {
  title: string;
  panelId: number;
  fromDate: number;
  toDate: number;
}

export const ChartCard: FC<Props> = ({ title, panelId, fromDate, toDate }) => {
  const { accessData } = useAuth();
  const { panels } = useWindTurbinePanels();

  return (
    <ChartCardWrapper>
      <Typography
        variant="bodyM"
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
      >
        {title}
      </Typography>
      <InvestigationDataChartIframeWrapper>
        <InvestigationDataChartIframe
          title={title}
          src={getGrafanaWidgetLink({
            panelId: panelId.toString(),
            panelParameters: panels?.[panelId] || {},
            authToken: accessData?.accessToken || '',
            from: fromDate,
            to: toDate,
          })}
        />
      </InvestigationDataChartIframeWrapper>
    </ChartCardWrapper>
  );
};
