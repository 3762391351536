import { styled } from '@mui/material/styles';

const ReportPageWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  backgroundColor: theme.palette.black[500],
  display: 'flex',
  flexDirection: 'column',
}));

export default ReportPageWrapper;
