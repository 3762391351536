import { useContext } from 'react';

import { ParkContext } from './ParkContext';
import ParkProvider from './ParkProvider';

const useParkContext = () => {
  const context = useContext(ParkContext);

  if (typeof context === 'undefined' || context === null) {
    throw new Error('useParkContext must be used within a ParkProvider');
  }

  return context;
};

export { useParkContext, ParkProvider };
