import { styled } from '@mui/material';

export const WeatherDailyItems = styled('div')(({ theme }) => ({
  display: 'grid',
  gridAutoFlow: 'row',
  gridAutoRows: '25%',
  flexGrow: 1,

  [theme.breakpoints.down('tablet')]: {
    gridAutoRows: '87.75px',
  },
}));
