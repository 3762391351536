import { FC } from 'react';

import { CommonSvgProps } from 'types/general';

export const Information: FC<CommonSvgProps> = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 4C16.415 4 20 7.58501 20 12C20 16.415 16.415 20 12 20C7.58501 20 4 16.415 4 12C4 7.58501 7.58501 4 12 4ZM13.3311 16C13.8811 16 14.3271 15.554 14.3271 15.004C14.3271 14.454 13.8811 14.007 13.3311 14.007H12.9981V10.757C12.9981 10.314 12.7591 10 12.318 10C12.315 10 10.683 10 10.683 10C10.129 10 9.67804 10.45 9.67804 11.005C9.67804 11.56 10.129 12.01 10.683 12.01H10.995V14.007H10.654C10.104 14.007 9.65704 14.454 9.65704 15.004C9.65704 15.554 10.104 16 10.654 16H13.3311ZM12 6.65799C12.741 6.65799 13.342 7.25899 13.342 7.99999C13.342 8.74099 12.741 9.342 12 9.342C11.259 9.342 10.658 8.74099 10.658 7.99999C10.658 7.25899 11.259 6.65799 12 6.65799Z"
        fill="#767B84"
      />
    </svg>
  );
};
