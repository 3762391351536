import { styled } from '@mui/material';

import { WindTurbineDtoStatusEnum } from 'openapi-api/admin-service';

export const WindTurbineStatusWrapper = styled('div')<{
  status: WindTurbineDtoStatusEnum;
  height?: number | string;
}>(({ theme, status, height }) => {
  let bgColor: string | undefined = theme.palette.grey[50];
  switch (status) {
    case WindTurbineDtoStatusEnum.CREATED:
    case WindTurbineDtoStatusEnum.READY:
      bgColor = theme.palette.green[200];
      break;
    case WindTurbineDtoStatusEnum.OFFLINE:
      bgColor = theme.palette.grey[50];
      break;
    case WindTurbineDtoStatusEnum.NOT_SET:
      bgColor = theme.palette.grey[50];
      break;
    case WindTurbineDtoStatusEnum.ONBOARDING:
      bgColor = theme.palette.yellow[200];
      break;
    case WindTurbineDtoStatusEnum.ERROR:
      bgColor = theme.palette.warning[200];
      break;
  }

  return {
    padding: theme.spacing(0.75, 2),
    height: height,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: bgColor,
    borderRadius: '15px',
  };
});
