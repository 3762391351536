import { styled } from '@mui/material';

export const MobileMenuUser = styled('div', {
  shouldForwardProp: (propName) => propName !== 'open',
})<{ open?: boolean }>(({ theme, open }) => ({
  position: 'relative',
  width: '100%',
  padding: theme.spacing(2, 0),
  borderRadius: '12px',
  backgroundColor: theme.palette.black[500],
  textAlign: 'center',

  ...(open && {
    '& svg': {
      transform: 'rotate(180deg)',
    },

    '& path': {
      stroke: theme.palette.white.main,
    },
  }),
}));

export const MobileMenuUserArrow = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  right: theme.spacing(2),
  transform: 'translateY(-50%)',
}));

export const MobileMenuUserList = styled('ul')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.5),
  width: '100%',
  margin: 0,
  padding: 0,
}));

export const MobileMenuUserListItem = styled('li')(() => ({
  listStyleType: 'none',
  '& a': {
    textDecoration: 'none',
  },
}));

export const MobileMenuUserListItemWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1.5),
  padding: theme.spacing(1.5, 2),

  '& path': {
    stroke: theme.palette.grey[500],
  },

  '& .MuiTypography-root': {
    color: theme.palette.white.main,
  },
}));
