import { styled } from '@mui/material';

export const CloseIcon = styled('div')(({ theme }) => ({
  display: 'flex',
  height: '24px',
  width: '24px',
  cursor: 'pointer',

  '& > svg': {
    color: theme.palette.grey[300],
  },
}));
