import { Box, Typography } from '@mui/material';
import { useCallback, useMemo } from 'react';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import OrWrapper from './styles/OrWrapper';
import useApi from '../../../contexts/api';
import { SelectItem } from '../../../types/general';
import { useAsyncResource } from '../../../utils/hooks/useAsyncResource';
import { FormSelect } from '../form/Select';
import { CustomSelectProps } from '../form/Select/types';
import TextField from '../form/TextField';
import { LocationDto } from 'openapi-api/admin-service';

type Props = {
  setParkIsCreated?: (status: boolean) => void;
  selectProps: Partial<CustomSelectProps> & {
    name: string;
    defaultValue: string | string[];
  };
};

const SelectOrCreatePark = ({ setParkIsCreated, selectProps }: Props) => {
  const { locationControllerApi } = useApi();
  const { t } = useTranslation();
  const { setValue, clearErrors, watch } = useFormContext();

  const existingCustomer = watch('existingCustomer');
  const newCustomer = watch('newCustomer');

  const getParks = useCallback(async () => {
    try {
      const owner = existingCustomer as string;

      if (!owner) return [];

      const response = await locationControllerApi.getAllForCustomer({
        customerName: owner,
      });

      return response.data as LocationDto[];
    } catch {}
  }, [existingCustomer, locationControllerApi]);

  const clearSelectedParks = useCallback(() => {
    setValue(selectProps.name, selectProps.defaultValue);
    clearErrors(selectProps.name);
  }, [clearErrors, selectProps.defaultValue, selectProps.name, setValue]);

  const { resource: parks } = useAsyncResource({
    fetchResource: getParks,
  });

  const selectItems: SelectItem[] = useMemo(
    () =>
      parks?.map(({ name }) => ({
        label: name as string,
        value: name as string,
      })) || [],
    [parks],
  );

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      {!newCustomer && (
        <>
          <FormSelect
            name={selectProps.name}
            label={t`components.assetOnboarding.parkName`}
            items={selectItems}
            fullWidth
            dataTestId="selectParks"
            onItemClick={() => {
              setValue('newPark', { name: '', coordinates: '' });
              clearErrors('newPark');
              setParkIsCreated?.(false);
            }}
            multiple={!!selectProps.multiple}
          />
          <OrWrapper>
            <Typography
              variant="bodyM"
              color="grey.600"
              component="div"
              sx={{ background: 'white', px: 1, zIndex: 1 }}
            >{t`or`}</Typography>
          </OrWrapper>
          <Typography variant="h4">{t`components.assetOnboarding.createPark`}</Typography>
        </>
      )}
      <TextField
        name="newPark.name"
        label={t`components.assetOnboarding.parkName`}
        fullWidth
        onInput={() => {
          clearSelectedParks();
          setParkIsCreated?.(false);
        }}
        testId="newParkName"
      />
      <TextField
        name="newPark.coordinates"
        label={t`components.assetOnboarding.coordinates`}
        fullWidth
        onInput={() => {
          clearSelectedParks();
          setParkIsCreated?.(false);
        }}
        testId="newParkCoordinates"
      />
    </Box>
  );
};
export default SelectOrCreatePark;
