import { FC } from 'react';

import { CommonSvgProps } from 'types/general';

export const Calendar: FC<CommonSvgProps> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    {...props}
  >
    <path
      d="M3.25 6C3.25 4.89543 4.14543 4 5.25 4H19.25C20.3546 4 21.25 4.89543 21.25 6V19C21.25 20.1046 20.3546 21 19.25 21H5.25C4.14543 21 3.25 20.1046 3.25 19V6Z"
      stroke="#767B84"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.25 8H20.25"
      stroke="#767B84"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.25 3V5"
      stroke="#767B84"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.25 3V5"
      stroke="#767B84"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
