import { useCallback, useEffect, useState } from 'react';

import useInterval from 'utils/hooks/useInterval';
import useLocalStorage from 'utils/hooks/useLocalStorage';

const defaultSecondsToExpire = 60 * 60;

const applyMousedownListener = (dialogId: string, refreshTimer: () => void) => {
  const listener = (event: Event) => {
    const dialog = document.getElementById(dialogId);

    if (dialog?.contains(event.target as Node)) {
      return;
    }
    refreshTimer();
  };
  document.addEventListener('mousedown', listener);
  return () => {
    document.removeEventListener('mousedown', listener);
  };
};

const useLogoutTimer = (isLoggedIn: boolean, dialogId: string) => {
  const [lastAction, setLastAction] = useLocalStorage<number>('last-action');
  const [secondsToExpire, setSecondsToExpire] = useState(
    defaultSecondsToExpire,
  );
  const [showConfirmation, setShowConfirmation] = useState(false);

  const refreshTimer = useCallback(() => {
    setShowConfirmation(false);
    setLastAction(Date.now());
  }, [setLastAction]);

  const check = useCallback(() => {
    if (!lastAction || !isLoggedIn) {
      return;
    }
    const now = Date.now();
    const logoutTime = lastAction + defaultSecondsToExpire * 1000;
    const diff = logoutTime - now;

    setSecondsToExpire(Math.floor(diff / 1000));
  }, [lastAction, isLoggedIn]);

  useInterval(() => {
    check();
  }, 1000);

  useEffect(() => {
    if (isLoggedIn) {
      refreshTimer();
      return;
    }
    setSecondsToExpire(defaultSecondsToExpire);
  }, [isLoggedIn, refreshTimer]);

  useEffect(() => {
    return applyMousedownListener(dialogId, refreshTimer);
  }, [dialogId, refreshTimer]);

  useEffect(() => {
    setShowConfirmation(secondsToExpire <= 300);
  }, [secondsToExpire]);

  return { showConfirmation, secondsToExpire, refreshTimer };
};

export default useLogoutTimer;
