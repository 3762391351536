import { styled } from '@mui/material';

import CommonButton from 'components/common/CommonButton';
import { ICommonButtonProps } from 'components/common/CommonButton/CommonButton';

export const ClearButton = styled((props: ICommonButtonProps) => (
  <CommonButton {...props} />
))(({ theme }) => ({
  color: theme.palette.grey[300],

  '&.Mui-disabled': {
    color: theme.palette.grey[300],
    opacity: 0.45,
  },
}));
