import { styled } from '@mui/material';

export const InfoBlock = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  marginTop: theme.spacing(3),
  padding: theme.spacing(3, 4),
  borderRadius: '20px',
  backgroundColor: theme.palette.grey[50],

  [theme.breakpoints.down('desktop')]: {
    padding: theme.spacing(3),
  },
}));
