/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { PageReportRequestHistoryDto } from '../models';
// @ts-ignore
import { ReportMissingDataCheckDto } from '../models';
// @ts-ignore
import { ReportMissingDataIntervalDto } from '../models';
// @ts-ignore
import { ReportRequestDto } from '../models';
/**
 * ReportRequestControllerApi - axios parameter creator
 * @export
 */
export const ReportRequestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ReportRequestDto} reportRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReportRequest: async (reportRequestDto: ReportRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportRequestDto' is not null or undefined
            assertParamExists('createReportRequest', 'reportRequestDto', reportRequestDto)
            const localVarPath = `/report/request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reportRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ReportMissingDataCheckDto} reportMissingDataCheckDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportMissingDataIntervals: async (reportMissingDataCheckDto: ReportMissingDataCheckDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportMissingDataCheckDto' is not null or undefined
            assertParamExists('getReportMissingDataIntervals', 'reportMissingDataCheckDto', reportMissingDataCheckDto)
            const localVarPath = `/report/request/missing-data-intervals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reportMissingDataCheckDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportRequests: async (page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/report/request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportRequestControllerApi - functional programming interface
 * @export
 */
export const ReportRequestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportRequestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ReportRequestDto} reportRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createReportRequest(reportRequestDto: ReportRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createReportRequest(reportRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ReportMissingDataCheckDto} reportMissingDataCheckDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReportMissingDataIntervals(reportMissingDataCheckDto: ReportMissingDataCheckDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReportMissingDataIntervalDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReportMissingDataIntervals(reportMissingDataCheckDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReportRequests(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageReportRequestHistoryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReportRequests(page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportRequestControllerApi - factory interface
 * @export
 */
export const ReportRequestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportRequestControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {ReportRequestControllerApiCreateReportRequestRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReportRequest(requestParameters: ReportRequestControllerApiCreateReportRequestRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.createReportRequest(requestParameters.reportRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReportRequestControllerApiGetReportMissingDataIntervalsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportMissingDataIntervals(requestParameters: ReportRequestControllerApiGetReportMissingDataIntervalsRequest, options?: AxiosRequestConfig): AxiosPromise<Array<ReportMissingDataIntervalDto>> {
            return localVarFp.getReportMissingDataIntervals(requestParameters.reportMissingDataCheckDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReportRequestControllerApiGetReportRequestsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportRequests(requestParameters: ReportRequestControllerApiGetReportRequestsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<PageReportRequestHistoryDto> {
            return localVarFp.getReportRequests(requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createReportRequest operation in ReportRequestControllerApi.
 * @export
 * @interface ReportRequestControllerApiCreateReportRequestRequest
 */
export interface ReportRequestControllerApiCreateReportRequestRequest {
    /**
     * 
     * @type {ReportRequestDto}
     * @memberof ReportRequestControllerApiCreateReportRequest
     */
    readonly reportRequestDto: ReportRequestDto
}

/**
 * Request parameters for getReportMissingDataIntervals operation in ReportRequestControllerApi.
 * @export
 * @interface ReportRequestControllerApiGetReportMissingDataIntervalsRequest
 */
export interface ReportRequestControllerApiGetReportMissingDataIntervalsRequest {
    /**
     * 
     * @type {ReportMissingDataCheckDto}
     * @memberof ReportRequestControllerApiGetReportMissingDataIntervals
     */
    readonly reportMissingDataCheckDto: ReportMissingDataCheckDto
}

/**
 * Request parameters for getReportRequests operation in ReportRequestControllerApi.
 * @export
 * @interface ReportRequestControllerApiGetReportRequestsRequest
 */
export interface ReportRequestControllerApiGetReportRequestsRequest {
    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof ReportRequestControllerApiGetReportRequests
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof ReportRequestControllerApiGetReportRequests
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof ReportRequestControllerApiGetReportRequests
     */
    readonly sort?: Array<string>
}

/**
 * ReportRequestControllerApi - object-oriented interface
 * @export
 * @class ReportRequestControllerApi
 * @extends {BaseAPI}
 */
export class ReportRequestControllerApi extends BaseAPI {
    /**
     * 
     * @param {ReportRequestControllerApiCreateReportRequestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportRequestControllerApi
     */
    public createReportRequest(requestParameters: ReportRequestControllerApiCreateReportRequestRequest, options?: AxiosRequestConfig) {
        return ReportRequestControllerApiFp(this.configuration).createReportRequest(requestParameters.reportRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReportRequestControllerApiGetReportMissingDataIntervalsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportRequestControllerApi
     */
    public getReportMissingDataIntervals(requestParameters: ReportRequestControllerApiGetReportMissingDataIntervalsRequest, options?: AxiosRequestConfig) {
        return ReportRequestControllerApiFp(this.configuration).getReportMissingDataIntervals(requestParameters.reportMissingDataCheckDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReportRequestControllerApiGetReportRequestsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportRequestControllerApi
     */
    public getReportRequests(requestParameters: ReportRequestControllerApiGetReportRequestsRequest = {}, options?: AxiosRequestConfig) {
        return ReportRequestControllerApiFp(this.configuration).getReportRequests(requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }
}
