import { styled } from '@mui/material';

export const StatsItem = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',

  [theme.breakpoints.down('desktop')]: {
    alignItems: 'flex-start',
  },
}));
