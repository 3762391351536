import { styled } from '@mui/material/styles';

import { TabsSection } from 'components/common/TabsSection/TabsSection';

export const TabSectionsWrapper = styled(TabsSection)(({ theme }) => ({
  flexGrow: 0,
  marginTop: theme.spacing(5),
  '& .MuiTabs-flexContainer': {
    paddingLeft: '0',
    gap: theme.spacing(5),
  },
  '& .MuiTabs-scroller': {
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
  },

  [theme.breakpoints.down('desktop')]: {
    marginTop: theme.spacing(4),

    '& .MuiTabs-flexContainer': {
      gap: theme.spacing(2.5),
    },
  },
}));
