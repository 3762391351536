import { ChipProps, Typography } from '@mui/material';
import { FC } from 'react';

import { StyledIdBlock } from './styles';

interface IIdBlockProps extends ChipProps {
  id?: string;
}

export const IdBlock: FC<IIdBlockProps> = ({ id, ...rest }) => {
  return (
    <StyledIdBlock
      label={<Typography variant="bodySStrong">{`MAC: ${id}`}</Typography>}
      {...rest}
    />
  );
};
