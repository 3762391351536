import { styled } from '@mui/material/styles';

export const TabWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'disabled',
})<{ disabled?: boolean }>(({ theme, disabled = false }) => ({
  padding: theme.spacing(3, 0),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'column',
  position: 'relative',
  cursor: disabled ? 'auto' : 'pointer',
  width: '100%',
  minWidth: '335px',
  borderBottom: `1px solid ${theme.palette.grey['800']}`,
  '& h4': {
    color: disabled ? theme.palette.grey[700] : theme.palette.white.main,
  },
}));
