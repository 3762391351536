import { Typography, styled } from '@mui/material';

const Dash = styled(Typography)(({ theme }) => ({
  color:
    theme.palette.mode === 'light'
      ? theme.palette.black[500]
      : theme.palette.grey[700],
}));

export default Dash;
