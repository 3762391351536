import { Dialog } from '@mui/material';
import { styled } from '@mui/material/styles';

const DialogWrapper = styled(Dialog, {
  shouldForwardProp: (prop) => prop !== 'yPadding' && prop !== 'width',
})<{ yPadding?: number; width?: number }>(({ theme, yPadding = 8, width }) => ({
  '& .MuiPaper-root': {
    padding: theme.spacing(yPadding, 6),
    borderRadius: theme.spacing(1.5),
    margin: 0,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.only('desktop')]: {
      width: width ? `${width}px` : 'auto',
    },
    [theme.breakpoints.down('desktop')]: {
      borderRadius: 0,
    },
    [theme.breakpoints.down('tablet')]: {
      padding: theme.spacing(7.5, 2.5),
    },
  },
}));

export default DialogWrapper;
