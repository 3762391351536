import { FC } from 'react';

import { CommonSvgProps } from 'types/general';

export const Copy: FC<CommonSvgProps> = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.76923 15H4.84615C4.35652 15 3.88695 14.8055 3.54073 14.4593C3.1945 14.1131 3 13.6435 3 13.1538V4.84615C3 4.35652 3.1945 3.88695 3.54073 3.54073C3.88695 3.1945 4.35652 3 4.84615 3H13.1538C13.6435 3 14.1131 3.1945 14.4593 3.54073C14.8055 3.88695 15 4.35652 15 4.84615V5.76923"
      stroke="#BABEC5"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 11C9 9.89543 9.89543 9 11 9H19C20.1046 9 21 9.89543 21 11V19C21 20.1046 20.1046 21 19 21H11C9.89543 21 9 20.1046 9 19V11Z"
      stroke="#BABEC5"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
