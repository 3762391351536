import { styled } from '@mui/material';

import { DateTimePicker } from 'components/common/DatePicker/DateTimePicker';

export const DateTimePickerStyled = styled(DateTimePicker)(({ theme }) => ({
  [theme.breakpoints.down('tablet')]: {
    flexDirection: 'column',
    gap: theme.spacing(3),
    '& > .MuiTypography-root': {
      display: 'none',
    },
  },
}));
