import { css } from '@emotion/react';

const globalStyles = css`
  body {
    height: 100dvh;
    margin: 0;
    padding: 0;
  }

  #root {
    height: 100%;
  }

  * {
    box-sizing: border-box;
  }

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #aaa;
    border-radius: 20px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: #666;
  }
  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px rgb(239, 242, 247) inset;
  }

  iframe {
    border: none;
    width: 100%;
  }

  a {
    text-decoration: none;
  }
`;

export default globalStyles;
