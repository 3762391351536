import { styled } from '@mui/material';

import { Link } from 'components/common/Link';

export const BreadcrumbLink = styled(Link, {
  shouldForwardProp: (prop) => prop !== 'active',
})<{ active?: boolean }>(({ theme, active = false }) => ({
  ...theme.typography.bodyS,
  display: 'flex',
  alignItems: 'center',
  columnGap: theme.spacing(0.75),
  color: active ? theme.palette.grey[200] : theme.palette.grey[700],
  textDecoration: 'none',
}));
