import { ChipFilterValue } from 'components/common/ChipFilter';
import { PeriodFilterValues } from 'types/general';

export const timePeriodOptions: ChipFilterValue[] = [
  { tKey: 'components.periodFilter.day', value: PeriodFilterValues.day },
  { tKey: 'components.periodFilter.week', value: PeriodFilterValues.week },
  { tKey: 'components.periodFilter.month', value: PeriodFilterValues.month },
  {
    tKey: 'components.periodFilter.current',
    value: PeriodFilterValues.current,
  },
];
