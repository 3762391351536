import { Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { WTLink } from './WTLink';
import CommonButton from 'components/common/CommonButton';
import useApi from 'contexts/api';
import useNotificationsContext from 'contexts/notifications';
import { AssetAddedDto } from 'openapi-api/admin-service';
import { getStringFromISODate } from 'utils/functions/getStringFromISODate';

interface Props {
  details: AssetAddedDto;
  setAddUserId: (publicId?: string) => void;
  extended: boolean;
  locationId?: number | string;
}

const TemplateAssetAdded: FC<Props> = ({
  details,
  setAddUserId,
  extended,
  locationId,
}) => {
  const { publicId, eventDateTime, assetNameForAdmin } = details;
  const { setIgnoreClickOutside } = useNotificationsContext();
  const { t } = useTranslation();
  const { windTurbineControllerApi } = useApi();
  const [isAssetConnected, setIsAssetConnected] = useState(true);

  useEffect(() => {
    const setConnectionStatus = async () => {
      if (typeof publicId === 'undefined') {
        setIsAssetConnected(true);
        return;
      }
      try {
        const isConnected = (
          await windTurbineControllerApi.getWindTurbine({ publicId })
        ).data?.connected;
        setIsAssetConnected(!!isConnected);
      } catch {
        setIsAssetConnected(true);
      }
    };
    setConnectionStatus();
  }, [publicId, windTurbineControllerApi]);

  if (!publicId) {
    return null;
  }

  return (
    <>
      <Typography variant={extended ? 'bodyM' : 'bodyS'}>
        <Trans
          i18nKey="notifications.assetAdded"
          values={{ eventDateTime: getStringFromISODate(eventDateTime) }}
          components={[
            <WTLink
              key={`assetAdded-link-${publicId}`}
              locationId={locationId}
              publicId={publicId}
              linkText={assetNameForAdmin}
              newTab={extended}
            />,
          ]}
        />
      </Typography>
      {!isAssetConnected && (
        <CommonButton
          sx={{ width: 'fit-content' }}
          size="medium"
          onClick={() => {
            setIgnoreClickOutside(true);
            setAddUserId(publicId);
          }}
        >
          {t`buttons.connectUser`}
        </CommonButton>
      )}
    </>
  );
};

export default TemplateAssetAdded;
