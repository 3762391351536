import { styled } from '@mui/material';

export const FormButtons = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(3),

  [theme.breakpoints.down('desktop')]: {
    position: 'static',
    flexDirection: 'column-reverse',
  },
}));
