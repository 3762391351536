import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  EditParkDetailsPopup,
  WeatherDaily,
  WeatherHourly,
} from './components';
import {
  CustomerNameWrapper,
  HeadStatItem,
  MoreActionsButtonStyled,
  TabsSectionStyled,
  WeatherWrapper,
  Wrapper,
} from './styles';
import { Breadcrumbs } from 'components/common/Breadcrumbs';
import CommonButton from 'components/common/CommonButton';
import MoreActionsMenu from 'components/common/MoreActionsMenu';
import { ITab } from 'components/common/TabsSection/TabsSection';
import { WindTurbinesTable } from 'components/shared/WindTurbinesTable';
import routePaths from 'constants/routePaths';
import { useParkContext } from 'contexts/park';
import { convertWh } from 'utils/functions/converters';

export const ParkComponent: FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { details, weather } = useParkContext();
  const [isMobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);
  const [isEditParkOpened, setEditParkOpened] = useState<boolean>(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('tablet'));

  const windTurbineCounterText = useMemo(() => {
    if (details?.turbineCount === 1) {
      return `1 ${t('pages.parkDetails.windTurbinesCounter.singular')}`;
    }

    return `${details?.turbineCount || 0} ${t(
      'pages.parkDetails.windTurbinesCounter.plural',
    )}`;
  }, [details?.turbineCount, t]);

  const onEditParkDetailsClick = useCallback(() => {
    setMobileMenuOpen(false);
    setEditParkOpened(true);
  }, []);

  const editParkDetailsDefaultValues = useMemo(
    () => ({
      name: details?.locationName || '',
      coordinates:
        details?.latitude && details?.longitude
          ? `${details?.latitude}, ${details?.longitude}`
          : '',
    }),
    [details?.latitude, details?.locationName, details?.longitude],
  );

  const weatherTabs = useMemo(
    (): ITab[] => [
      {
        label: (
          <Typography variant="bodyMStrong">
            {t('pages.parkDetails.weather.todayWeatherTitle')}
          </Typography>
        ),
        value: <WeatherHourly />,
        key: 'today',
      },
      {
        label: (
          <Typography variant="bodyMStrong">
            {t('pages.parkDetails.weather.dailyWeatherTitle')}
          </Typography>
        ),
        value: <WeatherDaily />,
        key: 'daily',
      },
    ],
    [t],
  );

  return (
    <Wrapper>
      <Breadcrumbs
        items={[
          {
            label: t('pages.parks.title'),
            path: routePaths.parks.root,
          },
          { label: details?.locationName || '', path: '' },
        ]}
      />
      <Box
        display="flex"
        flexDirection="column"
        gap={{ mobile: 3, desktop: 4 }}
        marginTop={{ mobile: 3, desktop: 5 }}
      >
        <Box position="relative">
          <Typography variant="subheading">{windTurbineCounterText}</Typography>
          <Typography variant="h1" sx={{ mt: 1.5 }}>
            {details?.locationName}
          </Typography>
          <Box position="relative">
            <CustomerNameWrapper>
              <Typography variant="h5">{details?.customerName}</Typography>
            </CustomerNameWrapper>
            <Box
              display="flex"
              flexDirection={{ mobile: 'column', tablet: 'row' }}
              gap={{ mobile: 0.5, desktop: 1.5 }}
              width="fit-content"
              marginTop={{ mobile: 2, desktop: 2.5 }}
            >
              <HeadStatItem>
                <Typography variant="bodyS">
                  {t('pages.parkDetails.activeEnergyCounter')}
                </Typography>
                <Typography variant="bodySStrong">
                  {convertWh(details?.totalActiveEnergy || 0, t)}
                </Typography>
              </HeadStatItem>
              <HeadStatItem>
                <Typography variant="bodyS">
                  {t('pages.parkDetails.lostEnergyCounter')}
                </Typography>
                <Typography variant="bodySStrong">
                  {convertWh(details?.totalLostEnergy || 0, t)}
                </Typography>
              </HeadStatItem>
              <CommonButton
                variant="outlined"
                size="small"
                sx={{
                  display: {
                    mobile: 'none',
                    desktop: 'inline-flex',
                  },
                  py: 0.75,
                }}
                onClick={onEditParkDetailsClick}
              >
                {t('pages.parkDetails.editParkDetails.title')}
              </CommonButton>
            </Box>
            <MoreActionsButtonStyled
              onClick={() => setMobileMenuOpen(true)}
              data-testid="park-actions-menu-button"
            />
          </Box>
        </Box>
        {isMobile && weather && <TabsSectionStyled tabs={weatherTabs} />}
        {!isMobile && weather && (
          <WeatherWrapper>
            <WeatherHourly />
            <WeatherDaily />
          </WeatherWrapper>
        )}
        {details?.locationId !== undefined && (
          <WindTurbinesTable locationId={details.locationId} />
        )}
      </Box>
      {!!isMobileMenuOpen && (
        <MoreActionsMenu
          title={details?.locationName}
          items={[
            {
              label: t('pages.parkDetails.editParkDetails.title'),
              onClick: onEditParkDetailsClick,
              dataTestId: 'park-actions-menu-editPark-item',
            },
          ]}
          onClose={() => setMobileMenuOpen(false)}
          dataTestId="park-actions-menu"
        />
      )}
      <EditParkDetailsPopup
        isOpen={isEditParkOpened}
        onClose={() => setEditParkOpened(false)}
        defaultValues={editParkDetailsDefaultValues}
        dataTestId="edit-park-details"
      />
    </Wrapper>
  );
};
