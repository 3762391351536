import { useEffect, useRef } from 'react';

const useInterval = (callback: () => void, period: number) => {
  const savedCallback = useRef(callback);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const intervalId = setInterval(() => savedCallback.current(), period);
    return () => {
      clearInterval(intervalId);
    };
  }, [period]);
};

export default useInterval;
