import { styled } from '@mui/material';

import Tabs from 'components/common/Tabs';

export const TabsStyled = styled(Tabs)(({ theme }) => ({
  marginTop: theme.spacing(6.5),

  [theme.breakpoints.down('desktop')]: {
    marginTop: theme.spacing(5),
  },
}));
