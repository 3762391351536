import { Button, styled } from '@mui/material';

export const ActionButton = styled(Button)<{ color?: string }>(
  ({ theme, color }) => ({
    ...theme.typography.buttonPrimary,
    ...(!color && { color: theme.palette.green[600] }),
    textTransform: 'uppercase',

    '&: hover': {
      ...(!color && { color: theme.palette.green[600] }),
      backgroundColor: 'unset',
    },
  }),
);
