import { styled } from '@mui/material';

export const NextPrevButton = styled('div', {
  shouldForwardProp: (propName) =>
    propName !== 'next' && propName !== 'disabled',
})<{ next?: boolean; disabled?: boolean }>(({ theme, next, disabled }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexShrink: 0,
  height: '52px',
  width: '52px',
  border: `2px solid ${theme.palette.grey[200]}`,
  borderRadius: '50%',
  userSelect: 'none',
  opacity: disabled ? 0.45 : 1,
  pointerEvents: disabled ? 'none' : 'auto',
  cursor: 'pointer',

  '& > svg': {
    transform: next ? 'scaleX(-1)' : 'none',
  },
}));
