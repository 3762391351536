/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { NotificationDtoDetails } from './notification-dto-details';

/**
 * 
 * @export
 * @interface NotificationDto
 */
export interface NotificationDto {
    /**
     * 
     * @type {number}
     * @memberof NotificationDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    'type'?: NotificationDtoTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationDto
     */
    'markedAsRead'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationDto
     */
    'isNew'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    'createDatetime'?: string;
    /**
     * 
     * @type {number}
     * @memberof NotificationDto
     */
    'customerId'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotificationDto
     */
    'locationId'?: number;
    /**
     * 
     * @type {NotificationDtoDetails}
     * @memberof NotificationDto
     */
    'details'?: NotificationDtoDetails;
}

/**
    * @export
    * @enum {string}
    */
export enum NotificationDtoTypeEnum {
    ERROR = 'ERROR',
    REMINDER = 'REMINDER',
    WARNING = 'WARNING'
}


