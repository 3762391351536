import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

export const FormWrapper = styled(Grid)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[800]}`,
  borderRadius: '25px',
  padding: theme.spacing(4),

  [theme.breakpoints.down('desktop')]: {
    padding: theme.spacing(3),
  },
}));
