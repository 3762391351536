import { ThemeProvider } from '@mui/material';
import React, { FC, PropsWithChildren } from 'react';

import { ApiProvider } from './api';
import { AuthProvider } from './auth';
import { Compose } from './Compose';
import GlobalDataProvider from './globalData/Provider';
import { GlobalLoaderProvider } from './globalLoader';
import { ResponsePopupProvider } from './responsePopup';
import { UserProvider } from './user';
import { lightTheme } from 'styles/theme';

const providers = [
  GlobalLoaderProvider,
  AuthProvider,
  ApiProvider,
  GlobalDataProvider,
  ResponsePopupProvider,
  UserProvider,
];

const AppProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  return (
    <ThemeProvider theme={lightTheme}>
      <Compose components={providers}>{children}</Compose>
    </ThemeProvider>
  );
};

export default AppProvider;
