import { Typography, Box, Tooltip } from '@mui/material';
import { FC, useMemo } from 'react';

import { ActiveChip, InactiveChip } from './styles';
import { ControlStatuses } from './types';

export interface WindTurbineControlStatusProps {
  status: ControlStatuses;
  text?: string;
  dataTestId?: string;
}

export const WindTurbineControlStatus: FC<WindTurbineControlStatusProps> = ({
  status,
  text,
  dataTestId,
}) => {
  const activityChip = useMemo(() => {
    switch (status) {
      case ControlStatuses.ACTIVE:
        return <ActiveChip />;
      case ControlStatuses.INACTIVE:
        return <InactiveChip />;
      default:
        return <></>;
    }
  }, [status]);

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      px={1}
      gap={1}
      sx={{
        overflow: 'hidden',
      }}
    >
      {activityChip}
      <Tooltip title={text}>
        <Typography
          variant="bodyS"
          sx={{
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
          data-testid={dataTestId}
        >
          {text}
        </Typography>
      </Tooltip>
    </Box>
  );
};
