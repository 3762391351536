import { styled } from '@mui/material';

export const FieldsWrapperGeneral = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gap: theme.spacing(3),
  '& > *:last-child': {
    gridColumn: '1 / 3',
  },

  [theme.breakpoints.down('tablet')]: {
    gridTemplateColumns: '1fr',
    '& > *:last-child': {
      gridColumn: 'unset',
    },
  },
}));
