export const getStringFromISODate = (dateTime?: string) => {
  if (!dateTime) return undefined;

  const dateObj = new Date(dateTime);

  const addTrailingZero = (datePart: number) =>
    datePart < 10 ? `0${datePart}` : datePart;

  const day = addTrailingZero(dateObj.getDate());
  const month = addTrailingZero(dateObj.getMonth() + 1);
  const year = dateObj.getFullYear();

  const hours = addTrailingZero(dateObj.getHours());
  const minutes = addTrailingZero(dateObj.getMinutes());
  const seconds = addTrailingZero(dateObj.getSeconds());

  return `${hours}:${minutes}:${seconds} ${day}.${month}.${year}`;
};
