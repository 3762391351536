import { styled } from '@mui/material';

export const ContainerStyled = styled('div')(({ theme }) => ({
  height: '132px',
  borderRadius: '16px',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: theme.palette.grey[200],
  backgroundColor: theme.palette.grey[300],
}));
