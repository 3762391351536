import { styled } from '@mui/material/styles';

export const NotificationsBlockHeaderWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(3, 2.5, 2.5),
  justifyContent: 'space-between',
  '& svg': {
    width: '24px',
    height: '24px',
  },
}));
