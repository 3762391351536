/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { PageUserDetailsDto } from '../models';
// @ts-ignore
import { UserDetailsDto } from '../models';
// @ts-ignore
import { UserUpdatePasswordRequestDto } from '../models';
// @ts-ignore
import { UserUpdatePasswordResponseDto } from '../models';
/**
 * AdminUserControllerApi - axios parameter creator
 * @export
 */
export const AdminUserControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<'USER' | 'ADMIN' | 'VIEWER' | 'SUPER_ADMIN'>} [rolePermission] 
         * @param {Array<'ACTIVE' | 'INACTIVE'>} [status] 
         * @param {string} [search] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminUserDetails: async (rolePermission?: Array<'USER' | 'ADMIN' | 'VIEWER' | 'SUPER_ADMIN'>, status?: Array<'ACTIVE' | 'INACTIVE'>, search?: string, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin-user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (rolePermission) {
                localVarQueryParameter['rolePermission'] = rolePermission;
            }

            if (status) {
                localVarQueryParameter['status'] = status;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminUserDetailsById: async (userId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getAdminUserDetailsById', 'userId', userId)
            const localVarPath = `/admin-user/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserUpdatePasswordRequestDto} userUpdatePasswordRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePassword2: async (userUpdatePasswordRequestDto: UserUpdatePasswordRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userUpdatePasswordRequestDto' is not null or undefined
            assertParamExists('updatePassword2', 'userUpdatePasswordRequestDto', userUpdatePasswordRequestDto)
            const localVarPath = `/admin-user/password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userUpdatePasswordRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminUserControllerApi - functional programming interface
 * @export
 */
export const AdminUserControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminUserControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Array<'USER' | 'ADMIN' | 'VIEWER' | 'SUPER_ADMIN'>} [rolePermission] 
         * @param {Array<'ACTIVE' | 'INACTIVE'>} [status] 
         * @param {string} [search] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminUserDetails(rolePermission?: Array<'USER' | 'ADMIN' | 'VIEWER' | 'SUPER_ADMIN'>, status?: Array<'ACTIVE' | 'INACTIVE'>, search?: string, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageUserDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminUserDetails(rolePermission, status, search, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminUserDetailsById(userId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminUserDetailsById(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UserUpdatePasswordRequestDto} userUpdatePasswordRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePassword2(userUpdatePasswordRequestDto: UserUpdatePasswordRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserUpdatePasswordResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePassword2(userUpdatePasswordRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminUserControllerApi - factory interface
 * @export
 */
export const AdminUserControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminUserControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {AdminUserControllerApiGetAdminUserDetailsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminUserDetails(requestParameters: AdminUserControllerApiGetAdminUserDetailsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<PageUserDetailsDto> {
            return localVarFp.getAdminUserDetails(requestParameters.rolePermission, requestParameters.status, requestParameters.search, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminUserControllerApiGetAdminUserDetailsByIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminUserDetailsById(requestParameters: AdminUserControllerApiGetAdminUserDetailsByIdRequest, options?: AxiosRequestConfig): AxiosPromise<UserDetailsDto> {
            return localVarFp.getAdminUserDetailsById(requestParameters.userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminUserControllerApiUpdatePassword2Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePassword2(requestParameters: AdminUserControllerApiUpdatePassword2Request, options?: AxiosRequestConfig): AxiosPromise<UserUpdatePasswordResponseDto> {
            return localVarFp.updatePassword2(requestParameters.userUpdatePasswordRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getAdminUserDetails operation in AdminUserControllerApi.
 * @export
 * @interface AdminUserControllerApiGetAdminUserDetailsRequest
 */
export interface AdminUserControllerApiGetAdminUserDetailsRequest {
    /**
     * 
     * @type {Array<'USER' | 'ADMIN' | 'VIEWER' | 'SUPER_ADMIN'>}
     * @memberof AdminUserControllerApiGetAdminUserDetails
     */
    readonly rolePermission?: Array<'USER' | 'ADMIN' | 'VIEWER' | 'SUPER_ADMIN'>

    /**
     * 
     * @type {Array<'ACTIVE' | 'INACTIVE'>}
     * @memberof AdminUserControllerApiGetAdminUserDetails
     */
    readonly status?: Array<'ACTIVE' | 'INACTIVE'>

    /**
     * 
     * @type {string}
     * @memberof AdminUserControllerApiGetAdminUserDetails
     */
    readonly search?: string

    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof AdminUserControllerApiGetAdminUserDetails
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof AdminUserControllerApiGetAdminUserDetails
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof AdminUserControllerApiGetAdminUserDetails
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for getAdminUserDetailsById operation in AdminUserControllerApi.
 * @export
 * @interface AdminUserControllerApiGetAdminUserDetailsByIdRequest
 */
export interface AdminUserControllerApiGetAdminUserDetailsByIdRequest {
    /**
     * 
     * @type {number}
     * @memberof AdminUserControllerApiGetAdminUserDetailsById
     */
    readonly userId: number
}

/**
 * Request parameters for updatePassword2 operation in AdminUserControllerApi.
 * @export
 * @interface AdminUserControllerApiUpdatePassword2Request
 */
export interface AdminUserControllerApiUpdatePassword2Request {
    /**
     * 
     * @type {UserUpdatePasswordRequestDto}
     * @memberof AdminUserControllerApiUpdatePassword2
     */
    readonly userUpdatePasswordRequestDto: UserUpdatePasswordRequestDto
}

/**
 * AdminUserControllerApi - object-oriented interface
 * @export
 * @class AdminUserControllerApi
 * @extends {BaseAPI}
 */
export class AdminUserControllerApi extends BaseAPI {
    /**
     * 
     * @param {AdminUserControllerApiGetAdminUserDetailsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserControllerApi
     */
    public getAdminUserDetails(requestParameters: AdminUserControllerApiGetAdminUserDetailsRequest = {}, options?: AxiosRequestConfig) {
        return AdminUserControllerApiFp(this.configuration).getAdminUserDetails(requestParameters.rolePermission, requestParameters.status, requestParameters.search, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminUserControllerApiGetAdminUserDetailsByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserControllerApi
     */
    public getAdminUserDetailsById(requestParameters: AdminUserControllerApiGetAdminUserDetailsByIdRequest, options?: AxiosRequestConfig) {
        return AdminUserControllerApiFp(this.configuration).getAdminUserDetailsById(requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminUserControllerApiUpdatePassword2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserControllerApi
     */
    public updatePassword2(requestParameters: AdminUserControllerApiUpdatePassword2Request, options?: AxiosRequestConfig) {
        return AdminUserControllerApiFp(this.configuration).updatePassword2(requestParameters.userUpdatePasswordRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}
