import { useOutlet } from 'react-router-dom';

import { HeadBlock, WindTurbinePageTabs } from './components';
import { PageWrapper, TabsContent } from './styles';
import { WindTurbineProvider } from 'contexts/WindTurbine';

export const WindTurbine = () => {
  const outlet = useOutlet();

  return (
    <WindTurbineProvider>
      <PageWrapper>
        <HeadBlock />
        <WindTurbinePageTabs />
        <TabsContent>{outlet}</TabsContent>
      </PageWrapper>
    </WindTurbineProvider>
  );
};
