import { styled } from '@mui/material';

export const ButtonsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  gap: theme.spacing(3, 2),
  marginTop: theme.spacing(6.5),

  [theme.breakpoints.down('tablet')]: {
    flexDirection: 'column',
    alignItems: 'center',
  },
}));
