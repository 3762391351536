import { FC } from 'react';

import {
  ButtonsWrapper,
  CloseButton,
  ContentWrapper,
  Dialog,
  InnerWrapper,
} from './styles';
import { IPopupProps, ParentComposition } from './types';
import { Close } from 'components/icons';

const Popup: FC<IPopupProps> & ParentComposition = ({
  isOpen,
  children,
  onClose,
  className,
  dataTestId,
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      className={className}
      data-testid={dataTestId}
    >
      {children}
      <CloseButton onClick={onClose} data-testid="popup-close-button">
        <Close />
      </CloseButton>
    </Dialog>
  );
};

Popup.InnerWrapper = InnerWrapper;
Popup.Buttons = ButtonsWrapper;
Popup.Content = ContentWrapper;

export { Popup };
