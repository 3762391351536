import { styled } from '@mui/material/styles';

export const UnreadStatusLine = styled('div')<{ extended: boolean }>(({
  theme,
  extended,
}) => {
  return {
    position: 'absolute',
    left: 0,
    top: extended ? '12px' : 0,
    bottom: extended ? '12px' : 0,
    width: '4px',
    borderRadius: theme.spacing(0, 0.5, 0.5, 0),
    backgroundColor: theme.palette.green[500],
  };
});
