import { FC } from 'react';

import { CommonSvgProps } from 'types/general';

export const WindDirectionIcon: FC<CommonSvgProps> = ({ className }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M8 2L13 14L8 11.4737L3 14L8 2Z" fill="#21252C" />
  </svg>
);
