import { styled } from '@mui/material';

export const InfoItemWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(1),

  [theme.breakpoints.down('desktop')]: {
    flexDirection: 'column',
  },
}));
