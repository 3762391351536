import { FC } from 'react';

import { CommonSvgProps } from 'types/general';

export const Check: FC<CommonSvgProps> = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 4C16.415 4 20 7.58501 20 12C20 16.415 16.415 20 12 20C7.58501 20 4 16.415 4 12C4 7.58501 7.58501 4 12 4ZM14.8848 9.422C15.0268 9.433 15.1618 9.467 15.2888 9.533C15.3718 9.577 15.4488 9.632 15.5168 9.697C15.7808 9.949 15.8868 10.335 15.7898 10.687C15.7478 10.839 15.6688 10.974 15.5658 11.093C14.3788 12.337 13.1628 13.552 11.9198 14.739C11.8378 14.81 11.7498 14.871 11.6498 14.915C11.3948 15.028 11.0978 15.028 10.8428 14.915C10.7428 14.871 10.6548 14.81 10.5728 14.739C9.86878 14.066 9.17878 13.378 8.50678 12.673C8.40378 12.554 8.32477 12.419 8.28277 12.266C8.25777 12.176 8.24577 12.082 8.24677 11.988C8.25077 11.607 8.47978 11.253 8.82578 11.093C8.93978 11.04 9.06278 11.009 9.18778 11.002C9.31278 10.994 9.43878 11.011 9.55778 11.05C9.70878 11.099 9.83878 11.184 9.95378 11.293L11.2468 12.586C12.2148 11.617 13.1618 10.626 14.1528 9.681C14.1888 9.65 14.1888 9.65 14.2248 9.621C14.2768 9.584 14.3288 9.551 14.3848 9.523C14.4988 9.467 14.6188 9.437 14.7438 9.424C14.7918 9.421 14.8378 9.42 14.8848 9.422Z"
        fill="#A4E256"
      />
    </svg>
  );
};
