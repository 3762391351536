import { Close } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { FC } from 'react';
import { Control, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { MacAddressRow } from '../styles';
import { FormValues } from '../types';
import CommonButton from 'components/common/CommonButton';
import TextField from 'components/common/form/TextField';
import { Plus } from 'components/icons';

interface IMacAddressesForPark {
  control: Control<FormValues>;
  parkName?: string;
  parkIndex: number;
}

export const MacAddressesForPark: FC<IMacAddressesForPark> = ({
  control,
  parkName,
  parkIndex,
}) => {
  const { t } = useTranslation();

  const { fields, append, remove } = useFieldArray({
    control,
    name: `macAddresses.${parkIndex}`,
  });

  return (
    <>
      <Typography variant="h4" color="black.600" component="div" sx={{ pb: 3 }}>
        {parkName}
      </Typography>

      <Box display="flex" flexDirection="column" gap={4}>
        {fields.map((field, index) => {
          if (index === 0) {
            return (
              <TextField
                label={t`components.assetOnboarding.mac`}
                fullWidth
                key={field.id}
                name={`macAddresses[${parkIndex}][${index}].value`}
                testId={`macAddresses[${parkIndex}][${index}]`}
              />
            );
          } else {
            return (
              <MacAddressRow key={field.id}>
                <TextField
                  label={t`components.assetOnboarding.mac`}
                  fullWidth
                  name={`macAddresses[${parkIndex}][${index}].value`}
                  testId={`macAddresses[${parkIndex}][${index}]`}
                  sx={{ mb: 0 }}
                />
                <IconButton
                  onClick={() => remove(index)}
                  data-testid={`macAddresses[${parkIndex}].remove.${index}`}
                >
                  <Close />
                </IconButton>
              </MacAddressRow>
            );
          }
        })}
      </Box>

      <CommonButton
        variant="text"
        onClick={() => append({ value: '' })}
        data-testid={`park-${parkName}-add-mac-address`}
        size="medium"
        sx={{
          mt: 2.5,
          px: 0,
          py: 0.5,
          textTransform: 'none',
          fontSize: 16,
        }}
      >
        <Plus />
        {t`components.assetOnboarding.addMac`}
      </CommonButton>
    </>
  );
};
