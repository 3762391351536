import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

const ButtonsWrapper = styled(Grid)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),

  [theme.breakpoints.down('desktop')]: {
    width: '100%',
  },

  [theme.breakpoints.down('tablet')]: {
    flexWrap: 'wrap',
  },
}));

export default ButtonsWrapper;
