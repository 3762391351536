import { Menu } from '@mui/material';
import { styled } from '@mui/material/styles';

const MenuWrapper = styled(Menu)(({ theme }) => ({
  marginTop: theme.spacing(0.5),
  '& .MuiList-root': {
    padding: 0,
  },
  '& .MuiPaper-root': {
    borderRadius: theme.spacing(1.5),
  },
}));

export default MenuWrapper;
