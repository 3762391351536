import { TextFieldProps } from '@mui/material';
import React, { FC, useCallback, ChangeEvent } from 'react';
import { useFormContext, useController } from 'react-hook-form';

import TextFieldStyled from './styles/TextFieldStyled';

export type CustomTextFieldProps = TextFieldProps & {
  name: string;
  testId?: string;
  customError?: string | null;
  onChangeAction?: (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => void;
};

const TextField: FC<CustomTextFieldProps> = ({
  name,
  customError,
  onChangeAction,
  InputProps,
  testId,
  ...otherProps
}) => {
  const { control } = useFormContext();
  const {
    field,
    fieldState: { error },
  } = useController({ control, name });

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      onChangeAction?.(event);
      field.onChange(event);
    },
    [field, onChangeAction],
  );

  const helperText = customError || (error ? error?.message : ' ');

  return (
    <TextFieldStyled
      {...otherProps}
      variant="filled"
      {...field}
      onChange={handleChange}
      error={!!customError || !!error}
      helperText={helperText}
      InputProps={{
        ...InputProps,
        disableUnderline: true,
      }}
      inputProps={{
        'data-testid': testId,
      }}
    />
  );
};

export default TextField;
