import { styled } from '@mui/material/styles';

const NotificationsListWrapper = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[100]}`,
  borderBottom: 'none',
  borderTopLeftRadius: theme.spacing(1.5),
  borderTopRightRadius: theme.spacing(1.5),
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  width: '100%',

  '& > *:last-child': {
    borderBottom: 'none',
  },
}));

export default NotificationsListWrapper;
