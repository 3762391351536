const grafanaElementHeight = 48;
const grafanaElementsGap = 3;
const grafanaAmountOfStatuses = 22;
const grafanaAmountOfErrors = 71;

export const statusesHeight =
  (grafanaElementHeight + grafanaElementsGap) * grafanaAmountOfStatuses -
  grafanaElementsGap;

export const errorsHeight =
  (grafanaElementHeight + grafanaElementsGap) * grafanaAmountOfErrors -
  grafanaElementsGap;

export const noActiveStatusesOrErrorsHeight = 200;
