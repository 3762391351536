import { styled } from '@mui/material';

import CommonButton from 'components/common/CommonButton';

const CommonButtonStyled = styled(CommonButton)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '50px',
  width: '50px',
  minWidth: 'unset',
  padding: 0,
  borderRadius: '50%',

  '& svg': {
    pointerEvents: 'none',
  },
}));

export default CommonButtonStyled;
