import { FC } from 'react';

import { CommonSvgProps } from 'types/general';

export const Humidity: FC<CommonSvgProps> = ({ className }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M3 17.4532C5.31768 15.9736 8.37347 16.1475 10.5093 17.9395L10.9077 18.2736C13.586 20.5206 17.5423 20.4407 20.1252 18.0874C20.4736 17.77 20.7189 17.5465 21 17.2904"
      stroke="#A7ABB2"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M7.88661 12.185C6.2484 11.8002 4.48363 12.0698 3.01367 13.0028"
      stroke="#A7ABB2"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M8.60041 7.95574C6.76932 7.29185 4.68873 7.49208 3 8.57018"
      stroke="#A7ABB2"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M15.6001 15.5481C18.0751 15.5481 20.1001 13.6269 20.1001 11.2963C20.1001 10.0785 19.4588 8.92368 18.1763 7.94734C16.8938 6.971 15.9263 5.52225 15.6001 4C15.2738 5.52225 14.3176 6.9815 13.0238 7.94734C11.7301 8.91318 11.1001 10.089 11.1001 11.2963C11.1001 13.6269 13.1251 15.5481 15.6001 15.5481Z"
      stroke="#A7ABB2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
