import { Popover, alpha, styled } from '@mui/material';

export const NotificationsPopover = styled(Popover)(({ theme }) => ({
  '& > .MuiPaper-root': {
    borderRadius: '12px',
    backgroundColor: theme.palette.white.main,
    boxShadow: `3px 4px 12px 0px ${alpha(
      theme.palette.black[600] as string,
      0.08,
    )}, 0px 0px 1px 0px ${alpha(theme.palette.black[600] as string, 0.08)}`,
  },

  [theme.breakpoints.down('desktop')]: {
    '& > .MuiPaper-root': {
      maxHeight: '100%',
      maxWidth: '100%',
      height: '100%',
      width: '100%',
      borderRadius: 0,
    },
  },
}));
