import { styled } from '@mui/material';

export const ErrorChip = styled('div')(({ theme }) => ({
  display: 'inline-block',
  margin: '4px',
  background: theme.palette.error.main,
  borderRadius: '50%',
  width: '8px',
  minWidth: '8px',
  height: '8px',
  boxShadow: `0 0 0 4px ${theme.palette.warning[20]}`,
}));

export const ActiveChip = styled('div')(({ theme }) => ({
  display: 'inline-block',
  background: theme.palette.green[600],
  borderRadius: '50%',
  width: '8px',
  height: '8px',
}));

export const InactiveChip = styled('div')(({ theme }) => ({
  display: 'inline-block',
  background: theme.palette.grey[300],
  borderRadius: '50%',
  width: '8px',
  height: '8px',
}));
