import { useContext } from 'react';

import { WindTurbinePanelsContext } from './WindTurbinePanelsContext';
import WindTurbinePanelsProvider from './WindTurbinePanelsProvider';

const useWindTurbinePanels = () => {
  const context = useContext(WindTurbinePanelsContext);

  if (typeof context === 'undefined') {
    throw new Error(
      'useWindTurbinePanels must be used within an WindTurbinePanelsContext',
    );
  }

  return context;
};

export default useWindTurbinePanels;
export { WindTurbinePanelsProvider };
