import { styled } from '@mui/material';

const Header = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  margin: theme.spacing(0, -2.5),
  padding: theme.spacing(1.5, 2.5),
  borderBottom: `1px solid ${theme.palette.grey[100]}`,
}));

export default Header;
