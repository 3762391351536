import { useContext } from 'react';

import { WindTurbineContext } from './WindTurbineContext';
import WindTurbineProvider from './WindTurbineProvider';

const useWindTurbine = () => {
  const context = useContext(WindTurbineContext);

  if (typeof context === 'undefined') {
    throw new Error(
      'useWindTurbine must be used within an WindTurbineProvider',
    );
  }

  return context;
};

export default useWindTurbine;
export { WindTurbineProvider };
