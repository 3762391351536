import { styled } from '@mui/material';

import { GrafanaIframe } from '../../GrafanaIframe';

export const IframeCounter = styled(GrafanaIframe)(() => ({
  position: 'absolute',
  top: '-16px',
  right: '-16px',
  left: 'unset',
  height: '200px',
  width: '450px',
}));
