import { Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { WindTurbineStatusWrapper } from './styles';
import {
  WindTurbineDtoErrorTypeEnum,
  WindTurbineDtoStatusEnum,
} from 'openapi-api/admin-service';

export interface IWindTurbineStatusProps {
  status?: WindTurbineDtoStatusEnum;
  errorType?: WindTurbineDtoErrorTypeEnum;
  height?: number | string;
}

export const WindTurbineStatus: FC<IWindTurbineStatusProps> = ({
  status = WindTurbineDtoStatusEnum.NOT_SET,
  errorType,
  height,
}) => {
  const { t } = useTranslation();
  const statusText =
    status === WindTurbineDtoStatusEnum.ERROR && errorType
      ? t(`pages.turbine.errorTypeMapping.${errorType}`)
      : t(`pages.turbine.statusMapping.${status}`);

  return (
    <WindTurbineStatusWrapper status={status} height={height}>
      <Typography variant="subheading">{statusText.toUpperCase()}</Typography>
    </WindTurbineStatusWrapper>
  );
};
