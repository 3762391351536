// WARNING: due to codegen, the api context uses globalAxios instance
// so all interceptors are bound to it.

import React, { FC, PropsWithChildren } from 'react';

import ApiContext, { api } from './Context';

const ApiProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  return <ApiContext.Provider value={api}>{children}</ApiContext.Provider>;
};

export default ApiProvider;
