import { Theme, useMediaQuery } from '@mui/material';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import useNotificationsContext from 'contexts/notifications';

export const useNotificationNavigation = (link: string, newTab?: boolean) => {
  const navigate = useNavigate();
  const isMobileOrTablet = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('desktop'),
  );
  const { closeNotificationsPopup } = useNotificationsContext();

  return useCallback(() => {
    if (newTab) window.open(link, '_blank');
    else {
      if (isMobileOrTablet) closeNotificationsPopup();
      navigate(link);
    }
  }, [closeNotificationsPopup, isMobileOrTablet, link, navigate, newTab]);
};
