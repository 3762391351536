import { styled } from '@mui/material';

export const MainContentWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '206px',
  padding: theme.spacing(3, 3, 4),

  [theme.breakpoints.down('desktop')]: {
    height: '234px',
    padding: theme.spacing(3),
  },
}));
