import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

const useUpdateSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const updateSearchParams = useCallback(
    (field: string, value?: string | number | null, pageField = 'page') => {
      if (value?.toString()) {
        searchParams.set(field, value.toString());
      } else {
        searchParams.delete(field);
      }
      // page reset to 0
      if (field !== pageField) {
        searchParams.set(pageField, '0');
      }
      setSearchParams(searchParams, { replace: true });
    },
    [searchParams, setSearchParams],
  );

  return { searchParams, setSearchParams, updateSearchParams };
};

export default useUpdateSearchParams;
