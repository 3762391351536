import { Box, styled } from '@mui/material';

export const StyledTabPanel = styled(Box)<{ hidden: boolean }>(
  ({ hidden }) => ({
    flexGrow: 1,
    minHeight: 0,
    display: hidden ? 'none' : 'flex',
    padding: 0,
  }),
);
