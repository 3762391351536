import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

const NoDataContainer = styled(Grid)(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(2),
  padding: theme.spacing(5, 3),
  backgroundColor: theme.palette.grey[50],
  borderRadius: theme.spacing(0.5),
}));

export default NoDataContainer;
