import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const FilterButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'isOpen' && prop !== 'light',
})<{ isOpen?: boolean; light: boolean }>(({ theme, isOpen, light }) => {
  let backgroundColor;
  let svgColor;
  if (isOpen) {
    backgroundColor = theme.palette.grey[light ? 100 : 700];
    svgColor = light ? theme.palette.black[500] : theme.palette.white.main;
  } else {
    backgroundColor = theme.palette.grey[light ? 50 : 900];
    svgColor = theme.palette.grey[600];
  }

  return {
    textTransform: 'none',
    height: '48px',
    padding: theme.spacing(0.75, 2.5),
    borderRadius: '24px',
    display: 'flex',
    gap: theme.spacing(1.5),
    backgroundColor,
    '&:hover': {
      backgroundColor: theme.palette.grey[light ? 100 : 900],
    },
    '& svg': {
      color: svgColor,
    },
    [theme.breakpoints.down('desktop')]: {
      width: '100%',
    },
    [theme.breakpoints.down('tablet')]: {
      width: '48px',
      minWidth: '48px',
      padding: 0,
    },
  };
});

export default FilterButton;
