import {
  ButtonOwnProps,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { debounce } from 'lodash';
import React, {
  FC,
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { TextEllipsisWrapper } from './styles';
import { ShowHideButton } from '../../styles';

export interface TextEllipsisProps {
  text: string;
  linesVisible: number;
}

export const TextEllipsis: FC<TextEllipsisProps> = ({ text, linesVisible }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('tablet'),
  );

  const [clamped, setClamped] = useState(true);
  const [showButton, setShowButton] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const toggleEllipsis: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.stopPropagation();
      setClamped(!clamped);
    },
    [clamped],
  );
  const showHideButtonProps = useMemo<Partial<ButtonOwnProps>>(() => {
    if (isMobile && clamped) return { variant: 'outlined', size: 'small' };
    return { variant: 'text', size: 'text' };
  }, [clamped, isMobile]);

  const hasClamping = useCallback((el: HTMLDivElement) => {
    const { clientHeight, scrollHeight } = el;
    return clientHeight !== scrollHeight;
  }, []);

  const checkButtonAvailability = useCallback(() => {
    if (containerRef.current) {
      const hadClampedState = clamped;

      // Check if clamped state will change component height and set showButton accordingly
      if (!hadClampedState)
        containerRef.current.style.webkitLineClamp = String(linesVisible);
      setShowButton(hasClamping(containerRef.current));
      if (!hadClampedState) containerRef.current.style.webkitLineClamp = '';
    }
  }, [clamped, hasClamping, linesVisible]);

  useEffect(() => {
    checkButtonAvailability();
  }, [checkButtonAvailability, text]);

  useEffect(() => {
    const debouncedCheck = debounce(checkButtonAvailability, 50);

    window.addEventListener('resize', debouncedCheck);

    return () => {
      window.removeEventListener('resize', debouncedCheck);
    };
  }, [checkButtonAvailability]);

  return (
    <>
      <TextEllipsisWrapper
        ref={containerRef}
        clamped={clamped}
        linesVisible={linesVisible}
      >
        <Typography
          variant="bodyS"
          color="grey.900"
          sx={{ margin: 0, wordBreak: 'break-word', whiteSpace: 'pre-line' }}
        >
          {text}
        </Typography>
      </TextEllipsisWrapper>
      {showButton && (
        <ShowHideButton
          {...showHideButtonProps}
          onClick={toggleEllipsis}
          clamped={clamped}
        >
          {clamped ? t('buttons.showMore') : t('buttons.hide')}
        </ShowHideButton>
      )}
    </>
  );
};
