import { SwitchProps } from '@mui/material';
import React, { FC } from 'react';

import StyledSwitch from './styles/StyledSwitch';

const Switch: FC<SwitchProps> = (props) => {
  return (
    <StyledSwitch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  );
};

export default Switch;
