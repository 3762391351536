/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { LocationAssetMappingDetailsDto } from '../models';
// @ts-ignore
import { LocationDto } from '../models';
// @ts-ignore
import { LocationPageResponse } from '../models';
// @ts-ignore
import { LocationUpdateDto } from '../models';
// @ts-ignore
import { LocationWeatherDto } from '../models';
// @ts-ignore
import { LocationWindTurbineDetailsDto } from '../models';
/**
 * LocationControllerApi - axios parameter creator
 * @export
 */
export const LocationControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {LocationDto} locationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (locationDto: LocationDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationDto' is not null or undefined
            assertParamExists('create', 'locationDto', locationDto)
            const localVarPath = `/location`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(locationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findLocationAssetMappingDetails: async (customerId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('findLocationAssetMappingDetails', 'customerId', customerId)
            const localVarPath = `/location/customer/{customerId}/asset-mappings`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/location/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} customerName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllForCustomer: async (customerName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerName' is not null or undefined
            assertParamExists('getAllForCustomer', 'customerName', customerName)
            const localVarPath = `/location/customer/{customerName}`
                .replace(`{${"customerName"}}`, encodeURIComponent(String(customerName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocationByName: async (name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('getLocationByName', 'name', name)
            const localVarPath = `/location/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocationWindTurbineDetails: async (locationId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('getLocationWindTurbineDetails', 'locationId', locationId)
            const localVarPath = `/location/id/{locationId}`
                .replace(`{${"locationId"}}`, encodeURIComponent(String(locationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {'ADD_TO_LOCATION_DATETIME' | 'TOTAL_ACTIVE_ENERGY' | 'TOTAL_LOST_ENERGY'} [sort] 
         * @param {'ASC' | 'DESC'} [sortOrder] 
         * @param {'ACTIVE' | 'INACTIVE' | 'ERROR'} [status] 
         * @param {number} [customerId] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocations: async (page?: number, size?: number, sort?: 'ADD_TO_LOCATION_DATETIME' | 'TOTAL_ACTIVE_ENERGY' | 'TOTAL_LOST_ENERGY', sortOrder?: 'ASC' | 'DESC', status?: 'ACTIVE' | 'INACTIVE' | 'ERROR', customerId?: number, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/location`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} locationName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWeather: async (locationName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationName' is not null or undefined
            assertParamExists('getWeather', 'locationName', locationName)
            const localVarPath = `/location/{locationName}/weather`
                .replace(`{${"locationName"}}`, encodeURIComponent(String(locationName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} locationId 
         * @param {LocationUpdateDto} locationUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update1: async (locationId: number, locationUpdateDto: LocationUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('update1', 'locationId', locationId)
            // verify required parameter 'locationUpdateDto' is not null or undefined
            assertParamExists('update1', 'locationUpdateDto', locationUpdateDto)
            const localVarPath = `/location/{locationId}`
                .replace(`{${"locationId"}}`, encodeURIComponent(String(locationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(locationUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LocationControllerApi - functional programming interface
 * @export
 */
export const LocationControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LocationControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {LocationDto} locationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(locationDto: LocationDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(locationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findLocationAssetMappingDetails(customerId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LocationAssetMappingDetailsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findLocationAssetMappingDetails(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LocationDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} customerName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllForCustomer(customerName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LocationDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllForCustomer(customerName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLocationByName(name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLocationByName(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLocationWindTurbineDetails(locationId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationWindTurbineDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLocationWindTurbineDetails(locationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {'ADD_TO_LOCATION_DATETIME' | 'TOTAL_ACTIVE_ENERGY' | 'TOTAL_LOST_ENERGY'} [sort] 
         * @param {'ASC' | 'DESC'} [sortOrder] 
         * @param {'ACTIVE' | 'INACTIVE' | 'ERROR'} [status] 
         * @param {number} [customerId] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLocations(page?: number, size?: number, sort?: 'ADD_TO_LOCATION_DATETIME' | 'TOTAL_ACTIVE_ENERGY' | 'TOTAL_LOST_ENERGY', sortOrder?: 'ASC' | 'DESC', status?: 'ACTIVE' | 'INACTIVE' | 'ERROR', customerId?: number, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationPageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLocations(page, size, sort, sortOrder, status, customerId, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} locationName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWeather(locationName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationWeatherDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWeather(locationName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} locationId 
         * @param {LocationUpdateDto} locationUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update1(locationId: number, locationUpdateDto: LocationUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update1(locationId, locationUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LocationControllerApi - factory interface
 * @export
 */
export const LocationControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LocationControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {LocationControllerApiCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(requestParameters: LocationControllerApiCreateRequest, options?: AxiosRequestConfig): AxiosPromise<LocationDto> {
            return localVarFp.create(requestParameters.locationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LocationControllerApiFindLocationAssetMappingDetailsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findLocationAssetMappingDetails(requestParameters: LocationControllerApiFindLocationAssetMappingDetailsRequest, options?: AxiosRequestConfig): AxiosPromise<Array<LocationAssetMappingDetailsDto>> {
            return localVarFp.findLocationAssetMappingDetails(requestParameters.customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll(options?: AxiosRequestConfig): AxiosPromise<Array<LocationDto>> {
            return localVarFp.getAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LocationControllerApiGetAllForCustomerRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllForCustomer(requestParameters: LocationControllerApiGetAllForCustomerRequest, options?: AxiosRequestConfig): AxiosPromise<Array<LocationDto>> {
            return localVarFp.getAllForCustomer(requestParameters.customerName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LocationControllerApiGetLocationByNameRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocationByName(requestParameters: LocationControllerApiGetLocationByNameRequest, options?: AxiosRequestConfig): AxiosPromise<LocationDto> {
            return localVarFp.getLocationByName(requestParameters.name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LocationControllerApiGetLocationWindTurbineDetailsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocationWindTurbineDetails(requestParameters: LocationControllerApiGetLocationWindTurbineDetailsRequest, options?: AxiosRequestConfig): AxiosPromise<LocationWindTurbineDetailsDto> {
            return localVarFp.getLocationWindTurbineDetails(requestParameters.locationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LocationControllerApiGetLocationsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocations(requestParameters: LocationControllerApiGetLocationsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<LocationPageResponse> {
            return localVarFp.getLocations(requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.sortOrder, requestParameters.status, requestParameters.customerId, requestParameters.search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LocationControllerApiGetWeatherRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWeather(requestParameters: LocationControllerApiGetWeatherRequest, options?: AxiosRequestConfig): AxiosPromise<LocationWeatherDto> {
            return localVarFp.getWeather(requestParameters.locationName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LocationControllerApiUpdate1Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update1(requestParameters: LocationControllerApiUpdate1Request, options?: AxiosRequestConfig): AxiosPromise<LocationDto> {
            return localVarFp.update1(requestParameters.locationId, requestParameters.locationUpdateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for create operation in LocationControllerApi.
 * @export
 * @interface LocationControllerApiCreateRequest
 */
export interface LocationControllerApiCreateRequest {
    /**
     * 
     * @type {LocationDto}
     * @memberof LocationControllerApiCreate
     */
    readonly locationDto: LocationDto
}

/**
 * Request parameters for findLocationAssetMappingDetails operation in LocationControllerApi.
 * @export
 * @interface LocationControllerApiFindLocationAssetMappingDetailsRequest
 */
export interface LocationControllerApiFindLocationAssetMappingDetailsRequest {
    /**
     * 
     * @type {number}
     * @memberof LocationControllerApiFindLocationAssetMappingDetails
     */
    readonly customerId: number
}

/**
 * Request parameters for getAllForCustomer operation in LocationControllerApi.
 * @export
 * @interface LocationControllerApiGetAllForCustomerRequest
 */
export interface LocationControllerApiGetAllForCustomerRequest {
    /**
     * 
     * @type {string}
     * @memberof LocationControllerApiGetAllForCustomer
     */
    readonly customerName: string
}

/**
 * Request parameters for getLocationByName operation in LocationControllerApi.
 * @export
 * @interface LocationControllerApiGetLocationByNameRequest
 */
export interface LocationControllerApiGetLocationByNameRequest {
    /**
     * 
     * @type {string}
     * @memberof LocationControllerApiGetLocationByName
     */
    readonly name: string
}

/**
 * Request parameters for getLocationWindTurbineDetails operation in LocationControllerApi.
 * @export
 * @interface LocationControllerApiGetLocationWindTurbineDetailsRequest
 */
export interface LocationControllerApiGetLocationWindTurbineDetailsRequest {
    /**
     * 
     * @type {number}
     * @memberof LocationControllerApiGetLocationWindTurbineDetails
     */
    readonly locationId: number
}

/**
 * Request parameters for getLocations operation in LocationControllerApi.
 * @export
 * @interface LocationControllerApiGetLocationsRequest
 */
export interface LocationControllerApiGetLocationsRequest {
    /**
     * 
     * @type {number}
     * @memberof LocationControllerApiGetLocations
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof LocationControllerApiGetLocations
     */
    readonly size?: number

    /**
     * 
     * @type {'ADD_TO_LOCATION_DATETIME' | 'TOTAL_ACTIVE_ENERGY' | 'TOTAL_LOST_ENERGY'}
     * @memberof LocationControllerApiGetLocations
     */
    readonly sort?: 'ADD_TO_LOCATION_DATETIME' | 'TOTAL_ACTIVE_ENERGY' | 'TOTAL_LOST_ENERGY'

    /**
     * 
     * @type {'ASC' | 'DESC'}
     * @memberof LocationControllerApiGetLocations
     */
    readonly sortOrder?: 'ASC' | 'DESC'

    /**
     * 
     * @type {'ACTIVE' | 'INACTIVE' | 'ERROR'}
     * @memberof LocationControllerApiGetLocations
     */
    readonly status?: 'ACTIVE' | 'INACTIVE' | 'ERROR'

    /**
     * 
     * @type {number}
     * @memberof LocationControllerApiGetLocations
     */
    readonly customerId?: number

    /**
     * 
     * @type {string}
     * @memberof LocationControllerApiGetLocations
     */
    readonly search?: string
}

/**
 * Request parameters for getWeather operation in LocationControllerApi.
 * @export
 * @interface LocationControllerApiGetWeatherRequest
 */
export interface LocationControllerApiGetWeatherRequest {
    /**
     * 
     * @type {string}
     * @memberof LocationControllerApiGetWeather
     */
    readonly locationName: string
}

/**
 * Request parameters for update1 operation in LocationControllerApi.
 * @export
 * @interface LocationControllerApiUpdate1Request
 */
export interface LocationControllerApiUpdate1Request {
    /**
     * 
     * @type {number}
     * @memberof LocationControllerApiUpdate1
     */
    readonly locationId: number

    /**
     * 
     * @type {LocationUpdateDto}
     * @memberof LocationControllerApiUpdate1
     */
    readonly locationUpdateDto: LocationUpdateDto
}

/**
 * LocationControllerApi - object-oriented interface
 * @export
 * @class LocationControllerApi
 * @extends {BaseAPI}
 */
export class LocationControllerApi extends BaseAPI {
    /**
     * 
     * @param {LocationControllerApiCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationControllerApi
     */
    public create(requestParameters: LocationControllerApiCreateRequest, options?: AxiosRequestConfig) {
        return LocationControllerApiFp(this.configuration).create(requestParameters.locationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LocationControllerApiFindLocationAssetMappingDetailsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationControllerApi
     */
    public findLocationAssetMappingDetails(requestParameters: LocationControllerApiFindLocationAssetMappingDetailsRequest, options?: AxiosRequestConfig) {
        return LocationControllerApiFp(this.configuration).findLocationAssetMappingDetails(requestParameters.customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationControllerApi
     */
    public getAll(options?: AxiosRequestConfig) {
        return LocationControllerApiFp(this.configuration).getAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LocationControllerApiGetAllForCustomerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationControllerApi
     */
    public getAllForCustomer(requestParameters: LocationControllerApiGetAllForCustomerRequest, options?: AxiosRequestConfig) {
        return LocationControllerApiFp(this.configuration).getAllForCustomer(requestParameters.customerName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LocationControllerApiGetLocationByNameRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationControllerApi
     */
    public getLocationByName(requestParameters: LocationControllerApiGetLocationByNameRequest, options?: AxiosRequestConfig) {
        return LocationControllerApiFp(this.configuration).getLocationByName(requestParameters.name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LocationControllerApiGetLocationWindTurbineDetailsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationControllerApi
     */
    public getLocationWindTurbineDetails(requestParameters: LocationControllerApiGetLocationWindTurbineDetailsRequest, options?: AxiosRequestConfig) {
        return LocationControllerApiFp(this.configuration).getLocationWindTurbineDetails(requestParameters.locationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LocationControllerApiGetLocationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationControllerApi
     */
    public getLocations(requestParameters: LocationControllerApiGetLocationsRequest = {}, options?: AxiosRequestConfig) {
        return LocationControllerApiFp(this.configuration).getLocations(requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.sortOrder, requestParameters.status, requestParameters.customerId, requestParameters.search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LocationControllerApiGetWeatherRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationControllerApi
     */
    public getWeather(requestParameters: LocationControllerApiGetWeatherRequest, options?: AxiosRequestConfig) {
        return LocationControllerApiFp(this.configuration).getWeather(requestParameters.locationName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LocationControllerApiUpdate1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationControllerApi
     */
    public update1(requestParameters: LocationControllerApiUpdate1Request, options?: AxiosRequestConfig) {
        return LocationControllerApiFp(this.configuration).update1(requestParameters.locationId, requestParameters.locationUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }
}
