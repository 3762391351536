import { Button, ButtonProps } from '@mui/material';
import { FC } from 'react';

export interface ICommonButtonProps extends ButtonProps {}

const CommonButton: FC<ICommonButtonProps> = ({ children, ...rest }) => {
  return (
    <Button disableRipple disableElevation {...rest}>
      {children}
    </Button>
  );
};
export default CommonButton;
