import Menu from '@mui/material/Menu';
import { styled } from '@mui/material/styles';

const MenuWrapper = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    marginTop: theme.spacing(1),
    borderRadius: theme.spacing(1.5),
  },
  '& .MuiList-root': {
    padding: theme.spacing(0.5, 0),
  },
  '& .MuiButtonBase-root': {
    padding: theme.spacing(1.5, 2.5),
  },
}));

export default MenuWrapper;
