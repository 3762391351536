import { IconButton } from '@mui/material';
import { FC } from 'react';

import CopyIconWrapper from './styles/CopyIconWrapper';
import { useCopyToClipboard } from '../../../utils/hooks/useCopyToClipboard';
import { Copy, CopySuccess } from 'components/icons';

export type Props = {
  text?: string;
};

const CopyIcon: FC<Props> = ({ text = '' }) => {
  const { copied, copyText } = useCopyToClipboard(text);

  return (
    <CopyIconWrapper>
      {!copied && (
        <IconButton onClick={copyText}>
          <Copy />
        </IconButton>
      )}
      {copied && <CopySuccess />}
    </CopyIconWrapper>
  );
};
export default CopyIcon;
