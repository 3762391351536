import { styled } from '@mui/material/styles';

const CountBox = styled('div')<{ active?: boolean }>(({ theme, active }) => ({
  padding: theme.spacing(0.25, 0.875),
  borderRadius: theme.spacing(0.5),
  border: `1px solid ${
    active ? theme.palette.green[500] : theme.palette.grey[100]
  }`,
  backgroundColor: active ? theme.palette.green[500] : 'transparent',
}));

export default CountBox;
