import { SxProps } from '@mui/material';

export enum WindTurbinesTableContext {
  Park = 'park',
  Unassigned = 'unassigned',
}

export interface WindTurbinesTableProps {
  locationId?: number;
  showUnassigned?: boolean;
  className?: string;
  sx?: SxProps;
}
