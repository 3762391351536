import { Typography } from '@mui/material';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutlet } from 'react-router-dom';

import { PageContentWrapper, PageWrapper } from './styles';
import Tabs from 'components/common/Tabs';
import { TabType, TabsComponentVariant } from 'components/common/Tabs/types';
import routePaths from 'constants/routePaths';

export const Users: FC = () => {
  const { t } = useTranslation();
  const outlet = useOutlet();

  const tabs = useMemo(
    (): TabType[] => [
      {
        label: t('pages.users.tabs.admin'),
        value: routePaths.users.admin,
      },
      {
        label: t('pages.users.tabs.customer'),
        value: routePaths.users.customer,
      },
    ],
    [t],
  );

  return (
    <PageWrapper>
      <Typography variant="h1">{t('pages.users.title')}</Typography>
      <Tabs
        tabs={tabs}
        componentVariant={TabsComponentVariant.Tabs}
        sx={{ mt: { mobile: 4, desktop: 5 } }}
      />
      <PageContentWrapper>{outlet}</PageContentWrapper>
    </PageWrapper>
  );
};
