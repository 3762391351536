import { Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { InvestigationRequestStatusWrapper } from './styles';
import { RestorationRequestDtoStatusEnum } from 'openapi-api/admin-service';

export interface IInvestigationRequestStatusProps {
  status?: RestorationRequestDtoStatusEnum;
}

export const InvestigationRequestStatus: FC<
  IInvestigationRequestStatusProps
> = ({ status = RestorationRequestDtoStatusEnum.PENDING }) => {
  const { t } = useTranslation();
  const statusText = t(`pages.investigation.statusMapping.${status}`);

  return (
    <InvestigationRequestStatusWrapper status={status}>
      <Typography variant="subheading" color="black.500">
        {statusText.toUpperCase()}
      </Typography>
    </InvestigationRequestStatusWrapper>
  );
};
