import { styled } from '@mui/material';

import CommonButton from 'components/common/CommonButton';

export const AddUserButton = styled(CommonButton)(({ theme }) => ({
  [theme.breakpoints.down('tablet')]: {
    minHeight: 'auto',
    minWidth: 'auto',
    height: '48px',
    width: '48px',
    padding: 0,
  },
}));
