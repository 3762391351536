import { FormControl, styled } from '@mui/material';

export const SelectWrapper = styled(FormControl)(({ theme, disabled }) => ({
  opacity: disabled ? 0.5 : 1,

  '& .MuiFormLabel-root': {
    color:
      theme.palette.mode === 'light'
        ? theme.palette.grey[800]
        : theme.palette.grey[200],
    '&.Mui-disabled': {
      color: theme.palette.grey[800],
    },
  },
}));
