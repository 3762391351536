import { styled } from '@mui/material';

export const EmailWrapper = styled('div')(({ theme }) => ({
  flex: 1,

  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(1),
  justifyContent: 'space-between',

  [theme.breakpoints.down('desktop')]: {
    flex: 0,
    flexDirection: 'column',
  },
}));
