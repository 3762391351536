import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonsWrapper, OpenLinkIcon, Wrapper } from './styles';
import CommonButton from 'components/common/CommonButton';
import { VideoStreamDto } from 'openapi-api/admin-service';

interface Props {
  data: VideoStreamDto;
  onDeleteClick: (id?: number) => void;
}

export const VideoStreamCard: FC<Props> = ({
  data,

  onDeleteClick,
}) => {
  const { t } = useTranslation('', {
    keyPrefix: 'pages.turbine.tabs.streaming',
  });

  return (
    <Wrapper>
      <Typography variant="h5" color="black.900">
        {data.cameraViewName}
      </Typography>
      <ButtonsWrapper>
        <CommonButton
          variant="outlined"
          size="small"
          onClick={() => onDeleteClick(data.id)}
        >
          {t('deleteStreamButtonText')}
        </CommonButton>
        <Box
          component="a"
          href={data.link}
          target="_blank"
          rel="noreferrer noopener"
          sx={{ width: 'fit-content' }}
        >
          <CommonButton size="small" startIcon={<OpenLinkIcon />}>
            {t('openStreamButtonText')}
          </CommonButton>
        </Box>
      </ButtonsWrapper>
    </Wrapper>
  );
};
