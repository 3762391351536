import {
  InputAdornment,
  SxProps,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import utc from 'dayjs/plugin/utc';
import { FC } from 'react';

import { DatePickerStyled, DatePickerPaper } from './styles';
import { Calendar } from 'components/icons';

dayjs.extend(utc);
dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
  weekStart: 1,
});

type DateSelectProps = {
  label?: string;
  value?: Dayjs | null;
  minDate?: Dayjs;
  maxDate?: Dayjs;
  defaultValue?: Dayjs | null;
  placeholder?: string;
  disabled?: boolean;
  onChange: (value: Dayjs | null) => void;
  sx?: SxProps;
};

const DateSelect: FC<DateSelectProps> = ({
  label,
  value,
  placeholder,
  disabled,
  defaultValue = null,
  minDate,
  maxDate,
  onChange,
  ...props
}) => {
  const breakpoints = useTheme().breakpoints;
  const isDesktop = useMediaQuery(breakpoints.down('desktop'));

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
      <DatePickerStyled
        label={label}
        defaultValue={defaultValue}
        value={value}
        format="DD/MM/YYYY"
        disabled={disabled}
        maxDate={maxDate}
        minDate={minDate}
        onChange={(newValue) => onChange(newValue as Dayjs)}
        desktopModeMediaQuery={breakpoints.up('desktop')}
        slots={{
          openPickerIcon: Calendar,
          desktopPaper: DatePickerPaper,
          mobilePaper: DatePickerPaper,
        }}
        slotProps={{
          popper: {
            sx: {
              zIndex: (theme) => theme.zIndex.globalLoader,
            },
          },
          textField: {
            InputProps: isDesktop
              ? {
                  endAdornment: (
                    <InputAdornment position="end">
                      <Calendar />
                    </InputAdornment>
                  ),
                }
              : undefined,
          },
        }}
        {...props}
      />
    </LocalizationProvider>
  );
};

export default DateSelect;
