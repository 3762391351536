import { styled } from '@mui/material';

import { InformerBlockVariants } from '../types';

export const Wrapper = styled('div', {
  shouldForwardProp: (propName) => propName !== 'variant',
})<{ variant?: InformerBlockVariants }>(({ theme, variant }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1.5),
  padding: theme.spacing(1.5, 2),
  borderWidth: '1px',
  borderStyle: 'solid',
  borderRadius: '12px',
  textAlign: 'left',
  ...(variant === InformerBlockVariants.Default && {
    borderColor: theme.palette.grey[600],
    backgroundColor: theme.palette.grey[50],
  }),
  ...(variant === InformerBlockVariants.Success && {
    borderColor: theme.palette.green[600],
    backgroundColor: theme.palette.green[200],
  }),
  ...(variant === InformerBlockVariants.Warning && {
    borderColor: theme.palette.warning.main,
    backgroundColor: theme.palette.connect[20],
  }),
  ...(variant === InformerBlockVariants.Error && {
    borderColor: theme.palette.error.main,
    backgroundColor: theme.palette.warning[20],
  }),
}));
