import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/material/styles';

const SearchFieldIcon = styled(SearchIcon, {
  shouldForwardProp: (prop) => prop !== 'light',
})<{ light?: boolean }>(({ theme, light = false }) => ({
  padding: theme.spacing(1),
  display: 'flex',
  height: '40px',
  width: '40px',
  marginLeft: theme.spacing(0.5),
  color: light ? theme.palette.grey[400] : theme.palette.grey[600],
  cursor: 'pointer',
}));

export default SearchFieldIcon;
