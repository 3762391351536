import { Dialog, styled } from '@mui/material';

const DialogStyled = styled(Dialog)(() => ({
  '& .MuiDialog-container': {
    position: 'relative',
    alignItems: 'flex-end',
  },

  '& .MuiPaper-root': {
    width: '100%',
    margin: 0,
    borderRadius: '16px 16px 0 0',
  },
}));

export default DialogStyled;
