import { styled } from '@mui/material/styles';

const DateRangeWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  gap: theme.spacing(1.5),
  alignItems: 'center',
}));

export default DateRangeWrapper;
