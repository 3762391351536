/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { VideoStreamCreateDto } from '../models';
// @ts-ignore
import { VideoStreamDto } from '../models';
/**
 * VideoStreamControllerApi - axios parameter creator
 * @export
 */
export const VideoStreamControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {VideoStreamCreateDto} videoStreamCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVideoStream: async (videoStreamCreateDto: VideoStreamCreateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'videoStreamCreateDto' is not null or undefined
            assertParamExists('createVideoStream', 'videoStreamCreateDto', videoStreamCreateDto)
            const localVarPath = `/video-stream`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(videoStreamCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVideoStream: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteVideoStream', 'id', id)
            const localVarPath = `/video-stream/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} assetPublicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVideoStreams: async (assetPublicId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetPublicId' is not null or undefined
            assertParamExists('getVideoStreams', 'assetPublicId', assetPublicId)
            const localVarPath = `/video-stream/asset/{assetPublicId}`
                .replace(`{${"assetPublicId"}}`, encodeURIComponent(String(assetPublicId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VideoStreamControllerApi - functional programming interface
 * @export
 */
export const VideoStreamControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VideoStreamControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {VideoStreamCreateDto} videoStreamCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createVideoStream(videoStreamCreateDto: VideoStreamCreateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VideoStreamDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createVideoStream(videoStreamCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteVideoStream(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteVideoStream(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} assetPublicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVideoStreams(assetPublicId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VideoStreamDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVideoStreams(assetPublicId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VideoStreamControllerApi - factory interface
 * @export
 */
export const VideoStreamControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VideoStreamControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {VideoStreamControllerApiCreateVideoStreamRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVideoStream(requestParameters: VideoStreamControllerApiCreateVideoStreamRequest, options?: AxiosRequestConfig): AxiosPromise<VideoStreamDto> {
            return localVarFp.createVideoStream(requestParameters.videoStreamCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VideoStreamControllerApiDeleteVideoStreamRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVideoStream(requestParameters: VideoStreamControllerApiDeleteVideoStreamRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteVideoStream(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VideoStreamControllerApiGetVideoStreamsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVideoStreams(requestParameters: VideoStreamControllerApiGetVideoStreamsRequest, options?: AxiosRequestConfig): AxiosPromise<Array<VideoStreamDto>> {
            return localVarFp.getVideoStreams(requestParameters.assetPublicId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createVideoStream operation in VideoStreamControllerApi.
 * @export
 * @interface VideoStreamControllerApiCreateVideoStreamRequest
 */
export interface VideoStreamControllerApiCreateVideoStreamRequest {
    /**
     * 
     * @type {VideoStreamCreateDto}
     * @memberof VideoStreamControllerApiCreateVideoStream
     */
    readonly videoStreamCreateDto: VideoStreamCreateDto
}

/**
 * Request parameters for deleteVideoStream operation in VideoStreamControllerApi.
 * @export
 * @interface VideoStreamControllerApiDeleteVideoStreamRequest
 */
export interface VideoStreamControllerApiDeleteVideoStreamRequest {
    /**
     * 
     * @type {number}
     * @memberof VideoStreamControllerApiDeleteVideoStream
     */
    readonly id: number
}

/**
 * Request parameters for getVideoStreams operation in VideoStreamControllerApi.
 * @export
 * @interface VideoStreamControllerApiGetVideoStreamsRequest
 */
export interface VideoStreamControllerApiGetVideoStreamsRequest {
    /**
     * 
     * @type {string}
     * @memberof VideoStreamControllerApiGetVideoStreams
     */
    readonly assetPublicId: string
}

/**
 * VideoStreamControllerApi - object-oriented interface
 * @export
 * @class VideoStreamControllerApi
 * @extends {BaseAPI}
 */
export class VideoStreamControllerApi extends BaseAPI {
    /**
     * 
     * @param {VideoStreamControllerApiCreateVideoStreamRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VideoStreamControllerApi
     */
    public createVideoStream(requestParameters: VideoStreamControllerApiCreateVideoStreamRequest, options?: AxiosRequestConfig) {
        return VideoStreamControllerApiFp(this.configuration).createVideoStream(requestParameters.videoStreamCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VideoStreamControllerApiDeleteVideoStreamRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VideoStreamControllerApi
     */
    public deleteVideoStream(requestParameters: VideoStreamControllerApiDeleteVideoStreamRequest, options?: AxiosRequestConfig) {
        return VideoStreamControllerApiFp(this.configuration).deleteVideoStream(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VideoStreamControllerApiGetVideoStreamsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VideoStreamControllerApi
     */
    public getVideoStreams(requestParameters: VideoStreamControllerApiGetVideoStreamsRequest, options?: AxiosRequestConfig) {
        return VideoStreamControllerApiFp(this.configuration).getVideoStreams(requestParameters.assetPublicId, options).then((request) => request(this.axios, this.basePath));
    }
}
