import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Divider } from '@mui/material';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { MacAddressesForPark } from './components';
import { defaultValues } from './constants';
import {
  ButtonsWrapper,
  CurrentStepWrapper,
  Dialogue,
  FormWrapper,
} from './styles';
import { FormValues } from './types';
import { validationSchemas } from './validationSchema';
import routePaths from '../../../../constants/routePaths';
import useApi from '../../../../contexts/api';
import CommonButton from '../../../common/CommonButton';
import SelectOrCreateCustomer from '../../../common/SelectOrCreateCustomer';
import SelectOrCreatePark from 'components/common/SelectOrCreatePark';
import SuccessDialog from 'components/common/SuccessDialog';
import { ArrowLeft, ArrowRight } from 'components/icons';
import useGlobalData from 'contexts/globalData';
import { CustomerMappingDto } from 'openapi-api/admin-service';

const AssetOnboarding = () => {
  const { t } = useTranslation();
  const {
    isAssetOnboardingFormOpened,
    setIsAssetOnboardingFormOpened,
    setUpdateWTList,
  } = useGlobalData();
  const { assetOnboardingControllerApi, locationControllerApi } = useApi();
  const [showSuccess, setShowSuccess] = useState(false);
  const [userCreated, setUserCreated] = useState(false);
  const [parkIsCreated, setParkIsCreated] = useState(false);
  const navigate = useNavigate();
  const [step, setStep] = useState<0 | 1 | 2>(0);

  const isParkNameExist = useCallback(
    async (parkName: string) => {
      try {
        const existingPark = (
          await locationControllerApi.getLocationByName({
            name: parkName.trim(),
          })
        ).data;
        if (existingPark) return true;
        return false;
      } catch {
        return false;
      }
    },
    [locationControllerApi],
  );

  const schema = useMemo(() => {
    return validationSchemas[step](t, isParkNameExist);
  }, [isParkNameExist, step, t]);

  const form = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const {
    control,
    reset,
    watch,
    formState: { isDirty, errors },
    trigger,
    setValue,
    clearErrors,
  } = form;

  const existingCustomer = watch('existingCustomer');
  const existingParks = watch('existingParks');
  const newPark = watch('newPark');

  const parksForStep3 = useMemo(() => {
    if (existingParks?.length) {
      return existingParks;
    } else if (newPark?.name) {
      return [newPark.name];
    }

    return [];
  }, [existingParks, newPark?.name]);

  const closeOnboardingDialogue = useCallback(() => {
    setIsAssetOnboardingFormOpened(false);
    setStep(0);
    reset();
  }, [reset, setIsAssetOnboardingFormOpened]);

  const handleSubmit = form.handleSubmit(async (data: FormValues) => {
    try {
      const owner = (data.existingCustomer || data.newCustomer) as string;

      if (!parkIsCreated && data?.newPark?.name && data?.newPark?.coordinates) {
        const [latitude, longitude] = data?.newPark.coordinates
          .replace(/ /g, '')
          ?.split(',')
          .map((coordinateStr) => Number(coordinateStr));

        await locationControllerApi.create({
          locationDto: {
            name: data?.newPark?.name,
            latitude,
            longitude,
            customerName: owner,
          },
        });

        setParkIsCreated(true);
      }

      const values: CustomerMappingDto[] =
        data.macAddresses?.flatMap((parkMacs, parkIndex) => {
          const parkName =
            data?.newPark?.name || (data?.existingParks?.[parkIndex] as string);
          return parkMacs.map(({ value }) => ({
            owner: owner,
            publicId: value,
            locationName: parkName,
          }));
        }) || [];

      await assetOnboardingControllerApi.addMapping({
        customerMappingDto: values,
      });
      closeOnboardingDialogue();
      setUpdateWTList(true);
      setShowSuccess(true);
    } catch (e) {
      if (axios.isAxiosError(e) && e.response?.data.message) {
        const message: string = e.response.data.message;
        toast.error(message);

        if (
          message.startsWith('Location with name') &&
          message.endsWith('already exists.')
        ) {
          setStep(1);
          await trigger('newPark.name');
        }
      }
    }
  });

  const canGoStep2 = useMemo(() => {
    if (existingCustomer || userCreated) return true;
  }, [existingCustomer, userCreated]);

  const goStep2 = useCallback(async () => {
    try {
      await trigger('newCustomer');
      await trigger('existingCustomer');
      setStep(1);
    } catch {}
  }, [trigger]);

  const canGoStep3 = useMemo(() => {
    if (existingParks?.length || (newPark?.name && newPark?.coordinates))
      return true;
  }, [existingParks?.length, newPark?.coordinates, newPark?.name]);

  const goStep3 = useCallback(async () => {
    try {
      await trigger('existingParks');
      if (!parkIsCreated) await trigger('newPark');

      if (!Object.keys(errors).length) {
        setValue(
          'macAddresses',
          new Array(parksForStep3.length).fill([{ value: '' }]),
        );
        setStep(2);
      }
    } catch {}
  }, [errors, parkIsCreated, parksForStep3.length, setValue, trigger]);

  return (
    <>
      <Dialogue
        open={isAssetOnboardingFormOpened}
        onClose={closeOnboardingDialogue}
        fullWidth
      >
        <FormProvider {...form}>
          <FormWrapper onSubmit={handleSubmit}>
            <Box height="100%" display="flex" flexDirection="column">
              <Typography
                variant="subheading"
                sx={{ pb: 1 }}
                color="green.700"
                component="div"
              >
                {t`step`} {`${step + 1}/3`}
              </Typography>
              <Typography
                variant="h2"
                sx={{ mb: 5 }}
                color="black.600"
              >{t`components.assetOnboarding.title`}</Typography>

              <CurrentStepWrapper isCurrent={step === 0}>
                <Typography
                  variant="bodyM"
                  color="black.600"
                  component="div"
                  sx={{ pb: 3 }}
                >{t`components.assetOnboarding.subTitleUser`}</Typography>

                <SelectOrCreateCustomer setUserIsCreated={setUserCreated} />

                <ButtonsWrapper
                  padding={2}
                  display="flex"
                  gap={2}
                  justifyContent="center"
                >
                  <CommonButton
                    variant="outlined"
                    onClick={closeOnboardingDialogue}
                    data-testid="close-button"
                  >
                    {t`cancel`}
                  </CommonButton>
                  <CommonButton
                    variant="contained"
                    data-testid="submit"
                    endIcon={<ArrowRight />}
                    disabled={!canGoStep2}
                    onClick={goStep2}
                  >
                    {t`next`}
                  </CommonButton>
                </ButtonsWrapper>
              </CurrentStepWrapper>

              <CurrentStepWrapper isCurrent={step === 1}>
                <Typography
                  variant="bodyM"
                  color="black.600"
                  component="div"
                  sx={{ pb: 3 }}
                >
                  {userCreated
                    ? t`components.assetOnboarding.subTitleCreatePark`
                    : t`components.assetOnboarding.subTitlePark`}
                </Typography>

                <SelectOrCreatePark
                  setParkIsCreated={setParkIsCreated}
                  selectProps={{
                    name: 'existingParks',
                    defaultValue: [],
                    multiple: true,
                  }}
                />

                <ButtonsWrapper display="flex" gap={2} justifyContent="center">
                  <CommonButton
                    variant="outlined"
                    onClick={() => {
                      setValue('existingParks', []);
                      setValue('newPark', { name: '', coordinates: '' });
                      clearErrors('existingParks');
                      clearErrors('newPark');
                      setStep(0);
                    }}
                    data-testid="close-button"
                    startIcon={<ArrowLeft />}
                  >
                    {t`back`}
                  </CommonButton>
                  <CommonButton
                    variant="contained"
                    data-testid="submit"
                    endIcon={<ArrowRight />}
                    disabled={!canGoStep3}
                    onClick={goStep3}
                  >
                    {t`next`}
                  </CommonButton>
                </ButtonsWrapper>
              </CurrentStepWrapper>

              {step === 2 && (
                <CurrentStepWrapper isCurrent={true}>
                  <Typography
                    variant="bodyM"
                    color="black.600"
                    component="div"
                    sx={{ pb: 3 }}
                  >{t`components.assetOnboarding.subTitleMac`}</Typography>

                  {parksForStep3.map((parkName, parkIndex) => (
                    <>
                      <MacAddressesForPark
                        key={`park-${parkName}-macAddresses`}
                        control={control}
                        parkName={parkName}
                        parkIndex={parkIndex}
                      />
                      {parkIndex !== parksForStep3.length - 1 && (
                        <Divider variant="fullWidth" sx={{ my: 4 }} />
                      )}
                    </>
                  ))}

                  <ButtonsWrapper
                    display="flex"
                    gap={2}
                    justifyContent="center"
                  >
                    <CommonButton
                      variant="outlined"
                      onClick={() => {
                        setValue('macAddresses', []);
                        clearErrors('macAddresses');
                        setStep(1);
                      }}
                      data-testid="close-button"
                      startIcon={<ArrowLeft />}
                    >
                      {t`back`}
                    </CommonButton>
                    <CommonButton
                      variant="contained"
                      data-testid="submit"
                      type="submit"
                      disabled={!isDirty}
                    >
                      {t('buttons.submit')}
                    </CommonButton>
                  </ButtonsWrapper>
                </CurrentStepWrapper>
              )}
            </Box>
          </FormWrapper>
        </FormProvider>
      </Dialogue>
      <SuccessDialog
        title={t`components.assetOnboarding.successTitle`}
        buttonText={t`components.assetOnboarding.successButton`}
        onClose={() => {
          setShowSuccess(false);
          navigate(routePaths.parks.root);
        }}
        open={showSuccess}
      />
    </>
  );
};

export default AssetOnboarding;
