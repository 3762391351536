import React, { useEffect, FC } from 'react';
import { useLocation, useNavigate, useOutlet } from 'react-router-dom';

import GlobalLoader from 'components/common/GlobalLoader';
import routePaths from 'constants/routePaths';
import useAuth from 'contexts/auth';
import ProtectedRoutesProvider from 'contexts/ProtectedRoutesProvider';

interface Props {
  loginPath?: string;
}

const ProtectedRoutes: FC<Props> = ({ loginPath = routePaths.login }) => {
  const outlet = useOutlet();
  const navigate = useNavigate();
  const location = useLocation();
  const { dataExists, tokenApplied } = useAuth();

  useEffect(() => {
    if (!dataExists) {
      navigate(loginPath, { state: { from: location } });
    }
  }, [dataExists, loginPath, navigate, location]);

  return tokenApplied ? (
    <ProtectedRoutesProvider>{outlet}</ProtectedRoutesProvider>
  ) : (
    <GlobalLoader open />
  );
};

export default ProtectedRoutes;
