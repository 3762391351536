import { styled } from '@mui/material/styles';

const TopLine = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(5),
  gap: theme.spacing(5),
  [theme.breakpoints.down('desktop')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: theme.spacing(3),
    padding: theme.spacing(0, 2.5),
  },
}));

export default TopLine;
