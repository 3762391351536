import React, { useMemo, useState, FC, PropsWithChildren } from 'react';

import GlobalDataContext from './Context';

const GlobalDataProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isAssetOnboardingFormOpened, setIsAssetOnboardingFormOpened] =
    useState(false);
  const [updateWTList, setUpdateWTList] = useState(false);

  const contextValue = useMemo(
    () => ({
      isMobileMenuOpen,
      setIsMobileMenuOpen,
      isAssetOnboardingFormOpened,
      setIsAssetOnboardingFormOpened,
      updateWTList,
      setUpdateWTList,
    }),
    [isMobileMenuOpen, isAssetOnboardingFormOpened, updateWTList],
  );

  return (
    <GlobalDataContext.Provider value={contextValue}>
      {children}
    </GlobalDataContext.Provider>
  );
};

export default GlobalDataProvider;
