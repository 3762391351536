import { AssetDetailsDto } from 'openapi-api/admin-service';

export enum ReportWidgets {
  AggregatedTotal = '2',
  SpecificTotalActiveEnergy = '3',
  SpecificPerfomanceDiagram = '4',
  Errors = '5',
}

export enum ReportWidgetsMobile {
  AggregatedTotal = '8',
  SpecificTotalActiveEnergy = '9',
  SpecificPerfomanceDiagram = '10',
}

export interface IParkToAssets {
  [key: string]: AssetDetailsDto[];
}
