import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { CounterBlock, Wrapper } from './styles';
import CommonButton from 'components/common/CommonButton';
import { ArrowRight, Error } from 'components/icons';
import routePaths from 'constants/routePaths';

interface Props {
  unassignedTurbineCount?: number;
}

export const UnassignedTurbines: FC<Props> = ({ unassignedTurbineCount }) => {
  const { t } = useTranslation();

  if (!unassignedTurbineCount) {
    return null;
  }

  return (
    <Wrapper>
      <Box>
        <CounterBlock>
          <Error />
          <Typography variant="bodyMStrong">
            <Trans
              i18nKey="pages.parks.unassignedTurbines.counter"
              values={{ count: unassignedTurbineCount }}
            />
          </Typography>
        </CounterBlock>
        <Typography
          variant="h3"
          sx={{ mt: 1.5 }}
        >{t`pages.parks.unassignedTurbines.title`}</Typography>
        <Typography
          variant="bodyM"
          sx={{ display: 'block', mt: 1 }}
        >{t`pages.parks.unassignedTurbines.subtitle`}</Typography>
      </Box>
      <Link to={routePaths.parks.unassigned().root}>
        <CommonButton
          endIcon={<ArrowRight />}
        >{t`pages.parks.unassignedTurbines.buttonText`}</CommonButton>
      </Link>
    </Wrapper>
  );
};
