import { styled } from '@mui/material';

export const FieldsWrapperTime = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1.5),

  [theme.breakpoints.down('desktop')]: {
    flexDirection: 'column',
    gap: theme.spacing(2.5),
  },
}));
