import { styled } from '@mui/material';

import { OpenLink } from 'components/icons';

export const OpenLinkIcon = styled(OpenLink)(({ theme }) => ({
  height: '18px',
  width: '18px',

  '& > path': {
    stroke: theme.palette.black[500],
  },
}));
