import { useContext } from 'react';

import AuthContext from './Context';
import AuthProvider from './Provider';

const useAuth = () => {
  const context = useContext(AuthContext);

  if (typeof context === 'undefined' || context === null) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
};

export default useAuth;
export { AuthProvider };
