import { styled } from '@mui/material';

export const CloseButton = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1.25),
  right: theme.spacing(1.25),

  '& svg': {
    color: theme.palette.grey[500],
  },
}));
