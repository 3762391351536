import { Grid, SxProps } from '@mui/material';
import Typography from '@mui/material/Typography';
import { FC, useCallback, useMemo } from 'react';

import { NotificationChip } from './NotificationChip';
import { UnreadStatusLine, Wrapper } from './styles';
import { TemplateBottomRow } from './styles/TemplateBottomRow';
import TemplateAssetActivePowerDeflectionError from './TemplateAssetActivePowerDeflectionError';
import TemplateAssetActivePowerDeflectionErrorResolved from './TemplateAssetActivePowerDeflectionErrorResolved';
import TemplateAssetAdded from './TemplateAssetAdded';
import TemplateAssetConnectionError from './TemplateAssetConnectionError';
import TemplateAssetConnectionErrorResolved from './TemplateAssetConnectionErrorResolved';
import TemplateAssetErrorFired from './TemplateAssetErrorFired';
import TemplateAssetErrorResolved from './TemplateAssetErrorResolved';
import TemplateControllerConnected from './TemplateControllerConnected';
import TemplateControllerDisconnected from './TemplateControllerDisconnected';
import TemplateInvestigationDashboardReady from './TemplateInvestigationDashboardReady';
import TemplateReportReady from './TemplateReportReady';
import TemplateTagsCollectingError from './TemplateTagsCollectingError';
import TemplateTagsCollectingErrorResolved from './TemplateTagsCollectingErrorResolved';
import { TemplateWeatherWarning } from './TemplateWeatherWarning';
import {
  AssetActivePowerDeflectionErrorDtoAllOfEventEnum as AssetEventEnum,
  AssetAddedDto,
  AssetConnectionErrorDto,
  AssetErrorFiredDto,
  AssetErrorResolvedDto,
  AssetTagsCollectionErrorDto,
  AssetTagsCollectionErrorResolvedDto,
  NotificationDto,
  ReportReadyDto,
  ControllerDisconnectedDto,
  ControllerConnectedDto,
  AssetActivePowerDeflectionErrorDto,
  AssetActivePowerDeflectionErrorResolvedDto,
  WeatherWarningDto,
  InvestigationDashboardReadyDto,
} from 'openapi-api/admin-service';
import { getStringFromISODate } from 'utils/functions/getStringFromISODate';

interface Props {
  notification: NotificationDto;
  onClick?: (notification: NotificationDto) => void;
  setAddUserId: (publicId?: string) => void;
  sx?: SxProps;
  extended?: boolean;
}

const NotificationMessage: FC<Props> = ({
  notification,
  onClick,
  setAddUserId,
  extended = false,
  sx,
}) => {
  const { details, markedAsRead, locationId } = notification;
  const handleClick = useCallback(
    () => notification?.id && onClick && onClick(notification),
    [notification, onClick],
  );

  const notificationTemplate = useMemo(() => {
    if (details?.event === AssetEventEnum.ASSET_ADDED) {
      return (
        <TemplateAssetAdded
          details={details as AssetAddedDto}
          setAddUserId={setAddUserId}
          extended={extended}
          locationId={locationId}
        />
      );
    }

    if (details?.event === AssetEventEnum.ASSET_CONNECTION_ERROR) {
      return (
        <TemplateAssetConnectionError
          details={details as AssetConnectionErrorDto}
          extended={extended}
          locationId={locationId}
        />
      );
    }

    if (details?.event === AssetEventEnum.ASSET_CONNECTION_ERROR_RESOLVED) {
      return (
        <TemplateAssetConnectionErrorResolved
          details={details as AssetConnectionErrorDto}
          extended={extended}
          locationId={locationId}
        />
      );
    }

    if (details?.event === AssetEventEnum.ASSET_ERROR_FIRED) {
      return (
        <TemplateAssetErrorFired
          details={details as AssetErrorFiredDto}
          extended={extended}
          locationId={locationId}
        />
      );
    }

    if (details?.event === AssetEventEnum.ASSET_ERROR_RESOLVED) {
      return (
        <TemplateAssetErrorResolved
          details={details as AssetErrorResolvedDto}
          extended={extended}
          locationId={locationId}
        />
      );
    }

    if (details?.event === AssetEventEnum.ASSET_TAGS_COLLECTION_ERROR) {
      return (
        <TemplateTagsCollectingError
          details={details as AssetTagsCollectionErrorDto}
          extended={extended}
          locationId={locationId}
        />
      );
    }

    if (
      details?.event === AssetEventEnum.ASSET_TAGS_COLLECTION_ERROR_RESOLVED
    ) {
      return (
        <TemplateTagsCollectingErrorResolved
          details={details as AssetTagsCollectionErrorResolvedDto}
          extended={extended}
          locationId={locationId}
        />
      );
    }

    if (details?.event === AssetEventEnum.REPORT_READY) {
      return (
        <TemplateReportReady
          details={details as unknown as ReportReadyDto}
          extended={extended}
        />
      );
    }

    if (details?.event === AssetEventEnum.CONTROLLER_DISCONNECTED) {
      return (
        <TemplateControllerDisconnected
          details={details as ControllerDisconnectedDto}
          extended={extended}
          locationId={locationId}
        />
      );
    }

    if (details?.event === AssetEventEnum.CONTROLLER_CONNECTED) {
      return (
        <TemplateControllerConnected
          details={details as ControllerConnectedDto}
          extended={extended}
          locationId={locationId}
        />
      );
    }

    if (details?.event === AssetEventEnum.ASSET_ACTIVE_POWER_DEFLECTION_ERROR) {
      return (
        <TemplateAssetActivePowerDeflectionError
          details={details as AssetActivePowerDeflectionErrorDto}
          extended={extended}
          locationId={locationId}
        />
      );
    }

    if (
      details?.event ===
      AssetEventEnum.ASSET_ACTIVE_POWER_DEFLECTION_ERROR_RESOLVED
    ) {
      return (
        <TemplateAssetActivePowerDeflectionErrorResolved
          details={details as AssetActivePowerDeflectionErrorResolvedDto}
          extended={extended}
          locationId={locationId}
        />
      );
    }

    if (details?.event === AssetEventEnum.WEATHER_WARNING) {
      return (
        <TemplateWeatherWarning
          details={details as unknown as WeatherWarningDto}
          extended={extended}
        />
      );
    }

    if (details?.event === AssetEventEnum.INVESTIGATION_DASHBOARD_READY) {
      return (
        <TemplateInvestigationDashboardReady
          details={details as unknown as InvestigationDashboardReadyDto}
          extended={extended}
          locationId={locationId}
        />
      );
    }
  }, [details, extended, setAddUserId, locationId]);

  if (!details || !notificationTemplate) {
    return null;
  }

  return (
    <Wrapper
      sx={sx}
      onClick={handleClick}
      data-testid="notification-wrapper"
      extended={extended}
    >
      {!markedAsRead && <UnreadStatusLine extended={extended} />}
      {extended && (
        <Grid
          sx={{ mb: 2 }}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <NotificationChip notification={notification} />
          <Typography color="grey.600" variant="bodyS">
            {getStringFromISODate(notification.details?.eventDateTime)}
          </Typography>
        </Grid>
      )}
      {!!notificationTemplate && (
        <TemplateBottomRow extended={extended}>
          {notificationTemplate}
        </TemplateBottomRow>
      )}
    </Wrapper>
  );
};

export default NotificationMessage;
