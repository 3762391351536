/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { LastNewNotificationDto } from '../models';
// @ts-ignore
import { NotificationBatchDto } from '../models';
// @ts-ignore
import { NotificationCreateDto } from '../models';
// @ts-ignore
import { NotificationCursor } from '../models';
// @ts-ignore
import { NotificationStatsDto } from '../models';
// @ts-ignore
import { PageNotificationDto } from '../models';
/**
 * NotificationControllerApi - axios parameter creator
 * @export
 */
export const NotificationControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} xApiKey 
         * @param {NotificationCreateDto} notificationCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNotification: async (xApiKey: string, notificationCreateDto: NotificationCreateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('addNotification', 'xApiKey', xApiKey)
            // verify required parameter 'notificationCreateDto' is not null or undefined
            assertParamExists('addNotification', 'notificationCreateDto', notificationCreateDto)
            const localVarPath = `/notification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiKey != null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notificationCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastNewNotification: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/notification/lastNew`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'ERROR' | 'REMINDER' | 'WARNING'} [type] 
         * @param {boolean} [isNew] 
         * @param {boolean} [markedAsRead] 
         * @param {string} [searchBy] 
         * @param {string} [fromCreateDatetime] 
         * @param {string} [toCreateDatetime] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotifications: async (type?: 'ERROR' | 'REMINDER' | 'WARNING', isNew?: boolean, markedAsRead?: boolean, searchBy?: string, fromCreateDatetime?: string, toCreateDatetime?: string, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/notification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (isNew !== undefined) {
                localVarQueryParameter['isNew'] = isNew;
            }

            if (markedAsRead !== undefined) {
                localVarQueryParameter['markedAsRead'] = markedAsRead;
            }

            if (searchBy !== undefined) {
                localVarQueryParameter['searchBy'] = searchBy;
            }

            if (fromCreateDatetime !== undefined) {
                localVarQueryParameter['fromCreateDatetime'] = (fromCreateDatetime as any instanceof Date) ?
                    (fromCreateDatetime as any).toISOString() :
                    fromCreateDatetime;
            }

            if (toCreateDatetime !== undefined) {
                localVarQueryParameter['toCreateDatetime'] = (toCreateDatetime as any instanceof Date) ?
                    (toCreateDatetime as any).toISOString() :
                    toCreateDatetime;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NotificationCursor} notificationCursor 
         * @param {'ERROR' | 'REMINDER' | 'WARNING'} [type] 
         * @param {boolean} [markedAsRead] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationsByCursor: async (notificationCursor: NotificationCursor, type?: 'ERROR' | 'REMINDER' | 'WARNING', markedAsRead?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'notificationCursor' is not null or undefined
            assertParamExists('getNotificationsByCursor', 'notificationCursor', notificationCursor)
            const localVarPath = `/notification/by-cursor`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (markedAsRead !== undefined) {
                localVarQueryParameter['markedAsRead'] = markedAsRead;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notificationCursor, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStats: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/notification/stats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LastNewNotificationDto} lastNewNotificationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markAsNotNew: async (lastNewNotificationDto: LastNewNotificationDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'lastNewNotificationDto' is not null or undefined
            assertParamExists('markAsNotNew', 'lastNewNotificationDto', lastNewNotificationDto)
            const localVarPath = `/notification/markAsNotNew`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(lastNewNotificationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<number>} notificationIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markAsRead: async (notificationIds: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'notificationIds' is not null or undefined
            assertParamExists('markAsRead', 'notificationIds', notificationIds)
            const localVarPath = `/notification/{notificationIds}/markedAsRead`
                .replace(`{${"notificationIds"}}`, encodeURIComponent(String(notificationIds)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'ERROR' | 'REMINDER' | 'WARNING'} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markAsReadByType: async (type?: 'ERROR' | 'REMINDER' | 'WARNING', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/notification/markedAsRead`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationControllerApi - functional programming interface
 * @export
 */
export const NotificationControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} xApiKey 
         * @param {NotificationCreateDto} notificationCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addNotification(xApiKey: string, notificationCreateDto: NotificationCreateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addNotification(xApiKey, notificationCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLastNewNotification(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LastNewNotificationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLastNewNotification(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {'ERROR' | 'REMINDER' | 'WARNING'} [type] 
         * @param {boolean} [isNew] 
         * @param {boolean} [markedAsRead] 
         * @param {string} [searchBy] 
         * @param {string} [fromCreateDatetime] 
         * @param {string} [toCreateDatetime] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotifications(type?: 'ERROR' | 'REMINDER' | 'WARNING', isNew?: boolean, markedAsRead?: boolean, searchBy?: string, fromCreateDatetime?: string, toCreateDatetime?: string, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageNotificationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNotifications(type, isNew, markedAsRead, searchBy, fromCreateDatetime, toCreateDatetime, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NotificationCursor} notificationCursor 
         * @param {'ERROR' | 'REMINDER' | 'WARNING'} [type] 
         * @param {boolean} [markedAsRead] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotificationsByCursor(notificationCursor: NotificationCursor, type?: 'ERROR' | 'REMINDER' | 'WARNING', markedAsRead?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationBatchDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNotificationsByCursor(notificationCursor, type, markedAsRead, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStats(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationStatsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStats(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {LastNewNotificationDto} lastNewNotificationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markAsNotNew(lastNewNotificationDto: LastNewNotificationDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markAsNotNew(lastNewNotificationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<number>} notificationIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markAsRead(notificationIds: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markAsRead(notificationIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {'ERROR' | 'REMINDER' | 'WARNING'} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markAsReadByType(type?: 'ERROR' | 'REMINDER' | 'WARNING', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markAsReadByType(type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NotificationControllerApi - factory interface
 * @export
 */
export const NotificationControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {NotificationControllerApiAddNotificationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNotification(requestParameters: NotificationControllerApiAddNotificationRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.addNotification(requestParameters.xApiKey, requestParameters.notificationCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastNewNotification(options?: AxiosRequestConfig): AxiosPromise<LastNewNotificationDto> {
            return localVarFp.getLastNewNotification(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NotificationControllerApiGetNotificationsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotifications(requestParameters: NotificationControllerApiGetNotificationsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<PageNotificationDto> {
            return localVarFp.getNotifications(requestParameters.type, requestParameters.isNew, requestParameters.markedAsRead, requestParameters.searchBy, requestParameters.fromCreateDatetime, requestParameters.toCreateDatetime, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NotificationControllerApiGetNotificationsByCursorRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationsByCursor(requestParameters: NotificationControllerApiGetNotificationsByCursorRequest, options?: AxiosRequestConfig): AxiosPromise<NotificationBatchDto> {
            return localVarFp.getNotificationsByCursor(requestParameters.notificationCursor, requestParameters.type, requestParameters.markedAsRead, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStats(options?: AxiosRequestConfig): AxiosPromise<NotificationStatsDto> {
            return localVarFp.getStats(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NotificationControllerApiMarkAsNotNewRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markAsNotNew(requestParameters: NotificationControllerApiMarkAsNotNewRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.markAsNotNew(requestParameters.lastNewNotificationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NotificationControllerApiMarkAsReadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markAsRead(requestParameters: NotificationControllerApiMarkAsReadRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.markAsRead(requestParameters.notificationIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NotificationControllerApiMarkAsReadByTypeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markAsReadByType(requestParameters: NotificationControllerApiMarkAsReadByTypeRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.markAsReadByType(requestParameters.type, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for addNotification operation in NotificationControllerApi.
 * @export
 * @interface NotificationControllerApiAddNotificationRequest
 */
export interface NotificationControllerApiAddNotificationRequest {
    /**
     * 
     * @type {string}
     * @memberof NotificationControllerApiAddNotification
     */
    readonly xApiKey: string

    /**
     * 
     * @type {NotificationCreateDto}
     * @memberof NotificationControllerApiAddNotification
     */
    readonly notificationCreateDto: NotificationCreateDto
}

/**
 * Request parameters for getNotifications operation in NotificationControllerApi.
 * @export
 * @interface NotificationControllerApiGetNotificationsRequest
 */
export interface NotificationControllerApiGetNotificationsRequest {
    /**
     * 
     * @type {'ERROR' | 'REMINDER' | 'WARNING'}
     * @memberof NotificationControllerApiGetNotifications
     */
    readonly type?: 'ERROR' | 'REMINDER' | 'WARNING'

    /**
     * 
     * @type {boolean}
     * @memberof NotificationControllerApiGetNotifications
     */
    readonly isNew?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof NotificationControllerApiGetNotifications
     */
    readonly markedAsRead?: boolean

    /**
     * 
     * @type {string}
     * @memberof NotificationControllerApiGetNotifications
     */
    readonly searchBy?: string

    /**
     * 
     * @type {string}
     * @memberof NotificationControllerApiGetNotifications
     */
    readonly fromCreateDatetime?: string

    /**
     * 
     * @type {string}
     * @memberof NotificationControllerApiGetNotifications
     */
    readonly toCreateDatetime?: string

    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof NotificationControllerApiGetNotifications
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof NotificationControllerApiGetNotifications
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof NotificationControllerApiGetNotifications
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for getNotificationsByCursor operation in NotificationControllerApi.
 * @export
 * @interface NotificationControllerApiGetNotificationsByCursorRequest
 */
export interface NotificationControllerApiGetNotificationsByCursorRequest {
    /**
     * 
     * @type {NotificationCursor}
     * @memberof NotificationControllerApiGetNotificationsByCursor
     */
    readonly notificationCursor: NotificationCursor

    /**
     * 
     * @type {'ERROR' | 'REMINDER' | 'WARNING'}
     * @memberof NotificationControllerApiGetNotificationsByCursor
     */
    readonly type?: 'ERROR' | 'REMINDER' | 'WARNING'

    /**
     * 
     * @type {boolean}
     * @memberof NotificationControllerApiGetNotificationsByCursor
     */
    readonly markedAsRead?: boolean
}

/**
 * Request parameters for markAsNotNew operation in NotificationControllerApi.
 * @export
 * @interface NotificationControllerApiMarkAsNotNewRequest
 */
export interface NotificationControllerApiMarkAsNotNewRequest {
    /**
     * 
     * @type {LastNewNotificationDto}
     * @memberof NotificationControllerApiMarkAsNotNew
     */
    readonly lastNewNotificationDto: LastNewNotificationDto
}

/**
 * Request parameters for markAsRead operation in NotificationControllerApi.
 * @export
 * @interface NotificationControllerApiMarkAsReadRequest
 */
export interface NotificationControllerApiMarkAsReadRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof NotificationControllerApiMarkAsRead
     */
    readonly notificationIds: Array<number>
}

/**
 * Request parameters for markAsReadByType operation in NotificationControllerApi.
 * @export
 * @interface NotificationControllerApiMarkAsReadByTypeRequest
 */
export interface NotificationControllerApiMarkAsReadByTypeRequest {
    /**
     * 
     * @type {'ERROR' | 'REMINDER' | 'WARNING'}
     * @memberof NotificationControllerApiMarkAsReadByType
     */
    readonly type?: 'ERROR' | 'REMINDER' | 'WARNING'
}

/**
 * NotificationControllerApi - object-oriented interface
 * @export
 * @class NotificationControllerApi
 * @extends {BaseAPI}
 */
export class NotificationControllerApi extends BaseAPI {
    /**
     * 
     * @param {NotificationControllerApiAddNotificationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationControllerApi
     */
    public addNotification(requestParameters: NotificationControllerApiAddNotificationRequest, options?: AxiosRequestConfig) {
        return NotificationControllerApiFp(this.configuration).addNotification(requestParameters.xApiKey, requestParameters.notificationCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationControllerApi
     */
    public getLastNewNotification(options?: AxiosRequestConfig) {
        return NotificationControllerApiFp(this.configuration).getLastNewNotification(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NotificationControllerApiGetNotificationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationControllerApi
     */
    public getNotifications(requestParameters: NotificationControllerApiGetNotificationsRequest = {}, options?: AxiosRequestConfig) {
        return NotificationControllerApiFp(this.configuration).getNotifications(requestParameters.type, requestParameters.isNew, requestParameters.markedAsRead, requestParameters.searchBy, requestParameters.fromCreateDatetime, requestParameters.toCreateDatetime, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NotificationControllerApiGetNotificationsByCursorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationControllerApi
     */
    public getNotificationsByCursor(requestParameters: NotificationControllerApiGetNotificationsByCursorRequest, options?: AxiosRequestConfig) {
        return NotificationControllerApiFp(this.configuration).getNotificationsByCursor(requestParameters.notificationCursor, requestParameters.type, requestParameters.markedAsRead, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationControllerApi
     */
    public getStats(options?: AxiosRequestConfig) {
        return NotificationControllerApiFp(this.configuration).getStats(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NotificationControllerApiMarkAsNotNewRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationControllerApi
     */
    public markAsNotNew(requestParameters: NotificationControllerApiMarkAsNotNewRequest, options?: AxiosRequestConfig) {
        return NotificationControllerApiFp(this.configuration).markAsNotNew(requestParameters.lastNewNotificationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NotificationControllerApiMarkAsReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationControllerApi
     */
    public markAsRead(requestParameters: NotificationControllerApiMarkAsReadRequest, options?: AxiosRequestConfig) {
        return NotificationControllerApiFp(this.configuration).markAsRead(requestParameters.notificationIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NotificationControllerApiMarkAsReadByTypeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationControllerApi
     */
    public markAsReadByType(requestParameters: NotificationControllerApiMarkAsReadByTypeRequest = {}, options?: AxiosRequestConfig) {
        return NotificationControllerApiFp(this.configuration).markAsReadByType(requestParameters.type, options).then((request) => request(this.axios, this.basePath));
    }
}
