import { styled } from '@mui/material';

const OrWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  '& .MuiTypography-root': {
    position: 'relative',
    color: theme.palette.grey[600],
    backgroundColor: theme.palette.black[500],
  },

  '&:before': {
    content: "''",
    position: 'absolute',
    height: '100%',
    width: '1px',
    backgroundColor: theme.palette.grey[800],
  },

  [theme.breakpoints.down('desktop')]: {
    '& .MuiTypography-root': {
      padding: theme.spacing(0, 1),
    },

    '&:before': {
      height: '1px',
      width: '100%',
    },
  },
}));

export default OrWrapper;
