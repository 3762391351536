import { styled } from '@mui/material/styles';

const LoaderWrapper = styled('div')(({ theme }) => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  left: 0,
  top: 0,
  backgroundColor: 'white',
  zIndex: theme.zIndex.globalLoader,
}));

export default LoaderWrapper;
