import { Box, Theme, useMediaQuery } from '@mui/material';
import { GridCellParams } from '@mui/x-data-grid';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { generateColumns } from './columns';
import {
  adminUsersFilterValues,
  clickDisabledFields,
  fieldsToSend,
} from './constants';
import { AddUserButton, TableHeadWrapper } from '../../styles';
import { AppliedFilters } from 'components/common/AppliedFilters';
import {
  CommonTable,
  FilterMenu,
  Search,
  TablePagination,
} from 'components/common/Table/CommonTable';
import { Plus } from 'components/icons';
import routePaths from 'constants/routePaths';
import useApi from 'contexts/api';
import { FilterItem } from 'types/general';
import { formatObjFromQuery } from 'utils/functions/formatQuery';
import { getRowsPerPage } from 'utils/functions/getRowsPerPage';
import { useAsyncResourceWithPulling } from 'utils/hooks/useAsyncResourceWithPulling';
import useDataGrid from 'utils/hooks/useDataGrid';

export const AdminUsers: FC = () => {
  const navigate = useNavigate();
  const { adminUserControllerApi } = useApi();
  const isDesktop = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.up('desktop'),
  );
  const { t } = useTranslation();
  const {
    sortModel,
    onSortModelChange,
    searchParams,
    paginationModel,
    onPaginationModelChange,
    onSearch,
    onFilterModelChange,
  } = useDataGrid();

  const getAdminUsers = useCallback(async () => {
    const objFromQuery = formatObjFromQuery(fieldsToSend, searchParams);

    try {
      return (await adminUserControllerApi.getAdminUserDetails(objFromQuery))
        .data;
    } catch {}
  }, [searchParams, adminUserControllerApi]);

  const { resource: adminUsers, isLoading } = useAsyncResourceWithPulling({
    fetchResource: getAdminUsers,
    pullingInterval: 30,
  });

  const checkedFilterValues: Record<string, FilterItem> = useMemo(
    () => ({
      status: { value: searchParams.get('status') },
      rolePermission: { value: searchParams.get('rolePermission') },
    }),
    [searchParams],
  );

  const columns = useMemo(() => generateColumns({ t }), [t]);

  const rows = useMemo(
    () =>
      adminUsers?.content?.map((user) => ({ id: user.userId!, ...user })) || [],
    [adminUsers?.content],
  );

  const rowsPerPageOptions = useMemo(
    () => getRowsPerPage(t, !isDesktop),
    [isDesktop, t],
  );

  const filterValues = useMemo(() => adminUsersFilterValues(t), [t]);

  const onCellClick = useCallback(
    ({ row, field }: GridCellParams) => {
      if (clickDisabledFields.includes(field)) return;

      if (typeof row.userId !== 'undefined') {
        navigate(routePaths.user.profile(row.userId));
      }
    },
    [navigate],
  );

  return (
    <div>
      <TableHeadWrapper>
        <Search onSearch={onSearch} light sx={{ width: '100%' }} />
        <Box display="flex" gap={2} justifyContent="flex-end">
          <FilterMenu
            checkedFilterValues={checkedFilterValues}
            filterValues={filterValues}
            onFilterModelChange={onFilterModelChange}
            light
            sx={{ ml: 'auto' }}
          />
          <AddUserButton endIcon={<Plus />}>
            {isDesktop ? t('pages.users.adminUsers.addUserButtonText') : null}
          </AddUserButton>
        </Box>
      </TableHeadWrapper>
      <AppliedFilters
        checkedFilterValues={checkedFilterValues}
        filterValues={filterValues}
        onFilterModelChange={onFilterModelChange}
        sx={{ mt: 2 }}
      />
      <CommonTable
        columns={columns}
        rows={rows}
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        sortingMode="server"
        rowCount={adminUsers?.size}
        onCellClick={onCellClick}
        loading={isLoading}
        sx={{
          mt: 2.5,
          maxWidth: '100%',
          '& .MuiDataGrid-row:hover': {
            backgroundColor: 'grey.50',
            cursor: 'pointer',
          },
        }}
      />
      <TablePagination
        count={adminUsers?.totalElements}
        page={paginationModel.page}
        rowsPerPage={paginationModel.pageSize}
        rowsPerPageOptions={rowsPerPageOptions}
        onPaginationModelChange={onPaginationModelChange}
        sx={{
          ...(!isDesktop && {
            '& .MuiTablePagination-input': { width: 66 },
          }),
        }}
      />
    </div>
  );
};
