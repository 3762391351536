/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface AssetCommandDto
 */
export interface AssetCommandDto {
    /**
     * 
     * @type {string}
     * @memberof AssetCommandDto
     */
    'assetPublicId': string;
    /**
     * 
     * @type {string}
     * @memberof AssetCommandDto
     */
    'operationalStatus'?: AssetCommandDtoOperationalStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof AssetCommandDto
     */
    'accelerationStatus'?: AssetCommandDtoAccelerationStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum AssetCommandDtoOperationalStatusEnum {
    NOT_SET = 'NOT_SET',
    ACTIVATED = 'ACTIVATED',
    DEACTIVATED = 'DEACTIVATED'
}
/**
    * @export
    * @enum {string}
    */
export enum AssetCommandDtoAccelerationStatusEnum {
    NOT_SET = 'NOT_SET',
    ACTIVATED = 'ACTIVATED',
    DEACTIVATED = 'DEACTIVATED'
}


