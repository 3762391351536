import { styled } from '@mui/material/styles';

import publicPath from '../../../../utils/publicPath';
import CommonDialog from '../../CommonDialog';

const SuccessDialogWrapper = styled(CommonDialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    backgroundColor: theme.palette.black['600'],
    backgroundImage: `url(${publicPath('/images/success-bg.png')})`,
    backgroundSize: 'cover',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(6.5),
    [theme.breakpoints.up('desktop')]: {
      maxWidth: '540px',
    },
    [theme.breakpoints.down('desktop')]: {
      gap: 0,
      justifyContent: 'space-between',
    },
  },
}));

export default SuccessDialogWrapper;
