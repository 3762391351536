import { Dialog as DialogMui, styled } from '@mui/material';

export const Dialog = styled(DialogMui)(({ theme }) => ({
  '& .MuiDialog-paper': {
    width: '540px',
    padding: theme.spacing(8, 6),
    borderRadius: '25px',
    textAlign: 'center',
    backgroundColor: 'white',

    [theme.breakpoints.down('desktop')]: {
      height: '100%',
      maxHeight: '100%',
      width: '100%',
      maxWidth: 'unset',
      minWidth: 'unset',
      margin: 0,
      padding: theme.spacing(7.5, 15, 5),
      borderRadius: 0,
    },

    [theme.breakpoints.down('tablet')]: {
      padding: theme.spacing(8, 2.5, 5),
    },
  },
}));
