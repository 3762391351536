import { FormControlLabel } from '@mui/material';
import { styled } from '@mui/material/styles';

const RadioRow = styled(FormControlLabel)(({ theme }) => ({
  height: '48px',
  padding: theme.spacing(1.5, 2.5),
  margin: 0,
  display: 'flex',
  gap: theme.spacing(2),
  '&:hover': {
    backgroundColor: theme.palette.grey[50],
  },

  '& .MuiRadio-root': {
    padding: 0,
  },
}));

export default RadioRow;
