import { Global } from '@emotion/react';
import React from 'react';
import { useOutlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import AppProvider from 'contexts/AppProvider';
import globalStyles from 'styles/globalStyles';

const App: React.FC = () => {
  const outlet = useOutlet();

  return (
    <AppProvider>
      <ToastContainer theme="colored" />
      <Global styles={globalStyles} />
      {outlet}
    </AppProvider>
  );
};

export default App;
