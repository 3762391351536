export enum WIDGETS {
  WIND_SPEED = 35,
  GENERATOR_SPEED = 36,
  GENERATOR_SPEED_CHART = 63,
  ACTIVE_POWER = 23,
  WIND_SPEED_CHART = 12,
  ACTIVE_POWER_CHART = 16,
  ACTIVE_POWER_COUNTER = 58,
  TOTAL_LOST_ENERGY_CHART = 61,
  TOTAL_LOST_ENERGY_COUNTER = 62,
  NETWORK_PARAMETERS_VERTICAL_1 = 17,
  NETWORK_PARAMETERS_VERTICAL_2 = 37,
  NETWORK_PARAMETERS_VERTICAL_3 = 38,
  NETWORK_PARAMETERS_VERTICAL_1_MOBILE = 25,
  NETWORK_PARAMETERS_VERTICAL_2_MOBILE = 44,
  NETWORK_PARAMETERS_VERTICAL_3_MOBILE = 45,
  ELECTRICAL_PARAMETERS_VERTICAL_1 = 24,
  ELECTRICAL_PARAMETERS_VERTICAL_2 = 40,
  ELECTRICAL_PARAMETERS_VERTICAL_3 = 41,
  ELECTRICAL_PARAMETERS_VERTICAL_1_MOBILE = 54,
  ELECTRICAL_PARAMETERS_VERTICAL_2_MOBILE = 55,
  ELECTRICAL_PARAMETERS_VERTICAL_3_MOBILE = 56,
  SUMMARY_PARAMETERS_VERTICAL_1 = 14,
  SUMMARY_PARAMETERS_VERTICAL_2 = 42,
  SUMMARY_PARAMETERS_VERTICAL_1_MOBILE = 46,
  SUMMARY_PARAMETERS_VERTICAL_2_MOBILE = 51,
  TEMPERATURE_PARAMETERS_1 = 39,
  TEMPERATURE_PARAMETERS_2 = 43,
  TEMPERATURE_PARAMETERS_1_MOBILE = 39,
  TEMPERATURE_PARAMETERS_2_MOBILE = 57,
  STATUSES = 12,
  STATUSES_MOBILE = 30,
  ERRORS = 29,
  ERRORS_MOBILE = 31,
}
