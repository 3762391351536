import { SxProps } from '@mui/material';
import Typography from '@mui/material/Typography';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import OrWrapper from './styles/OrWrapper';

interface NoDataProps {
  sx?: SxProps;
}
const OrHorizontal: FC<NoDataProps> = ({ sx }) => {
  const { t } = useTranslation();

  return (
    <OrWrapper sx={sx}>
      <Typography
        variant="bodyM"
        color="grey.600"
        component="div"
        sx={{ background: 'white', px: 1, zIndex: 1 }}
      >{t`or`}</Typography>
    </OrWrapper>
  );
};
export default OrHorizontal;
