import { styled } from '@mui/material';

export const StatusFilterItem = styled('div', {
  shouldForwardProp: (propName) => propName !== 'isActive',
})<{ isActive?: boolean }>(({ theme, isActive }) => ({
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  gap: theme.spacing(1.5),
  padding: theme.spacing(4),
  backgroundColor: isActive
    ? theme.palette.green['500']
    : theme.palette.grey['900'],
  cursor: 'pointer',

  '& .MuiTypography-root': {
    color: isActive ? theme.palette.black[900] : theme.palette.white.main,
  },

  '&:not(:first-of-type):after': {
    content: '""',
    position: 'absolute',
    left: '0px',
    height: '55px',
    width: '1px',
    backgroundColor: isActive ? 'transparent' : theme.palette.grey['800'],
  },

  [theme.breakpoints.down('desktop')]: {
    gap: theme.spacing(1),
    padding: theme.spacing(2.5, 2),

    '&:not(:first-of-type):after': {
      top: 0,
      height: '100%',
    },
  },
}));
