import { styled } from '@mui/material';

export const InnerWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: '100%',

  [theme.breakpoints.down('desktop')]: {
    justifyContent: 'space-between',
  },
}));
