import { Box, Typography } from '@mui/material';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { getWeatherDateNaming } from './helpers';
import {
  HumidityIcon,
  UviIcon,
  WeatherDailyItem,
  WeatherDailyItems,
  WeatherDailyTitle,
  WeatherDailyWrapper,
  WindIcon,
} from './styles';
import { UviChip } from '../../styles';
import {
  ConditionIcon,
  ConditionTitle,
  DegreesCelcius,
  PrecipitationChance,
} from 'components/shared/Weather';
import { useParkContext } from 'contexts/park';

export const WeatherDaily: FC = () => {
  const { t } = useTranslation();
  const { weather } = useParkContext();

  const getWeatherDate = useCallback(
    (datetime?: string) => getWeatherDateNaming({ datetime, t }),
    [t],
  );

  return (
    <WeatherDailyWrapper>
      <WeatherDailyTitle variant="h4">
        {t('pages.parkDetails.weather.dailyWeatherTitle')}
      </WeatherDailyTitle>
      <WeatherDailyItems>
        {weather?.dayForecast?.map((weatherItem) => (
          <WeatherDailyItem key={weatherItem.datetime}>
            <Box>
              <Box display="flex" flexDirection="column" gap={0.25}>
                <ConditionIcon iconLink={weatherItem.conditionIcon} isDay />
                <PrecipitationChance
                  variant="bodyXSStrong"
                  color="green.600"
                  chanceOfRain={weatherItem.dailyChanceOfRain}
                  chanceOfSnow={weatherItem.dailyChanceOfSnow}
                  sx={{ display: { mobile: 'block', desktop: 'none' } }}
                />
              </Box>
              <Box display="flex" flexDirection="column" gap={0.25}>
                <Typography variant="bodyS" color="grey.500">
                  {getWeatherDate(weatherItem.datetime)}
                </Typography>
                <Box>
                  <DegreesCelcius
                    degrees={weatherItem.minTemperatureC}
                    withSign
                    variant="bodySStrong"
                  />
                  <Typography variant="bodySStrong">{'...'}</Typography>
                  <DegreesCelcius
                    degrees={weatherItem.maxTemperatureC}
                    withSign
                    variant="bodySStrong"
                  />
                </Box>
              </Box>
            </Box>
            <Box>
              <ConditionTitle
                variant="bodyS"
                showTooltip
                conditionCode={weatherItem.conditionCode}
                isDay
              />
              <PrecipitationChance
                variant="bodyXSStrong"
                color="green.600"
                chanceOfRain={weatherItem.dailyChanceOfRain}
                chanceOfSnow={weatherItem.dailyChanceOfSnow}
              />
            </Box>
            <Box>
              <Box
                display="flex"
                flexDirection="column"
                gap={0.5}
                whiteSpace="nowrap"
              >
                <WindIcon
                  sx={{ display: { mobile: 'none', desktop: 'block' } }}
                />
                <Box display="flex" alignItems="center" gap={0.5}>
                  <Typography variant="bodyS">{`${
                    weatherItem.maxWindSpeedMps
                  } ${t('weather.windSpeedMps')}`}</Typography>
                  <Typography variant="bodyXS" color="grey.600">
                    {t('weather.windSpeed')}
                  </Typography>
                </Box>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                gap={0.5}
                whiteSpace="nowrap"
              >
                <HumidityIcon
                  sx={{ display: { mobile: 'none', desktop: 'block' } }}
                />
                <Box display="flex" alignItems="center" gap={0.5}>
                  <Typography variant="bodyS">
                    {`${weatherItem.averageHumidity}%`}
                  </Typography>
                  <Typography
                    variant="bodyXS"
                    color="grey.600"
                    sx={{ display: { mobile: 'block', desktop: 'none' } }}
                  >
                    {t('weather.humidity')}
                  </Typography>
                </Box>
              </Box>
              <Box
                display={{ mobile: 'none', desktop: 'flex' }}
                flexDirection="column"
                gap={0.5}
                width="40px"
                whiteSpace="nowrap"
              >
                <UviIcon />
                <UviChip uvIndex={weatherItem.uv}>
                  <Typography variant="subheading">{weatherItem.uv}</Typography>
                </UviChip>
              </Box>
            </Box>
          </WeatherDailyItem>
        ))}
      </WeatherDailyItems>
    </WeatherDailyWrapper>
  );
};
