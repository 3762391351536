import { styled } from '@mui/material/styles';

import CommonDialog from '../../../../common/CommonDialog';

export const Dialogue = styled(CommonDialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    [theme.breakpoints.up('desktop')]: {
      maxWidth: '540px',
      width: '100%',
    },
  },
}));
