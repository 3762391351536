import {
  Grid,
  SxProps,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { FC } from 'react';

import { WindTurbineParametersWrapper } from './styles';
import { WIDGETS } from '../../constants';
import { GrafanaIframe } from '../GrafanaIframe';

interface Props {
  name: string;
  widgets: WIDGETS[];
  widgetsMobile: WIDGETS[];
  height?: number | number[];
  sx?: SxProps;
  vertical?: boolean;
  mobileGap?: number;
}

export const WindTurbineParameters: FC<Props> = ({
  name,
  widgets,
  widgetsMobile,
  height,
  sx,
  vertical = true,
  mobileGap = 0.5,
}) => {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('tablet'));

  return (
    <Grid container flexDirection="column" sx={sx}>
      <Typography variant="h4" sx={{ mb: 3 }}>
        {name}
      </Typography>
      <Grid
        display="flex"
        rowGap={{ mobile: mobileGap, tablet: 5 }}
        columnGap={4}
        flexDirection={vertical || isMobile ? 'column' : 'row'}
      >
        {(isMobile ? widgetsMobile : widgets).map((widget, index) => (
          <WindTurbineParametersWrapper
            key={widget}
            height={Array.isArray(height) ? height[index] : height}
          >
            <GrafanaIframe panelId={widget} />
          </WindTurbineParametersWrapper>
        ))}
      </Grid>
    </Grid>
  );
};
