export const sortingMapping = (
  mapping: Record<string, string>,
  value?: string,
): string[] | undefined => {
  if (!value) return undefined;

  const [field, order] = value.split(',');

  return [`${mapping[field]},${order}`];
};
