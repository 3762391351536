import { styled } from '@mui/material';

import { Humidity, Uvi, Wind } from 'components/icons/weather';

export const WindIcon = styled(Wind)(() => ({
  height: '20px',
  width: '20px',
}));

export const HumidityIcon = styled(Humidity)(() => ({
  height: '20px',
  width: '20px',
}));

export const UviIcon = styled(Uvi)(() => ({
  height: '20px',
  width: '20px',
}));
