import { Radio, RadioGroup } from '@mui/material';
import Typography from '@mui/material/Typography';
import { FC, useCallback } from 'react';

import RadioRow from '../styles/RadioRow';
import { FilterProps } from '../types';

export const RadioFilter: FC<FilterProps> = ({
  values,
  field,
  localValues,
  setLocalValues,
}) => {
  const handleToggle = useCallback(
    (value: string) => {
      setLocalValues((old) => ({ ...old, [field]: { value } }));
    },
    [setLocalValues, field],
  );

  return (
    <RadioGroup name={field}>
      {values.map(({ label, value }) => (
        <RadioRow
          value={value}
          control={<Radio />}
          label={<Typography variant="bodyM">{label}</Typography>}
          checked={localValues[field]?.value === value}
          onClick={() => handleToggle(value as string)}
          key={value}
        />
      ))}
    </RadioGroup>
  );
};
