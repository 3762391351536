import { ManipulateType } from 'dayjs';

import { ChipFilterValue } from 'components/common/ChipFilter';
import { TranslationFunction } from 'types/general';

export enum TagsCategories {
  NETWORK = 'network',
  SUMMARY = 'summary',
  TEMPERATURE = 'temperature',
  FREQUENCY_CONVERTER = 'frequencyConverter',
  MECHANICAL_PARAMETERS = 'mechanicalParameters',
}

export const categoryOptions: ChipFilterValue[] = [
  { tKey: 'pages.viewTags.network', value: TagsCategories.NETWORK },
  { tKey: 'pages.viewTags.summary', value: TagsCategories.SUMMARY },
  { tKey: 'pages.viewTags.temperature', value: TagsCategories.TEMPERATURE },
  {
    tKey: 'pages.viewTags.frequencyConverter',
    value: TagsCategories.FREQUENCY_CONVERTER,
  },
  {
    tKey: 'pages.viewTags.mechanicalParameters',
    value: TagsCategories.MECHANICAL_PARAMETERS,
  },
];

export enum SelectTimePeriods {
  LAST_DAY = 'lastDay',
  LAST_WEEK = 'lastWeek',
}

export const dayjsManipulateTypeMapping: Record<
  SelectTimePeriods,
  ManipulateType
> = {
  [SelectTimePeriods.LAST_DAY]: 'day',
  [SelectTimePeriods.LAST_WEEK]: 'week',
};

export const selectTimePeriodOptions = (t: TranslationFunction) => [
  {
    label: t('pages.viewTags.lastDay'),
    value: SelectTimePeriods.LAST_DAY,
  },
  {
    label: t('pages.viewTags.lastWeek'),
    value: SelectTimePeriods.LAST_WEEK,
  },
];

export const panelsMapping = {
  [TagsCategories.NETWORK]: [
    { panelId: 63, tKey: 'pages.viewTags.panels.activePower' },
    { panelId: 64, tKey: 'pages.viewTags.panels.reactivePower' },
    { panelId: 65, tKey: 'pages.viewTags.panels.totalPower' },
    { panelId: 66, tKey: 'pages.viewTags.panels.turbinePowerCoefficient' },
    { panelId: 67, tKey: 'pages.viewTags.panels.frequency' },
    { panelId: 68, tKey: 'pages.viewTags.panels.voltageL1N' },
    { panelId: 69, tKey: 'pages.viewTags.panels.voltageL2N' },
    { panelId: 70, tKey: 'pages.viewTags.panels.voltageL3N' },
    { panelId: 71, tKey: 'pages.viewTags.panels.voltageL1L2' },
    { panelId: 72, tKey: 'pages.viewTags.panels.voltageL2L3' },
    { panelId: 73, tKey: 'pages.viewTags.panels.voltageL1L3' },
    { panelId: 74, tKey: 'pages.viewTags.panels.currentL1' },
    { panelId: 75, tKey: 'pages.viewTags.panels.currentL2' },
    { panelId: 76, tKey: 'pages.viewTags.panels.currentL3' },
    { panelId: 77, tKey: 'pages.viewTags.panels.currentN' },
  ],
  [TagsCategories.SUMMARY]: [
    { panelId: 79, tKey: 'pages.viewTags.panels.totalActiveEnergy' },
    { panelId: 80, tKey: 'pages.viewTags.panels.totalActiveEnergyConsumed' },
    { panelId: 81, tKey: 'pages.viewTags.panels.totalActiveEnergyDelivered' },
    { panelId: 82, tKey: 'pages.viewTags.panels.totalApparentEnergy' },
    { panelId: 83, tKey: 'pages.viewTags.panels.totalReactiveEnergy' },
    { panelId: 84, tKey: 'pages.viewTags.panels.totalReactiveEnergyInd' },
    { panelId: 85, tKey: 'pages.viewTags.panels.totalReactiveEnergyCap' },
    { panelId: 86, tKey: 'pages.viewTags.panels.totalLostEnergy' },
  ],
  [TagsCategories.TEMPERATURE]: [
    { panelId: 87, tKey: 'pages.viewTags.panels.temperatureGenPhaseA' },
    { panelId: 88, tKey: 'pages.viewTags.panels.temperatureGenPhaseB' },
    { panelId: 89, tKey: 'pages.viewTags.panels.temperatureGenPhaseC' },
    { panelId: 90, tKey: 'pages.viewTags.panels.temperaturePLCBox' },
    { panelId: 91, tKey: 'pages.viewTags.panels.temperatureDCResistor' },
    { panelId: 92, tKey: 'pages.viewTags.panels.temperatureBrakeResistor' },
    { panelId: 93, tKey: 'pages.viewTags.panels.temperatureUZBoxBack' },
    { panelId: 94, tKey: 'pages.viewTags.panels.temperatureUZBoxFront' },
  ],
  [TagsCategories.FREQUENCY_CONVERTER]: [
    { panelId: 95, tKey: 'pages.viewTags.panels.pinv' },
    { panelId: 96, tKey: 'pages.viewTags.panels.pafe' },
    { panelId: 97, tKey: 'pages.viewTags.panels.generatorFrequency' },
    { panelId: 98, tKey: 'pages.viewTags.panels.dCBusVoltage' },
    { panelId: 99, tKey: 'pages.viewTags.panels.gridInjectionHours' },
    { panelId: 100, tKey: 'pages.viewTags.panels.freqAFEf' },
    { panelId: 101, tKey: 'pages.viewTags.panels.vrsAFErms' },
    { panelId: 102, tKey: 'pages.viewTags.panels.vstAFErms' },
    { panelId: 103, tKey: 'pages.viewTags.panels.vtrAFErms' },
    { panelId: 104, tKey: 'pages.viewTags.panels.vrsINVrms' },
    { panelId: 105, tKey: 'pages.viewTags.panels.vstINVrms' },
    { panelId: 106, tKey: 'pages.viewTags.panels.vtrINVrms' },
    { panelId: 107, tKey: 'pages.viewTags.panels.irOUTrms' },
    { panelId: 108, tKey: 'pages.viewTags.panels.isOUTrms' },
    { panelId: 109, tKey: 'pages.viewTags.panels.itOUTrms' },
    { panelId: 110, tKey: 'pages.viewTags.panels.irINVrms' },
    { panelId: 111, tKey: 'pages.viewTags.panels.isINVrms' },
    { panelId: 112, tKey: 'pages.viewTags.panels.itINVrms' },

    { panelId: 113, tKey: 'pages.viewTags.panels.annualTotalEnergy' },
    { panelId: 114, tKey: 'pages.viewTags.panels.totalEnergy' },
    { panelId: 115, tKey: 'pages.viewTags.panels.totalSupplyHours' },
    { panelId: 116, tKey: 'pages.viewTags.panels.dailyTotalEnergy' },
    { panelId: 117, tKey: 'pages.viewTags.panels.monthlyTotalEnergy' },
    { panelId: 118, tKey: 'pages.viewTags.panels.temperatureAFE' },
    { panelId: 119, tKey: 'pages.viewTags.panels.temperatureInverter' },
    { panelId: 120, tKey: 'pages.viewTags.panels.cabinetTemperature' },
  ],
  [TagsCategories.MECHANICAL_PARAMETERS]: [
    { panelId: 12, tKey: 'pages.viewTags.panels.windSpeed' },
    { panelId: 121, tKey: 'pages.viewTags.panels.generatorSpeed' },
  ],
};
