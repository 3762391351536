import { styled } from '@mui/material/styles';

import { ChipFilter } from 'components/common/ChipFilter';

export const StyledChipFilter = styled(ChipFilter)(() => ({
  flexWrap: 'nowrap',
  overflowX: 'scroll',
  textWrap: 'nowrap',

  scrollbarWidth: 'none',
  '::-webkit-scrollbar': {
    display: 'none',
  },
}));
