import { styled } from '@mui/material';

export const PageWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(5, 0, 10),
  height: '100%',
  [theme.breakpoints.down('desktop')]: {
    padding: theme.spacing(2.5, 2.5, 7.5),
  },
}));
