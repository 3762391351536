import { styled } from '@mui/material/styles';

export const CurrentStepWrapper = styled('div')<{ isCurrent?: boolean }>(
  ({ isCurrent }) =>
    isCurrent
      ? {
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
        }
      : {
          display: 'none',
        },
);
