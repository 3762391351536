import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

const ChipWrapper = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('desktop')]: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
}));

export default ChipWrapper;
