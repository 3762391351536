import { FC } from 'react';

import { CommonSvgProps } from 'types/general';

export const Download: FC<CommonSvgProps> = (props) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7 12.5L12 17.5M12 17.5L17 12.5M12 17.5L12 4.5"
      stroke="#21252C"
      strokeWidth="2"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M6 20.5H18"
      stroke="#21252C"
      strokeWidth="2"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </svg>
);
