import Typography from '@mui/material/Typography';
import * as React from 'react';
import { FC, useState } from 'react';

import { TabWrapper, ExtendWrapper, SubmenuItem } from './styles';
import { Link } from '../Link';
import { TabType } from '../Tabs/types';
import { MainMenuChevronDown, MainMenuChevronUp } from 'components/icons';

interface Props {
  tab: TabType;
  itemClick: (value: string) => void;
}
export const MobileSubMenu: FC<Props> = ({ tab, itemClick }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <TabWrapper
        onClick={() => setExpanded((old) => !old)}
        key={tab.value}
        disabled={tab.disabled}
        sx={(theme) => ({
          backgroundColor: expanded ? theme.palette.black[500] : 'transparent',
        })}
      >
        <Typography variant="h4">{tab.label}</Typography>
        <ExtendWrapper>
          {expanded ? <MainMenuChevronUp /> : <MainMenuChevronDown />}
        </ExtendWrapper>
      </TabWrapper>
      {expanded &&
        tab.submenu?.map((item) => (
          <Link
            to={item.link as string}
            component="a"
            key={`${item.label}-${item.link}`}
            sx={{ textDecoration: 'none' }}
          >
            <SubmenuItem
              variant="bodyL"
              onClick={() => itemClick(item.link as string)}
            >
              {item.label}
            </SubmenuItem>
          </Link>
        ))}
    </>
  );
};
