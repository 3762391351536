/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { JwtAuthentication } from '../models';
/**
 * JwtAuthControllerApi - axios parameter creator
 * @export
 */
export const JwtAuthControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/jwt/sign-out`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} refreshToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshToken: async (refreshToken: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'refreshToken' is not null or undefined
            assertParamExists('refreshToken', 'refreshToken', refreshToken)
            const localVarPath = `/auth/jwt/refresh-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (refreshToken !== undefined) {
                localVarQueryParameter['refreshToken'] = refreshToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} portalOrigin 
         * @param {string} username 
         * @param {string} signature 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signIn: async (portalOrigin: string, username: string, signature: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'portalOrigin' is not null or undefined
            assertParamExists('signIn', 'portalOrigin', portalOrigin)
            // verify required parameter 'username' is not null or undefined
            assertParamExists('signIn', 'username', username)
            // verify required parameter 'signature' is not null or undefined
            assertParamExists('signIn', 'signature', signature)
            const localVarPath = `/auth/jwt/sign-in`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (username !== undefined) {
                localVarQueryParameter['username'] = username;
            }

            if (signature !== undefined) {
                localVarQueryParameter['signature'] = signature;
            }

            if (portalOrigin != null) {
                localVarHeaderParameter['Portal-Origin'] = String(portalOrigin);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * JwtAuthControllerApi - functional programming interface
 * @export
 */
export const JwtAuthControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = JwtAuthControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logout(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logout(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} refreshToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshToken(refreshToken: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JwtAuthentication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshToken(refreshToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} portalOrigin 
         * @param {string} username 
         * @param {string} signature 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signIn(portalOrigin: string, username: string, signature: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JwtAuthentication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signIn(portalOrigin, username, signature, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * JwtAuthControllerApi - factory interface
 * @export
 */
export const JwtAuthControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = JwtAuthControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.logout(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {JwtAuthControllerApiRefreshTokenRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshToken(requestParameters: JwtAuthControllerApiRefreshTokenRequest, options?: AxiosRequestConfig): AxiosPromise<JwtAuthentication> {
            return localVarFp.refreshToken(requestParameters.refreshToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {JwtAuthControllerApiSignInRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signIn(requestParameters: JwtAuthControllerApiSignInRequest, options?: AxiosRequestConfig): AxiosPromise<JwtAuthentication> {
            return localVarFp.signIn(requestParameters.portalOrigin, requestParameters.username, requestParameters.signature, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for refreshToken operation in JwtAuthControllerApi.
 * @export
 * @interface JwtAuthControllerApiRefreshTokenRequest
 */
export interface JwtAuthControllerApiRefreshTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof JwtAuthControllerApiRefreshToken
     */
    readonly refreshToken: string
}

/**
 * Request parameters for signIn operation in JwtAuthControllerApi.
 * @export
 * @interface JwtAuthControllerApiSignInRequest
 */
export interface JwtAuthControllerApiSignInRequest {
    /**
     * 
     * @type {string}
     * @memberof JwtAuthControllerApiSignIn
     */
    readonly portalOrigin: string

    /**
     * 
     * @type {string}
     * @memberof JwtAuthControllerApiSignIn
     */
    readonly username: string

    /**
     * 
     * @type {string}
     * @memberof JwtAuthControllerApiSignIn
     */
    readonly signature: string
}

/**
 * JwtAuthControllerApi - object-oriented interface
 * @export
 * @class JwtAuthControllerApi
 * @extends {BaseAPI}
 */
export class JwtAuthControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JwtAuthControllerApi
     */
    public logout(options?: AxiosRequestConfig) {
        return JwtAuthControllerApiFp(this.configuration).logout(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {JwtAuthControllerApiRefreshTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JwtAuthControllerApi
     */
    public refreshToken(requestParameters: JwtAuthControllerApiRefreshTokenRequest, options?: AxiosRequestConfig) {
        return JwtAuthControllerApiFp(this.configuration).refreshToken(requestParameters.refreshToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {JwtAuthControllerApiSignInRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JwtAuthControllerApi
     */
    public signIn(requestParameters: JwtAuthControllerApiSignInRequest, options?: AxiosRequestConfig) {
        return JwtAuthControllerApiFp(this.configuration).signIn(requestParameters.portalOrigin, requestParameters.username, requestParameters.signature, options).then((request) => request(this.axios, this.basePath));
    }
}
