import { styled } from '@mui/material';

export const StatsWrapper = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, auto)',
  gap: theme.spacing(2.5),

  [theme.breakpoints.down('desktop')]: {
    width: '100%',
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
}));
