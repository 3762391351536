import { styled } from '@mui/material/styles';

const Main = styled('main')<{ noPaddings: boolean }>(
  ({ theme, noPaddings }) => ({
    position: 'relative',
    overflowX: 'hidden',
    padding: noPaddings ? 0 : theme.spacing(0, 7.5, 0, 7.5),
    [theme.breakpoints.down('desktop')]: {
      padding: theme.spacing(0),
    },
    [theme.breakpoints.down('tablet')]: {
      paddingTop: noPaddings ? 0 : theme.spacing(0, 6.5),
    },
  }),
);

export default Main;
