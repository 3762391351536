import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { NotificationsContext } from './NotificationsContext';
import { INotificationsContext } from './types';
import { notificationsPopupTabs } from 'components/common/Notifications/NotificationsPopup/constants';
import useApi from 'contexts/api';
import {
  NotificationDto,
  NotificationDtoTypeEnum,
  LastNewNotificationDto,
  NotificationControllerApiGetNotificationsRequest,
} from 'openapi-api/admin-service';
import { useAsyncResource } from 'utils/hooks/useAsyncResource';
import { useAsyncResourceWithPulling } from 'utils/hooks/useAsyncResourceWithPulling';

const NotificationsProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const [initialTab, setInitialTab] = useState<number>(0);
  const [lastNewNotification, setLastNewNotification] =
    useState<LastNewNotificationDto>();

  const [notifications, setNotifications] = useState<NotificationDto[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const size = 10;

  const [ignoreClickOutside, setIgnoreClickOutside] = useState(false);

  const [isNotificationsPopupOpened, setIsNotificationsPopupOpened] =
    useState(false);

  const { notificationControllerApi } = useApi();

  const loadNotifications = useCallback(
    async (type: NotificationDtoTypeEnum) => {
      const params: NotificationControllerApiGetNotificationsRequest = {
        type,
        markedAsRead: false,
        page: 0,
        size,
      };

      setIsLoading(true);
      try {
        const response = (
          await notificationControllerApi.getNotifications(params)
        ).data;
        setNotifications(response?.content || []);
      } catch {
      } finally {
        setIsLoading(false);
      }
    },
    [notificationControllerApi],
  );

  const resetNotificationsData = useCallback(() => {
    setNotifications([]);
  }, []);

  const getUnreadNotificationsCount = useCallback(async () => {
    try {
      const unreadCount = (await notificationControllerApi.getStats()).data
        .unread;
      return {
        [NotificationDtoTypeEnum.ERROR]:
          unreadCount?.[NotificationDtoTypeEnum.ERROR] || 0,
        [NotificationDtoTypeEnum.REMINDER]:
          unreadCount?.[NotificationDtoTypeEnum.REMINDER] || 0,
        [NotificationDtoTypeEnum.WARNING]:
          unreadCount?.[NotificationDtoTypeEnum.WARNING] || 0,
      };
    } catch {
      return {
        [NotificationDtoTypeEnum.ERROR]: 0,
        [NotificationDtoTypeEnum.REMINDER]: 0,
        [NotificationDtoTypeEnum.WARNING]: 0,
      };
    }
  }, [notificationControllerApi]);

  const {
    resource: unreadNotificationsCount,
    fetch: fetchUnreadNotificationsCount,
  } = useAsyncResource({
    disableGlobalLoader: true,
    fetchResource: getUnreadNotificationsCount,
    defaultValue: {
      [NotificationDtoTypeEnum.ERROR]: 0,
      [NotificationDtoTypeEnum.REMINDER]: 0,
      [NotificationDtoTypeEnum.WARNING]: 0,
    },
  });

  const getLastNewNotification = useCallback(async () => {
    try {
      return (await notificationControllerApi.getLastNewNotification()).data;
    } catch {}
  }, [notificationControllerApi]);

  const {
    resource: lastNewNotificationFetched,
    fetch: fetchLastNewNotification,
  } = useAsyncResourceWithPulling({
    fetchResource: getLastNewNotification,
    pullingInterval: 5,
  });

  const markAsNotNew = useCallback(async () => {
    if (!lastNewNotificationFetched?.id) return;

    setLastNewNotification(undefined);

    try {
      await notificationControllerApi.markAsNotNew({
        lastNewNotificationDto: lastNewNotificationFetched,
      });
      fetchLastNewNotification();
    } catch {}
  }, [
    notificationControllerApi,
    lastNewNotificationFetched,
    fetchLastNewNotification,
  ]);

  const openNotificationsPopup = useCallback(
    (tab?: number) => {
      setIsNotificationsPopupOpened(true);
      loadNotifications(notificationsPopupTabs[tab || 0]);
      setInitialTab(tab || 0);
      markAsNotNew();
    },
    [loadNotifications, markAsNotNew],
  );

  const closeNotificationsPopup = useCallback(() => {
    if (ignoreClickOutside) return;
    setIsNotificationsPopupOpened(false);
    resetNotificationsData();
  }, [ignoreClickOutside, resetNotificationsData]);

  useEffect(() => {
    setLastNewNotification((prevValue) =>
      prevValue?.id === lastNewNotificationFetched?.id
        ? prevValue
        : lastNewNotificationFetched,
    );
  }, [lastNewNotificationFetched]);

  useEffect(() => {
    if (lastNewNotification?.id) {
      fetchUnreadNotificationsCount();
    }
  }, [fetchUnreadNotificationsCount, lastNewNotification]);

  const readNotification = useCallback(
    async (notification: NotificationDto) => {
      if (!notification?.id || !notification?.type) {
        return;
      }

      try {
        await notificationControllerApi.markAsRead({
          notificationIds: [notification.id],
        });
        if (unreadNotificationsCount[notification.type] > size) {
          loadNotifications(notification.type);
        } else {
          const updatedList = notifications.filter(
            ({ id }) => id !== notification.id,
          );
          setNotifications(updatedList);
        }
        await fetchUnreadNotificationsCount();
      } catch {}
    },
    [
      fetchUnreadNotificationsCount,
      loadNotifications,
      notificationControllerApi,
      notifications,
      unreadNotificationsCount,
    ],
  );

  const contextValue: INotificationsContext = useMemo(
    () => ({
      isNotificationsPopupOpened,
      initialTab,
      openNotificationsPopup,
      closeNotificationsPopup,
      notifications,
      loadNotifications,
      isLoading: isLoading,
      readNotification,
      unreadNotificationsCount,
      fetchUnreadNotificationsCount,
      lastNewNotification,
      markAsNotNew,
      setIgnoreClickOutside,
    }),
    [
      isNotificationsPopupOpened,
      initialTab,
      openNotificationsPopup,
      closeNotificationsPopup,
      notifications,
      loadNotifications,
      isLoading,
      readNotification,
      unreadNotificationsCount,
      fetchUnreadNotificationsCount,
      lastNewNotification,
      markAsNotNew,
    ],
  );

  return (
    <NotificationsContext.Provider value={contextValue}>
      {children}
    </NotificationsContext.Provider>
  );
};

export default NotificationsProvider;
