import { styled } from '@mui/material';

import CommonButton from 'components/common/CommonButton';

export const ActionsMenuButton = styled(CommonButton, {
  shouldForwardProp: (prop) => prop !== 'isMobile',
})<{ isMobile?: boolean }>(({ theme, isMobile = true }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '50px',
  width: '50px',
  minWidth: 'unset',
  padding: 0,
  borderRadius: '50%',

  '& svg': {
    pointerEvents: 'none',
    transform: isMobile ? 'none' : 'rotate(90deg)',
  },
  ...(isMobile
    ? {
        [theme.breakpoints.up('desktop')]: {
          display: 'none',
        },
      }
    : {
        [theme.breakpoints.down('desktop')]: {
          display: 'none',
        },
      }),
}));
