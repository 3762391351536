import { styled } from '@mui/material';

import { Download } from 'components/icons';

const DownloadIcon = styled(Download)(() => ({
  height: '18px',
  width: '18px',
}));

export default DownloadIcon;
