import { Typography, TypographyOwnProps } from '@mui/material';
import { FC } from 'react';

interface Props extends TypographyOwnProps {
  degrees?: number;
  withSign?: boolean;
}

export const DegreesCelcius: FC<Props> = ({ degrees, withSign, ...props }) => {
  const sign = withSign && degrees && degrees > 0 ? '+' : '';

  return <Typography {...props}>{`${sign}${degrees}\u00b0C`}</Typography>;
};
