import MuiBackdrop, { BackdropProps } from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
import React, { FC } from 'react';

const Backdrop = styled(MuiBackdrop)(({ theme }) => ({
  zIndex: theme.zIndex.globalLoader,
  backdropFilter: 'blur(7px)',
}));

const GlobalLoader: FC<BackdropProps> = (props) => {
  return (
    <Backdrop {...props}>
      <CircularProgress />
    </Backdrop>
  );
};

export default GlobalLoader;
