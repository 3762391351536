import { styled, Grid } from '@mui/material';

export const MechanicalParameterNameWrapper = styled(Grid)(({ theme }) => ({
  flexDirection: 'column',
  alignSelf: 'center',
  flexGrow: 1,
  flexWrap: 'nowrap',
  textAlign: 'left',
  gap: theme.spacing(1.5),
  [theme.breakpoints.down('desktop')]: {
    textAlign: 'center',
    gap: theme.spacing(1),
  },
}));
