import { GridPaginationModel, GridSortModel } from '@mui/x-data-grid';
import { createContext } from 'react';

import {
  CustomerDto,
  PageRestorationRequestDto,
} from 'openapi-api/admin-service';

export interface IInvestigationContext {
  customers: CustomerDto[];
  investigationsHistory: PageRestorationRequestDto;
  isHistoryLoading: boolean;
  refreshHistory: () => void;
  sortModel: GridSortModel;
  onSortModelChange: (value: GridSortModel) => void;
  paginationModel: GridPaginationModel;
  onPaginationModelChange: ({ page, pageSize }: GridPaginationModel) => void;
  resetInvestigationDashboard: (restorationId: string) => Promise<void>;
}

export const InvestigationContext = createContext<IInvestigationContext>(null!);
