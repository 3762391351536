import { styled } from '@mui/material/styles';

const LogoContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',

  [theme.breakpoints.down('desktop')]: {
    height: '136px',
    width: '136px',
    justifySelf: 'center',
    alignSelf: 'center',
    marginLeft: '-8px',
  },
  [theme.breakpoints.down('tablet')]: {
    margin: '-36px 0 0 0',
  },
}));

export default LogoContainer;
