import { styled } from '@mui/material/styles';
import { Form } from 'react-router-dom';

const FormWrapper = styled(Form)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
}));

export default FormWrapper;
