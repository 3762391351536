import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import { BottomPartContent, CreateReportForm } from './components';
import { FormPartContainer, ReportPageWrapper } from './styles';
import { ReportProvider } from 'contexts/report';

export const Reports = () => {
  const { t } = useTranslation();

  return (
    <ReportProvider>
      <ReportPageWrapper>
        <FormPartContainer>
          <Typography
            variant="h1"
            color="white.main"
            marginBottom={{ mobile: 4, desktop: 5 }}
            sx={{ mb: 5 }}
          >
            {t('pages.reports.title')}
          </Typography>
          <CreateReportForm />
        </FormPartContainer>
        <BottomPartContent />
      </ReportPageWrapper>
    </ReportProvider>
  );
};
