import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import CommonButton from 'components/common/CommonButton';
import { NotificationPopupVariants } from 'components/common/NotificationPopup/types';
import useInvestigationContext from 'contexts/investigation';
import useResponsePopup from 'contexts/responsePopup';

interface IResetDashboardButtonProps {
  restorationId: string;
}

export const ResetDashboardButton: FC<IResetDashboardButtonProps> = ({
  restorationId,
}) => {
  const { t } = useTranslation();
  const { refreshHistory, resetInvestigationDashboard } =
    useInvestigationContext();
  const { openPopup, closePopup } = useResponsePopup();

  const resetDashboard = useCallback(async () => {
    try {
      await resetInvestigationDashboard(restorationId);

      openPopup({
        variant: NotificationPopupVariants.Success,
        title: t('pages.investigation.historyTable.resetPopup.resetSuccess'),
        primaryButton: {
          text: t('continueSession'),
          onClick: closePopup,
        },
      });

      refreshHistory();
    } catch {}
  }, [
    resetInvestigationDashboard,
    restorationId,
    openPopup,
    t,
    closePopup,
    refreshHistory,
  ]);

  const openResetPopup = useCallback(() => {
    openPopup({
      variant: NotificationPopupVariants.Error,
      title: t('pages.investigation.historyTable.resetPopup.title'),
      subtitle: t('pages.investigation.historyTable.resetPopup.subtitle'),
      primaryButton: {
        text: t('pages.investigation.historyTable.resetPopup.yesReset'),
        onClick: resetDashboard,
      },
      secondaryButton: {
        text: t('pages.investigation.historyTable.resetPopup.cancel'),
        onClick: closePopup,
      },
    });
  }, [closePopup, openPopup, resetDashboard, t]);

  return (
    <CommonButton size="small" variant="outlined" onClick={openResetPopup}>
      {t('pages.investigation.historyTable.resetData')}
    </CommonButton>
  );
};
