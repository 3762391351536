import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { FC, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { defaultValues } from './constants';
import { UpdatePasswordFormValues } from './types';
import { ValidationLine } from './ValidationLine';
import {
  validationSchema,
  atLeastOneLowercase,
  atLeastOneNumber,
  atLeastOneSpecial,
  atLeastOneUppercase,
  lengthValidation,
} from './validationSchema';
import CommonButton from 'components/common/CommonButton';
import { PasswordField } from 'components/common/form/PasswordField';
import { NotificationPopupVariants } from 'components/common/NotificationPopup/types';
import { Popup } from 'components/common/Popup';
import FormWrapper from 'components/pages/Login/styles/FormWrapper';
import useApi from 'contexts/api';
import useResponsePopup from 'contexts/responsePopup';

interface Props {
  onClose: () => void;
}

export const UpdatePasswordPopup: FC<Props> = ({ onClose }: Props) => {
  const { t } = useTranslation();
  const { adminUserControllerApi } = useApi();
  const { openPopup, closePopup } = useResponsePopup();

  const form = useForm<UpdatePasswordFormValues>({
    resolver: yupResolver(validationSchema(t)),
    defaultValues,
    mode: 'onTouched',
  });

  const {
    handleSubmit: handleSubmitForm,
    formState: { isValid, isDirty, isSubmitSuccessful },
    watch,
    trigger,
  } = form;

  const newPassword = watch('newPassword');
  const newPasswordRepeat = watch('newPasswordRepeat');

  useEffect(() => {
    if (!!newPasswordRepeat) {
      trigger('newPasswordRepeat');
    }
  }, [newPassword, newPasswordRepeat, trigger]);

  const handleSubmit = handleSubmitForm(
    async (data: UpdatePasswordFormValues) => {
      try {
        await adminUserControllerApi.updatePassword2({
          userUpdatePasswordRequestDto: data,
        });

        openPopup({
          variant: NotificationPopupVariants.Success,
          title: t('components.updatePasswordPopup.successTitle'),
          primaryButton: {
            text: t('buttons.continueSession'),
            onClick: closePopup,
          },
        });
      } catch (e) {
        if (axios.isAxiosError(e) && e.response?.data.message) {
          toast.error(e.response.data.message);
        }
      }
    },
  );

  useEffect(() => {
    if (!isSubmitSuccessful) return;

    onClose();
  }, [isSubmitSuccessful, onClose]);

  return (
    <Popup isOpen onClose={onClose}>
      <FormProvider {...form}>
        <FormWrapper onSubmit={handleSubmit}>
          <Popup.InnerWrapper>
            <Popup.Content sx={{ textAlign: 'left' }}>
              <Typography sx={{ mb: { mobile: 5, desktop: 4 } }} variant="h2">
                {t('components.updatePasswordPopup.title')}
              </Typography>

              <Typography sx={{ display: 'block', mb: 3 }} variant="bodyM">
                {t('components.updatePasswordPopup.subtitle')}
              </Typography>

              <PasswordField
                sx={{ mb: 4 }}
                name="currentPassword"
                label={t('components.updatePasswordPopup.currentPasswordLabel')}
              />

              <PasswordField
                sx={{ mb: 2 }}
                name="newPassword"
                label={t('components.updatePasswordPopup.newPasswordLabel')}
              />

              <Grid
                display="grid"
                gridTemplateColumns="1fr 1fr"
                rowGap={1}
                columnGap={1.5}
                marginBottom={4}
                width="100%"
              >
                <ValidationLine
                  value={newPassword}
                  title={t(
                    'components.updatePasswordPopup.validations.passwordLength',
                  )}
                  regExp={lengthValidation}
                />
                <ValidationLine
                  value={newPassword}
                  title={t(
                    'components.updatePasswordPopup.validations.oneNumber',
                  )}
                  regExp={atLeastOneNumber}
                />
                <ValidationLine
                  value={newPassword}
                  title={t(
                    'components.updatePasswordPopup.validations.oneLowercase',
                  )}
                  regExp={atLeastOneLowercase}
                />
                <ValidationLine
                  value={newPassword}
                  title={t(
                    'components.updatePasswordPopup.validations.oneUppercase',
                  )}
                  regExp={atLeastOneUppercase}
                />
                <ValidationLine
                  value={newPassword}
                  title={t(
                    'components.updatePasswordPopup.validations.oneSpecial',
                  )}
                  regExp={atLeastOneSpecial}
                />
              </Grid>

              <PasswordField
                name="newPasswordRepeat"
                label={t(
                  'components.updatePasswordPopup.newPasswordRepeatLabel',
                )}
              />
            </Popup.Content>
            <Popup.Buttons sx={{ mt: { mobile: 5, desktop: 4 } }}>
              <CommonButton
                variant="contained"
                data-testid="submit"
                type="submit"
                disabled={!isDirty || !isValid}
                sx={{ width: { mobile: '226px', tablet: 'auto' } }}
              >
                {t('buttons.save')}
              </CommonButton>
              <CommonButton
                variant="outlined"
                onClick={onClose}
                data-testid="close-button"
              >
                {t('buttons.cancel')}
              </CommonButton>
            </Popup.Buttons>
          </Popup.InnerWrapper>
        </FormWrapper>
      </FormProvider>
    </Popup>
  );
};
