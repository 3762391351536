import { styled } from '@mui/material';

export const ParkItemsCounter = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0.5, 0),

  '& .MuiTypography-root': {
    color: theme.palette.green[700],
  },

  '& svg > path': {
    stroke: theme.palette.green[700],
  },
}));
