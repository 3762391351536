import { styled } from '@mui/material';
import { Form } from 'react-router-dom';

const FormStyled = styled(Form)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
}));

export default FormStyled;
