import { FC } from 'react';

import { ParkComponent } from './ParkComponent';
import { ParkProvider } from 'contexts/park';

export const ParkContainer: FC = () => {
  return (
    <ParkProvider>
      <ParkComponent />
    </ParkProvider>
  );
};
