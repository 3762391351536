import { FC } from 'react';

import { CommonSvgProps } from 'types/general';

export const SuccessIcon: FC<CommonSvgProps> = (props) => (
  <svg
    width="72"
    height="72"
    viewBox="0 0 72 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="36" cy="36" r="36" fill="#404753" />
    <circle opacity="0.6" cx="36" cy="36" r="28" fill="#A4E256" />
    <g clipPath="url(#clip0_2011_1285)">
      <circle cx="36" cy="36" r="20" fill="#A4E256" />
      <path
        d="M25.166 35.1668L33.4993 43.5002L45.9993 29.3335"
        stroke="white"
        strokeWidth="4"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2011_1285">
        <rect
          width="40"
          height="40"
          fill="white"
          transform="translate(16 16)"
        />
      </clipPath>
    </defs>
  </svg>
);
