import { Typography } from '@mui/material';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { timePeriodOptions } from './constants';
import { getSubheaderText } from './helpers';
import {
  IframeCounter,
  IframeCounterWrapper,
  IframeWrapper,
  WindTurbineChartHead,
  WindTurbineChartSubheader,
  WindTurbineChartWrapper,
} from './styles';
import { WIDGETS } from '../../constants';
import { GrafanaIframe } from '../GrafanaIframe';
import { ChipFilter } from 'components/common/ChipFilter';
import { PeriodFilterValues } from 'types/general';

interface Props {
  title: string;
  panelId: WIDGETS;
  subheaderPanelId?: WIDGETS;
}

export const WindTurbineChart: FC<Props> = ({
  title,
  panelId,
  subheaderPanelId,
}) => {
  const { t } = useTranslation();
  const [timePeriod, setTimePeriod] = useState(timePeriodOptions[3]);

  const subheader = useMemo(() => {
    if (subheaderPanelId && timePeriod.value !== PeriodFilterValues.current) {
      return (
        <WindTurbineChartSubheader>
          <Typography variant="bodyS">
            {getSubheaderText(subheaderPanelId, timePeriod.tKey, t)}
          </Typography>
          <IframeCounterWrapper>
            <IframeCounter
              panelId={subheaderPanelId}
              from={timePeriod.value as PeriodFilterValues}
              shouldRefresh={false}
            />
          </IframeCounterWrapper>
        </WindTurbineChartSubheader>
      );
    }
  }, [subheaderPanelId, timePeriod, t]);

  return (
    <WindTurbineChartWrapper>
      <WindTurbineChartHead>
        <Typography variant="h4">{title}</Typography>
        <ChipFilter
          value={timePeriod}
          options={timePeriodOptions}
          onChange={setTimePeriod}
        />
      </WindTurbineChartHead>
      {subheader}
      <IframeWrapper>
        <GrafanaIframe
          panelId={panelId}
          from={timePeriod.value as PeriodFilterValues}
          shouldRefresh={timePeriod.value === PeriodFilterValues.current}
        />
      </IframeWrapper>
    </WindTurbineChartWrapper>
  );
};
