import {
  UserDetailsDtoRolePermissionEnum,
  UserDetailsDtoStatusEnum,
} from 'openapi-api/admin-service';
import { FilterValues, TranslationFunction } from 'types/general';

export const adminUsersFilterValues = (
  t: TranslationFunction,
): FilterValues => ({
  status: {
    label: t('pages.users.adminUsers.filters.statusFilterTitle'),
    values: [
      {
        label: t('pages.users.adminUsers.statuses.active'),
        value: UserDetailsDtoStatusEnum.ACTIVE,
      },
      {
        label: t('pages.users.adminUsers.statuses.inactive'),
        value: UserDetailsDtoStatusEnum.INACTIVE,
      },
    ],
  },
  rolePermission: {
    label: t('pages.users.adminUsers.filters.roleFilterTitle'),
    values: [
      {
        label: t(`userRoles.${UserDetailsDtoRolePermissionEnum.SUPER_ADMIN}`),
        value: UserDetailsDtoRolePermissionEnum.SUPER_ADMIN,
      },
      {
        label: t(`userRoles.${UserDetailsDtoRolePermissionEnum.ADMIN}`),
        value: UserDetailsDtoRolePermissionEnum.ADMIN,
      },
      {
        label: t(`userRoles.${UserDetailsDtoRolePermissionEnum.VIEWER}`),
        value: UserDetailsDtoRolePermissionEnum.VIEWER,
      },
    ],
  },
});

export const fieldsToSend = [
  'search',
  'status',
  'rolePermission',
  'sort',
  'size',
  'page',
];

export const clickDisabledFields = ['actions'];
