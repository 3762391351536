import { Box, Chip, ChipProps, useTheme } from '@mui/material';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface IEmailVerificationStatusChipProps {
  verified?: boolean;
}

export const EmailVerificationStatusChip: FC<
  IEmailVerificationStatusChipProps
> = ({ verified }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const props: ChipProps & { iconColor?: string } = useMemo(
    () =>
      verified
        ? {
            color: 'success',
            label: t('pages.updateProfile.verified'),
            iconColor: theme.palette.green[600],
          }
        : {
            color: 'warning',
            label: t('pages.updateProfile.notVerified'),
            iconColor: theme.palette.warning.main,
          },
    [t, theme, verified],
  );

  const { iconColor, ...rest } = props;

  return (
    <Chip
      sx={{
        height: '20px',
        width: 'fit-content',
        '& .MuiChip-label': {
          textTransform: 'none',
          ...theme.typography.bodyS,
        },
      }}
      icon={
        <Box
          width="12px"
          height="12px"
          sx={() => ({
            borderRadius: '50%',
            backgroundColor: iconColor,
          })}
        />
      }
      size="small"
      {...rest}
    />
  );
};
