import { alpha, styled } from '@mui/material';

export const WeatherPreviewWrapper = styled('div', {
  shouldForwardProp: (propName) => propName !== 'noData',
})<{ noData: boolean }>(({ theme, noData }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  height: '82px',
  padding: theme.spacing(2, 3),
  borderTop: `1px solid ${theme.palette.grey[100]}`,
  backgroundColor: noData
    ? alpha(theme.palette.grey[900], 0.05)
    : 'transparent',

  [theme.breakpoints.down('desktop')]: {
    height: '40px',
    padding: theme.spacing(1, 3),
  },

  [theme.breakpoints.down('tablet')]: {
    padding: theme.spacing(1, 2),
  },
}));
