import * as yup from 'yup';

import { TranslationFunction } from 'types/general';

const validationSchema = (t: TranslationFunction) =>
  yup.object({
    name: yup
      .string()
      .required(t('form.errors.fieldRequired'))
      .matches(/^[a-zA-Z0-9 ]{1,48}$/, t('form.errors.incorrectParkName'))
      .strict()
      .trim(t('form.errors.spacesAreNotAllowed')),
    coordinates: yup
      .string()
      .required(t('form.errors.fieldRequired'))
      .matches(
        /^(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)$/,
        t('form.errors.incorrectFormat'),
      ),
  });
export default validationSchema;
