import { styled } from '@mui/material';

export const SlideWrapper = styled('div')(({ theme }) => ({
  flex: `0 0 20%`,
  minWidth: 0,
  paddingLeft: theme.spacing(0.5),

  [theme.breakpoints.down('desktop')]: {
    flexBasis: `calc(86px + ${theme.spacing(0.5)})`,
  },
}));
