import { FC } from 'react';

import { CommonSvgProps } from 'types/general';

export const Uvi: FC<CommonSvgProps> = ({ className }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M2.33936 6.72766V14.3406C2.33936 15.9628 3.65441 17.2779 5.27661 17.2779V17.2779C6.89881 17.2779 8.21387 15.9628 8.21387 14.3406V6.72766"
      stroke="#A7ABB2"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M11.6118 6.72766L14.9845 17.3553L16.6529 12.0415L18.3213 6.72766"
      stroke="#A7ABB2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.7847 6.72766L21.7847 17.2779"
      stroke="#A7ABB2"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);
