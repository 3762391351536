import { styled } from '@mui/material/styles';

import publicPath from 'utils/publicPath';

const MainLayoutWrapper = styled('div')<{ showBgImage: boolean }>(
  ({ theme, showBgImage }) => ({
    width: '100%',
    display: 'grid',
    height: 'auto',
    minHeight: '100dvh',
    gridTemplateRows: '80px 1fr',
    backgroundColor: theme.palette.white.main,
    position: 'relative',

    [theme.breakpoints.down('desktop')]: {
      gridTemplateRows: '56px 1fr',
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      backgroundImage: showBgImage
        ? `url(${publicPath('/images/main-background.png')})`
        : 'none',
      backgroundSize: 'cover',
      width: '100%',
      height: '358px',
      [theme.breakpoints.down('desktop')]: {
        height: '428px',
      },
    },
  }),
);

export default MainLayoutWrapper;
