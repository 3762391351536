import { Box, Typography, styled } from '@mui/material';
import { FC } from 'react';

import { WindDirectionIcon } from 'components/icons/weather';

interface Props {
  direction?: string;
  degree?: number;
}

const Icon = styled(WindDirectionIcon)``;

export const WindDirection: FC<Props> = ({ direction, degree }) => {
  return (
    <Box display="flex" alignItems="center" gap={0.25}>
      <Typography variant="bodyS">{direction}</Typography>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Icon sx={{ transform: `rotate(${degree}deg)` }} />
      </Box>
    </Box>
  );
};
