import { FC } from 'react';

import { CommonSvgProps } from 'types/general';

export const CopySuccess: FC<CommonSvgProps> = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2C17.5188 2 22.0001 6.48126 22.0001 12C22.0001 17.5188 17.5188 22.0001 12 22.0001C6.48126 22.0001 2 17.5188 2 12C2 6.48126 6.48126 2 12 2ZM15.606 8.77742C15.7835 8.79117 15.9522 8.83367 16.111 8.91617C16.2147 8.97117 16.311 9.03992 16.396 9.12117C16.726 9.43618 16.8585 9.91868 16.7372 10.3587C16.6847 10.5487 16.586 10.7174 16.4572 10.8662C14.9735 12.4212 13.4535 13.9399 11.8997 15.4237C11.7972 15.5124 11.6872 15.5887 11.5622 15.6437C11.2435 15.7849 10.8722 15.7849 10.5535 15.6437C10.4285 15.5887 10.3185 15.5124 10.216 15.4237C9.33596 14.5824 8.47346 13.7224 7.63345 12.8412C7.5047 12.6924 7.40595 12.5237 7.35345 12.3324C7.3222 12.2199 7.3072 12.1024 7.30845 11.9849C7.31345 11.5087 7.5997 11.0662 8.0322 10.8662C8.17471 10.7999 8.32846 10.7612 8.48471 10.7524C8.64096 10.7424 8.79846 10.7637 8.94721 10.8124C9.13596 10.8737 9.29846 10.9799 9.44221 11.1162L11.0585 12.7324C12.2685 11.5212 13.4522 10.2824 14.691 9.10117C14.736 9.06242 14.736 9.06242 14.781 9.02617C14.846 8.97992 14.911 8.93867 14.981 8.90367C15.1235 8.83367 15.2735 8.79617 15.4297 8.77992C15.4897 8.77617 15.5472 8.77492 15.606 8.77742Z"
      fill="#A4E256"
    />
  </svg>
);
