import { styled } from '@mui/material';

const ListingWrapper = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gap: theme.spacing(3),
  marginTop: theme.spacing(2.5),

  [theme.breakpoints.down('tablet')]: {
    gridTemplateColumns: '1fr',
    gap: theme.spacing(2),
  },
}));

export default ListingWrapper;
