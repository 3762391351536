import { styled } from '@mui/material/styles';

export const PageWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(8.5, 0, 6.5, 0),
  display: 'grid',
  gridTemplateRows: 'auto auto 1fr',
  height: '100%',
  [theme.breakpoints.down('desktop')]: {
    padding: theme.spacing(3, 0, 5, 0),
  },
}));

export default PageWrapper;
