import dayjs, { Dayjs } from 'dayjs';

import { ReportTimeframes } from './types';

export const getReportTimeframePeriod = (
  value?: string,
): { from: Dayjs; to: Dayjs } | undefined => {
  if (!value) return;

  const currentDatetime = dayjs();

  if (value === ReportTimeframes.today) {
    return {
      from: currentDatetime.startOf('day'),
      to: currentDatetime,
    };
  }

  if (value === ReportTimeframes.yesterday) {
    return {
      from: currentDatetime.subtract(1, 'day').startOf('day'),
      to: currentDatetime.subtract(1, 'day').endOf('day'),
    };
  }

  if (value === ReportTimeframes.lastWeek) {
    return {
      from: currentDatetime.subtract(1, 'week').startOf('week'),
      to: currentDatetime.subtract(1, 'week').endOf('week'),
    };
  }

  if (value === ReportTimeframes.lastMonth) {
    return {
      from: currentDatetime.subtract(1, 'month').startOf('month'),
      to: currentDatetime.subtract(1, 'month').endOf('month'),
    };
  }
};
