import { styled } from '@mui/material';

export const TimePickerActionBar = styled('div')(({ theme }) => ({
  gridColumn: '1 / 4',
  gridRow: '3',

  padding: theme.spacing(1),
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: theme.spacing(1),
}));
