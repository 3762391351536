import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useMemo,
  useState,
} from 'react';

import Context from './Context';
import GlobalLoader from 'components/common/GlobalLoader';

const GlobalLoaderProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);

  const showLoader = useCallback(() => {
    setIsLoading(true);
  }, []);

  const hideLoader = useCallback(() => {
    setIsLoading(false);
  }, []);

  const contextValue = useMemo(
    () => ({
      showLoader,
      hideLoader,
    }),
    [hideLoader, showLoader],
  );

  return (
    <Context.Provider value={contextValue}>
      <GlobalLoader open={isLoading} />
      {children}
    </Context.Provider>
  );
};

export default GlobalLoaderProvider;
