import { styled } from '@mui/material';

import CommonDialog from '../../CommonDialog';

export const ConnectUserDialogWrapper = styled(CommonDialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    alignItems: 'flex-start',
    [theme.breakpoints.up('desktop')]: {
      width: '540px',
    },
  },
}));
