import * as yup from 'yup';

import { TranslationFunction } from '../../../types/general';

const validationSchemaStep1 = (t: TranslationFunction) =>
  yup.object().shape(
    {
      existingCustomer: yup
        .string()
        .when('newCustomer', (newCustomer, schema) => {
          if (!newCustomer?.[0])
            return schema.required(t`form.errors.fieldRequired`);
          return schema;
        }),
      newCustomer: yup
        .string()
        .when('existingCustomer', (existingCustomer, schema) => {
          if (!existingCustomer?.[0])
            return schema
              .required(t`form.errors.fieldRequired`)
              .matches(
                /^[a-zA-Z0-9!@#$%^&*]{10,32}$/,
                t`form.errors.incorrectUsername`,
              );
          return schema;
        }),
      existingPark: yup.string().optional(),
      newPark: yup.object().optional(),
    },
    [['newCustomer', 'existingCustomer']],
  );

const validationSchemaStep2 = (
  t: TranslationFunction,
  isParkNameExist: (parkName: string) => Promise<boolean>,
) =>
  yup.object().shape(
    {
      existingCustomer: yup.string(),
      newCustomer: yup.string(),
      existingPark: yup.string().when('newPark', (newPark, schema) => {
        if (!newPark?.[0]?.name && !newPark?.[0]?.coordinates)
          return schema.required(t`form.errors.fieldRequired`);
        return schema;
      }),
      newPark: yup.object().when('existingPark', (existingPark, schema) => {
        if (!existingPark?.[0])
          return schema
            .shape({
              name: yup
                .string()
                .required(t`form.errors.fieldRequired`)
                .matches(
                  /^[a-zA-Z0-9 ]{1,48}$/,
                  t`form.errors.incorrectParkName`,
                )
                .strict()
                .trim(t('form.errors.spacesAreNotAllowed'))
                .test({
                  message: t('form.errors.uniqueParkName'),
                  test: async (parkName = '') => {
                    try {
                      const parkNameExists = await isParkNameExist(parkName);
                      return !parkNameExists;
                    } catch (e) {
                      return false;
                    }
                  },
                }),
              coordinates: yup
                .string()
                .required(t`form.errors.fieldRequired`)
                .matches(
                  /^(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)$/,
                  t`form.errors.incorrectFormat`,
                ),
            })
            .required(t`form.errors.fieldRequired`);
        return schema;
      }),
    },
    [['existingPark', 'newPark']],
  );

export const validationSchemas = [validationSchemaStep1, validationSchemaStep2];
