import { WIDGETS } from '../../constants';
import { TranslationFunction } from 'types/general';

export const getSubheaderText = (
  panelId: WIDGETS,
  periodTKey: string,
  t: TranslationFunction,
): string => {
  const periodText = t(periodTKey).toLowerCase();
  if (panelId === WIDGETS.TOTAL_LOST_ENERGY_COUNTER) {
    return `${t('pages.turbine.totalLostEnergyCounterText')} ${periodText}`;
  }

  if (panelId === WIDGETS.ACTIVE_POWER_COUNTER) {
    return `${t('pages.turbine.activePowerCounterText')} ${periodText}`;
  }

  return '';
};
