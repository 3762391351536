import { Box, Typography } from '@mui/material';
import { FC, useMemo } from 'react';

import { ButtonsWrapper, CloseButton, Dialog } from './styles';
import { NotificationPopupProps, NotificationPopupVariants } from './types';
import CommonButton from '../CommonButton';
import { ICommonButtonProps } from '../CommonButton/CommonButton';
import {
  Close,
  SuccessIcon,
  TriangleError,
  TriangleWarning,
} from 'components/icons';

export const NotificationPopup: FC<NotificationPopupProps> = ({
  variant = NotificationPopupVariants.Warning,
  isOpen,
  title,
  subtitle,
  onClose,
  primaryButton,
  secondaryButton,
  allowPopupClose = true,
  children,
}) => {
  const icon = useMemo(() => {
    if (variant === NotificationPopupVariants.Success) {
      return <SuccessIcon />;
    }

    if (variant === NotificationPopupVariants.Warning) {
      return <TriangleWarning />;
    }

    if (variant === NotificationPopupVariants.Error) {
      return <TriangleError />;
    }
  }, [variant]);

  const primaryButtonColor = useMemo((): ICommonButtonProps['color'] => {
    if (variant === NotificationPopupVariants.Warning) {
      return 'black';
    }

    return 'primary';
  }, [variant]);

  return (
    <Dialog
      open={!!isOpen}
      onClose={allowPopupClose ? onClose : undefined}
      variant={variant}
    >
      {icon}
      <Typography variant="h1" sx={{ mt: 4 }}>
        {title}
      </Typography>
      {subtitle && (
        <Typography variant="bodyM" sx={{ mt: 2.5 }} color="grey.800">
          {subtitle}
        </Typography>
      )}
      {children && <Box sx={{ mt: 4 }}>{children}</Box>}
      <ButtonsWrapper>
        {primaryButton && (
          <CommonButton
            onClick={primaryButton.onClick}
            color={primaryButtonColor}
            sx={{ width: { mobile: '226px', tablet: 'auto' } }}
          >
            {primaryButton.text}
          </CommonButton>
        )}
        {secondaryButton && (
          <CommonButton
            variant="outlined"
            onClick={secondaryButton.onClick}
            sx={{ width: 'fit-content' }}
          >
            {secondaryButton.text}
          </CommonButton>
        )}
      </ButtonsWrapper>
      {allowPopupClose && (
        <CloseButton onClick={onClose}>
          <Close />
        </CloseButton>
      )}
    </Dialog>
  );
};
