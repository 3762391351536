import { styled } from '@mui/material/styles';

export const NotificationCountersWrapper = styled('div')<{
  isTooltipOpen: boolean;
}>(({ theme, isTooltipOpen }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  paddingRight: theme.spacing(2.5),
  border: `1px solid ${
    isTooltipOpen ? theme.palette.white.main : theme.palette.grey[900]
  }`,
  borderRadius: '18px',
  cursor: 'pointer',
}));
