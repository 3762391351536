import { Box, Grid, Radio, RadioGroup } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Dayjs } from 'dayjs';
import { FC, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import DateRangePicker from '../../../../../DatePicker/DateRangePicker';
import { ValueProps } from '../../../../../DatePicker/DateRangePicker/DateRangePicker';
import RadioRow from '../styles/RadioRow';
import { FilterProps } from '../types';
import OrHorizontal from 'components/common/OrHorizontal';
import { getTimeframeSelectItems } from 'components/pages/protected/Reports/components/CreateReportForm/constants';

type FromToType = Record<Partial<ValueProps>, string | null>;
const defaultFromTo = { fromDate: null, toDate: null };

const getDateFromValue = (value: string | null) => {
  if (!value?.includes(',')) {
    return { from: null, to: null };
  }
  const [from, to] = value.split(',');
  return {
    from,
    to,
  };
};

export const DateRangeFilter: FC<FilterProps> = ({
  field,
  localValues,
  setLocalValues,
}) => {
  const { t } = useTranslation();
  const tempFromTo = useRef<FromToType>(defaultFromTo);
  const handleToggle = useCallback(
    (value: string) => {
      tempFromTo.current = defaultFromTo;
      setLocalValues((old) => ({ ...old, [field]: { value } }));
    },
    [setLocalValues, field],
  );
  const handleChangeFromTo = useCallback(
    (key: ValueProps, value: Dayjs | null) => {
      if (!value) {
        tempFromTo.current[key] = null;
        return;
      }
      tempFromTo.current[key] = (
        key === 'fromDate' ? value.startOf('day') : value.endOf('day')
      ).toISOString();
      if (tempFromTo.current.toDate && tempFromTo.current.fromDate) {
        setLocalValues((old) => ({
          ...old,
          [field]: {
            value: `${tempFromTo.current.fromDate},${tempFromTo.current.toDate}`,
          },
        }));
      }
    },
    [field, setLocalValues],
  );

  return (
    <Grid>
      <RadioGroup name={field}>
        {getTimeframeSelectItems(t).map(({ label, value }) => (
          <RadioRow
            key={value}
            value={value}
            control={<Radio />}
            label={<Typography variant="bodyM">{label}</Typography>}
            checked={localValues[field]?.value === value}
            onClick={() => handleToggle(value)}
          />
        ))}
      </RadioGroup>
      <Box sx={{ mx: 2.5 }}>
        <OrHorizontal sx={{ my: 1.5 }} />
      </Box>
      <DateRangePicker
        withoutDash
        sx={{ flexDirection: 'column', px: 2.5, mb: 2.5 }}
        onChange={handleChangeFromTo}
        fromDate={getDateFromValue(localValues[field]?.value).from}
        toDate={getDateFromValue(localValues[field]?.value).to}
      />
    </Grid>
  );
};
