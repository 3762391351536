import { styled } from '@mui/material';

export const TableAboveWrapper = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gap: theme.spacing(4),

  [theme.breakpoints.down('desktop')]: {
    gridTemplateColumns: 'auto min-content',
    gap: theme.spacing(2),
  },
}));
