import { Theme } from '@mui/material';

import { NotificationDtoTypeEnum } from 'openapi-api/admin-service';

export const notificationsPopupTabs = [
  NotificationDtoTypeEnum.ERROR,
  NotificationDtoTypeEnum.WARNING,
  NotificationDtoTypeEnum.REMINDER,
];

export const tabTitleTranslationMap = {
  [NotificationDtoTypeEnum.ERROR]: 'notifications.errors',
  [NotificationDtoTypeEnum.WARNING]: 'notifications.warnings',
  [NotificationDtoTypeEnum.REMINDER]: 'notifications.reminders',
};

export const getTabIndicatorColorMap = (theme: Theme) => ({
  [NotificationDtoTypeEnum.ERROR]: theme.palette.error.main,
  [NotificationDtoTypeEnum.WARNING]: theme.palette.warning.main,
  [NotificationDtoTypeEnum.REMINDER]: theme.palette.green[600],
});
