import { styled } from '@mui/material';

import CommonButton from 'components/common/CommonButton';

export const GoBackButton = styled(CommonButton)(({ theme }) => ({
  width: 'fit-content',
  color: theme.palette.grey[700],
  ...theme.typography.bodyS,

  '& path': {
    fill: theme.palette.grey[800],
  },
}));
