import {
  GridSortDirection,
  GridSortItem,
  GridSortModel,
} from '@mui/x-data-grid';

export const formatSortToArray = (sortModel: GridSortModel): string[] => {
  const result = sortModel?.map((item) => ({
    field: item.field,
    sort: item.sort?.toUpperCase() as GridSortDirection,
  }));
  return result?.map((item) => `${item.field},${item.sort}`);
};

export const formatSortToQuery = (sortModel: GridSortModel): string =>
  formatSortToArray(sortModel).join('&');

export const formatQueryToSort = (sorting: string): GridSortModel => {
  const sortParams = sorting.split('&');
  const objectArray: GridSortItem[] = [];
  // Loop through each sort parameter and extract field and sort values
  sortParams.forEach((param) => {
    const [field, sort] = param.split(',');
    objectArray.push({
      field: field.trim(),
      sort: sort.trim().toLowerCase() as GridSortDirection,
    } as GridSortItem);
  });
  return objectArray;
};

const getSearchParamWithoutAll = (
  searchParams: URLSearchParams,
  param: string,
) => {
  const convertedValue = searchParams.get(param);
  if (convertedValue === 'All') return;

  if (convertedValue === 'true') return true;
  if (convertedValue === 'false') return false;

  return convertedValue || undefined;
};

export const formatObjFromQuery = (
  listOfProps: string[],
  searchParams: URLSearchParams,
) => {
  return listOfProps.reduce(
    (
      editableObj: { [key: string]: string | undefined | boolean },
      currentValue: string,
    ) => ({
      ...editableObj,
      [currentValue]: getSearchParamWithoutAll(searchParams, currentValue),
    }),
    {},
  );
};
