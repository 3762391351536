import React, { FC, PropsWithChildren, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { UserContext } from './Context';
import useApi from 'contexts/api';
import useAuth from 'contexts/auth';
import { UserDetailsDtoRolePermissionEnum } from 'openapi-api/admin-service';
import { useAsyncResource } from 'utils/hooks/useAsyncResource';

const UserProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const { userDetailsControllerApi } = useApi();
  const { tokenApplied } = useAuth();
  const { t } = useTranslation();

  const loadUser = useCallback(async () => {
    if (tokenApplied) {
      return (await userDetailsControllerApi.getUserDetail()).data;
    }
  }, [userDetailsControllerApi, tokenApplied]);

  const { resource: user, fetch: refresh } = useAsyncResource({
    fetchResource: loadUser,
    defaultIsLoading: true,
    disableGlobalLoader: true,
  });

  const userRoleProps = useMemo(() => {
    switch (user?.rolePermission) {
      case UserDetailsDtoRolePermissionEnum.SUPER_ADMIN:
        return {
          isSuperAdmin: true,
          translatedRole: t(`userRoles.${user?.rolePermission}`),
        };
      case UserDetailsDtoRolePermissionEnum.ADMIN:
        return {
          isAdmin: true,
          translatedRole: t(`userRoles.${user?.rolePermission}`),
        };
      default:
        return {
          isViewer: true,
          translatedRole: t(`userRoles.${user?.rolePermission}`),
        };
    }
  }, [t, user?.rolePermission]);

  const contextValue = useMemo(
    () => ({ user: user || {}, refresh, ...userRoleProps }),
    [refresh, user, userRoleProps],
  );

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
};

export default UserProvider;
