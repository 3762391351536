import { FC } from 'react';

import { CommonSvgProps } from 'types/general';

export const TriangleError: FC<CommonSvgProps> = (props) => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="64" height="64" fill="white" />
    <path
      d="M29.1786 15.1298C30.4019 12.9055 33.598 12.9056 34.8214 15.1298L51.3755 45.2282C52.5558 47.3742 51.0032 50 48.5541 50H15.4459C12.9968 50 11.4442 47.3742 12.6245 45.2282L29.1786 15.1298Z"
      fill="#FF623F"
    />
    <path
      d="M30.0413 24.6502C30.0186 23.7456 30.7458 23 31.6508 23H32.3492C33.2542 23 33.9814 23.7456 33.9587 24.6502L33.6392 37.4302C33.6174 38.3035 32.9033 39 32.0297 39H31.9703C31.0968 39 30.3826 38.3035 30.3608 37.4302L30.0413 24.6502Z"
      fill="white"
    />
    <path
      d="M29.8279 44.4687C29.6825 43.2423 30.6405 42.1641 31.8754 42.1641H32.1228C33.3578 42.1641 34.3157 43.2423 34.1704 44.4687C34.0473 45.5062 33.1677 46.2878 32.1228 46.2878H31.8754C30.8306 46.2878 29.9509 45.5062 29.8279 44.4687Z"
      fill="white"
    />
  </svg>
);
