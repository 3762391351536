import { Tooltip, TooltipProps, styled } from '@mui/material';

const TooltipStyled = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip classes={{ tooltip: className }} {...props} />
))(({ theme }) => ({
  maxWidth: '290px',
  padding: theme.spacing(2.5),
  borderRadius: '20px',
  boxShadow: `0px 8px 12px 0px rgba(54, 52, 60, 0.20), 0px 0px 1px 0px rgba(0, 0, 0, 0.10)`,
  backgroundColor: theme.palette.white.main,
  cursor: 'pointer',

  '& .MuiTooltip-arrow': {
    color: theme.palette.white.main,
  },

  [theme.breakpoints.down('desktop')]: {
    maxWidth: '220px',
  },
}));

export default TooltipStyled;
