import { yupResolver } from '@hookform/resolvers/yup';
import { VisibilityOutlined, VisibilityOffOutlined } from '@mui/icons-material';
import { Alert, IconButton, InputAdornment } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useCallback, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { defaultValues } from './constants';
import FormContainer from './styles/FormContainer';
import FormWrapper from './styles/FormWrapper';
import LoginContainer from './styles/LoginContainer';
import LogoContainer from './styles/LogoContainer';
import { FormValues } from './types';
import validationSchema from './validationSchema';
import routePaths from '../../../constants/routePaths';
import useAuth from '../../../contexts/auth';
import CommonButton from '../../common/CommonButton';
import TextField from 'components/common/form/TextField';
import { Logo, Error } from 'components/icons';

const Login = () => {
  const { onLogin } = useAuth();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [error, setError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { t } = useTranslation();

  const form = useForm<FormValues>({
    resolver: yupResolver(validationSchema(t)),
    defaultValues,
  });

  const { control, handleSubmit: handleSubmitForm } = form;

  const values = useWatch({ control }) as FormValues;

  const handleClickShowPassword = useCallback(() => {
    setShowPassword((prev) => !prev);
  }, []);

  const handleMouseInput = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
    },
    [],
  );

  const handleSubmit = handleSubmitForm(async (data: FormValues) => {
    setError(false);
    try {
      await onLogin(data.username, data.password);
      if (state?.from) {
        navigate(state.from);
      } else {
        navigate(routePaths.parks.root);
      }
    } catch {
      setError(true);
    }
  });

  return (
    <LoginContainer>
      <LogoContainer>
        <Logo />
      </LogoContainer>
      <Grid container alignItems="center" justifyContent="center">
        <FormContainer>
          <Typography variant="h2" sx={{ mb: 5 }}>
            {t('pages.logIn.title')}
          </Typography>
          {error && (
            <Alert
              iconMapping={{
                error: <Error />,
              }}
              severity="error"
              sx={{ mb: 5 }}
            >
              {t('pages.logIn.incorrect')}
            </Alert>
          )}
          <FormProvider {...form}>
            <FormWrapper onSubmit={handleSubmit}>
              <TextField
                label={t('username')}
                fullWidth
                name="username"
                sx={{ mb: 4 }}
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                label={t('password')}
                fullWidth
                name="password"
                type={showPassword ? 'text' : 'password'}
                sx={{ mb: 6 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseInput}
                        onMouseUp={handleMouseInput}
                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityOutlined />
                        ) : (
                          <VisibilityOffOutlined />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{ shrink: true }}
              />
              <CommonButton
                type="submit"
                sx={{ width: '200px' }}
                disabled={!values.username || !values.password}
              >
                {t('buttons.submit')}
              </CommonButton>
            </FormWrapper>
          </FormProvider>
        </FormContainer>
      </Grid>
    </LoginContainer>
  );
};

export default Login;
