import { ReactNode } from 'react';

export enum NotificationPopupVariants {
  Success = 'Success',
  Warning = 'Warning',
  Error = 'Error',
}

export interface NotificationPopupProps {
  variant?: NotificationPopupVariants;
  isOpen?: boolean;
  title?: string;
  subtitle?: string;
  onClose?: () => void;
  primaryButton?: {
    text: string;
    onClick: () => void;
  };
  secondaryButton?: {
    text: string;
    onClick?: () => void;
  };
  allowPopupClose?: boolean;
  children?: ReactNode;
}
