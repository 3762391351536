import { Box, SxProps, Typography, useTheme } from '@mui/material';
import { FC, ReactNode } from 'react';

import { Wrapper } from './styles';
import { InformerBlockVariants } from './types';
import { Check, Error, Information } from 'components/icons';

interface Props {
  title?: string;
  variant?: InformerBlockVariants;
  children?: ReactNode;
  sx?: SxProps;
}

export const InformerBlock: FC<Props> = ({
  title,
  variant = InformerBlockVariants.Default,
  children,
  sx,
}) => {
  const theme = useTheme();

  const informerBlockIconsMapping = {
    [InformerBlockVariants.Default]: <Information />,
    [InformerBlockVariants.Success]: <Check />,
    [InformerBlockVariants.Warning]: (
      <Error color={theme.palette.warning.main} />
    ),
    [InformerBlockVariants.Error]: <Error />,
  };

  return (
    <Wrapper variant={variant} sx={sx}>
      <Box display="flex" gap={1.5}>
        <Box sx={{ height: '24px', width: '24px' }}>
          {informerBlockIconsMapping[variant]}
        </Box>
        {!!title && <Typography variant="bodyMStrong">{title}</Typography>}
      </Box>
      {children}
    </Wrapper>
  );
};
