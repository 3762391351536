import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { NotificationPopupVariants } from '../../../../../common/NotificationPopup/types';
import CommonButton from 'components/common/CommonButton';
import useApi from 'contexts/api';
import useReport from 'contexts/report';
import useResponsePopup from 'contexts/responsePopup';
import { ReportRequestDtoReportFormatEnum } from 'openapi-api/report-service';
import { useHandleResponseError } from 'utils/hooks/useHandleResponseError';

interface Props {
  reportFormat: ReportRequestDtoReportFormatEnum;
}

export const GenerateReportButton: FC<Props> = ({ reportFormat }) => {
  const { reportValues, assetPublicIds, reportMissingDataIntervals } =
    useReport();
  const [requested, setRequested] = useState(false);
  const { t } = useTranslation();
  const { reportRequestControllerApi } = useApi();
  const { openPopup, closePopup: closeResponsePopup } = useResponsePopup();

  const { handleResponse } = useHandleResponseError();

  const createReportRequest = useCallback(async () => {
    if (
      !reportValues ||
      reportValues.customer.id === undefined ||
      !reportValues.customer.name
    )
      return;

    try {
      await reportRequestControllerApi.createReportRequest({
        reportRequestDto: {
          reportFormat,
          customerId: reportValues.customer.id,
          customerName: reportValues.customer.name,
          assetPublicIds,
          startDatetime: reportValues.period.from,
          endDatetime: reportValues.period.to,
        },
      });

      openPopup({
        variant: NotificationPopupVariants.Success,
        title: t('pages.reports.generateReportSuccessTitle'),
        subtitle: t('pages.reports.generateReportSuccessSubtitle'),
        primaryButton: {
          text: t('continueSession'),
          onClick: closeResponsePopup,
        },
      });

      setRequested(true);
    } catch (e) {
      handleResponse(e);
    }
  }, [
    reportFormat,
    reportValues,
    assetPublicIds,
    reportRequestControllerApi,
    openPopup,
    t,
    closeResponsePopup,
    handleResponse,
  ]);

  const handleClick = useCallback(() => {
    if (reportMissingDataIntervals.length) {
      openPopup({
        variant: NotificationPopupVariants.Error,
        title: t(
          'pages.reports.content.missingDataNotice.generateReportWarningTitle',
        ),
        subtitle: t(
          'pages.reports.content.missingDataNotice.generateReportWarningSubtitle',
        ),
        primaryButton: {
          text: t(
            'pages.reports.content.missingDataNotice.generateReportWarningPrimaryButtonText',
          ),
          onClick: createReportRequest,
        },
        secondaryButton: {
          text: t('cancel'),
          onClick: closeResponsePopup,
        },
      });
    } else {
      createReportRequest();
    }
  }, [
    t,
    reportMissingDataIntervals,
    createReportRequest,
    openPopup,
    closeResponsePopup,
  ]);

  useEffect(() => {
    setRequested(false);
  }, [reportValues]);

  const buttonText = useMemo(() => {
    if (reportFormat === ReportRequestDtoReportFormatEnum.PDF) {
      return requested
        ? t('pages.reports.generatePdfRequested')
        : t('pages.reports.generatePdf');
    }

    if (reportFormat === ReportRequestDtoReportFormatEnum.CSV) {
      return requested
        ? t('pages.reports.generateCsvRequested')
        : t('pages.reports.generateCsv');
    }
  }, [reportFormat, requested, t]);

  return (
    <CommonButton disabled={requested} onClick={handleClick}>
      {buttonText}
    </CommonButton>
  );
};
