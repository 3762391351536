import { useState } from 'react';

export const usePopup = (): [boolean, () => void, () => void] => {
  const [isOpen, setPopupOpen] = useState<boolean>(false);

  const openPopup = () => setPopupOpen(true);
  const closePopup = () => setPopupOpen(false);

  return [isOpen, openPopup, closePopup];
};
