import { InvestigationDataCategory } from './constants';

export const pickPanelsByCategories = (
  mapping: {
    [key: string]: { panelId: number; tKey: string }[];
  },
  categories: string[],
): { panelId: number; tKey: string }[] => {
  return Object.keys(mapping)
    .filter((key) => categories.includes(key))
    .map((key) => mapping[key as InvestigationDataCategory])
    .flat();
};
