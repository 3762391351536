import { styled } from '@mui/material/styles';

export const MainContent = styled('div')(({ theme }) => ({
  boxShadow:
    '0px 5px 10px 0px rgba(30, 34, 40, 0.10), 0px 0px 1px 0px rgba(30, 34, 40, 0.08)',
  borderRadius: theme.spacing(0, 0, 2, 2),
  padding: theme.spacing(4, 4, 5),
  backgroundColor: theme.palette.white.main,

  [theme.breakpoints.down('desktop')]: {
    padding: theme.spacing(2.5, 2.5, 4),
  },
}));

export default MainContent;
