import { Box, useMediaQuery, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ChartCard } from './components';
import {
  SelectTimePeriods,
  TagsCategories,
  categoryOptions,
  panelsMapping,
  selectTimePeriodOptions,
} from './constants';
import {
  GridTable,
  GridTableWrapper,
  HeadWrapper,
  PageWrapper,
  StyledChipFilter,
} from './styles';
import { ChipFilterValue } from 'components/common/ChipFilter';
import CommonButton from 'components/common/CommonButton';
import { Select } from 'components/common/Select';
import useApi from 'contexts/api';
import { WindTurbinePanelsProvider } from 'contexts/WindTurbinePanels';
import { SelectItem } from 'types/general';
import { useAsyncResource } from 'utils/hooks/useAsyncResource';

const ViewTagsPage = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { windTurbineControllerApi } = useApi();

  const { publicId } = useParams();
  const [category, setCategory] = useState(categoryOptions[0]);
  const [page, setPage] = useState(0);
  const selectOptions: SelectItem[] = selectTimePeriodOptions(t);
  const [timePeriod, setTimePeriod] = useState(selectOptions[0].value);

  const isDesktop = useMediaQuery(theme.breakpoints.only('desktop'));
  const isTablet = useMediaQuery(theme.breakpoints.only('tablet'));

  const getWTName = useCallback(async () => {
    if (typeof publicId === 'undefined') {
      return;
    }
    try {
      const data = (
        await windTurbineControllerApi.getWindTurbine({ publicId: publicId })
      ).data;
      return data?.name;
    } catch {}
  }, [publicId, windTurbineControllerApi]);

  const { resource: wtName } = useAsyncResource({ fetchResource: getWTName });

  const pageSize = useMemo(() => {
    if (isDesktop) return 20;
    if (isTablet) return 10;
    return 5;
  }, [isDesktop, isTablet]);

  const handleChangeCategory = (value: ChipFilterValue) => {
    setPage(0);
    setCategory(value);
  };

  const currentTotal = useMemo(() => (page + 1) * pageSize, [page, pageSize]);

  const widgets = useMemo(() => {
    return panelsMapping?.[category.value as TagsCategories]?.slice(
      0,
      currentTotal,
    );
  }, [category.value, currentTotal]);

  const hasMore = useMemo(
    () =>
      panelsMapping?.[category.value as TagsCategories].length > currentTotal,
    [category.value, currentTotal],
  );

  return (
    <WindTurbinePanelsProvider dashboardType="ADMIN_VIEW_TAGS">
      <PageWrapper>
        <HeadWrapper>
          <Box display="flex" flexDirection="column" gap={1.5}>
            <Typography variant="bodyM">{t`pages.viewTags.subtitle`}</Typography>
            <Typography variant="h2">{wtName}</Typography>
          </Box>
          <Box
            display="flex"
            flexDirection={isDesktop ? 'row' : 'column'}
            justifyContent="space-between"
            gap={3}
            alignItems={isDesktop ? 'center' : 'left'}
            overflow="hidden"
          >
            <StyledChipFilter
              value={category}
              options={categoryOptions}
              optionsSize="medium"
              variant="contained"
              onChange={handleChangeCategory}
            />
            <Select
              name="timePeriod"
              value={timePeriod || ''}
              items={selectOptions}
              label={t('form.timeframe')}
              onItemClick={setTimePeriod}
              sx={{ width: { mobile: '100%', desktop: '193px' } }}
            />
          </Box>
        </HeadWrapper>
        <GridTableWrapper>
          <GridTable>
            {widgets.map(({ panelId, tKey }) => (
              <ChartCard
                title={t(tKey)}
                panelId={panelId}
                timePeriod={timePeriod as SelectTimePeriods}
                key={panelId}
              />
            ))}
          </GridTable>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              flexGrow: 1,
            }}
          >
            {hasMore && (
              <CommonButton
                variant="outlined"
                color="secondary"
                sx={{ backgroundColor: 'transparent' }}
                onClick={() => setPage(page + 1)}
              >
                {t('pages.viewTags.loadMore')}
              </CommonButton>
            )}
          </Box>
        </GridTableWrapper>
      </PageWrapper>
    </WindTurbinePanelsProvider>
  );
};

export default ViewTagsPage;
