import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { FC, useCallback, useMemo, useState } from 'react';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { defaultValues } from './constants';
import {
  ConnectUserDialogWrapper,
  ButtonsWrapper,
  CurrentStepWrapper,
  FormWrapper,
} from './styles';
import { FormValues } from './types';
import { validationSchemas } from './validationSchema';
import useApi from '../../../contexts/api';
import CommonButton from '../CommonButton';
import SelectOrCreateCustomer from '../SelectOrCreateCustomer';
import SelectOrCreatePark from '../SelectOrCreatePark';
import SuccessDialog from '../SuccessDialog';
import { ArrowLeft, ArrowRight } from 'components/icons';

type Props = {
  publicId: string;
  onClose: (e?: React.MouseEvent<Element, MouseEvent>) => void;
  onSuccess: (e?: React.MouseEvent<Element, MouseEvent>) => void;
};

const ConnectUser: FC<Props> = ({ publicId, onClose, onSuccess }) => {
  const { t } = useTranslation();
  const [showSuccess, setShowSuccess] = useState(false);
  const { assetOnboardingControllerApi, locationControllerApi } = useApi();
  const [userCreated, setUserCreated] = useState(false);
  const [parkIsCreated, setParkIsCreated] = useState(false);
  const [step, setStep] = useState<0 | 1>(0);

  const isParkNameExist = useCallback(
    async (parkName: string) => {
      try {
        const existingPark = (
          await locationControllerApi.getLocationByName({
            name: parkName.trim(),
          })
        ).data;
        if (existingPark) return true;
        return false;
      } catch {
        return false;
      }
    },
    [locationControllerApi],
  );

  const schema = useMemo(() => {
    return validationSchemas[step](t, isParkNameExist);
  }, [isParkNameExist, step, t]);

  const form = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const {
    reset,
    watch,
    formState: { isDirty },
    handleSubmit,
    trigger,
    setValue,
    clearErrors,
  } = form;

  const existingCustomer = watch('existingCustomer');

  const handleClose = useCallback(
    (e: React.MouseEvent<Element, MouseEvent>) => {
      setStep(0);
      reset();
      onClose(e);
    },
    [reset, onClose],
  );

  const formSubmit = async (data: FormValues) => {
    try {
      const owner = (data.existingCustomer || data.newCustomer) as string;

      if (!parkIsCreated && data?.newPark?.name && data?.newPark?.coordinates) {
        const [latitude, longitude] = data?.newPark.coordinates
          .replace(/ /g, '')
          ?.split(',')
          .map((coordinateStr) => Number(coordinateStr));

        await locationControllerApi.create({
          locationDto: {
            name: data?.newPark?.name,
            latitude,
            longitude,
            customerName: owner,
          },
        });

        setParkIsCreated(true);
      }

      await assetOnboardingControllerApi.addMapping({
        customerMappingDto: [
          {
            publicId,
            owner,
            locationName: data?.newPark?.name || (data?.existingPark as string),
          },
        ],
      });
      setShowSuccess(true);
    } catch (e) {
      if (axios.isAxiosError(e) && e.response?.data.message) {
        const message: string = e.response.data.message;
        toast.error(message);

        if (
          message.startsWith('Location with name') &&
          message.endsWith('already exists.')
        ) {
          await trigger('newPark.name');
        }
      }
    }
  };

  const canGoStep2 = useMemo(() => {
    if (existingCustomer || userCreated) return true;
  }, [existingCustomer, userCreated]);

  const goStep2 = useCallback(async () => {
    try {
      await trigger('newCustomer');
      await trigger('existingCustomer');
      setStep(1);
    } catch {}
  }, [trigger]);

  return (
    <>
      <ConnectUserDialogWrapper
        open={!!publicId && !showSuccess}
        onClose={handleClose}
      >
        <FormProvider {...form}>
          <FormWrapper onSubmit={handleSubmit(formSubmit)}>
            <Box height="100%" display="flex" flexDirection="column">
              <Typography
                variant="subheading"
                sx={{ pb: 1 }}
                color="green.700"
                component="div"
              >
                {t`step`} {`${step + 1}/2`}
              </Typography>
              <Typography
                variant="h2"
                sx={{ mb: 5 }}
                color="black.600"
              >{t`components.connectUser.title`}</Typography>

              <CurrentStepWrapper isCurrent={step === 0}>
                <Typography
                  variant="bodyM"
                  color="black.600"
                  component="div"
                  sx={{ pb: 3 }}
                >{t`components.assetOnboarding.subTitleUser`}</Typography>

                <SelectOrCreateCustomer setUserIsCreated={setUserCreated} />

                <ButtonsWrapper
                  padding={2}
                  display="flex"
                  gap={2}
                  justifyContent="center"
                >
                  <CommonButton
                    variant="outlined"
                    onClick={handleClose}
                    data-testid="close-button"
                  >
                    {t`cancel`}
                  </CommonButton>
                  <CommonButton
                    variant="contained"
                    data-testid="submit"
                    endIcon={<ArrowRight />}
                    disabled={!canGoStep2}
                    onClick={goStep2}
                  >
                    {t`next`}
                  </CommonButton>
                </ButtonsWrapper>
              </CurrentStepWrapper>

              <CurrentStepWrapper isCurrent={step === 1}>
                <Typography
                  variant="bodyM"
                  color="black.600"
                  component="div"
                  sx={{ pb: 3 }}
                >
                  {userCreated
                    ? t`components.assetOnboarding.subTitleCreatePark`
                    : t`components.assetOnboarding.subTitleOnePark`}
                </Typography>

                <SelectOrCreatePark
                  selectProps={{
                    name: 'existingPark',
                    defaultValue: '',
                  }}
                />

                <ButtonsWrapper display="flex" gap={2} justifyContent="center">
                  <CommonButton
                    variant="outlined"
                    onClick={() => {
                      setValue('existingPark', '');
                      setValue('newPark', { name: '', coordinates: '' });
                      clearErrors('existingPark');
                      clearErrors('newPark');
                      setStep(0);
                    }}
                    data-testid="close-button"
                    startIcon={<ArrowLeft />}
                  >
                    {t`back`}
                  </CommonButton>
                  <CommonButton
                    variant="contained"
                    data-testid="submit"
                    type="submit"
                    disabled={!isDirty}
                  >
                    {t('buttons.submit')}
                  </CommonButton>
                </ButtonsWrapper>
              </CurrentStepWrapper>
            </Box>
          </FormWrapper>
        </FormProvider>
      </ConnectUserDialogWrapper>
      <SuccessDialog
        title={t`components.userConnectionSuccess.title`}
        buttonText={t`components.userConnectionSuccess.button`}
        onClose={(e) => {
          e?.stopPropagation();
          setShowSuccess(false);
          onSuccess(e as React.MouseEvent<Element, MouseEvent>);
        }}
        open={showSuccess}
      />
    </>
  );
};
export default ConnectUser;
