import * as yup from 'yup';

import { TranslationFunction } from 'types/general';

const validationSchema = (t: TranslationFunction) =>
  yup.object({
    link: yup.string().required(t('form.errors.fieldRequired')),
    cameraViewName: yup
      .string()
      .required(t('form.errors.fieldRequired'))
      .min(
        5,
        t('pages.turbine.tabs.streaming.addStreamForm.cameraNameLengthError'),
      )
      .max(
        32,
        t('pages.turbine.tabs.streaming.addStreamForm.cameraNameLengthError'),
      ),
  });
export default validationSchema;
