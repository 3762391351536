import { styled } from '@mui/material';

export const InvestigationDataChartIframeWrapper = styled('div')(() => ({
  position: 'relative',
  height: '165px',
  overflow: 'hidden',
}));

export const InvestigationDataChartIframe = styled('iframe')(() => ({
  height: '173px',

  position: 'absolute',
  top: '-4px',
  left: '-4px',
  width: 'calc(100% + 8px)',
}));
