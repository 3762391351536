import { styled } from '@mui/material';

const ButtonsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: theme.spacing(5),
}));

export default ButtonsWrapper;
