/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CustomerMappingDto } from '../models';
// @ts-ignore
import { WindTurbineInitDto } from '../models';
// @ts-ignore
import { WindTurbineInitResponseDto } from '../models';
// @ts-ignore
import { WindTurbineReadyDto } from '../models';
/**
 * AssetOnboardingControllerApi - axios parameter creator
 * @export
 */
export const AssetOnboardingControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<CustomerMappingDto>} customerMappingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMapping: async (customerMappingDto: Array<CustomerMappingDto>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerMappingDto' is not null or undefined
            assertParamExists('addMapping', 'customerMappingDto', customerMappingDto)
            const localVarPath = `/asset/onboarding/mapping`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerMappingDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} publicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disconnect: async (publicId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publicId' is not null or undefined
            assertParamExists('disconnect', 'publicId', publicId)
            const localVarPath = `/asset/onboarding/mapping/{publicId}`
                .replace(`{${"publicId"}}`, encodeURIComponent(String(publicId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orangepiId 
         * @param {string} xApiKey 
         * @param {Array<WindTurbineInitDto>} windTurbineInitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initWindTurbine: async (orangepiId: string, xApiKey: string, windTurbineInitDto: Array<WindTurbineInitDto>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orangepiId' is not null or undefined
            assertParamExists('initWindTurbine', 'orangepiId', orangepiId)
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('initWindTurbine', 'xApiKey', xApiKey)
            // verify required parameter 'windTurbineInitDto' is not null or undefined
            assertParamExists('initWindTurbine', 'windTurbineInitDto', windTurbineInitDto)
            const localVarPath = `/asset/onboarding/init/wind-turbine`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (orangepiId !== undefined) {
                localVarQueryParameter['orangepiId'] = orangepiId;
            }

            if (xApiKey != null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(windTurbineInitDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orangepiId 
         * @param {string} xApiKey 
         * @param {Array<WindTurbineReadyDto>} windTurbineReadyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readyWindTurbine: async (orangepiId: string, xApiKey: string, windTurbineReadyDto: Array<WindTurbineReadyDto>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orangepiId' is not null or undefined
            assertParamExists('readyWindTurbine', 'orangepiId', orangepiId)
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('readyWindTurbine', 'xApiKey', xApiKey)
            // verify required parameter 'windTurbineReadyDto' is not null or undefined
            assertParamExists('readyWindTurbine', 'windTurbineReadyDto', windTurbineReadyDto)
            const localVarPath = `/asset/onboarding/ready/wind-turbine`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (orangepiId !== undefined) {
                localVarQueryParameter['orangepiId'] = orangepiId;
            }

            if (xApiKey != null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(windTurbineReadyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AssetOnboardingControllerApi - functional programming interface
 * @export
 */
export const AssetOnboardingControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AssetOnboardingControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Array<CustomerMappingDto>} customerMappingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addMapping(customerMappingDto: Array<CustomerMappingDto>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addMapping(customerMappingDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} publicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async disconnect(publicId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.disconnect(publicId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} orangepiId 
         * @param {string} xApiKey 
         * @param {Array<WindTurbineInitDto>} windTurbineInitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async initWindTurbine(orangepiId: string, xApiKey: string, windTurbineInitDto: Array<WindTurbineInitDto>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WindTurbineInitResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.initWindTurbine(orangepiId, xApiKey, windTurbineInitDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} orangepiId 
         * @param {string} xApiKey 
         * @param {Array<WindTurbineReadyDto>} windTurbineReadyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readyWindTurbine(orangepiId: string, xApiKey: string, windTurbineReadyDto: Array<WindTurbineReadyDto>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readyWindTurbine(orangepiId, xApiKey, windTurbineReadyDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AssetOnboardingControllerApi - factory interface
 * @export
 */
export const AssetOnboardingControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AssetOnboardingControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {AssetOnboardingControllerApiAddMappingRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMapping(requestParameters: AssetOnboardingControllerApiAddMappingRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.addMapping(requestParameters.customerMappingDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AssetOnboardingControllerApiDisconnectRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disconnect(requestParameters: AssetOnboardingControllerApiDisconnectRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.disconnect(requestParameters.publicId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AssetOnboardingControllerApiInitWindTurbineRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initWindTurbine(requestParameters: AssetOnboardingControllerApiInitWindTurbineRequest, options?: AxiosRequestConfig): AxiosPromise<Array<WindTurbineInitResponseDto>> {
            return localVarFp.initWindTurbine(requestParameters.orangepiId, requestParameters.xApiKey, requestParameters.windTurbineInitDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AssetOnboardingControllerApiReadyWindTurbineRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readyWindTurbine(requestParameters: AssetOnboardingControllerApiReadyWindTurbineRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.readyWindTurbine(requestParameters.orangepiId, requestParameters.xApiKey, requestParameters.windTurbineReadyDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for addMapping operation in AssetOnboardingControllerApi.
 * @export
 * @interface AssetOnboardingControllerApiAddMappingRequest
 */
export interface AssetOnboardingControllerApiAddMappingRequest {
    /**
     * 
     * @type {Array<CustomerMappingDto>}
     * @memberof AssetOnboardingControllerApiAddMapping
     */
    readonly customerMappingDto: Array<CustomerMappingDto>
}

/**
 * Request parameters for disconnect operation in AssetOnboardingControllerApi.
 * @export
 * @interface AssetOnboardingControllerApiDisconnectRequest
 */
export interface AssetOnboardingControllerApiDisconnectRequest {
    /**
     * 
     * @type {string}
     * @memberof AssetOnboardingControllerApiDisconnect
     */
    readonly publicId: string
}

/**
 * Request parameters for initWindTurbine operation in AssetOnboardingControllerApi.
 * @export
 * @interface AssetOnboardingControllerApiInitWindTurbineRequest
 */
export interface AssetOnboardingControllerApiInitWindTurbineRequest {
    /**
     * 
     * @type {string}
     * @memberof AssetOnboardingControllerApiInitWindTurbine
     */
    readonly orangepiId: string

    /**
     * 
     * @type {string}
     * @memberof AssetOnboardingControllerApiInitWindTurbine
     */
    readonly xApiKey: string

    /**
     * 
     * @type {Array<WindTurbineInitDto>}
     * @memberof AssetOnboardingControllerApiInitWindTurbine
     */
    readonly windTurbineInitDto: Array<WindTurbineInitDto>
}

/**
 * Request parameters for readyWindTurbine operation in AssetOnboardingControllerApi.
 * @export
 * @interface AssetOnboardingControllerApiReadyWindTurbineRequest
 */
export interface AssetOnboardingControllerApiReadyWindTurbineRequest {
    /**
     * 
     * @type {string}
     * @memberof AssetOnboardingControllerApiReadyWindTurbine
     */
    readonly orangepiId: string

    /**
     * 
     * @type {string}
     * @memberof AssetOnboardingControllerApiReadyWindTurbine
     */
    readonly xApiKey: string

    /**
     * 
     * @type {Array<WindTurbineReadyDto>}
     * @memberof AssetOnboardingControllerApiReadyWindTurbine
     */
    readonly windTurbineReadyDto: Array<WindTurbineReadyDto>
}

/**
 * AssetOnboardingControllerApi - object-oriented interface
 * @export
 * @class AssetOnboardingControllerApi
 * @extends {BaseAPI}
 */
export class AssetOnboardingControllerApi extends BaseAPI {
    /**
     * 
     * @param {AssetOnboardingControllerApiAddMappingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetOnboardingControllerApi
     */
    public addMapping(requestParameters: AssetOnboardingControllerApiAddMappingRequest, options?: AxiosRequestConfig) {
        return AssetOnboardingControllerApiFp(this.configuration).addMapping(requestParameters.customerMappingDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AssetOnboardingControllerApiDisconnectRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetOnboardingControllerApi
     */
    public disconnect(requestParameters: AssetOnboardingControllerApiDisconnectRequest, options?: AxiosRequestConfig) {
        return AssetOnboardingControllerApiFp(this.configuration).disconnect(requestParameters.publicId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AssetOnboardingControllerApiInitWindTurbineRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetOnboardingControllerApi
     */
    public initWindTurbine(requestParameters: AssetOnboardingControllerApiInitWindTurbineRequest, options?: AxiosRequestConfig) {
        return AssetOnboardingControllerApiFp(this.configuration).initWindTurbine(requestParameters.orangepiId, requestParameters.xApiKey, requestParameters.windTurbineInitDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AssetOnboardingControllerApiReadyWindTurbineRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetOnboardingControllerApi
     */
    public readyWindTurbine(requestParameters: AssetOnboardingControllerApiReadyWindTurbineRequest, options?: AxiosRequestConfig) {
        return AssetOnboardingControllerApiFp(this.configuration).readyWindTurbine(requestParameters.orangepiId, requestParameters.xApiKey, requestParameters.windTurbineReadyDto, options).then((request) => request(this.axios, this.basePath));
    }
}
