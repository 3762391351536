import { DataGridProps } from '@mui/x-data-grid';
import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import StickyFill from 'stickyfilljs';

import {
  ColumnSortedAscendingIconComponent,
  ColumnSortedDescendingIconComponent,
  ColumnUnsortedIconComponent,
} from './components/Icons';
import TableWrapper from './styles/TableWrapper';
import { getRowsPerPage } from 'utils/functions/getRowsPerPage';

interface ICommonTableProps extends DataGridProps {}

export const CommonTable: FC<ICommonTableProps> = ({ ...rest }) => {
  const { t } = useTranslation();
  const slots = useMemo(
    () => ({
      columnUnsortedIcon: ColumnUnsortedIconComponent,
      columnSortedAscendingIcon: ColumnSortedAscendingIconComponent,
      columnSortedDescendingIcon: ColumnSortedDescendingIconComponent,
    }),
    [],
  );

  const onRefChange = useCallback((tableRef: HTMLDivElement | null) => {
    if (tableRef) {
      StickyFill.forceSticky();
      const elements = tableRef.querySelector('.MuiDataGrid-columnHeaders');
      if (elements) {
        const sticky = StickyFill.addOne(elements as HTMLElement);
        tableRef.addEventListener('scroll', () => sticky.refresh());
      }
    }
  }, []);

  const colCount = useMemo(
    () =>
      rest.columns.length -
      Object.values(
        rest?.initialState?.columns?.columnVisibilityModel || {},
      ).filter((v) => !v).length,
    [rest.columns.length, rest?.initialState?.columns?.columnVisibilityModel],
  );

  return (
    <TableWrapper
      slots={slots}
      disableRowSelectionOnClick
      getRowHeight={() => 'auto'}
      autoHeight={true}
      ref={onRefChange}
      slotProps={{
        ...rest.slotProps,
      }}
      pageSizeOptions={getRowsPerPage(t)}
      colCount={colCount}
      hideFooter
      {...rest}
    />
  );
};
