import { styled } from '@mui/material';

export const CountersWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: theme.spacing(4),

  [theme.breakpoints.down('desktop')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: theme.spacing(2.5),
    marginTop: 'auto',
  },
}));
