import { FC } from 'react';

import { CommonSvgProps } from 'types/general';

export const CheckMarkDouble: FC<CommonSvgProps> = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8 12.4848L12.2426 16.7275L20.7279 8.24219"
      stroke="#404753"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 12.4848L7.24264 16.7275M12.5 11.4996L15.7279 8.24219"
      stroke="#404753"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
