import { styled } from '@mui/material';

export const CustomerNameWrapper = styled('div')(({ theme }) => ({
  position: 'absolute',
  right: 0,
  bottom: 0,

  [theme.breakpoints.down('desktop')]: {
    position: 'static',
    marginTop: theme.spacing(3),
  },
}));
