import { styled } from '@mui/material';

export const HeadWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
  padding: theme.spacing(5, 7.5, 4, 7.5),
  [theme.breakpoints.down('desktop')]: {
    padding: theme.spacing(3, 2.5, 4, 2.5),
  },
}));
