import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FC } from 'react';
import * as React from 'react';

import SuccessDialogWrapper from './styles/DialogWrapper';
import CommonButton from '../CommonButton';
import { CommonDialogProps } from '../CommonDialog/CommonDialog';
import { SuccessIcon } from 'components/icons';

type Props = {
  title?: string;
  subtitle?: string;
  buttonText?: string;
  onClose?: (e?: React.MouseEvent<Element, MouseEvent>) => void;
} & CommonDialogProps;

const SuccessDialog: FC<Props> = ({
  title,
  subtitle,
  buttonText,
  onClose,
  children,
  ...props
}) => {
  return (
    <SuccessDialogWrapper {...props} onClose={onClose}>
      <Box sx={{ display: { xs: 'block', md: 'none' } }} />
      <Grid
        sx={{ textAlign: 'center' }}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <SuccessIcon />
        <Typography variant="h1" color="white.main" sx={{ mt: 4, mb: 2.5 }}>
          {title}
        </Typography>
        <Typography variant="bodyM" color="white.main">
          {subtitle}
        </Typography>
        {!!children && (
          <Grid mt={4} sx={{ width: '100%' }}>
            {children}
          </Grid>
        )}
      </Grid>
      <CommonButton
        variant="contained"
        data-testid="successButton"
        onClick={onClose}
      >
        {buttonText}
      </CommonButton>
    </SuccessDialogWrapper>
  );
};
export default SuccessDialog;
