import { createContext } from 'react';

import {
  LocationWeatherDto,
  LocationWindTurbineDetailsDto,
} from 'openapi-api/admin-service';

export interface IParkContext {
  details?: LocationWindTurbineDetailsDto;
  weather?: LocationWeatherDto;
  refetch: () => Promise<void>;
  isLoading?: boolean;
}

export const ParkContext = createContext<IParkContext>(null!);
