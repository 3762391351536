import { useContext } from 'react';

import ResponsePopupContext from './Context';
import ResponsePopupProvider from './Provider';

const useResponsePopup = () => {
  const context = useContext(ResponsePopupContext);

  if (typeof context === 'undefined' || context === null) {
    throw new Error(
      'useResponsePopup must be used within an ResponsePopupProvider',
    );
  }

  return context;
};

export default useResponsePopup;
export { ResponsePopupProvider };
