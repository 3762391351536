import Typography from '@mui/material/Typography';
import React, { FC } from 'react';

import CountBox from './styles/CountBox';
import TabTitleWrapper from './styles/TabTitleWrapper';

interface Props {
  title: null | string;
  count: number;
  active: boolean;
}
export const TabTitle: FC<Props> = ({ title, count, active }) => {
  return (
    <TabTitleWrapper>
      <Typography variant="bodyMStrong">{title}</Typography>
      <CountBox active={active}>
        <Typography variant="bodyXSStrong">{count}</Typography>
      </CountBox>
    </TabTitleWrapper>
  );
};
