import { Paper, styled } from '@mui/material';

export const TimePickerPaper = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(1.5),
  marginBottom: theme.spacing(1.5),
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',

  '& .MuiPickersToolbar-content': {
    '& .MuiTimePickerToolbar-hourMinuteLabel': {
      alignItems: 'center',
    },
    '& .MuiTimePickerToolbar-ampmSelection .MuiTimePickerToolbar-ampmLabel': {
      ...theme.typography.buttonPrimary,
      color: theme.palette.grey['500'],
      '&.Mui-selected': {
        color: theme.palette.black['900'],
      },
    },
  },

  '& .MuiPickersLayout-contentWrapper': {
    '& li': {
      ...theme.typography.bodyXS,
    },
    '& .MuiClockNumber-root': {
      ...theme.typography.bodyL,
    },
  },
}));
