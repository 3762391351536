import { styled } from '@mui/material';

export const ButtonsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),

  [theme.breakpoints.down('desktop')]: {
    gap: theme.spacing(1.5),
  },
}));
