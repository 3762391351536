import { FC } from 'react';

import { CommonSvgProps } from 'types/general';

export const Sorting: FC<CommonSvgProps> = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15 9L12 6L9 9"
      // stroke="#898E96"
      stroke="var(--first-stroke)"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M9 15L12 18L15 15"
      // stroke="#898E96"
      stroke="var(--second-stroke)"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);
