import { Box, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { WeatherSlideWindIcon, WeatherSlideWrapper } from './styles';
import { ConditionIcon, DegreesCelcius } from 'components/shared/Weather';
import { LocationHourWeatherDto } from 'openapi-api/admin-service';

export const WeatherSlide: FC<LocationHourWeatherDto> = ({
  datetime,
  conditionIcon,
  isDay,
  temperatureC,
  windSpeedMps,
  windDegree,
}) => {
  const { t } = useTranslation();

  return (
    <WeatherSlideWrapper>
      <Typography variant="bodyS" color="grey.500">
        {dayjs(datetime).format('HH:mm')}
      </Typography>
      <ConditionIcon iconLink={conditionIcon} isDay={isDay} />
      <DegreesCelcius degrees={temperatureC} variant="bodyMStrong" />
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box display="flex" alignItems="center" gap={0.25}>
          <Typography
            variant="bodyXS"
            whiteSpace="nowrap"
          >{`${windSpeedMps} ${t('weather.windSpeedMps')}`}</Typography>
          <WeatherSlideWindIcon
            sx={{ transform: `rotate(${windDegree}deg)` }}
          />
        </Box>
        <Typography variant="bodyXS" color="grey.600">
          {t('weather.windSpeed')}
        </Typography>
      </Box>
    </WeatherSlideWrapper>
  );
};
