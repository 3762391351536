import React, { FC, PropsWithChildren, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { IParkContext, ParkContext } from './ParkContext';
import useApi from 'contexts/api';
import { useAsyncResourceWithPulling } from 'utils/hooks/useAsyncResourceWithPulling';

const ParkProvider: FC<PropsWithChildren> = ({ children }) => {
  const { locationId } = useParams();
  const { locationControllerApi } = useApi();

  const getParkDetails = useCallback(async () => {
    if (!locationId) return;

    try {
      return (
        await locationControllerApi.getLocationWindTurbineDetails({
          locationId: Number(locationId),
        })
      ).data;
    } catch {}
  }, [locationControllerApi, locationId]);

  const {
    resource: parkDetails,
    isLoading: isParkDetailsLoading,
    fetch,
  } = useAsyncResourceWithPulling({
    fetchResource: getParkDetails,
    pullingInterval: 30,
  });

  const getParkWeather = useCallback(async () => {
    if (!parkDetails?.locationName) return;

    try {
      return (
        await locationControllerApi.getWeather({
          locationName: parkDetails.locationName,
        })
      ).data;
    } catch {}
  }, [locationControllerApi, parkDetails?.locationName]);

  const { resource: parkWeather } = useAsyncResourceWithPulling({
    fetchResource: getParkWeather,
    pullingInterval: 30,
  });

  const contextValue = useMemo(
    (): IParkContext => ({
      details: parkDetails,
      weather: parkWeather,
      refetch: fetch,
      isLoading: isParkDetailsLoading,
    }),
    [parkDetails, parkWeather, fetch, isParkDetailsLoading],
  );

  return (
    <ParkContext.Provider value={contextValue}>{children}</ParkContext.Provider>
  );
};

export default ParkProvider;
