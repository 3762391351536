import { styled } from '@mui/material';

export const Wrapper = styled('div')<{ extended: boolean }>(
  ({ theme, extended }) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(extended ? 3 : 2.5),
    backgroundColor: theme.palette.white.main,
    cursor: 'pointer',
    borderBottom: extended ? `1px solid ${theme.palette.grey[100]}` : 'none',

    '&:hover': {
      backgroundColor: theme.palette.grey[50],
    },

    [theme.breakpoints.down('desktop')]: {
      borderBottom: `1px solid ${theme.palette.grey[100]}`,
    },
  }),
);
