import { styled } from '@mui/material/styles';

export const CloseButton = styled('div')(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(1.5),
  top: theme.spacing(1.5),
  cursor: 'pointer',
  '& svg': {
    color: theme.palette.grey[500],
  },
}));
