import { createContext } from 'react';

import { UserDetailsDto } from 'openapi-api/admin-service';

export interface IUserContext {
  user: UserDetailsDto;
  refresh: () => Promise<void>;
  translatedRole: string;
  isSuperAdmin?: boolean;
  isAdmin?: boolean;
  isViewer?: boolean;
}

export const UserContext = createContext<IUserContext>(null!);
