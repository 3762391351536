import { styled } from '@mui/material';

export const ItemsBlock = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridTemplateRows: 'auto auto',
  gap: theme.spacing(1.5),

  [theme.breakpoints.down('desktop')]: {
    gridTemplateColumns: '1fr',
  },
}));
