import { styled } from '@mui/material';

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(5),
  padding: theme.spacing(3, 4),
  borderRadius: '12px',
  backgroundColor: theme.palette.yellow[200],

  [theme.breakpoints.down('desktop')]: {
    marginBottom: theme.spacing(2.5),
    padding: theme.spacing(3),
  },

  [theme.breakpoints.down('tablet')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: theme.spacing(3),
  },
}));

export default Wrapper;
