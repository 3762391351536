import { Box, SxProps } from '@mui/material';
import { FC } from 'react';

interface Props {
  height?: string;
  width?: string;
  iconLink?: string;
  isDay?: boolean;
  className?: string;
  sx?: SxProps;
}

export const ConditionIcon: FC<Props> = ({
  height = '24px',
  width = '24px',
  iconLink,
  isDay,
  className,
  sx,
}) => {
  const iconCode = iconLink?.split('/').pop()?.split('.png')[0];

  return (
    <Box display="flex" className={className} sx={sx}>
      <svg height={height} width={width}>
        <use
          xlinkHref={`/images/weather.svg#${
            isDay ? 'Day' : 'Night'
          }=${iconCode}`}
        />
      </svg>
    </Box>
  );
};
