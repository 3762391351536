import { Radio, RadioProps } from '@mui/material';
import { FC } from 'react';

import { RadioChecked, RadioDefault } from 'components/icons';

const RadioCustom: FC<RadioProps> = (props) => {
  return (
    <Radio {...props} icon={<RadioDefault />} checkedIcon={<RadioChecked />} />
  );
};

export default RadioCustom;
