import React, { FC, useMemo } from 'react';
import { useLocation, useOutlet } from 'react-router-dom';

import AssetOnboarding from './AssetOnboarding';
import Main from './styles/Main';
import MainLayoutWrapper from './styles/MainLayoutWrapper';
import routePaths from '../../../constants/routePaths';
import useGlobalData from '../../../contexts/globalData';
import { MobileMenu } from '../../common/Header/MobileMenu';
import { Header } from 'components/common/Header';

export const MainLayout: FC = () => {
  const outlet = useOutlet();
  const { pathname } = useLocation();
  const { isMobileMenuOpen } = useGlobalData();
  const showBgImage = useMemo(
    () => pathname === routePaths.parks.root,
    [pathname],
  );
  const noPaddings = useMemo(
    () =>
      pathname === routePaths.reports ||
      pathname.startsWith(routePaths.investigation.root) ||
      pathname.startsWith('/view-tags') ||
      pathname === routePaths.updateProfile,
    [pathname],
  );

  return (
    <MainLayoutWrapper showBgImage={showBgImage}>
      <Header showBgImage={showBgImage} />
      <Main noPaddings={noPaddings}>{outlet}</Main>
      {isMobileMenuOpen && <MobileMenu />}
      <AssetOnboarding />
    </MainLayoutWrapper>
  );
};
