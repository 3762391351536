import { Typography } from '@mui/material';
import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useNotificationNavigation } from './helpers';
import { WTLink } from './WTLink';
import CommonButton from 'components/common/CommonButton';
import routePaths from 'constants/routePaths';
import { InvestigationDashboardReadyDto } from 'openapi-api/admin-service';
import { getStringFromISODate } from 'utils/functions/getStringFromISODate';

interface Props {
  details: InvestigationDashboardReadyDto;
  extended: boolean;
  locationId?: number | string;
}

const TemplateInvestigationDashboardReady: FC<Props> = ({
  details,
  extended,
  locationId,
}) => {
  const {
    publicId,
    restorationRequestId,
    startDatetime,
    endDatetime,
    assetNameForAdmin,
  } = details;
  const { t } = useTranslation();

  const navigateFromNotification = useNotificationNavigation(
    routePaths.investigation.specific(restorationRequestId || ''),
    extended,
  );

  if (!publicId) {
    return null;
  }

  return (
    <>
      <Typography variant={extended ? 'bodyM' : 'bodyS'}>
        <Trans
          i18nKey="notifications.investigationDashboardReady"
          values={{
            startDateTime: getStringFromISODate(startDatetime),
            endDateTime: getStringFromISODate(endDatetime),
          }}
          components={[
            <WTLink
              key={`investigationDashboardReady-link-${publicId}`}
              locationId={locationId}
              publicId={publicId}
              linkText={assetNameForAdmin}
              newTab={extended}
            />,
          ]}
        />
      </Typography>
      <CommonButton
        sx={{ width: 'fit-content' }}
        size="medium"
        onClick={navigateFromNotification}
      >
        {t`buttons.viewDashboard`}
      </CommonButton>
    </>
  );
};

export default TemplateInvestigationDashboardReady;
