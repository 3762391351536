import { Typography, TypographyOwnProps } from '@mui/material';
import { FC } from 'react';

interface Props extends TypographyOwnProps {
  chanceOfRain?: number;
  chanceOfSnow?: number;
}

export const PrecipitationChance: FC<Props> = ({
  chanceOfRain,
  chanceOfSnow,
  ...props
}) => {
  if (!chanceOfRain && !chanceOfSnow) return null;

  return (
    <Typography {...props}>{`${chanceOfRain || chanceOfSnow}%`}</Typography>
  );
};
