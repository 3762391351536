import { styled } from '@mui/material';

import { CheckMark } from 'components/icons';

export const CheckMarkIcon = styled(CheckMark)(({ theme }) => ({
  marginLeft: 'auto',
  '& path': {
    stroke: theme.palette.green[600],
  },
}));
