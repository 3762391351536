import { styled, Grid } from '@mui/material';

export const MechanicalParameterWrapper = styled(Grid)(({ theme }) => ({
  height: '100%',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  padding: theme.spacing(0, 5, 0, 3),
  backgroundColor: 'rgb(24, 27, 31)',
  borderRadius: '25px',
  [theme.breakpoints.down('desktop')]: {
    height: '209px',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(3, 3, 0),
  },
  [theme.breakpoints.down('tablet')]: {
    height: '183px',
    padding: theme.spacing(3, 2, 0),
  },
}));
