import { useTranslation } from 'react-i18next';

export interface FilterItem {
  label?: string;
  value: string | null;
}

export enum FilterTypes {
  radio = 'radio',
  dates = 'dates',
  select = 'select',
}

export type FilterValue = {
  label: string;
  type?: FilterTypes;
  values: FilterItem[];
};

export type FilterModel = {
  field: string;
  value?: string | null;
};

export type FilterValues = Record<string, FilterValue>;

export type TranslationFunction = ReturnType<typeof useTranslation>['t'];

export type SelectItem = {
  label: string;
  value: string;
};

export enum GrafanaIframeTheme {
  light = 'light',
  dark = 'dark',
}

export enum PeriodFilterValues {
  day = 'now-24h',
  week = 'now-7d',
  month = 'now-30d',
  current = 'now-30m',
}

export interface CommonSvgProps {
  'data-testid'?: string;
  className?: string;
}
