import { Grid, useMediaQuery, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { ReportItem } from './ReportItem';
import { BottomPartContainer } from '../../styles';
import useApi from 'contexts/api';
import { useAsyncResourceWithPulling } from 'utils/hooks/useAsyncResourceWithPulling';

export const History = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.only('desktop'));
  const { reportRequestControllerApi } = useApi();
  const getReportsHistory = useCallback(async () => {
    try {
      const data = (
        await reportRequestControllerApi.getReportRequests({ size: 1000 })
      ).data;
      return data;
    } catch {}
  }, [reportRequestControllerApi]);

  const { resource } = useAsyncResourceWithPulling({
    fetchResource: getReportsHistory,
    pullingInterval: 30,
  });

  if (resource?.totalElements === 0) {
    if (isDesktop) {
      return (
        <BottomPartContainer>
          <Typography variant="h3" color="grey.600" textAlign="center">
            {t('pages.reports.specifyReportSettings')}
          </Typography>
        </BottomPartContainer>
      );
    }

    return null;
  }

  return (
    <BottomPartContainer>
      <Grid>
        <Typography variant="h3" sx={{ mb: { mobile: 3, desktop: 4 } }}>
          {t('pages.reports.historyTitle')}
        </Typography>
        <Grid container gap={1.5} flexDirection="column">
          {resource?.content?.map((reportItem) => (
            <ReportItem item={reportItem} key={reportItem.id} />
          ))}
        </Grid>
      </Grid>
    </BottomPartContainer>
  );
};
