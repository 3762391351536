import { TranslationFunction } from 'types/general';

export enum SelectParameter {
  ELPOWER = 'elpower',
  JANITZA = 'janitza',
  TEMPERATURE = 'temperature',
  MECHANICAL = 'mechanical',
}

export const getSelectParameterOptions = (t: TranslationFunction) => [
  {
    label: t('pages.investigationData.parameters.elpower'),
    value: SelectParameter.ELPOWER,
  },
  {
    label: t('pages.investigationData.parameters.janitza'),
    value: SelectParameter.JANITZA,
  },
  {
    label: t('pages.investigationData.parameters.temperature'),
    value: SelectParameter.TEMPERATURE,
  },
  {
    label: t('pages.investigationData.parameters.mechanical'),
    value: SelectParameter.MECHANICAL,
  },
];

export enum InvestigationDataCategory {
  // ELPOWER
  LineToLineRmsGridVoltage = 'lineToLineRmsGridVoltage',
  RmsGridCurrent = 'rmsGridCurrent',
  LineToLineRmsGeneratorVoltage = 'lineToLineRmsGeneratorVoltage',
  RmsGeneratorCurrent = 'rmsGeneratorCurrent',
  InternalDCLinkVoltage = 'internalDCLinkVoltage',
  GridFrequency = 'gridFrequency',
  GeneratorFrequency = 'generatorFrequency',
  PowerFromGenerator = 'powerFromGenerator',
  HeatsinkTemperature = 'heatsinkTemperature',
  InternalCabinetTemperature = 'internalCabinetTemperature',
  TotalSupplyHours = 'totalSupplyHours',
  TotalGridPowerInjectionHours = 'totalGridPowerInjectionHours',
  StatusFaultFlag = 'statusFaultFlag',
  TotalEnergyDeliveredToGrid = 'totalEnergyDeliveredToGrid',
  StateMachineInverter = 'stateMachineInverter',
  StateMachineErrorManagement = 'stateMachineErrorManagement',
  WindSpeedMS10 = 'windSpeedMS10',
  DogvaneDirection = 'dogvaneDirection',
  AdditionalStatusFaultFlagFromGeneratorsInverter = 'additionalStatus',

  // JANITZA
  Voltage = 'voltage',
  CurrentI = 'currentI',
  CurrentN = 'currentN',
  RealPower = 'realPower',
  ActivePower = 'activePower',
  ApparentPower = 'apparentPower',
  TotalPower = 'totalPower',
  FundReactivePower = 'fundReactivePower',
  ReactivePower = 'reactivePower',
  CosPhi = 'cosPhi',
  MeasuredFrequency = 'measuredFrequency',
  RotationField = 'rotationField',
  RealEnergy = 'realEnergy',
  ApparentEnergy = 'apparentEnergy',
  ReactiveEnergy = 'reactiveEnergy',
  Harmonic = 'harmonic',
}

export const getSelectCategoriesOptions = (
  currentParameter: SelectParameter,
  t: TranslationFunction,
) => {
  switch (currentParameter) {
    case SelectParameter.ELPOWER:
      return [
        {
          label: t(
            'pages.investigationData.categories.elpower.lineToLineRmsGridVoltage',
          ),
          value: InvestigationDataCategory.LineToLineRmsGridVoltage,
        },
        {
          label: t('pages.investigationData.categories.elpower.rmsGridCurrent'),
          value: InvestigationDataCategory.RmsGridCurrent,
        },
        {
          label: t(
            'pages.investigationData.categories.elpower.lineToLineRmsGeneratorVoltage',
          ),
          value: InvestigationDataCategory.LineToLineRmsGeneratorVoltage,
        },
        {
          label: t(
            'pages.investigationData.categories.elpower.rmsGeneratorCurrent',
          ),
          value: InvestigationDataCategory.RmsGeneratorCurrent,
        },
        {
          label: t(
            'pages.investigationData.categories.elpower.internalDCLinkVoltage',
          ),
          value: InvestigationDataCategory.InternalDCLinkVoltage,
        },
        {
          label: t('pages.investigationData.categories.elpower.gridFrequency'),
          value: InvestigationDataCategory.GridFrequency,
        },
        {
          label: t(
            'pages.investigationData.categories.elpower.generatorFrequency',
          ),
          value: InvestigationDataCategory.GeneratorFrequency,
        },
        {
          label: t(
            'pages.investigationData.categories.elpower.powerFromGenerator',
          ),
          value: InvestigationDataCategory.PowerFromGenerator,
        },
        {
          label: t(
            'pages.investigationData.categories.elpower.heatsinkTemperature',
          ),
          value: InvestigationDataCategory.HeatsinkTemperature,
        },
        {
          label: t(
            'pages.investigationData.categories.elpower.internalCabinetTemperature',
          ),
          value: InvestigationDataCategory.InternalCabinetTemperature,
        },
        {
          label: t(
            'pages.investigationData.categories.elpower.totalSupplyHours',
          ),
          value: InvestigationDataCategory.TotalSupplyHours,
        },
        {
          label: t(
            'pages.investigationData.categories.elpower.totalGridPowerInjectionHours',
          ),
          value: InvestigationDataCategory.TotalGridPowerInjectionHours,
        },
        {
          label: t(
            'pages.investigationData.categories.elpower.statusFaultFlag',
          ),
          value: InvestigationDataCategory.StatusFaultFlag,
        },
        {
          label: t(
            'pages.investigationData.categories.elpower.totalEnergyDeliveredToGrid',
          ),
          value: InvestigationDataCategory.TotalEnergyDeliveredToGrid,
        },
        {
          label: t(
            'pages.investigationData.categories.elpower.stateMachineInverter',
          ),
          value: InvestigationDataCategory.StateMachineInverter,
        },
        {
          label: t(
            'pages.investigationData.categories.elpower.stateMachineErrorManagement',
          ),
          value: InvestigationDataCategory.StateMachineErrorManagement,
        },
        {
          label: t('pages.investigationData.categories.elpower.windSpeedMS10'),
          value: InvestigationDataCategory.WindSpeedMS10,
        },
        {
          label: t(
            'pages.investigationData.categories.elpower.dogvaneDirection',
          ),
          value: InvestigationDataCategory.DogvaneDirection,
        },
        {
          label: t(
            'pages.investigationData.categories.elpower.additionalStatusFaultFlagFromGeneratorsInverter',
          ),
          value:
            InvestigationDataCategory.AdditionalStatusFaultFlagFromGeneratorsInverter,
        },
      ];
    case SelectParameter.JANITZA:
      return [
        {
          label: t('pages.investigationData.categories.janitza.voltage'),
          value: InvestigationDataCategory.Voltage,
        },
        {
          label: t('pages.investigationData.categories.janitza.currentI'),
          value: InvestigationDataCategory.CurrentI,
        },
        {
          label: t('pages.investigationData.categories.janitza.currentN'),
          value: InvestigationDataCategory.CurrentN,
        },
        {
          label: t('pages.investigationData.categories.janitza.realPower'),
          value: InvestigationDataCategory.RealPower,
        },
        {
          label: t('pages.investigationData.categories.janitza.activePower'),
          value: InvestigationDataCategory.ActivePower,
        },
        {
          label: t('pages.investigationData.categories.janitza.apparentPower'),
          value: InvestigationDataCategory.ApparentPower,
        },
        {
          label: t('pages.investigationData.categories.janitza.totalPower'),
          value: InvestigationDataCategory.TotalPower,
        },
        {
          label: t(
            'pages.investigationData.categories.janitza.fundReactivePower',
          ),
          value: InvestigationDataCategory.FundReactivePower,
        },
        {
          label: t('pages.investigationData.categories.janitza.reactivePower'),
          value: InvestigationDataCategory.ReactivePower,
        },
        {
          label: t('pages.investigationData.categories.janitza.cosPhi'),
          value: InvestigationDataCategory.CosPhi,
        },
        {
          label: t(
            'pages.investigationData.categories.janitza.measuredFrequency',
          ),
          value: InvestigationDataCategory.MeasuredFrequency,
        },
        {
          label: t('pages.investigationData.categories.janitza.rotationField'),
          value: InvestigationDataCategory.RotationField,
        },
        {
          label: t('pages.investigationData.categories.janitza.realEnergy'),
          value: InvestigationDataCategory.RealEnergy,
        },
        {
          label: t('pages.investigationData.categories.janitza.apparentEnergy'),
          value: InvestigationDataCategory.ApparentEnergy,
        },
        {
          label: t('pages.investigationData.categories.janitza.reactiveEnergy'),
          value: InvestigationDataCategory.ReactiveEnergy,
        },
        {
          label: t('pages.investigationData.categories.janitza.harmonic'),
          value: InvestigationDataCategory.Harmonic,
        },
      ];
    case SelectParameter.TEMPERATURE:
      return [];
    case SelectParameter.MECHANICAL:
      return [];
    default:
      return [];
  }
};

export const panelsMapping: {
  [param in SelectParameter]:
    | {
        [key: string]: {
          panelId: number;
          tKey: string;
        }[];
      }
    | {
        panelId: number;
        tKey: string;
      }[];
} = {
  [SelectParameter.ELPOWER]: {
    [InvestigationDataCategory.LineToLineRmsGridVoltage]: [
      { panelId: 101, tKey: 'pages.investigationData.panels.vrsAFErms' },
      { panelId: 102, tKey: 'pages.investigationData.panels.vstAFErms' },
      { panelId: 103, tKey: 'pages.investigationData.panels.vtrAFErms' },
    ],
    [InvestigationDataCategory.RmsGridCurrent]: [
      { panelId: 107, tKey: 'pages.investigationData.panels.irOUTrms' },
      { panelId: 108, tKey: 'pages.investigationData.panels.isOUTrms' },
      { panelId: 109, tKey: 'pages.investigationData.panels.itOUTrms' },
    ],
    [InvestigationDataCategory.LineToLineRmsGeneratorVoltage]: [
      { panelId: 104, tKey: 'pages.investigationData.panels.vrsINVrms' },
      { panelId: 105, tKey: 'pages.investigationData.panels.vstINVrms' },
      { panelId: 106, tKey: 'pages.investigationData.panels.vtrINVrms' },
    ],
    [InvestigationDataCategory.RmsGeneratorCurrent]: [
      { panelId: 110, tKey: 'pages.investigationData.panels.irINVrms' },
      { panelId: 111, tKey: 'pages.investigationData.panels.isINVrms' },
      { panelId: 112, tKey: 'pages.investigationData.panels.itINVrms' },
    ],
    [InvestigationDataCategory.InternalDCLinkVoltage]: [
      { panelId: 98, tKey: 'pages.investigationData.panels.dCBusVoltage' },
    ],
    [InvestigationDataCategory.GridFrequency]: [
      { panelId: 100, tKey: 'pages.investigationData.panels.freqAFEf' },
    ],
    [InvestigationDataCategory.GeneratorFrequency]: [
      {
        panelId: 97,
        tKey: 'pages.investigationData.panels.generatorFrequency',
      },
    ],
    [InvestigationDataCategory.PowerFromGenerator]: [
      { panelId: 95, tKey: 'pages.investigationData.panels.pinv' },
      { panelId: 96, tKey: 'pages.investigationData.panels.pafe' },
    ],
    [InvestigationDataCategory.HeatsinkTemperature]: [
      { panelId: 118, tKey: 'pages.investigationData.panels.temperatureAFE' },
      {
        panelId: 119,
        tKey: 'pages.investigationData.panels.temperatureInverter',
      },
    ],
    [InvestigationDataCategory.InternalCabinetTemperature]: [
      {
        panelId: 120,
        tKey: 'pages.investigationData.panels.cabinetTemperature',
      },
    ],
    [InvestigationDataCategory.TotalSupplyHours]: [
      {
        panelId: 115,
        tKey: 'pages.investigationData.panels.totalSupplyHours',
      },
    ],
    [InvestigationDataCategory.TotalGridPowerInjectionHours]: [
      {
        panelId: 99,
        tKey: 'pages.investigationData.panels.gridInjectionHours',
      },
    ],
    [InvestigationDataCategory.StatusFaultFlag]: [
      {
        panelId: 176,
        tKey: 'pages.investigationData.panels.gridVoltageAFESoftStartFLG1AFEHEX1B723',
      },
      {
        panelId: 177,
        tKey: 'pages.investigationData.panels.vectorModulationFLG1AFEHEX1B622',
      },
      {
        panelId: 178,
        tKey: 'pages.investigationData.panels.waitForWindFLG1AFEHEX1B521',
      },
      {
        panelId: 179,
        tKey: 'pages.investigationData.panels.waitForGridReConnectionTimeFLG1AFEHEX0B319',
      },
      {
        panelId: 180,
        tKey: 'pages.investigationData.panels.gridAFEContractorOpenFLG1AFEHEX0B218',
      },
      {
        panelId: 181,
        tKey: 'pages.investigationData.panels.permanentFaultFLG1AFEHEX3B1531',
      },
      {
        panelId: 182,
        tKey: 'pages.investigationData.panels.temporaryFaultFLG1AFEHEX3B1430',
      },
      {
        panelId: 183,
        tKey: 'pages.investigationData.panels.faultForConsecutiveTemporaryFaultFLG1AFEHEX3B1329',
      },
      {
        panelId: 184,
        tKey: 'pages.investigationData.panels.gridFrequencyBeyondLimitsFLG1AFEHEX3B1228',
      },
      {
        panelId: 185,
        tKey: 'pages.investigationData.panels.externalGridProtectionTripg59ForUKFLG1AFEHEX2B1026',
      },
      {
        panelId: 186,
        tKey: 'pages.investigationData.panels.voltageBeyondLimitsFLG1AFEHEX2B925',
      },
      {
        panelId: 187,
        tKey: 'pages.investigationData.panels.wrongGridsPhaseSequenceFLG1AFEHEX2B824',
      },
      {
        panelId: 188,
        tKey: 'pages.investigationData.panels.gridVoltageVIAFENotBalancedFLG1AFEHEX0B117',
      },
      {
        panelId: 189,
        tKey: 'pages.investigationData.panels.gridCurrentLiAFENotBalancedFLG1AFEHEX0B016',
      },

      {
        panelId: 190,
        tKey: 'pages.investigationData.panels.gridPreChargeSelectionFLGAFEHEX3B1515',
      },
      {
        panelId: 805,
        tKey: 'pages.investigationData.panels.gridPLLNonLockedFLGAFEHEX3B1414',
      },
      {
        panelId: 804,
        tKey: 'pages.investigationData.panels.stopBitLogicsFLGAFEHEX0B11',
      },
      {
        panelId: 803,
        tKey: 'pages.investigationData.panels.boostDCFromAFEInProgressFLGAFEHEX0B00',
      },
      {
        panelId: 802,
        tKey: 'pages.investigationData.panels.vbusUnderThresholdFLGAFEHEX3B1313',
      },
      {
        panelId: 801,
        tKey: 'pages.investigationData.panels.vrmsAFEBeyondSuperiorLimitFLGAFEHEX3B1212',
      },
      {
        panelId: 800,
        tKey: 'pages.investigationData.panels.iGBTOverheatingThermalSwitchTripFLGAFEHEX2B1111',
      },
      {
        panelId: 799,
        tKey: 'pages.investigationData.panels.faultFeedbackFromGridAFEContactorFLGAFEHEX2B99',
      },
      {
        panelId: 798,
        tKey: 'pages.investigationData.panels.faultLineGridUnderMinimumLimitLVFNonRTFLGAFEHEX2B88',
      },
      {
        panelId: 797,
        tKey: 'pages.investigationData.panels.faultForMaxGridVoltageSoftwareVlnISwFLGAFEHEX1B77',
      },
      {
        panelId: 796,
        tKey: 'pages.investigationData.panels.faultForMaxGridCurrentSoftwareLlnISwFLGAFEHEX1B66',
      },
      {
        panelId: 795,
        tKey: 'pages.investigationData.panels.hZFaultHwInternalControlCardHardwareProblemFLGAFEHEX1B55',
      },
      {
        panelId: 794,
        tKey: 'pages.investigationData.panels.driverAFEFaultHwInternalIgbtDriverProtectionFLGAFEHEX1B44',
      },
      {
        panelId: 793,
        tKey: 'pages.investigationData.panels.dCLinkBrakingChopperFaultFLGAFEHEX0B33',
      },
      {
        panelId: 811,
        tKey: 'pages.investigationData.panels.faultForMaxLineCurrentHardwareLlnIHwFLGAFEHEX0B22',
      },

      {
        panelId: 901,
        tKey: 'pages.investigationData.panels.vectorModulationOnFLG1INVHEX1B622',
      },
      {
        panelId: 792,
        tKey: 'pages.investigationData.panels.startCommandHardwareOnFLG1INVHEX0B218',
      },
      {
        panelId: 812,
        tKey: 'pages.investigationData.panels.permanentFaultFLG1INVHEX3B1531',
      },
      {
        panelId: 813,
        tKey: 'pages.investigationData.panels.temporaryFaultFLG1INVHEX3B1430',
      },
      {
        panelId: 814,
        tKey: 'pages.investigationData.panels.mechanicStopFailedFLG1INVHEX2B1127',
      },
      {
        panelId: 815,
        tKey: 'pages.investigationData.panels.softwareI2tGeneratorProtectionFLG1INVHEX1B521',
      },
      {
        panelId: 816,
        tKey: 'pages.investigationData.panels.faultContactorsRelayDriverCtrlBoardErrorFLG1INVHEX1B420',
      },
      {
        panelId: 900,
        tKey: 'pages.investigationData.panels.maxTurbineOverspeedFLG1INVHEX0B319',
      },
      {
        panelId: 817,
        tKey: 'pages.investigationData.panels.generatorVoltageViINVUnbalancedFLG1INVHEX0B117',
      },
      {
        panelId: 818,
        tKey: 'pages.investigationData.panels.generatorCurrentLiINVUnbalancedFLG1INVHEX0B016',
      },

      {
        panelId: 830,
        tKey: 'pages.investigationData.panels.stopBitLogicsFLGINVHEX0B11',
      },
      {
        panelId: 829,
        tKey: 'pages.investigationData.panels.setupNotCompatibleWithFwReleaseFaultFLGINVHEX3B1515',
      },
      {
        panelId: 828,
        tKey: 'pages.investigationData.panels.generatorVoltageVrmsINVUnderThresholdFLGINVHEX3B1414',
      },
      {
        panelId: 902,
        tKey: 'pages.investigationData.panels.generatorFrequencyBeyondLimitsFLGINVHEX3B1313',
      },
      {
        panelId: 827,
        tKey: 'pages.investigationData.panels.preChargeDCLinkFailedFLGINVHEX2B1010',
      },
      {
        panelId: 826,
        tKey: 'pages.investigationData.panels.faultFeedbackFromGeneratorINVContactorFLGINVHEX2B99',
      },
      {
        panelId: 825,
        tKey: 'pages.investigationData.panels.faultForMaxDCLinkBusVoltageSoftwareVbusSwFLGINVHEX2B88',
      },
      {
        panelId: 824,
        tKey: 'pages.investigationData.panels.faultForMaxGeneratorVoltageSoftwareVinvISwFLGINVHEX1B77',
      },
      {
        panelId: 822,
        tKey: 'pages.investigationData.panels.faultForMaxGeneratorCurrentSoftwareLinvISwFLGINVHEX1B66',
      },
      {
        panelId: 821,
        tKey: 'pages.investigationData.panels.driverINVFaultHwFLGINVHEX1B44',
      },
      {
        panelId: 820,
        tKey: 'pages.investigationData.panels.faultPerMaxVbusHwFLGINVHEX0B33',
      },
      {
        panelId: 819,
        tKey: 'pages.investigationData.panels.faultPerMaxLinvIHwFLGINVHEX0B22',
      },
    ],
    [InvestigationDataCategory.TotalEnergyDeliveredToGrid]: [
      {
        panelId: 116,
        tKey: 'pages.investigationData.panels.dailyTotalEnergy',
      },
      {
        panelId: 117,
        tKey: 'pages.investigationData.panels.monthlyTotalEnergy',
      },
      {
        panelId: 113,
        tKey: 'pages.investigationData.panels.annualTotalEnergy',
      },
      { panelId: 114, tKey: 'pages.investigationData.panels.totalEnergy' },
    ],
    [InvestigationDataCategory.StateMachineInverter]: [
      {
        panelId: 123,
        tKey: 'pages.investigationData.panels.stateMachineInverterStMach',
      },
    ],
    [InvestigationDataCategory.StateMachineErrorManagement]: [
      {
        panelId: 124,
        tKey: 'pages.investigationData.panels.stateMachineErrorManagementStError',
      },
    ],
    [InvestigationDataCategory.WindSpeedMS10]: [
      {
        panelId: 125,
        tKey: 'pages.investigationData.panels.instantaneousWindSpeed',
      },
      {
        panelId: 126,
        tKey: 'pages.investigationData.panels.dailyAverageWindSpeed',
      },
      {
        panelId: 127,
        tKey: 'pages.investigationData.panels.monthlyAverageWindSpeed',
      },
      {
        panelId: 128,
        tKey: 'pages.investigationData.panels.annualAverageWindSpeed',
      },
    ],
    [InvestigationDataCategory.DogvaneDirection]: [
      {
        panelId: 129,
        tKey: 'pages.investigationData.panels.dogvaneDirection',
      },
    ],
    [InvestigationDataCategory.AdditionalStatusFaultFlagFromGeneratorsInverter]:
      [
        {
          panelId: 840,
          tKey: 'pages.investigationData.panels.generatorStartFailedFLG2INVHEX0B032',
        },

        {
          panelId: 842,
          tKey: 'pages.investigationData.panels.gridSwitchTrippedFLG2AFEHEX0B335',
        },
        {
          panelId: 844,
          tKey: 'pages.investigationData.panels.maxNumberOfConsecutiveLVFRTFLG2AFEHEX1B638',
        },
        {
          panelId: 903,
          tKey: 'pages.investigationData.panels.lVFAnalysisInProgressStError100FLG2AFEHEX1B537',
        },
        {
          panelId: 841,
          tKey: 'pages.investigationData.panels.faultAntiIslandingDetectionInterventionFLG2AFEHEX2B941',
        },
        {
          panelId: 846,
          tKey: 'pages.investigationData.panels.limitationActivePowerFromOuterSetPointFLG2AFEHEX0B133',
        },
        {
          panelId: 847,
          tKey: 'pages.investigationData.panels.limitationActivePowerForFrequencyFLG2AFEHEX0B032',
        },
        {
          panelId: 845,
          tKey: 'pages.investigationData.panels.returnSignalStarTriangleUD1FLG2AFEHEX1B436',
        },
        {
          panelId: 848,
          tKey: 'pages.investigationData.panels.emulatedSPILimit5951FLG2AFEHEX0B234',
        },
      ],
  },
  [SelectParameter.JANITZA]: {
    [InvestigationDataCategory.Voltage]: [
      { panelId: 68, tKey: 'pages.investigationData.panels.voltageL1N' },
      { panelId: 69, tKey: 'pages.investigationData.panels.voltageL2N' },
      { panelId: 70, tKey: 'pages.investigationData.panels.voltageL3N' },
      { panelId: 71, tKey: 'pages.investigationData.panels.voltageL1L2' },
      { panelId: 72, tKey: 'pages.investigationData.panels.voltageL2L3' },
      { panelId: 73, tKey: 'pages.investigationData.panels.voltageL1L3' },
    ],
    [InvestigationDataCategory.CurrentI]: [
      { panelId: 74, tKey: 'pages.investigationData.panels.currentL1' },
      { panelId: 75, tKey: 'pages.investigationData.panels.currentL2' },
      { panelId: 76, tKey: 'pages.investigationData.panels.currentL3' },
    ],
    [InvestigationDataCategory.CurrentN]: [
      { panelId: 77, tKey: 'pages.investigationData.panels.currentN' },
    ],
    [InvestigationDataCategory.RealPower]: [
      {
        panelId: 138,
        tKey: 'pages.investigationData.panels.activePhasePowerP1',
      },
      {
        panelId: 135,
        tKey: 'pages.investigationData.panels.activePhasePowerP2',
      },
      {
        panelId: 137,
        tKey: 'pages.investigationData.panels.activePhasePowerP3',
      },
    ],
    [InvestigationDataCategory.ActivePower]: [
      { panelId: 63, tKey: 'pages.investigationData.panels.activePower' },
    ],
    [InvestigationDataCategory.ApparentPower]: [
      {
        panelId: 915,
        tKey: 'pages.investigationData.panels.totalPhasePowerS1',
      },
      {
        panelId: 916,
        tKey: 'pages.investigationData.panels.totalPhasePowerS2',
      },
      {
        panelId: 917,
        tKey: 'pages.investigationData.panels.totalPhasePowerS3',
      },
    ],
    [InvestigationDataCategory.TotalPower]: [
      { panelId: 65, tKey: 'pages.investigationData.panels.totalPower' },
    ],
    [InvestigationDataCategory.FundReactivePower]: [
      {
        panelId: 141,
        tKey: 'pages.investigationData.panels.reactivePhasePowerQ1',
      },
      {
        panelId: 142,
        tKey: 'pages.investigationData.panels.reactivePhasePowerQ2',
      },
      {
        panelId: 143,
        tKey: 'pages.investigationData.panels.reactivePhasePowerQ3',
      },
    ],
    [InvestigationDataCategory.ReactivePower]: [
      { panelId: 64, tKey: 'pages.investigationData.panels.reactivePower' },
    ],
    [InvestigationDataCategory.CosPhi]: [
      { panelId: 144, tKey: 'pages.investigationData.panels.cosPhiL1' },
      { panelId: 145, tKey: 'pages.investigationData.panels.cosPhiL2' },
      { panelId: 146, tKey: 'pages.investigationData.panels.cosPhiL3' },
    ],
    [InvestigationDataCategory.MeasuredFrequency]: [
      { panelId: 67, tKey: 'pages.investigationData.panels.frequency' },
    ],
    [InvestigationDataCategory.RotationField]: [
      { panelId: 147, tKey: 'pages.investigationData.panels.rotationField' },
    ],
    [InvestigationDataCategory.RealEnergy]: [
      {
        panelId: 148,
        tKey: 'pages.investigationData.panels.totalPhaseActiveEnergyL1',
      },
      {
        panelId: 149,
        tKey: 'pages.investigationData.panels.totalPhaseActiveEnergyL2',
      },
      {
        panelId: 150,
        tKey: 'pages.investigationData.panels.totalPhaseActiveEnergyL3',
      },
      {
        panelId: 79,
        tKey: 'pages.investigationData.panels.totalActiveEnergy',
      },
      {
        panelId: 154,
        tKey: 'pages.investigationData.panels.totalPhaseActiveEnergyConsumedL1',
      },
      {
        panelId: 151,
        tKey: 'pages.investigationData.panels.totalPhaseActiveEnergyConsumedL2',
      },
      {
        panelId: 153,
        tKey: 'pages.investigationData.panels.totalPhaseActiveEnergyConsumedL3',
      },
      {
        panelId: 80,
        tKey: 'pages.investigationData.panels.totalActiveEnergyConsumed',
      },
      {
        panelId: 157,
        tKey: 'pages.investigationData.panels.totalPhaseActiveEnergyDeliveredL1',
      },
      {
        panelId: 152,
        tKey: 'pages.investigationData.panels.totalPhaseActiveEnergyDeliveredL2',
      },
      {
        panelId: 918,
        tKey: 'pages.investigationData.panels.totalPhaseActiveEnergyDeliveredL3',
      },
      {
        panelId: 81,
        tKey: 'pages.investigationData.panels.totalActiveEnergyDelivered',
      },
    ],
    [InvestigationDataCategory.ApparentEnergy]: [
      {
        panelId: 155,
        tKey: 'pages.investigationData.panels.totalPhaseApparentEnergyL1',
      },
      {
        panelId: 904,
        tKey: 'pages.investigationData.panels.totalPhaseApparentEnergyL2',
      },
      {
        panelId: 159,
        tKey: 'pages.investigationData.panels.totalPhaseApparentEnergyL3',
      },
      {
        panelId: 82,
        tKey: 'pages.investigationData.panels.totalApparentEnergy',
      },
    ],
    [InvestigationDataCategory.ReactiveEnergy]: [
      {
        panelId: 160,
        tKey: 'pages.investigationData.panels.totalPhaseReactiveEnergyL1',
      },
      {
        panelId: 905,
        tKey: 'pages.investigationData.panels.totalPhaseReactiveEnergyL2',
      },
      {
        panelId: 906,
        tKey: 'pages.investigationData.panels.totalPhaseReactiveEnergyL3',
      },
      {
        panelId: 83,
        tKey: 'pages.investigationData.panels.totalReactiveEnergy',
      },
      {
        panelId: 163,
        tKey: 'pages.investigationData.panels.totalPhaseReactiveEnergyIndL1',
      },
      {
        panelId: 907,
        tKey: 'pages.investigationData.panels.totalPhaseReactiveEnergyIndL2',
      },
      {
        panelId: 908,
        tKey: 'pages.investigationData.panels.totalPhaseReactiveEnergyIndL3',
      },
      {
        panelId: 84,
        tKey: 'pages.investigationData.panels.totalReactiveEnergyInd',
      },
      {
        panelId: 167,
        tKey: 'pages.investigationData.panels.totalReactiveEnergyCapL1',
      },
      {
        panelId: 909,
        tKey: 'pages.investigationData.panels.totalReactiveEnergyCapL2',
      },
      {
        panelId: 910,
        tKey: 'pages.investigationData.panels.totalReactiveEnergyCapL3',
      },
      {
        panelId: 85,
        tKey: 'pages.investigationData.panels.totalReactiveEnergyCap',
      },
    ],
    [InvestigationDataCategory.Harmonic]: [
      { panelId: 171, tKey: 'pages.investigationData.panels.harmonicTHDUL1N' },
      { panelId: 911, tKey: 'pages.investigationData.panels.harmonicTHDUL2N' },
      { panelId: 912, tKey: 'pages.investigationData.panels.harmonicTHDUL3N' },
      { panelId: 174, tKey: 'pages.investigationData.panels.harmonicTHDIL1' },
      { panelId: 913, tKey: 'pages.investigationData.panels.harmonicTHDIL2' },
      { panelId: 914, tKey: 'pages.investigationData.panels.harmonicTHDIL3' },
    ],
  },
  [SelectParameter.TEMPERATURE]: [
    {
      panelId: 87,
      tKey: 'pages.investigationData.panels.temperatureGenPhaseA',
    },
    {
      panelId: 88,
      tKey: 'pages.investigationData.panels.temperatureGenPhaseB',
    },
    {
      panelId: 89,
      tKey: 'pages.investigationData.panels.temperatureGenPhaseC',
    },
    { panelId: 90, tKey: 'pages.investigationData.panels.temperaturePLCBox' },
    {
      panelId: 91,
      tKey: 'pages.investigationData.panels.temperatureDCResistor',
    },
    {
      panelId: 92,
      tKey: 'pages.investigationData.panels.temperatureBrakeResistor',
    },
    {
      panelId: 93,
      tKey: 'pages.investigationData.panels.temperatureUZBoxBack',
    },
    {
      panelId: 94,
      tKey: 'pages.investigationData.panels.temperatureUZBoxFront',
    },
  ],
  [SelectParameter.MECHANICAL]: [
    { panelId: 12, tKey: 'pages.investigationData.panels.windSpeed' },
    { panelId: 121, tKey: 'pages.investigationData.panels.generatorSpeed' },
  ],
};
