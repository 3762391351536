import { yupResolver } from '@hookform/resolvers/yup';
import { Typography } from '@mui/material';
import axios from 'axios';
import { FC, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-router-dom';
import { toast } from 'react-toastify';

import { defaultValues } from './constants';
import { FormValues } from './types';
import validationSchema from './validationSchema';
import CommonButton from 'components/common/CommonButton';
import TextField from 'components/common/form/TextField';
import { NotificationPopupVariants } from 'components/common/NotificationPopup/types';
import { Popup } from 'components/common/Popup';
import useApi from 'contexts/api';
import useResponsePopup from 'contexts/responsePopup';
import useWindTurbine from 'contexts/WindTurbine';

interface Props {
  refetch: () => Promise<void>;
  onClose: () => void;
}

export const AddVideoStreamPopup: FC<Props> = ({ refetch, onClose }) => {
  const { t } = useTranslation('');
  const { videoStreamControllerApi } = useApi();
  const { openPopup, closePopup } = useResponsePopup();

  const { windTurbine } = useWindTurbine();

  const form = useForm<FormValues>({
    resolver: yupResolver(validationSchema(t)),
    defaultValues,
  });

  const {
    handleSubmit,
    register,
    formState: { isDirty, isSubmitSuccessful },
  } = form;

  const onSubmit = handleSubmit(async ({ cameraViewName, link }) => {
    if (!windTurbine?.publicId) return;

    try {
      await videoStreamControllerApi.createVideoStream({
        videoStreamCreateDto: {
          assetPublicId: windTurbine.publicId,
          cameraViewName,
          link,
        },
      });

      await refetch();

      openPopup({
        variant: NotificationPopupVariants.Success,
        title: t('pages.turbine.tabs.streaming.addStreamForm.successTitle'),
        primaryButton: {
          text: t('buttons.continueSession'),
          onClick: closePopup,
        },
      });
    } catch (e) {
      if (axios.isAxiosError(e) && e.response?.data.message) {
        toast.error(e.response.data.message);
      }
    }
  });

  useEffect(() => {
    if (!isSubmitSuccessful) return;

    onClose();
  }, [isSubmitSuccessful, onClose]);

  return (
    <Popup isOpen onClose={onClose}>
      <FormProvider {...form}>
        <Form style={{ height: '100%' }} onSubmit={onSubmit}>
          <Popup.InnerWrapper>
            <Popup.Content>
              <Typography variant="h2" textAlign="left" sx={{ mb: 4 }}>
                {t('pages.turbine.tabs.streaming.addStreamForm.title')}
              </Typography>
              <Typography variant="bodyM" textAlign="left" display="block">
                {t('pages.turbine.tabs.streaming.addStreamForm.subtitle')}
              </Typography>
              <TextField
                label={t('pages.turbine.tabs.streaming.addStreamForm.urlLabel')}
                fullWidth
                testId="link"
                {...register('link')}
                sx={{ mt: 3 }}
              />
              <TextField
                label={t(
                  'pages.turbine.tabs.streaming.addStreamForm.cameraNameLabel',
                )}
                fullWidth
                testId="cameraViewName"
                {...register('cameraViewName')}
                sx={{ mt: 3 }}
              />
            </Popup.Content>
            <Popup.Buttons>
              <CommonButton
                disabled={!isDirty}
                type="submit"
                sx={{ width: { mobile: '226px', tablet: 'auto' } }}
              >
                {t('buttons.submit')}
              </CommonButton>
              <CommonButton variant="outlined" onClick={onClose}>
                {t('buttons.cancel')}
              </CommonButton>
            </Popup.Buttons>
          </Popup.InnerWrapper>
        </Form>
      </FormProvider>
    </Popup>
  );
};
