import { FC } from 'react';

import { CommonSvgProps } from 'types/general';

export const CheckMark: FC<CommonSvgProps> = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6 12L10.2426 16.2427L18.7279 7.75739"
      stroke="#D4D7DC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
