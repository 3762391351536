import { styled } from '@mui/material/styles';

import { Sorting } from 'components/icons';

const ColumnUnsortedIcon = styled('span')(({ theme }) => ({
  '--first-stroke': theme.palette.grey['500'],
  '--second-stroke': theme.palette.grey['500'],
}));

const ColumnSortedAscendingIcon = styled('span')(({ theme }) => ({
  '--first-stroke': 'black',
  '--second-stroke': theme.palette.grey['500'],
}));

const ColumnSortedDescendingIcon = styled('span')(({ theme }) => ({
  '--first-stroke': theme.palette.grey['500'],
  '--second-stroke': 'black',
}));

export const ColumnUnsortedIconComponent = () => (
  <ColumnUnsortedIcon>
    <Sorting />
  </ColumnUnsortedIcon>
);
export const ColumnSortedAscendingIconComponent = () => (
  <ColumnSortedAscendingIcon>
    <Sorting />
  </ColumnSortedAscendingIcon>
);

export const ColumnSortedDescendingIconComponent = () => (
  <ColumnSortedDescendingIcon>
    <Sorting />
  </ColumnSortedDescendingIcon>
);
