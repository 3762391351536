import { styled } from '@mui/material';

import DateRangePicker from 'components/common/DatePicker/DateRangePicker';

const DateRangePickerStyled = styled(DateRangePicker)(({ theme }) => ({
  [theme.breakpoints.down('tablet')]: {
    flexDirection: 'column',
    gap: theme.spacing(3),
    '& > .MuiTypography-root': {
      display: 'none',
    },
  },
}));

export default DateRangePickerStyled;
