import { styled } from '@mui/material';

const SortingItem = styled('li')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  padding: theme.spacing(1.5, 2.5),
  cursor: 'pointer',

  '&:hover': {
    backgroundColor: theme.palette.grey[50],
  },

  '& > .MuiRadio-root': {
    padding: 0,
  },

  [theme.breakpoints.down('desktop')]: {
    margin: theme.spacing(0, -2.5),
  },
}));

export { SortingItem };
