import { useContext } from 'react';

import { ReportContext } from './ReportContext';
import ReportProvider from './ReportProvider';

const useReport = () => {
  const context = useContext(ReportContext);

  if (typeof context === 'undefined' || context === null) {
    throw new Error('useReport must be used within a ReportProvider');
  }

  return context;
};

export default useReport;
export { ReportProvider };
