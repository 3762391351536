import { styled } from '@mui/material';

import { UserDetailsDtoStatusEnum } from 'openapi-api/admin-service';

export const UserStatusChip = styled('div', {
  shouldForwardProp: (propName) => propName !== 'status',
})<{ status?: UserDetailsDtoStatusEnum }>(({ theme, status }) => ({
  padding: theme.spacing(0.75, 2),
  borderRadius: '16px',
  backgroundColor:
    status === UserDetailsDtoStatusEnum.ACTIVE
      ? theme.palette.green[200]
      : theme.palette.grey[50],
}));
