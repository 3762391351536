import { styled } from '@mui/material';

export const GridItem = styled('div')(({ theme }) => ({
  display: 'grid',
  gap: theme.spacing(4),

  [theme.breakpoints.down('tablet')]: {
    gap: theme.spacing(3),
  },
}));
