import { styled } from '@mui/material/styles';

export const HeaderWrapper = styled('header')<{ showBgImage: boolean }>(
  ({ theme, showBgImage }) => ({
    zIndex: 5, // Should not limit the Notifications block zIndex [mobile, WTs list]
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 7.5),
    backgroundColor: showBgImage ? 'transparent' : theme.palette.black['500'],
    height: '100%',
    [theme.breakpoints.down('desktop')]: {
      padding: theme.spacing(0, 2.5),
    },
  }),
);
