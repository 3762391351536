import { ChangeEvent, createContext } from 'react';

import { WindTurbineDto } from 'openapi-api/admin-service';

export interface IWindTurbineContext {
  windTurbine?: WindTurbineDto;
  refetch: () => Promise<void>;
  handleTurbineActivityChange: (
    _: ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => void;
  handleTurbineAccelerationChange: (
    _: ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => void;
  isLoading?: boolean;
  showDeleteButton: boolean;
}

export const WindTurbineContext = createContext<IWindTurbineContext>(null!);
