import { styled } from '@mui/material';

export const AssetItem = styled('div', {
  shouldForwardProp: (propName) => propName !== 'variant',
})<{ variant: 'header' | 'white' | 'grey' }>(({ theme, variant }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(2, 1.5),
  borderRadius: '2px',
  ...(variant === 'grey' && {
    backgroundColor: theme.palette.grey[50],
  }),
}));
