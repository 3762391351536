import { styled } from '@mui/material';

export const ReportPeriodChip = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'flex-start',
  columnGap: theme.spacing(2.5),
  rowGap: theme.spacing(0.5),
  padding: theme.spacing(1.5, 3),
  borderRadius: '24px',
  backgroundColor: theme.palette.grey[50],

  [theme.breakpoints.down('desktop')]: {
    '& .MuiTypography-root': {
      fontSize: '14px',
    },
  },
}));
