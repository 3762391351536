import { styled } from '@mui/material';

const CounterBlock = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(0.5),

  '& > svg': {
    height: '24px',
    width: '24px',
    '& > path': {
      fill: theme.palette.warning.main,
    },
  },

  '& > .MuiTypography-root': {
    color: theme.palette.warning.main,
  },
}));

export default CounterBlock;
