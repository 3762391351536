import Typography, { TypographyProps } from '@mui/material/Typography';
import { ReactNode } from 'react';

export const renderCell = <T extends ReactNode>(
  value: T,
  variant: TypographyProps['variant'] = 'bodyS',
) => (
  <Typography variant={variant} color="rgba(18, 18, 23, 1)">
    {value}
  </Typography>
);
