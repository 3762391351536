import { FC } from 'react';

import { CommonSvgProps } from 'types/general';

export const Wind: FC<CommonSvgProps> = ({ className }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M17.7 7.69913C17.9927 7.41729 18.3497 7.21104 18.7401 7.09825C19.1304 6.98545 19.5424 6.96951 19.9403 7.05181C20.3383 7.1341 20.7101 7.31214 21.0237 7.57052C21.3373 7.8289 21.5832 8.15983 21.7401 8.53465C21.897 8.90948 21.9602 9.3169 21.9242 9.72164C21.8882 10.1264 21.754 10.5162 21.5334 10.8575C21.3128 11.1987 21.0123 11.481 20.658 11.6799C20.3037 11.8788 19.9062 11.9884 19.5 11.9991H2"
      stroke="#A7ABB2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.6 4.5998C9.83168 4.36351 10.1184 4.18839 10.4344 4.09016C10.7504 3.99192 11.0858 3.97363 11.4106 4.03693C11.7355 4.10023 12.0395 4.24314 12.2955 4.45284C12.5515 4.66255 12.7514 4.9325 12.8774 5.2385C13.0034 5.5445 13.0515 5.87698 13.0174 6.20614C12.9833 6.53531 12.8681 6.85086 12.682 7.12452C12.4959 7.39818 12.2449 7.6214 11.9513 7.77416C11.6578 7.92692 11.3309 8.00445 11 7.9998H2"
      stroke="#A7ABB2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.6 19.4002C12.8317 19.6365 13.1184 19.8116 13.4344 19.9098C13.7504 20.0081 14.0858 20.0264 14.4106 19.9631C14.7355 19.8998 15.0395 19.7569 15.2955 19.5472C15.5515 19.3375 15.7514 19.0675 15.8774 18.7615C16.0034 18.4555 16.0515 18.123 16.0174 17.7939C15.9833 17.4647 15.8681 17.1491 15.682 16.8755C15.4959 16.6018 15.2449 16.3786 14.9513 16.2258C14.6578 16.0731 14.3309 15.9955 14 16.0002H2"
      stroke="#A7ABB2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
