import { styled } from '@mui/material';

export const InfoBlockGrid = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '160px auto',
  gap: theme.spacing(2, 3),

  [theme.breakpoints.down('desktop')]: {
    gridTemplateColumns: '1fr',
    gap: theme.spacing(1),
  },
}));
