import { styled } from '@mui/material/styles';

const FormContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.white.main,
  maxWidth: '531px',
  borderRadius: theme.spacing(1.5),
  padding: theme.spacing(8, 6),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  [theme.breakpoints.down('desktop')]: {
    borderRadius: 0,
    padding: theme.spacing(4, 2.5),
    height: '100%',
    maxWidth: 'unset',
  },
  [theme.breakpoints.down('tablet')]: {
    borderRadius: theme.spacing(1.5, 1.5, 0, 0),
    padding: theme.spacing(4, 2.5, 5, 2.5),
    height: '100%',
  },
}));

export default FormContainer;
