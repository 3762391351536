import { DialogProps, useMediaQuery, useTheme } from '@mui/material';
import { FC, MouseEventHandler } from 'react';

import CloseButton from './styles/CloseButton';
import DialogWrapper from './styles/DialogWrapper';
import { Close } from 'components/icons';

export type CommonDialogProps = {
  yPadding?: number;
  width?: number;
  testId?: number;
  onClose?: MouseEventHandler<HTMLDivElement>;
} & Omit<DialogProps, 'onClose'>;

const CommonDialog: FC<CommonDialogProps> = ({ testId, ...props }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('desktop'));

  return (
    <DialogWrapper
      maxWidth={false}
      fullScreen={fullScreen}
      {...props}
      data-testid={testId}
    >
      {props.onClose && (
        <CloseButton onClick={(e) => props.onClose?.(e)}>
          <Close />
        </CloseButton>
      )}
      {props.children}
    </DialogWrapper>
  );
};
export default CommonDialog;
