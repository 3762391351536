import { styled } from '@mui/material';

export const WeatherWrapper = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: `repeat(2, calc(50% - ${theme.spacing(2)}))`,
  gridTemplateRows: 'minmax(330px, auto)',
  gap: theme.spacing(4),

  [theme.breakpoints.down('desktop')]: {
    gridTemplateRows: '377px',
    gap: theme.spacing(1.5),
  },
}));
