import { Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

import { UserStatusChip } from '../../styles';
import CommonButton from 'components/common/CommonButton';
import { renderCell } from 'components/common/Table/CommonTable/helpers';
import routePaths from 'constants/routePaths';
import {
  UserDetailsDto,
  UserDetailsDtoStatusEnum,
} from 'openapi-api/admin-service';
import { TranslationFunction } from 'types/general';

const formatToReadableDatetime = (value: string | null) => {
  if (!value) return '-';

  return dayjs(value).isValid() ? dayjs(value).format('DD/MM/YYYY') : '-';
};

type GenerateColumnsPropsType = {
  t: TranslationFunction;
};

export const generateColumns = ({
  t,
}: GenerateColumnsPropsType): GridColDef<UserDetailsDto>[] => [
  {
    field: 'username',
    headerName: t('pages.users.adminUsers.table.username'),
    flex: 1,
    minWidth: 250,
    disableColumnMenu: true,
    renderCell: ({ value }) => <Typography variant="h5">{value}</Typography>,
  },
  {
    field: 'rolePermission',
    headerName: t('pages.users.adminUsers.table.role'),
    minWidth: 152,
    disableColumnMenu: true,
    sortable: false,
    renderCell: ({ value }) => {
      return (
        <Typography variant="bodyM" textTransform="capitalize">
          {t(`userRoles.${value}`)}
        </Typography>
      );
    },
  },
  {
    field: 'status',
    headerName: t('pages.users.adminUsers.table.status'),
    minWidth: 120,
    sortable: false,
    disableColumnMenu: true,
    renderCell: ({ value }) => (
      <UserStatusChip status={value}>
        <Typography variant="subheading">
          {value === UserDetailsDtoStatusEnum.ACTIVE
            ? t('pages.users.adminUsers.statuses.active')
            : t('pages.users.adminUsers.statuses.inactive')}
        </Typography>
      </UserStatusChip>
    ),
  },
  {
    field: 'email',
    headerName: t('pages.users.adminUsers.table.email'),
    flex: 1,
    minWidth: 250,
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: 'createDatetime',
    headerName: t('pages.users.adminUsers.table.added'),
    minWidth: 148,
    disableColumnMenu: true,
    renderCell: ({ value }) => renderCell(formatToReadableDatetime(value)),
  },
  {
    field: 'lastSignInDatetime',
    headerName: t('pages.users.adminUsers.table.lastSignIn'),
    minWidth: 148,
    disableColumnMenu: true,
    renderCell: ({ value }) => renderCell(formatToReadableDatetime(value)),
  },
  {
    field: 'actions',
    headerName: '',
    minWidth: 191,
    sortable: false,
    disableColumnMenu: true,
    renderCell: ({ row }) => {
      const deleted = !!row.accessExpirationMs;

      if (deleted) {
        // TODO: add restore button with timer label (FS-12.4)
        return (
          <>
            <CommonButton variant="outlined" size="small">
              {'Restore'}
            </CommonButton>
          </>
        );
      }

      return (
        typeof row.userId !== 'undefined' && (
          <Link to={routePaths.user.profile(row.userId)}>
            <CommonButton variant="outlined" size="small">
              {t('pages.users.adminUsers.profileInfoButtonText')}
            </CommonButton>
          </Link>
        )
      );
    },
  },
];
