import React, { FC, PropsWithChildren } from 'react';

import { Compose } from './Compose';
import { NotificationsProvider } from './notifications';

const providers = [NotificationsProvider];

const ProtectedRoutesProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  return <Compose components={providers}>{children}</Compose>;
};

export default ProtectedRoutesProvider;
