import { createContext } from 'react';

import { GrafanaPanelParametersDto } from 'openapi-api/admin-service';

export interface IWindTurbinePanelsContext {
  panels?: { [key: string]: GrafanaPanelParametersDto };
  isLoading?: boolean;
}

export const WindTurbinePanelsContext =
  createContext<IWindTurbinePanelsContext>(null!);
