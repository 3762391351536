import { styled } from '@mui/material';

export const InfoBlockWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(3, 4, 3, 4),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  backgroundColor: theme.palette.grey[50],
  borderRadius: '20px',

  [theme.breakpoints.down('desktop')]: {
    padding: theme.spacing(3),
  },
}));
