import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import {
  LinkIcon,
  MissingDataNoticeItem,
  MissingDataNoticeList,
} from './styles';
import {
  InformerBlock,
  InformerBlockVariants,
} from 'components/common/InformerBlock';
import routePaths from 'constants/routePaths';
import useReport from 'contexts/report';
import { ReportMissingDataIntervalDto } from 'openapi-api/report-service';
import { getStringFromISODate } from 'utils/functions/getStringFromISODate';

interface Props {
  missingDataIntervals: ReportMissingDataIntervalDto[];
}

export const MissingDataNotice: FC<Props> = ({ missingDataIntervals }) => {
  const { t } = useTranslation();
  const { reportValues } = useReport();

  if (!reportValues) return null;

  return (
    <InformerBlock
      title={t('pages.reports.content.missingDataNotice.title')}
      variant={InformerBlockVariants.Warning}
    >
      <MissingDataNoticeList>
        {missingDataIntervals.map((missingDataInterval) => {
          const assetPublicId = missingDataInterval.publicId;
          const asset = reportValues.assets.find(
            (a) => a.publicId === assetPublicId,
          );

          return (
            <li key={`missing-data-interval-${assetPublicId}`}>
              <MissingDataNoticeItem>
                <Typography variant="bodyS">
                  <Trans
                    i18nKey="pages.reports.content.missingDataNotice.item"
                    values={{
                      assetName: asset?.turbineName,
                      assetPublicId,
                      startDatetime: getStringFromISODate(
                        missingDataInterval.startMissingDataDatetime,
                      ),
                      endDatetime: getStringFromISODate(
                        missingDataInterval.endMissingDataDatetime,
                      ),
                    }}
                    components={[
                      <Typography
                        key={`missing-data-notice-text-${assetPublicId}`}
                        variant="bodySStrong"
                      />,
                    ]}
                  />
                </Typography>
                <Box
                  display="flex"
                  alignItems="center"
                  gap={0.75}
                  component="a"
                  href={routePaths.investigation.prefilled(
                    reportValues.customer?.name as string,
                    asset?.locationName as string,
                    asset?.turbineName as string,
                    missingDataInterval.startMissingDataDatetime as string,
                    missingDataInterval.endMissingDataDatetime as string,
                  )}
                  target="_blank"
                >
                  <LinkIcon />
                  <Typography variant="buttonS" color="black.600">
                    {t('pages.reports.content.missingDataNotice.investigate')}
                  </Typography>
                </Box>
              </MissingDataNoticeItem>
            </li>
          );
        })}
      </MissingDataNoticeList>
    </InformerBlock>
  );
};
