import { styled } from '@mui/material';

export const MissingDataNoticeList = styled('ul')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  margin: theme.spacing(0, 0, 0, 4.5),
  paddingInlineStart: theme.spacing(3.5),

  [theme.breakpoints.down('desktop')]: {
    marginLeft: 0,
  },
}));
