import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

const ReportItemWrapper = styled(Grid)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[100]}`,
  borderRadius: theme.spacing(2.5),
  padding: theme.spacing(3),
  display: 'flex',
  gap: theme.spacing(5),
  alignItems: 'center',
  [theme.breakpoints.down('desktop')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: theme.spacing(2.5),
  },
}));

export default ReportItemWrapper;
