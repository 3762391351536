import { styled } from '@mui/material';

import { Popup } from 'components/common/Popup';

export const PopupStyled = styled(Popup)(({ theme }) => ({
  '& .MuiDialog-paper': {
    padding: theme.spacing(7, 2.5, 5),

    [theme.breakpoints.down('tablet')]: {
      padding: theme.spacing(7.5, 2.5, 5),
    },
  },
}));
