import { Dialog, styled } from '@mui/material';

const DialogStyled = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-container': {
    position: 'relative',
  },

  '& .MuiPaper-root': {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    maxHeight: 'unset',
    height: 'auto',
    maxWidth: '375px',
    width: '100%',
    margin: 0,
    padding: theme.spacing(0, 2.5, 6),
    borderRadius: 0,
    boxShadow: 'none',
  },

  [theme.breakpoints.down('tablet')]: {
    '& .MuiPaper-root': {
      top: 'unset',
      left: 0,
      maxWidth: 'unset',
      borderRadius: '16px 16px 0 0',
      padding: theme.spacing(0, 2.5, 5),
    },
  },
}));

export default DialogStyled;
