import { styled } from '@mui/material';
import { Link } from 'react-router-dom';

export const LogoLink = styled(Link)(({ theme }) => ({
  display: 'flex',

  '& > svg': {
    height: '48px',
    width: '48px',
  },

  [theme.breakpoints.down('desktop')]: {
    '& > svg': {
      height: '32px',
      width: '32px',
    },
  },
}));
