import { styled } from '@mui/material';

export const GridTable = styled('div')(({ theme }) => ({
  display: 'grid',
  gap: theme.spacing(2),
  [theme.breakpoints.only('mobile')]: {
    gridTemplateColumns: 'minmax(0, 1fr)',
  },
  [theme.breakpoints.only('tablet')]: {
    gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
  },
  [theme.breakpoints.only('desktop')]: {
    gridTemplateColumns: 'repeat(5, minmax(0, 1fr))',
  },
}));
