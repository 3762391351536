import { Chip, alpha, styled } from '@mui/material';

export const MultipleSelectChip = styled(Chip)(({ theme }) => ({
  height: '26px',
  padding: theme.spacing(0, 1.5),
  borderRadius: '20px',
  ...theme.typography.bodyS,
  backgroundColor: alpha(theme.palette.grey[900], 0.05),
  border: 'none',
  boxShadow: `0 0 0 1px inset ${theme.palette.grey[600]}`,

  '& .MuiChip-label': {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    padding: 0,
    color:
      theme.palette.mode === 'light'
        ? theme.palette.black[600]
        : theme.palette.white.main,
    textTransform: 'none',
  },
}));
