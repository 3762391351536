import { Box } from '@mui/material';
import { FC, useCallback, useMemo } from 'react';

import { FilterProps } from '../types';
import { Select } from 'components/common/Select';

export const SelectFilter: FC<FilterProps> = ({
  values,
  field,
  label,
  localValues,
  setLocalValues,
}) => {
  const onItemClick = useCallback(
    (value: string) => {
      setLocalValues((old) => ({
        ...old,
        [field]: {
          value,
        },
      }));
    },
    [setLocalValues, field],
  );

  const items = useMemo(
    () =>
      values.map(({ label: itemLabel, value: itemValue }) => ({
        label: itemLabel || 'undefined',
        value: itemValue || 'undefined',
      })),
    [values],
  );

  return (
    <Box px={2.5}>
      <Select
        name={field}
        label={label}
        items={items}
        onItemClick={onItemClick}
        value={localValues[field].value || ''}
        fullWidth
      />
    </Box>
  );
};
