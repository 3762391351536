import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useMemo,
  useState,
} from 'react';

import { IReportContext, IReportValues, ReportContext } from './ReportContext';
import useApi from 'contexts/api';
import { useAsyncResource } from 'utils/hooks/useAsyncResource';

const ReportProvider: FC<PropsWithChildren> = ({ children }) => {
  const { assetControllerApi, reportRequestControllerApi } = useApi();
  const [reportValues, setReportValues] = useState<IReportValues>();

  const assetPublicIds = useMemo(
    (): string[] =>
      reportValues?.assets
        .map((asset) => asset.publicId)
        .filter((publicAssetId): publicAssetId is string => !!publicAssetId) ||
      [],
    [reportValues?.assets],
  );

  const getReportPanels = useCallback(async () => {
    if (!reportValues) return undefined;

    try {
      return (
        await assetControllerApi.getAdminReportGrafanaPanels({
          requestBody: assetPublicIds,
        })
      ).data;
    } catch {}
  }, [reportValues, assetPublicIds, assetControllerApi]);

  const { resource: reportPanels } = useAsyncResource({
    defaultValue: undefined,
    fetchResource: getReportPanels,
  });

  const getReportMissingDataIntervals = useCallback(async () => {
    if (!reportValues?.period.from && !reportValues?.period.to) {
      return [];
    }

    try {
      return (
        await reportRequestControllerApi.getReportMissingDataIntervals({
          reportMissingDataCheckDto: {
            assetPublicIds,
            startDatetime: reportValues?.period.from,
            endDatetime: reportValues?.period.to,
          },
        })
      ).data;
    } catch {
      return [];
    }
  }, [reportValues, assetPublicIds, reportRequestControllerApi]);

  const { resource: reportMissingDataIntervals } = useAsyncResource({
    defaultValue: [],
    fetchResource: getReportMissingDataIntervals,
  });

  const contextValue = useMemo(
    (): IReportContext => ({
      reportValues,
      setReportValues,
      reportPanels,
      reportMissingDataIntervals,
      assetPublicIds,
    }),
    [
      reportValues,
      setReportValues,
      reportPanels,
      reportMissingDataIntervals,
      assetPublicIds,
    ],
  );

  return (
    <ReportContext.Provider value={contextValue}>
      {children}
    </ReportContext.Provider>
  );
};

export default ReportProvider;
