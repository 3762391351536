import { styled } from '@mui/material';

const Wrapper = styled('div', {
  shouldForwardProp: (propName) => propName !== 'isPopperOpened',
})<{ isPopperOpened?: boolean }>(({ theme, isPopperOpened }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1.5),
  padding: theme.spacing(1.5, 2.5),
  borderRadius: '590px',
  backgroundColor: isPopperOpened
    ? theme.palette.grey[700]
    : theme.palette.grey[900],
  whiteSpace: 'nowrap',
  userSelect: 'none',
  cursor: 'pointer',

  '& > .MuiTypography-root': {
    color: theme.palette.white.main,
  },

  '& > svg': {
    transform: isPopperOpened ? 'rotate(180deg)' : 'rotate(0deg)',
    '& > path': {
      stroke: theme.palette.grey[600],
    },
  },

  [theme.breakpoints.down('tablet')]: {
    width: '100%',
    justifyContent: 'center',
    padding: theme.spacing(1.5, 1),
  },
}));

export default Wrapper;
