import { LocationCountsDto } from 'openapi-api/admin-service';
import { FilterModel } from 'types/general';

export enum StatusFilterValues {
  TOTAL = 'TOTAL',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  ERROR = 'ERROR',
}

export interface StatusFilterItem {
  label: string;
  value?: StatusFilterValues;
  count?: number;
}

export interface IStatusFilterProps {
  counters?: LocationCountsDto;
  onFilterModelChange: (filterModel: FilterModel) => void;
}
