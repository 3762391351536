import { styled } from '@mui/material';

export const ParametersWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1, 0),
  borderTop: `1px solid ${theme.palette.grey[100]}`,
  borderBottom: `1px solid ${theme.palette.grey[100]}`,
  whiteSpace: 'nowrap',

  [theme.breakpoints.down('desktop')]: {
    flexWrap: 'wrap',
    rowGap: theme.spacing(1.5),

    '& > *:nth-child(2)': {
      gap: theme.spacing(2),
    },

    '& > *:nth-child(3)': {
      gap: theme.spacing(2.5),
      width: '100%',
      marginLeft: 0,
    },
  },
}));
