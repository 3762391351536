import { styled } from '@mui/material';

import { TablePaginationVariants } from '../types';

export const Wrapper = styled('div', {
  shouldForwardProp: (propName) => propName !== 'variant',
})<{ variant: TablePaginationVariants }>(({ theme, variant }) => ({
  position: 'sticky',
  left: 0,
  width: '100%',

  ...(variant === TablePaginationVariants.TABLE && {
    padding: theme.spacing(1.5, 3, 1.5, 4),
    border: `1px solid ${theme.palette.grey[100]}`,
    borderBottomLeftRadius: theme.spacing(1.5),
    borderBottomRightRadius: theme.spacing(1.5),

    [theme.breakpoints.down('desktop')]: {
      paddingLeft: theme.spacing(3),
    },
  }),

  ...(variant === TablePaginationVariants.DEFAULT && {
    padding: theme.spacing(1.5, 0),
  }),

  '& .MuiTablePagination-toolbar': {
    display: 'grid',
    gridTemplateColumns: '0 auto 1fr auto',
    minHeight: 'auto',
    padding: 0,
    fontFamily: 'DM Sans',
  },

  '& .MuiTablePagination-spacer': {
    flex: 0,
  },

  '& .MuiTablePagination-selectLabel': { display: 'none' },

  '& .MuiTablePagination-input': {
    display: 'flex',
    height: '40px',
    width: '140px',
    margin: 0,
    padding: theme.spacing(1, 1.5),
    borderRadius: '12px',
    backgroundColor: theme.palette.grey['50'],

    '& .MuiTablePagination-select': {
      display: 'flex',
      justifyContent: 'left',
      padding: 0,
      ...theme.typography.bodyS,
      color: theme.palette.grey[800],
      '&:focus': {
        backgroundColor: theme.palette.grey['50'],
      },
    },
    '& .MuiTablePagination-selectIcon': {
      width: '16px',
      height: '16px',
      right: theme.spacing(1.5),
      top: 'calc(50% - 8px)',
    },
  },

  '& .MuiTablePagination-displayedRows': {
    justifySelf: 'flex-end',
    margin: 0,
    fontFamily: 'DM Sans',
    fontWeight: 400,
    fontSize: '14px',
    color: theme.palette.grey[600],
  },

  '& .MuiTablePagination-toolbar .MuiTablePagination-actions': {
    marginLeft: theme.spacing(3),

    [theme.breakpoints.down('desktop')]: {
      marginLeft: theme.spacing(1.5),
    },
  },
}));
