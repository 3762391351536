import { FC } from 'react';

interface Props {
  color?: string;
  className?: string;
}

export const Error: FC<Props> = ({ color = '#FF4307', className }) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 4C16.915 4 20.5 7.585 20.5 12C20.5 16.415 16.915 20 12.5 20C8.085 20 4.5 16.415 4.5 12C4.5 7.585 8.085 4 12.5 4ZM13.499 15C13.499 14.448 13.051 14 12.499 14C11.947 14 11.499 14.448 11.499 15C11.499 15.552 11.947 16 12.499 16C13.051 16 13.499 15.552 13.499 15ZM13.499 9C13.499 8.448 13.051 8 12.499 8C11.947 8 11.499 8.448 11.499 9V12C11.499 12.552 11.947 13 12.499 13C13.051 13 13.499 12.552 13.499 12V9Z"
        fill={color}
      />
    </svg>
  );
};
