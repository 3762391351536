import { useCallback, useState } from 'react';

export const useCopyToClipboard = (text: string) => {
  const [copied, setCopied] = useState(false);

  const copyText = useCallback(async () => {
    await navigator.clipboard.writeText(text);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  }, [text]);

  return {
    copied,
    setCopied,
    copyText,
  };
};
