import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

const FormPartContainer = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(5, 7.5, 14, 7.5),
  [theme.breakpoints.down('desktop')]: {
    minHeight: '100vh',
    padding: theme.spacing(3, 2.5, 10, 2.5),
  },
}));

export default FormPartContainer;
