import { styled } from '@mui/material/styles';

import { WindTurbineDtoStatusEnum } from 'openapi-api/admin-service';

export const TableCellStatusLine = styled('div')<{
  windTurbineStatus?: WindTurbineDtoStatusEnum;
  isFirstRow: boolean;
}>(({ theme, windTurbineStatus, isFirstRow }) => {
  return {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '6px',
    height: '100%',
    borderRadius: theme.spacing(0, 0.5, 0.5, 0),
    borderTopLeftRadius: isFirstRow ? '16px' : 0,
    backgroundColor:
      windTurbineStatus === WindTurbineDtoStatusEnum.ERROR
        ? theme.palette.warning[20]
        : 'transparent',
  };
});
