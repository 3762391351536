import { styled } from '@mui/material/styles';

import publicPath from '../../../../utils/publicPath';

const LoginContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.black['600'],
  backgroundImage: `url(${publicPath('/images/login-background.png')})`,
  backgroundSize: 'cover',
  minHeight: '100%',
  width: '100%',
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  [theme.breakpoints.down('desktop')]: {
    backgroundImage: `url(${publicPath(
      '/images/login-background-tablet.png',
    )})`,
  },
  [theme.breakpoints.down('tablet')]: {
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'auto min-content',
    backgroundImage: `url(${publicPath(
      '/images/login-background-mobile.png',
    )})`,
  },
}));

export default LoginContainer;
