import { styled } from '@mui/material';

import { BadgeSize, BadgeVariant } from '../types';

const BadgeWrapper = styled('div')<{ variant: BadgeVariant; size: BadgeSize }>(
  ({ theme, variant, size }) => {
    let padding: string;
    let backgroundColor: string | undefined;
    let circleColor: string | undefined;
    let typography;

    switch (size) {
      case BadgeSize.Meduim:
        padding = theme.spacing(0.5, 1.5, 0.5, 1);
        typography = theme.typography.bodyM;
        break;
      case BadgeSize.Small: {
        padding = theme.spacing(0, 1.5, 0, 0.5);
        typography = theme.typography.bodyS;
      }
    }

    switch (variant) {
      case BadgeVariant.Active:
        backgroundColor = theme.palette.green[200];
        circleColor = theme.palette.green[600];
        break;
      case BadgeVariant.Error:
        backgroundColor = theme.palette.warning[200];
        circleColor = theme.palette.error.main;
        break;
      case BadgeVariant.Inactive:
        backgroundColor = theme.palette.grey[50];
        circleColor = theme.palette.grey[200];
        break;
      case BadgeVariant.Warning:
        backgroundColor = theme.palette.yellow[200];
        circleColor = theme.palette.warning.main;
    }

    return {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1.5),
      padding,
      borderRadius: '21px',
      backgroundColor,

      '&::before': {
        content: '""',
        height: '12px',
        width: '12px',
        backgroundColor: circleColor,
        borderRadius: '50%',
      },

      '& > .MuiTypography-root': {
        ...typography,
      },
    };
  },
);

export default BadgeWrapper;
