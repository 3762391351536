import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const MenuButtonWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
}));

export default MenuButtonWrapper;
