import { styled } from '@mui/material';

export const WeatherSlideWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(1),
  height: '150px',
  padding: theme.spacing(1.5),
  borderRadius: '12px',
  backgroundColor: theme.palette.white.main,
}));
