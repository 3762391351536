import { alpha, styled } from '@mui/material';

export const UserMenuWrapper = styled('div', {
  shouldForwardProp: (propName) => propName !== 'open',
})<{ open?: boolean }>(({ theme, open }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1.5),
  padding: theme.spacing(0.75, 2.5),
  borderRadius: '56px',
  backgroundColor: alpha(theme.palette.white.main, 0.1),
  cursor: 'pointer',

  '& path': {
    stroke: theme.palette.grey[500],
  },

  ...(open && {
    backgroundColor: alpha(theme.palette.white.main, 0.2),
    '& .MuiTypography-root': {
      color: theme.palette.white.main,
    },
    '& svg': {
      transform: 'rotate(180deg)',
    },
    '& path': {
      stroke: theme.palette.white.main,
    },
  }),
}));
