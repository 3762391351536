import React, { FC } from 'react';

interface ComposeProps {
  components: React.JSXElementConstructor<React.PropsWithChildren<any>>[];
  children: React.ReactNode;
}

export const Compose: FC<ComposeProps> = (props) => {
  const { components = [], children } = props;

  return (
    <>
      {components.reduceRight((acc, ComponentItem) => {
        return <ComponentItem>{acc}</ComponentItem>;
      }, children)}
    </>
  );
};
