import { styled } from '@mui/material';

import { OpenLink } from 'components/icons';

export const LinkIcon = styled(OpenLink)(({ theme }) => ({
  width: '18px',
  height: '18px',
  '& > path': {
    stroke: theme.palette.black[500],
    strokeWidth: 3,
  },
}));
