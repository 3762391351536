import { ParksSortingOrder, ParksSortingValues } from '../../types';
import { TranslationFunction } from 'types/general';

export const getSortingItems = (t: TranslationFunction) => [
  {
    label: t`pages.parks.sorting.lastAdded`,
    value: `${ParksSortingValues.addedDatetime},${ParksSortingOrder.desc}`,
  },
  {
    label: t`pages.parks.sorting.firstAdded`,
    value: `${ParksSortingValues.addedDatetime},${ParksSortingOrder.asc}`,
  },
  {
    label: t`pages.parks.sorting.highActiveEnergy`,
    value: `${ParksSortingValues.activeEnergy},${ParksSortingOrder.desc}`,
  },
  {
    label: t`pages.parks.sorting.lowActiveEnergy`,
    value: `${ParksSortingValues.activeEnergy},${ParksSortingOrder.asc}`,
  },
  {
    label: t`pages.parks.sorting.highLostEnergy`,
    value: `${ParksSortingValues.lostEnergy},${ParksSortingOrder.desc}`,
  },
  {
    label: t`pages.parks.sorting.lowLostEnergy`,
    value: `${ParksSortingValues.lostEnergy},${ParksSortingOrder.asc}`,
  },
];
